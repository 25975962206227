import examResultListStore from '@/modules/exam-result/exam-result-list-store';
import examResultViewStore from '@/modules/exam-result/exam-result-view-store';
import examResultFormStore from '@/modules/exam-result/exam-result-form-store';
import examResultDestroyStore from '@/modules/exam-result/exam-result-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: examResultDestroyStore,
    form: examResultFormStore,
    list: examResultListStore,
    view: examResultViewStore,
  },
};
