<template>
  <div>
    <el-menu-item
      :class="classFor('/score-output/one/edit')"
      :route="{ path: '/score-output/one/edit' }"
      index="/score-output/one/edit"
    >
      <i class="el-icon-s-order"></i>
      <span slot="title">
        <app-i18n code="entities.readExam.menu"></app-i18n>
      </span>
    </el-menu-item>
    <el-menu-item
      :class="classFor('/score-output')"
      :route="{ path: '/score-output' }"
      index="/score-output"
    >
      <i class="el-icon-fa-asterisk"></i>
      <span slot="title">
        <app-i18n
          code="entities.scoreOutput.menu"
        ></app-i18n>
      </span>
    </el-menu-item>
    <el-menu-item
      :class="classFor('/question')"
      :route="{ path: '/question' }"
      index="/question"
    >
      <i class="el-icon-fa-tasks"></i>
      <span slot="title">
        <app-i18n code="entities.question.menu"></app-i18n>
      </span>
    </el-menu-item>
    <el-menu-item
      :class="classFor('/exam')"
      :route="{ path: '/exam' }"
      index="/exam"
    >
      <i class="el-icon-fa-book"></i>
      <span slot="title">
        <app-i18n code="entities.exam.menu"></app-i18n>
      </span>
    </el-menu-item>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'menu-teacher-base.vue',
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
    }),

    asideWidth() {
      if (!this.collapsed) {
        return '200px'; //mobil icin 200px fazlasi iyi durmadi
      }

      return '0px';
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active =
        routePath === path ||
        routePath.startsWith(path + '/');

      // console.log("RoutePath: ", routePath);
      // console.log("Path: ", path);
      // console.log("Active: ", active);
      return {
        active,
      };
    },
  },
};
</script>

<style scoped>
.el-menu.collapsed span {
  visibility: hidden;
}
.el-menu {
  background-color: #a51e36;
}
.el-menu-item,
.el-menu-item i,
.el-submenu__title,
.el-submenu__title i {
  color: white;
}

.el-menu-item:hover,
.el-submenu:hover {
  background-color: #c8c8c833;
}

.el-submenu__icon-arrow .el-icon-arrow-down {
  color: white !important;
}

.el-submenu .el-menu-item,
.el-submenu .el-menu-item i {
  color: #a51e36;
}

.el-submenu__title:hover {
  background-color: #c8c8c833;
}
</style>
