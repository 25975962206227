<template>
  <div class="card-body">
    <h5 :class="('subtitle' + checkRTL).trim()">
      {{ questionDescription }}
    </h5>
    <div class="body-inner">
      <slot></slot>
    </div>
    <div class="body-nav text-right">
      <next />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Next from './next';

export default {
  name: 'question-body',
  components: { Next },
  data() {
    return {
      viewButton: false,
    };
  },
  computed: {
    ...mapGetters({
      questionDescription:
        'onlineExam/main/questionDescription',
      checkRTL: 'onlineExam/main/checkRTL',
    }),
  },
};
</script>

<style scoped>
.card-body {
  color: rgba(0, 0, 0, 0.87);
}

.body-inner {
  padding: 2rem 1rem;
  margin: 0 3rem;
  border-radius: 1rem;
}
</style>

<style>
h5.subtitle {
  font-weight: normal;
  font-size: 0.95rem;
  max-width: 1200px;
  margin: 1.5rem 0 1.2rem 0;
  line-height: 1.5;
}

.body-nav {
  padding-top: 1.2rem;
  padding-right: 3.5rem;
}
</style>
