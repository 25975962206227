<template>
  <div>{{ sayac | formatDurationFilter }}</div>
</template>

<script>
export default {
  name: 'topTimer',
  data: () => {
    return {
      counter: 0,
    };
  },
  computed: {
    sayac() {
      if (
        this.max > 0 &&
        this.max <= this.duration + this.counter
      ) {
        this.$timer.stop('log');
        this.$emit('stop');
      }
      return this.duration + this.counter;
    },
  },
  timers: {
    log: {
      time: 1000,
      autostart: true,
      repeat: true,
    },
  },
  methods: {
    log() {
      this.counter++;
    },
  },
  props: {
    duration: Number,
    max: {
      default: 0,
      type: Number,
    },
  },
  watch: {
    //function(newVal, oldVal) seklinde mudahale de edebiliyoruz.
    duration: function() {
      this.counter = 0; //duration guncellenince counter sifirlanmali
    },
  },
};
</script>

<style scoped></style>
