import authAxios from '@/shared/axios/auth-axios';
import { i18n } from '@/i18n';
import Message from '@/shared/message/message';
export class QuestionGroupService {
  static async update(id, data) {
    const body = {
      id,
      data
    };

    const response = await authAxios.put(`/question-group/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/question-group`, {
      params
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/question-group`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash
    };

    const response = await authAxios.post(`/question-group/import`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/question-group/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/question-group`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit
    };

    const response = await authAxios.get(`/question-group/autocomplete`, {
      params
    });
    try {
      const questionGroups = response.data.map(ql => {
        const lblTemp = i18n(
          `entities.question.enumerators.questionGroup.${ql.label}`
        );
        return {
          label: lblTemp.includes('entities.question.enumerators.questionGroup')
            ? ql.label
            : lblTemp,
          id: ql.id
        };
      });
      return questionGroups;
    } catch (e) {
      Message.error('Question Group language conversion error: ', e.message);
    }

    return response.data;
  }
}
