import Roles from '@/security/roles';
const roles = Roles.values;

class AuditLogPermissions {
  static get values() {
    return {
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.owner, roles.admin],
      },
    };
  }
}

export default AuditLogPermissions;
