<template>
  <div>
    <app-autocomplete-one-input
      :disabled="disabled"
      :fetchFn="fetchFn"
      v-if="mode !== 'multiple'"
      v-model="model"
    ></app-autocomplete-one-input>
    <app-autocomplete-many-input-ls
      :disabled="disabled"
      key="iammany"
      :fetchFn="fetchFn"
      @fetched="onfetched"
      v-if="mode === 'multiple'"
      v-model="model"
      :placeholder="placeholder"
      :showCreate="showCreate"
      :text="text"
    ></app-autocomplete-many-input-ls>
    <portal to="modal">
      <app-iam-new-form-modal
        :visible="dialogVisible"
        @close="onModalClose"
        @success="onModalSuccess"
        v-if="dialogVisible"
      ></app-iam-new-form-modal>
    </portal>
  </div>
</template>

<script>
import IamNewFormModal from '@/modules/iam/components/iam-new-form-modal';
import { IamPermissions } from '@/modules/iam/iam-permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'app-iam-user-autocomplete-input',
  props: [
    'value',
    'mode',
    'fetchFn',
    'mapperFn',
    'showCreate',
    'disabled',
    'text',
    'placeholder'
  ],

  components: {
    [IamNewFormModal.name]: IamNewFormModal
  },

  data() {
    return {
      dialogVisible: false
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    model: {
      get: function() {
        return this.value;
      },

      set: function(value) {
        this.$emit('input', value);
      }
    },

    hasPermissionToCreate() {
      return new IamPermissions(this.currentUser).create;
    }
  },

  methods: {
    doOpenModal() {
      this.dialogVisible = true;
    },

    onfetched(value) {
      this.$emit('fetched', value);
    },
    onModalSuccess(record) {
      if (this.mode === 'multiple') {
        this.model = [...this.model, this.mapperFn(record)];
      } else {
        this.model = this.mapperFn(record);
      }

      this.onModalClose();
    },
    onModalClose() {
      this.dialogVisible = false;
    },
  }
};
</script>

<style>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
