<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
        :class="checkRTL"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input :disabled="true" v-model="model[fields.id.name]" />
        </el-col>
      </el-form-item>
      <el-form-item
        :label="fields.type.label"
        :prop="fields.type.name"
        :required="fields.type.required"
        :class="checkRTL"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-select placeholder v-model="model[fields.type.name]">
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.type.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.message.label"
        :prop="fields.message.name"
        :required="fields.message.required"
        :class="checkRTL"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            :rows="4"
            type="textarea"
            v-model="model[fields.message.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.images.label"
        :prop="fields.images.name"
        :required="fields.images.required"
        :class="checkRTL"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-image-upload
            :max="fields.images.max"
            :path="fields.images.path"
            :schema="fields.images.fileSchema"
            v-model="model[fields.images.name]"
          ></app-image-upload>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.save"></app-i18n>
            </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.reset"></app-i18n>
            </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.cancel"></app-i18n>
            </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { SupportRequestsModel } from '@/modules/support-requests/support-requests-model';

const { fields } = SupportRequestsModel;
const formSchema = new FormSchema([
  fields.id,
  fields.type,
  fields.message,
  fields.images
]);

export default {
  name: 'app-support-requests-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },
  mounted() {
    if (this.isEditing && this.record.type === 'Comment')
      this.model.message = JSON.parse(this.model.message).message;
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'supportRequests/form/checkRTL',
      rtlAndPadding: 'supportRequests/form/rtlAndPadding',
    }),

    fields() {
      return fields;
    }
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      if (this.isEditing && this.record.type === 'Comment') {
        const originalVal = JSON.parse(this.record.message);
        originalVal.message = this.model.message;
        this.model.message = JSON.stringify(originalVal);
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values
      });
    }
  }
};
</script>

<style></style>
