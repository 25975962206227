import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const OrderListPage = () =>
  import('@/modules/order/components/order-list-page.vue');
const OrderViewPage = () =>
  import('@/modules/order/components/order-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'order',
        path: '/order',
        component: OrderListPage,
        meta: {
          auth: true,
          permission: Permissions.values.orderView,
        },
      },
      {
        name: 'orderView',
        path: '/order/:id',
        component: OrderViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.orderView,
        },
        props: true,
      },
    ],
  },
];
