import iamListStore from '@/modules/iam/iam-list-store';
import iamFormStore from '@/modules/iam/iam-form-store';
import iamViewStore from '@/modules/iam/iam-view-store';
import iamImporterStores from '@/modules/iam/iam-importer-store';

const {
  sImporterStore,
  tImporterStore,
} = iamImporterStores;

export default {
  namespaced: true,

  modules: {
    list: iamListStore,
    form: iamFormStore,
    view: iamViewStore,
    studentImporter: sImporterStore,
    teacherImporter: tImporterStore,
  },
};
