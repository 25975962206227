<template>
  <div class="q-wrap">
    <div class="q-content">
      <div>
        <landing-header
          :class="checkRTL.trim()"
          :title="title"
          :iconType="iconType"
        />
        <landing-body
          :description="description"
          :nextLink="nextLink"
          :viewButton="viewButton"
          :checkRTL="checkRTL"
          :examLanguage= "getExamLanguage"
        >
          <slot></slot>
        </landing-body>
      </div>
    </div>
    <landing-footer />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'landing-card',
  computed: {
    ...mapGetters({
      currentExam: 'onlineExam/main/currentExam',
    }),
    getExamLanguage() {
      if (this.currentExam)
        return this.currentExam.examLanguage.code;
      else return 'en';
    },
    currentId() {
      return this.$route.params.id;
    },
    computedNextLink() {
      return this.$route.params.id;
    },
  },

  props: {
    title: String,
    iconType: String,
    description: String,
    nextLink: { type: String, default: '' },
    viewButton: { required: false, default: true },
    checkRTL: String,
  },
};
</script>

<style lang="stylus" scoped>
.q-wrap {
  min-height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.q-content {
  display: flex;
  justify-content: center;
  align-items: stretch;

  > div {
    margin: 2.75rem -1.25rem;
    padding: 1rem 0rem;
    max-width: 1080px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    min-width: 600px;
  }
}
</style>
