import authAxios from '@/shared/axios/auth-axios';

export class StructureService {
  static async update(id, data) {
    data = this.buildStructureData(data);
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/structure/${id}`,
      body,
    );

    return response.data;
  }

  /***
   * Struct Item'i Gunceller
   * ***/
  static async updateItem(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/struct-item/${id}`,
      body,
    );

    return response.data;
  }

  static async updateItemOrder(id,data) {
     const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/struct-item/${id}/order`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/structure`, {
      params,
    });

    return response.data;
  }

  static async removeItems(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(
      `/struct-item`,
      {
        params,
      },
    );

    return response.data;
  }

  static async removeAllItems(structureId) {
    const params = {
      structureId,
    };

    const response = await authAxios.delete(
      `/struct-item/all`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/structure`,
      body,
    );

    return response.data;
  }

  static async addItem(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/struct-item`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/structure/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(
      `/structure/${id}`,
    );
    return this.spreadStructureData(response.data);
  }

  static async findItem(id) {
    const response = await authAxios.get(
      `/struct-item/${id}`,
    );
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/structure`, {
      params,
    });

    return response.data;
  }

  // static async listItems(structureId, filter, orderBy, limit, offset) {
  //   const params = {
  //     filter,
  //     orderBy,
  //     limit,
  //     offset,
  //   };
  //
  //   const response = await authAxios.get(`/struct-item`, {
  //     params,
  //   });
  //
  //   return response.data;
  // }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/structure/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static spreadStructureData(record) {
    record.hasBreak = false;
    if (record && record.structureData) {
      const breaks = record.structureData.breaks;
      if (breaks && breaks.length > 0) {
        record.hasBreak = true;
        record.breakBefore = breaks[0].breakBefore;
        record.breakLimit = breaks[0].breakLimit;
      }
    }
    return record;
  }

  static buildStructureData(data) {
    if (data.hasBreak === true) {
      const { breakBefore, breakLimit } = data;
      data.structureData = {
        breaks: [{ breakBefore, breakLimit }],
      };
    } else {
      data.structureData = { breaks: [] };
    }
    return data;
  }
}
