import { AuthToken } from '@/modules/auth/auth-token';
import Axios from 'axios';
import Qs from 'qs';
import * as moment from 'moment';
import config from '@/config';
import { getLanguageCode } from '@/i18n';

//axios instance olusturma
const authAxios = Axios.create({
  baseURL: config.backendUrl,
  paramsSerializer: function(params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (value instanceof moment || value instanceof Date) {
          return value.toISOString();
        }

        return value;
      }
    });
  }
});

//isteklerde token ve dili header'a ekle
authAxios.interceptors.request.use(
  async function(options) {
    const token = await AuthToken.get();

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }
    // options.headers['Access-Control-Allow-Origin'] = 'true';
    // console.log('Access control allow origin eklendi');
    options.headers['Accept-Language'] = getLanguageCode();

    return options;
  },
  function(error) {
    return Promise.reject(error);
  }
);

export default authAxios;
