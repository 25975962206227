import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import { i18n } from '@/i18n';

export default class RelationToManyField extends GenericField {
  constructor(
    name,
    label,
    viewUrl,
    readPermission,
    fetchFn,
    mapperFn,
    { required = false, min = undefined, max = undefined } = {}
  ) {
    super(name, label);

    this.required = required;
    this.min = min;
    this.max = max;

    if (required && (!min || min < 1)) {
      this.min = 1;
    }

    this.fetchFn = fetchFn;
    this.mapperFn = mapperFn;
    this.viewUrl = viewUrl;
    this.readPermission = readPermission;
  }

  forFilterRules() {
    return undefined;
  }
  forFilterInitialValue(value) {
    //console.log("filter initial",value);
    return this.mapperFn(value);
  }
  forFilterCast() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }
        return originalValue.map(o => o.id);
      });
  }

  forPresenter(value) {
    if (!value) {
      return [];
    }

    return value.map(item => this.mapperFn(item));
  }

  forFormInitialValue(value) {
    return this.forPresenter(value);
  }

  forFormRules() {
    const output = [];

    if (this.required) {
      output.push({
        type: 'array',
        required: !!this.required,
        message: i18n('validation.mixed.required').replace(
          '${path}',
          this.label
        )
      });
    }

    if (this.min || this.min === 0) {
      output.push({
        type: 'array',
        min: this.min,
        message: i18n('validation.array.min')
          .replace('${path}', this.label)
          .replace('${min}', this.min)
      });
    }

    if (this.max || this.max === 0) {
      output.push({
        type: 'array',
        max: this.max,
        message: i18n('validation.array.max')
          .replace('${path}', this.label)
          .replace('${max}', this.max)
      });
    }

    return output;
  }

  forFormCast() {
    return this.forFormCastCustom('id');
  }

  forFormCastCustom(keyStr) {
    let yupChain = yup
      .array()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return [];
        }
        return originalValue.map(item => item[keyStr]);
      });

    return yupChain;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue || !originalValue.length) {
          return null;
        }

        return originalValue.map(value => value.id).join(' ');
      });
  }

  forImport() {
    let yupChain = yup
      .array()
      .nullable(true)
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return null;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return originalValue
          .trim()
          .split(' ')
          .map(value => {
            return value;
          });
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    if (this.min || this.min === 0) {
      yupChain = yupChain.min(this.min);
    }

    if (this.max) {
      yupChain = yupChain.max(this.max);
    }

    return yupChain;
  }
}
