<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
      :class="checkRTL"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input :disabled="true" v-model="model[fields.id.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.exam.label"
        :prop="fields.exam.name"
        :required="fields.exam.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-exam-autocomplete-input
            :fetchFn="fields.exam.fetchFn"
            :mapperFn="fields.exam.mapperFn"
            v-model="model[fields.exam.name]"
            mode="single"
            :disabled="!isOwner"
          ></app-exam-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.student.label"
        :prop="fields.student.name"
        :required="fields.student.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-iam-user-autocomplete-input
            :fetchFn="fields.student.fetchFn"
            :mapperFn="fields.student.mapperFn"
            v-model="model[fields.student.name]"
            mode="single"
            :disabled="!isOwner"
          ></app-iam-user-autocomplete-input>
        </el-col>
      </el-form-item>

      <!--      <el-form-item-->
      <!--              :label="fields.aftermath.label"-->
      <!--              :prop="fields.aftermath.name"-->
      <!--              :required="fields.aftermath.required"-->
      <!--      >-->
      <!--        <el-col :lg="11" :md="16" :sm="24">-->
      <!--          <el-input-number :precision="0" :step="1" v-model="model[fields.aftermath.name]"></el-input-number>-->
      <!--        </el-col>-->
      <!--      </el-form-item>-->

      <el-form-item
        :label="fields.reading.label"
        :prop="fields.reading.name"
        :required="fields.reading.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            @change="sectionChanged"
            :precision="0"
            :step="1"
            v-model="model[fields.reading.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.writing.label"
        :prop="fields.writing.name"
        :required="fields.writing.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            @change="sectionChanged"
            :precision="0"
            :step="1"
            v-model="model[fields.writing.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.speaking.label"
        :prop="fields.speaking.name"
        :required="fields.speaking.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            @change="sectionChanged"
            :precision="0"
            :step="1"
            v-model="model[fields.speaking.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.listening.label"
        :prop="fields.listening.name"
        :required="fields.listening.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            @change="sectionChanged"
            :precision="0"
            :step="1"
            v-model="model[fields.listening.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.aftermath.label"
        :prop="fields.aftermath.name"
        :required="fields.aftermath.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :disabled="true"
            :precision="0"
            :step="1"
            v-model="model[fields.aftermath.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.grammar.label"
        :prop="fields.grammar.name"
        :required="fields.grammar.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.grammar.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.oralFluency.label"
        :prop="fields.oralFluency.name"
        :required="fields.oralFluency.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.oralFluency.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.pronunciation.label"
        :prop="fields.pronunciation.name"
        :required="fields.pronunciation.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.pronunciation.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.spelling.label"
        :prop="fields.spelling.name"
        :required="fields.spelling.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.spelling.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.vocabulary.label"
        :prop="fields.vocabulary.name"
        :required="fields.vocabulary.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.vocabulary.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.writtenDiscourse.label"
        :prop="fields.writtenDiscourse.name"
        :required="fields.writtenDiscourse.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.writtenDiscourse.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.resultStatus.label"
        :prop="fields.resultStatus.name"
        :required="fields.resultStatus.required"
        v-if="isEditing"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.resultStatus.name]"
            :disabled="true"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.resultStatus.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <!--      <el-form-item-->
      <!--        :label="fields.results.label"-->
      <!--        :prop="fields.results.name"-->
      <!--        :required="fields.results.required"-->
      <!--      >-->
      <!--        <el-col :lg="11" :md="16" :sm="24">-->
      <!--          <el-input v-model="model[fields.results.name]" />-->
      <!--        </el-col>-->
      <!--      </el-form-item>-->

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.save"></app-i18n>
            </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.reset"></app-i18n>
            </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.cancel"></app-i18n>
            </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ExamResultModel } from '@/modules/exam-result/exam-result-model';

const { fields } = ExamResultModel;
const formSchema = new FormSchema([
  fields.id,
  fields.exam,
  fields.student,
  fields.aftermath,
  fields.reading,
  fields.speaking,
  fields.writing,
  fields.listening,
  fields.grammar,
  fields.oralFluency,
  fields.pronunciation,
  fields.spelling,
  fields.vocabulary,
  fields.writtenDiscourse,
  fields.resultStatus
  //fields.results,
]);

export default {
  name: 'app-exam-result-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      isOwner: 'auth/isOwner',
      checkRTL: 'examResult/form/checkRTL',
      rtlAndPadding: 'examResult/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    }
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values
      });
    },

    sectionChanged() {
      const total =
        this.model[this.fields.listening.name] +
        this.model[this.fields.reading.name] +
        this.model[this.fields.speaking.name] +
        this.model[this.fields.writing.name];
      this.model[this.fields.aftermath.name] = Math.floor(total / 4);
    }
  }
};
</script>

<style></style>
