import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ScoreOutputPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.scoreOutputRead,
    );
    this.scoreOutputAutocomplete = permissionChecker.match(
      Permissions.values.scoreOutputAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.scoreOutputCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.scoreOutputEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.scoreOutputDestroy,
    );
  }
}
