import authAxios from '@/shared/axios/auth-axios';

export class ScoreOutputService {
  static async update(id, data) {
    const body = {
      id,
      data
    };

    const response = await authAxios.put(`/score-output/${id}`, body);

    return response.data;
  }

  static async publish() {
    const body = {};

    await authAxios.put(`/score-output/pub`, body);
  }

  static async publishAll() {
    const body = {};

    const response = await authAxios.put(`/score-output/pubAll`, body);
    //console.log('Datasini dondugun veri: ', response);
    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/score-output`, {
      params
    });

    return response.data;
  }

  static async assignPapers(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/assign-papers`, body);

    return response.data;
  }

  static async setPapersAsUnScored(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/unscore-papers`, body);

    return response.data;
  }

  static async deleteUnscoredPapers(_data) {
    const body = {
      data: {
        examPapers: _data
      }
    };

    await authAxios.delete(`/unscore-papers`, body);
  }

  static async deleteUncompletedPapers(_data){
    const body = {
      data: {
        examPapers: _data
      }
    };
    await authAxios.delete(`/uncompleted-papers`, body);
  }

  static async assignUncompletedPapers(data){
    const body = {
      data
    };
    //::console.log("AsUnc " + JSON.stringify(body))
    const response = await authAxios.post(`/assign-uncompleted`, body);

    return response.data;
  }

  static async reassignScores(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/reassign-scores`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/score-output/${id}`);
    return response.data;
  }

  static async doFindWithQuestion(id) {
    let response = null;
    if (id === 'one') {
      response = await authAxios.get(`/score-output/one`);
    } else {
      response = await authAxios.get(`/score-output/wq/${id}`);
    }
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/score-output`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit, filter = null) {
    const params = {
      query,
      limit,
      filter
    };

    const response = await authAxios.get(`/score-output/autocomplete`, {
      params
    });

    return response.data;
  }
}
