//const backendUrl = `https://apiexam.linspark.com/api`; //'http://3.65.57.62:8080/api'; //`/api`;
const backendUrl = `/api`;
const ihuPaymentService = 'https://api.ihu.edu.tr/api';
const RECAPTCHA_SITEKEY = '6LdkxtkbAAAAAExLqqUDQ4Md8GCVQSC2XsuFwGMt';
const logo = { style:'ls-logo', path: '/images/ls-logo.svg' };
export default {
  backendUrl,
  ihuPaymentService,
  RECAPTCHA_SITEKEY,
  logo
};
