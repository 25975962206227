import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class QuestionTypePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.questionTypeRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.questionTypeImport,
    );
    this.questionTypeAutocomplete = permissionChecker.match(
      Permissions.values.questionTypeAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.questionTypeCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.questionTypeEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.questionTypeDestroy,
    );
  }
}
