import authAxios from '@/shared/axios/auth-axios';

export class ExamService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/exam/${id}`,
      body,
    );

    return response.data;
  }

  static async updateManualQuestions(id, data, deleted) {
    const body = {
      id,
      data,
      deleted
    };

    await authAxios.put(
        `/exam/${id}/questions`,
        body,
    );
  }


  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/exam`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/exam`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/exam/import`,
      body,
    );

    return response.data;
  }

  static async uploadRecord(values) {
    const body = {
      data: values,
    };
    const response = await authAxios.post(
      `/upload/exam/records`,
      body,
    );
    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/exam/${id}`);
    return response.data;
  }

  static async getQuestion(examId, questionId) {
    const response = await authAxios.get(
      `/exam/${examId}/question/${questionId}`,
    );
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/exam`, {
      params,
    });

    return response.data;
  }

  static async listTest() {
    const params = { };

    const response = await authAxios.get(`/test`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/exam/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
