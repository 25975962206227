import Roles from '@/security/roles';
const roles = Roles.values;

class ExamPermissions {
  static get values() {
    return {
      examCreate: {
        id: 'examCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
        allowedStorageFolders: ['exam'],
      },
      examEdit: {
        id: 'examEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
        allowedStorageFolders: ['exam'],
      },
      examDestroy: {
        id: 'examDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
        allowedStorageFolders: ['exam'],
      },
      examRead: {
        id: 'examRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.canCreateExam,
          roles.student,
        ],
      },
      examAutocomplete: {
        id: 'examAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
      },
    };
  }
}

export default ExamPermissions;
