import { StructureService } from '@/modules/structure/structure-service';
import Errors from '@/shared/error/errors';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    //filter: {},
    pagination: {},
    sorter: {},
    table: null,
    structureId: null,
    structureSource: null
  },

  getters: {
    loading: state => state.loading,

    exportLoading: state => state.exportLoading,

    rows: state => state.rows || [],

    count: state => state.count,

    hasRows: (state, getters) => getters.count > 0,

    structureId: state => state.structureId,

    orderBy: state => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    //filter: (state) => state.filter,

    limit: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true
      };
    },

    selectedRows: state => {
      return state.table ? state.table.selection : [];
    },
    structureSource: state => {
      return state.structureSource;
    }
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      //state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      // state.filter =
      //   payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
      state.structureId = payload.structureId;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },

    STRUCTURE_SOURCE(state, payload) {
      state.structureSource = payload;
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FETCH_STARTED');

        const record = await StructureService.find(id);

        commit('FETCH_SUCCESS', {
          rows: record.structItems,
          count: record.structItems.length,
          structureId: id
        });
      } catch (error) {
        Errors.showMessage(error);
        commit('FETCH_ERROR');
      }
    },

    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    doChangePagination({ commit, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      //const filter = getters.filter;
      //dispatch('doFetch', { filter, keepPagination: true });
      dispatch('doFetch', { keepPagination: true });
    },

    doChangePaginationPageSize({ commit, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      //const filter = getters.filter;
      //dispatch('doFetch', { filter, keepPagination: true });
      dispatch('doFetch', { keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      //const filter = getters.filter;
      //dispatch('doFetch', { filter, keepPagination: true });
      dispatch('doFetch', { keepPagination: true });
    },

    doChangeSort({ commit, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      //const filter = getters.filter;
      //dispatch('doFetch', { filter, keepPagination: true });
      dispatch('doFetch', { keepPagination: true });
    },

    async doFetch(
      { commit, getters, rootGetters },
      { filter, keepPagination } = {}
    ) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        // const response = await StructureService.list(
        //   filter,
        //   getters.orderBy,
        //   getters.limit,
        //   getters.offset,
        // );

        let structure =
          rootGetters[`structure/${getters.structureSource}/record`];
        if (!structure) {
          //todo veri cekilebilir
          commit('FETCH_ERROR');
          return;
        }
        const response = structure.structItems;
        commit('FETCH_SUCCESS', {
          rows: response,
          count: response.length,
          structureId: structure.id
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    }
  }
};
