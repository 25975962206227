import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import StringArrayField from '@/shared/fields/string-array-field';
import StringMultiArrayField from '@/shared/fields/string-multi-array-field';
import IntegerField from '@/shared/fields/integer-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { QuestionTypeField } from '@/modules/question-type/question-type-field';
import { QuestionField } from '@/modules/question/question-field';
import { UserField } from '@/modules/auth/user-field';
import FilesField from '@/shared/fields/files-field';
import ImagesField from '@/shared/fields/images-field';
import { QuestionGroupField } from '../question-group/question-group-field';
import { LanguageField } from '../language/language-field';

function label(name) {
  return i18n(`entities.question.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.question.enumerators.${name}.${value}`);
  //return label.includes('entities.question.enumerators') ? value : label;
}

const fields = {
  id: new IdField('id', label('id')),
  keywords: new StringField('keywords', label('keywords'), {
    required: true
  }),
  audio: new FilesField('audio', label('audio'), 'questions/audio', {
    fileTypes: ['audio/*']
  }),
  image: new ImagesField('image', label('image'), 'questions/image', {
    size: 3145728
  }),
  options: new StringMultiArrayField('options', label('options')),
  extraOptions: new StringArrayField('extraOptions', label('extraOptions')),
  optionsAnswer: new StringArrayField('optionsAnswer', label('optionsAnswer')),
  optionsAnswerSynonyms: new StringMultiArrayField(
    'optionsAnswerSynonyms',
    label('optionsAnswerSynonyms')
  ),
  selections: new StringArrayField('selections', label('selections')),
  selection: new StringField('selection', label('selection')),
  input: new StringField('input', label('input')), // new selection
  inputs: new StringArrayField('inputs', label('inputs')), // new selections
  question: new StringField('question', label('question'), {
    required: true
  }),
  subtitle: new StringField('subtitle', label('subtitle')),
  editor: new StringField('editor', label('editor')),
  createdById: UserField.relationToOne('createdBy', label('createdById'), {}),
  questionType: QuestionTypeField.relationToOne(
    'questionType',
    label('questionType'),
    {
      required: true
    }
  ),
  questionGroups: QuestionGroupField.relationToMany(
    'questionGroups',
    label('questionGroups'),
    {
      required: true
    }
  ),
  questionLanguage: LanguageField.relationToOne(
    'questionLanguage',
    label('questionLanguage'),
    {
      required: true
    }
  ),
  description: new StringField(
    'description',
    label('description'),
    {
      required: true
    }
  ),
  initialTime: new IntegerField('initialTime', label('initialTime')),
  readingTime: new IntegerField('readingTime', label('readingTime')),
  time: new IntegerField('time', label('time')),
  recordTime: new IntegerField('recordTime', label('recordTime')),
  questionStatus: new EnumeratorField(
    'questionStatus',
    label('questionStatus'),
    [
      {
        id: '1',
        label: enumeratorLabel('questionStatus', 'draft')
      },
      {
        id: '3',
        label: enumeratorLabel('questionStatus', 'published')
      },
      {
        id: '9',
        label: enumeratorLabel('questionStatus', 'obsolete')
      }
    ],
    {
      required: true
    }
  ),
  section: new EnumeratorField(
    'section',
    label('section'),
    [
      {
        id: 'reading',
        label: enumeratorLabel('section', 'reading')
      },
      {
        id: 'listening',
        label: enumeratorLabel('section', 'listening')
      },
      {
        id: 'writing',
        label: enumeratorLabel('section', 'writing')
      },
      {
        id: 'speaking',
        label: enumeratorLabel('section', 'speaking')
      }
    ],
    {
      required: true
    }
  ),
  allowedUsers: UserField.relationToMany(
    'allowedUsers',
    label('allowedUsers'),
    {
      required: true
    }
  ),
  preVersions: QuestionField.relationToMany(
    'preVersions',
    label('preVersions'),
    {}
  ),
  data: new StringField('data', label('data'), {
    required: true
  }),
  answer: new StringField('answer', label('answer')),
  answerHint: new StringField('answerHint', label('answerHint')),
  transcribe: new StringField('transcribe', label('transcribe')),
  src: new StringField('src', label('src')),
  lockUntil: new DateTimeField('lockUntil', label('lockUntil'), {
    required: true
  }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange')
  )
};

export class QuestionModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
