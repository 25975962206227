import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ExamResultListPage = () =>
  import('@/modules/exam-result/components/exam-result-list-page.vue');
const ExamResultFormPage = () =>
  import('@/modules/exam-result/components/exam-result-form-page.vue');
const ExamResultViewPage = () =>
  import('@/modules/exam-result/components/exam-result-view-page.vue');
const ScoreReport = () =>
  import('@/modules/exam-result/components/score-report.vue');

const ExamResultAnalyzePage = () =>
  import('@/modules/exam-result/components/exam-result-analyze-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'examResult',
        path: '/exam-result',
        component: ExamResultListPage,
        meta: {
          auth: true,
          permission: Permissions.values.examResultView
        }
      },
      {
        name: 'examResultNew',
        path: '/exam-result/new',
        component: ExamResultFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.examResultCreate
        }
      },
      {
        name: 'examResultEdit',
        path: '/exam-result/:id/edit',
        component: ExamResultFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.examResultEdit
        },
        props: true
      },
      {
        name: 'examResultView',
        path: '/exam-result/:id',
        component: ExamResultViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.examResultView
        },
        props: true
      },
      {
        name: 'scoreReport',
        path: '/score-report/:id',
        component: ScoreReport,
        meta: {
          auth: true,
          permission: Permissions.values.examResultView
        },
        props: true
      },
      {
        name: 'examResultAnalyze',
        path: '/exam-analysis/:examId/:studentId',
        component: ExamResultAnalyzePage,
        meta: {
          auth: true,
          permission: Permissions.values.examResultView
        },
        props: true
      }
    ]
  }
];
