import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const StructureListPage = () =>
  import(
    '@/modules/structure/components/structure-list-page.vue'
  );
const StructureFormPage = () =>
  import(
    '@/modules/structure/components/structure-form-page.vue'
  );
const StructItemFormPage = () =>
  import(
    '@/modules/structure/components/struct-item-form-page.vue'
  );
const StructureViewPage = () =>
  import(
    '@/modules/structure/components/structure-view-page.vue'
  );
const StructItemViewPage = () =>
  import(
    '@/modules/structure/components/struct-item-view-page.vue'
  );
const StructureImporterPage = () =>
  import(
    '@/modules/structure/components/structure-importer-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'structure',
        path: '/structure',
        component: StructureListPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureView,
        },
      },
      {
        name: 'structureNew',
        path: '/structure/new',
        component: StructureFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureCreate,
        },
        props: true,
        //props: {key: 'structureNew'},
      },
      {
        name: 'structItemNew',
        path: '/struct-item/:structureId/new',
        component: StructItemFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureCreate,
        },
        props: true,
      },
      {
        name: 'structureImporter',
        path: '/structure/import',
        component: StructureImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureImport,
        },
      },
      {
        name: 'structureEdit',
        path: '/structure/:id/edit',
        //components: {default: StructureFormPage},
        component: StructureFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureEdit,
        },
        props: true,
        //props: {default: true, key: 'structureEdit'},
      },
      {
        name: 'structureView',
        path: '/structure/:id',
        component: StructureViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureView,
        },
        props: true,
      },
      {
        name: 'structItemEdit',
        path: '/struct-item/:structureId/:id/edit',
        component: StructItemFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureEdit,
        },
        props: true,
      },
      {
        name: 'structItemView',
        path: '/struct-item/:structureId/:id/:mode',
        component: StructItemViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.structureView,
        },
        props: true,
      },
    ],
  },
];
