<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      :class="checkRTL"
    >
      <!--      <el-form-item-->
      <!--        :label="fields.emails.label"-->
      <!--        :prop="fields.emails.name"-->
      <!--        :required="fields.emails.required"-->
      <!--        v-if="!single"-->
      <!--      >-->
      <!--        <el-col :lg="11" :md="16" :sm="24">-->
      <!--          <el-select-->
      <!--            :no-data-text="i18n('iam.new.emailsHint')"-->
      <!--            allow-create-->
      <!--            default-first-option-->
      <!--            filterable-->
      <!--            multiple-->
      <!--            placeholder-->
      <!--            ref="focus"-->
      <!--            v-model="model[fields.emails.name]"-->
      <!--          ></el-select>-->
      <!--        </el-col>-->
      <!--      </el-form-item>-->

      <el-form-item
        :label="fields.email.label"
        :prop="fields.email.name"
        :required="fields.email.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            ref="focus"
            v-model="model[fields.email.name]"
          />
        </el-col>
      </el-form-item>

      <div v-if="isSingleEmail">
        <el-form-item
          :label="fields.firstName.label"
          :prop="fields.firstName.name"
          :required="fields.firstName.required"
        >
          <el-col :lg="11" :md="16" :sm="24">
            <el-input
              v-model="model[fields.firstName.name]"
            />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.lastName.label"
          :prop="fields.lastName.name"
          :required="fields.lastName.required"
        >
          <el-col :lg="11" :md="16" :sm="24">
            <el-input
              v-model="model[fields.lastName.name]"
            />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.phoneNumber.label"
          :prop="fields.phoneNumber.name"
          :required="fields.phoneNumber.required"
        >
          <el-col :lg="11" :md="16" :sm="24">
            <el-input
              prefix-icon="el-icon-fa-plus"
              v-model="model[fields.phoneNumber.name]"
            />
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.avatarsIam.label"
          :prop="fields.avatarsIam.name"
          :required="fields.avatarsIam.required"
        >
          <el-col :lg="11" :md="16" :sm="24">
            <app-image-upload
              :max="fields.avatarsIam.max"
              :path="fields.avatarsIam.path"
              :schema="fields.avatarsIam.fileSchema"
              v-model="model[fields.avatarsIam.name]"
            ></app-image-upload>
          </el-col>
        </el-form-item>
      </div>

      <el-form-item
        :label="fields.rolesRequired.label"
        :prop="fields.rolesRequired.name"
        :required="fields.rolesRequired.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-select
            multiple
            placeholder
            v-model="model[fields.rolesRequired.name]"
          >
            <el-option
              :key="option.value"
              :label="option.label"
              :value="option.value"
              v-for="option in fields.rolesRequired.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.languages.label"
        :prop="fields.languages.name"
        :required="fields.languages.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-language-autocomplete-input
            :fetchFn="fields.languages.fetchFn"
            :mapperFn="fields.languages.mapperFn"
            :showCreate="false"
            v-model="model[fields.languages.name]"
            mode="multiple"
          ></app-language-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
            :class="rtlAndPadding"
          >
           <span class="card-title"> <app-i18n code="common.save"></app-i18n> </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
            :class="rtlAndPadding"
          >
            <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
            :class="rtlAndPadding"
          >
           <span class="card-title"> <app-i18n code="common.cancel"></app-i18n> </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/auth/user-model';
import { i18n } from '@/i18n';

const { fields } = UserModel;
let singleFormSchema = new FormSchema([
  fields.email,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsIam,
  fields.rolesRequired,
  fields.languages,
]);

const multipleFormSchema = new FormSchema([
  fields.emails,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.avatarsIam,
  fields.rolesRequired,
  fields.languages,
]);

export default {
  name: 'app-iam-new-form',

  props: ['saveLoading'],

  async created() {
    this.model = this.formSchema.initialValues();
  },

  data() {
    return {
      rules: this.single
        ? singleFormSchema.rules()
        : multipleFormSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'iam/form/checkRTL',
      rtlAndPadding: 'iam/form/rtlAndPadding'
    }),

    formSchema() {
      return this.single
        ? singleFormSchema
        : multipleFormSchema;
    },

    isSingleEmail() {
      return (
        !this.model ||
        !this.model.emails ||
        this.model.emails.length <= 1
      );
    },

    fields() {
      return fields;
    },
  },

  methods: {
    doReset() {
      this.model = this.formSchema.initialValues();
      this.$refs.form.resetFields();
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = this.formSchema.cast(
        this.model,
      );

      return this.$emit('submit', {
        id,
        values,
      });
    },

    doCancel() {
      this.$emit('cancel');
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
