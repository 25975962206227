import { ExamResultService } from '@/modules/exam-result/exam-result-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
export default {
  namespaced: true,

  state: {
    loading: false,
    reScoreLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading,
    reScoreLoading: state => !!state.reScoreLoading
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    },

    RESCORE_STARTED(state) {
      state.record = null;
      state.reScoreLoading = true;
    },

    RESCORE_SUCCESS(state, payload) {
      state.record = payload;
      state.reScoreLoading = false;
    },

    RESCORE_ERROR(state) {
      state.record = null;
      state.reScoreLoading = false;
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ExamResultService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/exam-result');
      }
    },
    async doFindScoreReport({ commit }, examResultId) {
      try {
        commit('FIND_STARTED');
        const record = await ExamResultService.findScoreReport(examResultId);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/exam-result');
      }
    },
    async doReScore({commit},values){
      try {
        commit('RESCORE_STARTED')
        await ExamResultService.reScore(values)
        Message.success(i18n('entities.examResult.reScore.success'));
        routerAsync().push('/assign-papers');
        commit('RESCORE_SUCCESS')
      }catch(error){
        Errors.handle(error)
        commit('RESCORE_ERROR')
      }
    }
  }
};
