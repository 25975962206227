import { ScoreOutputService } from '@/modules/score-output/score-output-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async deleteUnScoredPapers({ commit }, values){
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.deleteUnscoredPapers(values);
        commit('UPDATE_SUCCESS');
        Message.success(
            i18n('assignPapers.deleteUnScoredPapers.onSuccess'),
        );
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async deleteUncompletedPapers({ commit }, values){
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.deleteUncompletedPapers(values);
        commit('UPDATE_SUCCESS');
        Message.success(
            i18n('assignPapers.deleteUncompletedPapers.onSuccess'),
        );
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async assignUncompletedPapers({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.assignUncompletedPapers(values);
        commit('UPDATE_SUCCESS');
        Message.success(
            i18n('assignPapers.assignUncompletedPapers.onSuccess'),
        );
        routerAsync().push('/score-output');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async assignPapers({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.assignPapers(values);
        commit('UPDATE_SUCCESS');
        Message.success(
          i18n('assignPapers.assignPapers.onSuccess'),
        );
        routerAsync().push('/score-output');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async setPapersAsUnScored({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.setPapersAsUnScored(
          values,
        );
        commit('UPDATE_SUCCESS');
        Message.success(i18n('assignPapers.setPapersAsUnScored.onSuccess'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async reassignScores({ commit }, values) {
      try {
        commit('UPDATE_STARTED');
        await ScoreOutputService.reassignScores(values);
        commit('UPDATE_SUCCESS');
        Message.success(
          i18n('assignPapers.reassignScores.onSuccess'),
        );
        routerAsync().push('/score-output');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
