import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import {ExamField} from "../exam/exam-field";
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.order.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.order.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  paymentSystem: new StringField('paymentSystem', label('paymentSystem'), {
    "required": true,
    "max": 255
  }),
  psOrderNo: new StringField('psOrderNo', label('psOrderNo'), {
    "required": true,
    "max": 255
  }),
  description: new StringField('description', label('description'), {
    "required": true,
    "max": 255
  }),
  currency: new StringField('currency', label('currency'), {
    "required": true,
    "max": 255
  }),
  amount: new IntegerField(
      'amount',
      label('amount'),
      {
        required: true,
      },
  ),
  orderExpireAt: new DateTimeField(
      'orderExpireAt',
      label('orderExpireAt'),
  ),
  orderExpireAtRange: new DateTimeRangeField(
      'orderExpireAtRange',
      label('orderExpireAtRange'),
  ),
  orderStatus: new EnumeratorField(
      'orderStatus',
      label('orderStatus'),
      [
        {
          id: "0",
          label: enumeratorLabel('orderStatus', 'default'),
        },
        {
          id: "1",
          label: enumeratorLabel('orderStatus', 'started'),
        },
        {
          id: "6",
          label: enumeratorLabel('orderStatus', 'failed'),
        },
        {
          id: "11",
          label: enumeratorLabel('orderStatus', 'successful'),
        },
      ]
  ),

  exams: ExamField.relationToMany('exams', label('exams'), {
    "required": true
  }),
  student: UserField.relationToOne('createdBy',label('student'),{
    "required": true
  }),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  updatedAtRange: new DateTimeRangeField(
      'updatedAtRange',
      label('updatedAtRange'),
  ),
};

export class OrderModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
