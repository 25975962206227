import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { UserField } from '@/modules/auth/user-field';
import IntegerField from '@/shared/fields/integer-field';
import { ExamField } from '@/modules/exam/exam-field';
import IntegerRangeField from '@/shared/fields/integer-range-field';

function label(name) {
  return i18n(`entities.examResult.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.examResult.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  exam: ExamField.relationToOne('exam', label('exam')),
  student: UserField.relationToOne('student',label('student')),
  aftermath: new IntegerField(
    'aftermath',
    label('aftermath'),
  ),
  aftermathNumberRange: new IntegerRangeField(
    'aftermathNumberRange',
    label('aftermathNumberRange'),
  ),
  reading: new IntegerField('reading', label('reading')),
  readingNumberRange: new IntegerRangeField(
    'readingNumberRange',
    label('readingNumberRange'),
  ),
  speaking: new IntegerField('speaking', label('speaking')),
  speakingNumberRange: new IntegerRangeField(
    'speakingNumberRange',
    label('speakingNumberRange'),
  ),
  writing: new IntegerField('writing', label('writing')),
  writingNumberRange: new IntegerRangeField(
    'writingNumberRange',
    label('writingNumberRange'),
  ),
  listening: new IntegerField(
    'listening',
    label('listening'),
  ),
  listeningNumberRange: new IntegerRangeField(
    'listeningNumberRange',
    label('listeningNumberRange'),
  ),
  grammar: new IntegerField(
      'grammar',
      label('grammar'),
  ),
  oralFluency: new IntegerField(
      'oralFluency',
      label('oralFluency'),
  ),
  pronunciation: new IntegerField(
      'pronunciation',
      label('pronunciation'),
  ),
  spelling: new IntegerField(
      'spelling',
      label('spelling'),
  ),
  vocabulary: new IntegerField(
      'vocabulary',
      label('vocabulary'),
  ),
  writtenDiscourse: new IntegerField(
      'writtenDiscourse',
      label('writtenDiscourse'),
  ),
  results: new StringField('results', label('results')),
  resultStatus: new EnumeratorField(
      'resultStatus',
      label('resultStatus'),
      [
        {
          id: "0",
          label: enumeratorLabel('resultStatus', 'default'),
        },
        {
          id: "1",
          label: enumeratorLabel('resultStatus', 'calculated'),
        },
        {
          id: "2",
          label: enumeratorLabel('resultStatus', 'updated'),
        },
        {
          id: "6",
          label: enumeratorLabel('resultStatus', 'announced'),
        },
        {
          id: "11",
          label: enumeratorLabel('resultStatus', 'oldVersion'),
        },
      ]
  ),
  totalQC: new IntegerField(
      'totalQC',
      label('totalQC'),
  ),
  answeredQC: new IntegerField(
      'answeredQC',
      label('answeredQC'),
  ),
  examSessionDate: new DateTimeField(
      'examSessionDate',
      label('examSessionDate'),
  ),
  totalTimeSpent: new StringField('totalTimeSpent', label('totalTimeSpent')),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ExamResultModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
