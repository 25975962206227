import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ExamResultPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.examResultRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.examResultImport,
    );
    this.examResultAutocomplete = permissionChecker.match(
      Permissions.values.examResultAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.examResultCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.examResultEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.examResultDestroy,
    );
  }
}
