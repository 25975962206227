import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const SupportRequestsListPage = () =>
  import(
    '@/modules/support-requests/components/support-requests-list-page.vue'
  );
const SupportRequestsFormPage = () =>
  import(
    '@/modules/support-requests/components/support-requests-form-page.vue'
  );
const SupportRequestsViewPage = () =>
  import(
    '@/modules/support-requests/components/support-requests-view-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'supportRequests',
        path: '/support-requests',
        component: SupportRequestsListPage,
        meta: {
          auth: true,
          permission:
            Permissions.values.supportRequestsView,
        },
      },
      {
        name: 'supportRequestsNew',
        path: '/support-requests/new',
        component: SupportRequestsFormPage,
        meta: {
          auth: true,
          permission:
            Permissions.values.supportRequestsCreate,
        },
      },
      {
        name: 'supportRequestsEdit',
        path: '/support-requests/:id/edit',
        component: SupportRequestsFormPage,
        meta: {
          auth: true,
          permission:
            Permissions.values.supportRequestsEdit,
        },
        props: true,
      },
      {
        name: 'supportRequestsView',
        path: '/support-requests/:id',
        component: SupportRequestsViewPage,
        meta: {
          auth: true,
          permission:
            Permissions.values.supportRequestsView,
        },
        props: true,
      },
    ],
  },
];
