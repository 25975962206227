import questionListStore from '@/modules/question/question-list-store';
import questionViewStore from '@/modules/question/question-view-store';
import questionFormStore from '@/modules/question/question-form-store';
import questionDestroyStore from '@/modules/question/question-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: questionDestroyStore,
    form: questionFormStore,
    list: questionListStore,
    view: questionViewStore,
  },
};
