const en = {
  lang: 'en',
  common: {
    or: 'or',
    cancel: 'Cancel',
    reset: 'Reset',
    save: 'Save',
    saveAs: 'Save As',
    update: 'Update',
    publish: 'Publish',
    publishAll: 'Publish Scores',
    search: 'Search',
    edit: 'Edit',
    scoreReport: 'Score Report',
    remove: 'Remove',
    new: 'New',
    preview: 'Preview',
    export: 'Export to Excel',
    noDataToExport: 'No data to export',
    import: 'Import Excel',
    discard: 'Discard',
    yes: 'Yes',
    no: 'No',
    pause: 'Pause',
    areYouSure: 'Are you sure?',
    view: 'View',
    analyze: 'Analyze',
    destroy: 'Delete',
    mustSelectARow: 'Select a row from the table below',
    confirm: 'Confirm',
    next: 'Next',
    prev: 'Prev',
    saveQuestion: 'Save Question',
    saveText: 'Save Text',
    editor: 'Editor',
    add: 'Add',
    question: 'Question',
    send: 'Send',
    failedJSON: 'JSON value is not correct',
    breadcrumb: {
      noData: '--'
    }
  },

  app: {
    title: 'ITEX'
  },

  entities: {
    userDetail: {
      name: 'userDetail',
      label: 'User Details',
      menu: 'User Details',
      exporterFileName: 'userDetail_export',
      list: {
        menu: 'User Details',
        title: 'User Details'
      },
      create: {
        success: 'User Detail saved successfully'
      },
      update: {
        success: 'User Detail saved successfully'
      },
      destroy: {
        success: 'User Detail deleted successfully'
      },
      destroyAll: {
        success: 'User Detail(s) deleted successfully'
      },
      edit: {
        title: 'Edit User Detail'
      },
      fields: {
        id: 'Id',
        userName: 'UserName',
        studentNumber: 'StudentNumber',
        gender: 'Gender',
        birthdateRange: 'Birthdate',
        birthdate: 'Birthdate',
        userType: 'UserType',
        nationality: 'Nationality',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        gender: {
          male: 'Male',
          female: 'Female'
        },
        userType: {
          passive: 'Passive',
          student: 'Student',
          teacher: 'Teacher',
          admin: 'Admin'
        }
      },
      new: {
        title: 'New User Detail'
      },
      view: {
        title: 'View User Detail'
      },
      importer: {
        title: 'Import User Details',
        fileName: 'userDetail_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },

    question: {
      name: 'question',
      label: 'Questions',
      menu: 'Questions',
      exporterFileName: 'question_export',
      list: {
        menu: 'Questions',
        title: 'Questions'
      },
      create: {
        success: 'Question saved successfully'
      },
      update: {
        success: 'Question saved successfully'
      },
      destroy: {
        success: 'Question deleted successfully'
      },
      destroyAll: {
        success: 'Question(s) deleted successfully'
      },
      edit: {
        title: 'Edit Question'
      },
      fields: {
        id: 'Id',
        keywords: 'Keywords',
        createdById: 'Author',
        questionType: 'Question Type',
        section: 'Section',
        questionLanguage: 'Language',
        initialTime: 'Initial Time (sec)',
        recordTime: 'Record Time (sec)',
        question: 'Question',
        editor: 'Question',
        optionsAnswer: 'Question',
        readingTime: 'Reading Time (sec)',
        questionGroups: 'Groups',
        questionStatus: 'Status',
        allowedUsers: 'Allowed Teachers',
        preVersions: 'PreVersions',
        data: 'Data',
        answer: 'Answer',
        transcribe: 'Answer',
        answerHint: 'Answer Hint',
        subtitle: 'Question Sentence',
        lockUntil: 'Lock Until',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        audio: 'Audio',
        image: 'Image',
        time: 'Time (min)',
        description: 'Description'
      },
      enumerators: {
        questionStatus: {
          default: 'Default',
          draft: 'Draft',
          reviewed: 'Reviewed',
          published: 'Published',
          locked: 'Locked',
          obsolete: 'Obsolete'
        },
        section: {
          writing: 'Writing',
          reading: 'Reading',
          speaking: 'Speaking',
          listening: 'Listening'
        },
        questionGroup: {
          elementary: 'Elementary',
          preIntermediate: 'Pre Intermediate',
          intermediate: 'Intermediate',
          upperIntermediate: 'Upper Intermediate',
          advanced: 'Advanced',
          difficult: 'Difficult',
          medium: 'Medium',
          easy: 'Easy',
          itex: 'Itex',
          placement: 'Placement'
        }
      },
      new: {
        title: 'New Question'
      },
      view: {
        title: 'View Question'
      },
      preview: {
        title: 'Preview Question'
      },
      importer: {
        title: 'Import Questions',
        fileName: 'question_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },

    readExam: {
      //examResult tablosuna veri girisi
      menu: 'Mark Exams',
      list: {
        menu: 'Mark Exams',
        title: 'Mark Exams'
      }
    },

    scoreOutput: {
      name: 'scoreOutput',
      label: 'Scores',
      menu: 'Scores',
      fields: {
        scoreStatus: 'Status',
        teacher: 'Teacher',
        id: 'Id',
        examOutput: 'Exam Answer Id',
        score: 'Score',
        content: 'Content',
        pronunciation: 'Pronunciation',
        oralFluency: 'Oral Fluency',
        form: 'Form',
        grammar: 'Grammar',
        vocabulary: 'Vocabulary Range',
        development: 'Development, structure and coherence',
        linguistic: 'General linguistic range',
        spelling: 'Spelling',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
        questionTitle: 'Question',
        responseTitle: 'Response',
        answerStatisticsTitle: "Answer Statistics",
        wordCountTitle: "Word Count",
        timeSpentTitle: "Time Spent",
        timeSpentUnit: "Seconds",
        timeSpentUnitMinutes: "Minutes",
        answerHint: 'Answer Hint'
      },
      list: {
        menu: 'Score Outputs',
        title: 'Score Outputs'
      },
      edit: {
        title: 'Edit Score'
      },
      view: {
        title: 'View Score'
      },
      new: {
        title: 'New Score'
      },
      publish: 'Publish Scores',
      enumerators: {
        scoreStatus: {
          draft: 'Draft',
          saved: 'Saved',
          published: 'Published',
          evaluated: 'Evaluated'
        }
      },
      create: {
        success: 'Answers assigned successfully'
      },
      update: {
        success: 'Score saved successfully'
      },
      updateAll: {
        success: 'Scores saved successfully'
      },
      breadcrumb: {
        scoreReadAloud: 'Score Read Aloud',
        scoreRepeatSentence: 'Score Repeat Sentence',
        scoreDescribeImage: 'Score Describe Image',
        scoreRetellLecture: 'Score Re-tell Lecture',
        scoreAnswerShortQuestion: 'Score Answer Short Question',
        scoreSummarizeWrittenText: 'Score Summarize Written Text',
        scoreWriteEssay: 'Score Write Essay',
        scoreSummarizeSpokenText: 'Score Summarize Spoken Text'
      },
      noRecord: 'There is no answer to score',
      publishRecord: 'To Publish, Go to Scores Page',
      types: {
        answerShortQuestion: {
          content: {
            description: `Errors = replacements, omissions and insertions only Hesitations,
                filled or unfilled pauses, leading or trailing material are
                ignored in the scoring of content`,
            explanations: [
              `Mentions some disjointed elements of the presentation `,
              `Describes some basic elements of the presentation but does not make clear their interblackrelations or implications `
            ]
          }
        },
        describeImage: {
          content: {
            description: ` Errors = replacements, omissions and insertions only Hesitations,
                 filled or unfilled pauses, leading or trailing material are
                 ignored in the scoring of content`,
            explanations: [
              `Mentions some disjointed elements of the presentation`,
              `Describes some basic elements of the image, but does not make clear their interrelations or implications`,
              `Deals with only one key element in the image and refers to an implication or conclusion. Shows basic understanding of several core elements of the image`,
              `Deals with most key elements of the image and refers to their implications or conclusions`,
              `Describes all the key elements of the image and their relations, referring to their implications or conclusions`,
              `Describes all elements of the image and their relationships, possible development and conclusion or implications`
            ]
          }
        },

        readAloud: {
          content: {
            description: `Each replacement, omission or insertion of a word counts as one error. Maximum score: depends on the length of the item prompt`
          }
        },

        repeatSentence: {
          content: {
            description: `Errors = replacements, omissions and insertions only Hesitations,
                 filled or unfilled pauses, leading or trailing material are ignored in the scoring of content`,
            explanations: [
              `Almost nothing from the prompt in the response`,
              `Less than 50% of words in the response from the prompt in the correct sequence`,
              `At least 50% of words in the response from the prompt in the correct sequence`,
              `All words in the response from the prompt in the correct sequence`
            ]
          }
        },

        retellLecture: {
          content: {
            description: `Errors = replacements, omissions and insertions only Hesitations,
             filled or unfilled pauses, leading or trailing material are ignored in the scoring of content`,
            explanations: [
              `Mentions some disjointed elements of the presentation`,
              `Describes some basic elements of the presentation but does not make clear their interblackrelations or implications`,
              `Deals with only one key point and refers to an implication or conclusion. Shows basic understanding of several core elements of the presentation`,
              `Deals with most points in the presentation and refers to their implications and conclusions`,
              `Describes all key points of the presentation and their relations, referring to their implications and conclusions`,
              `Re-tells all points of the presentation and describes characters, aspects and actions, their relationships, the underlying development, implications and conclusions`
            ]
          }
        },

        summarizeSpokenText: {
          content: {
            description: 'Content',
            explanations: [
              `Omits or misrepresents the main aspects`,
              `Provides a fair summary of the text, but one or two aspects are missing`,
              `Provides a good summary of the text. All relevant aspects are mentioned.`
            ]
          },
          form: {
            explanations: [
              `Contains less than 40 words or more than 100 words.Summary is written in capital letters, contains no punctuation or consists only of bullet points or very short sentences`,
              `Contains 40-49 words or 71-100 words`,
              `Contains 50-70 words`
            ]
          },
          grammar: {
            explanations: [
              `Defective grammatical structure which could hinder communications`,
              `Contains grammatical errors with no hindrance to communication`,
              `Correct grammatical structures`
            ]
          },
          vocabulary: {
            explanations: [
              `Defective word choice which could hinder communication`,
              `Some lexical errors but with no hindrance to communication`,
              `Appropriate choice of words`
            ]
          },
          spelling: {
            explanations: [
              `More than one spelling errors`,
              `One spelling error`,
              `Correct spelling`
            ]
          }
        },

        summarizeWrittenText: {
          content: {
            description: 'Reading & Writing',
            explanations: [
              `Omits or misrepresents the main aspects of the text`,
              `Provides a fair summary of the text but misses one or two aspects`,
              `Provides a good summary of the text. All relevant aspects mentioned`
            ]
          },
          form: {
            description: 'Form, grammar, vocabulary',
            explanations: [
              `Not written in one single, complete sentence or contains fewer than 5 or more than 75 words. Summary is written in capital letters.`,
              `Is written in one, single, complete sentence`
            ]
          },
          grammar: {
            explanations: [
              `Has defective grammatical structure which could hinder communication`,
              `Contains grammatical errors but with no hindrance to communication`,
              `Has correct grammatical structure`
            ]
          },
          vocabulary: {
            explanations: [
              `Has defective word choice which could hinder communication`,
              `Contains lexical errors but with no hindrances to communication`,
              `Has appropriate choice of words`
            ]
          }
        },

        writeEssay: {
          content: {
            description:
              'Content; form; development, structure, coherence; grammar; general linguistic range; vocabulary range; spelling',
            explanations: [
              `Does not deal properly with the prompt`,
              `Deals with the prompt but omits a major aspect or more than one minor aspect`,
              `Deals with the prompt but does not deal with one minor aspect`,
              `Adequately deals with the prompt`
            ]
          },
          form: {
            explanations: [
              `Length is less than 120 or more than 380 words. Essay is written in capital letters, contains no punctuation or only consists of bullet points or very short sentences`,
              `Length is between 120 and 199 or between 301 and 380 words`,
              `Length is between 200 and 300 words`
            ]
          },
          grammar: {
            explanations: [
              `Contains mainly simple structures and/or several basic mistakes`,
              `Shows a relatively high degree of grammatical control. No mistakes which would lead to misunderstandings`,
              `Shows consistent grammatical control of complex language. Errors are rare and difficult to spot`
            ]
          },
          vocabulary: {
            explanations: [
              `Contains mainly basic vocabulary insufficient to deal with the topic at the required level`,
              `Shows a good range of vocabulary for matters connected to general academic topics. Lexical shortcomings lead to circumlocution or some imprecision`,
              `Good command of a broad lexical repertoire, idiomatic expressions and colloquialisms`
            ]
          },
          development: {
            explanations: [
              `Lacks coherence and mainly consists of lists or loose elements`,
              `Is incidentally less well structured, and some elements or paragraphs are poorly linked`,
              `Shows good development and logical structure`
            ]
          },
          linguistic: {
            explanations: [
              `Contains mainly basic language and lacks precision`,
              `Sufficient range of language to provide clear descriptions, express viewpoints and develop arguments`,
              `Exhibits smooth mastery of a wide range of language to formulate thoughts precisely, give emphasis, differentiate and eliminate ambiguity. No sign that the test taker is restricted in what they want to communicate`
            ]
          },
          spelling: {
            explanations: [
              `More than one spelling error`,
              `One spelling error`,
              `Correct spelling`
            ]
          }
        },

        writeFromDictation: {
          content: {
            description: 'Listening and writing',
            explanations: [
              `Each incorrect or misspelled word`,
              `Each correct word spelled correctly`
            ]
          }
        },

        pronunciation: {
          titles: [
            'Non-English',
            'Intrusive',
            'Intermediate',
            'Good',
            'Advanced',
            'Native-like'
          ],
          explanations: [
            `Pronunciation seems completely characteristic of another language. Many consonants and vowels are mispronounced, mis-ordered or omitted. Listeners may find more than 1/2 of the speech unintelligible. Stressed and unstressed syllables are realized in a non-English manner. Several words may have the wrong number of syllables`,
            `Many consonants and vowels are mispronounced, resulting in a strong intrusive foreign accent. Listeners may have difficulty understanding about 1/3 of the words. Many consonants may be distorted or omitted. Consonant sequences may be non-English. Stress is placed in a non-English manner; unstressed words may be reduced or omitted, and a few syllables added or Missed`,
            `Some consonants and vowels are consistently mispronounced in a nonnative like manner. At least 2/3 of speech is intelligible, but listeners might need to adjust to the accent. Some consonants are regularly omitted, and consonant sequences may be simplified. Stress may be placed incorrectly on some words or be unclear.`,
            `Most vowels and consonants are pronounced correctly. Some consistent errors might make a few words unclear. A few consonants in certain contexts may be regularly distorted, omitted or mispronounced. Stressdependent vowel reduction may occur on a few words.`,
            `Vowels and consonants are pronounced clearly and unambiguously. A few minor consonant, vowel or stress distortions do not affect intelligibility. All words are easily understandable. A few consonants or consonant sequences may be distorted. Stress is placed correctly on all common words, and sentence level stress is reasonable.`,
            `All vowels and consonants are produced in a manner that is easily understood by regular speakers of the language. The speaker uses assimilation and deletions appropriate to continuous speech. Stress is placed correctly in all words and sentence-level stress is fully appropriate.`
          ]
        },
        oralFluency: {
          titles: [
            `Disfluent`,
            `Limited`,
            `Intermediate`,
            `Good`,
            `Advanced`,
            `Native-like`
          ],
          explanations: [
            `Speech is slow and labored with little discernable phrase grouping, multiple hesitations, pauses, false starts, and/or major phonological simplifications. Most words are isolated, and there may be more than one long pause.`,
            `Speech has irregular phrasing or sentence rhythm. Poor phrasing, staccato or syllabic timing, and/or multiple hesitations, repetitions, and/or false starts make spoken performance notably uneven or discontinuous. Long utterances may have one or two long pauses and inappropriate sentencelevel word emphasis.`,
            `Speech may be uneven or staccato. Speech (if >= 6 words) has at least one smooth three-word run, and no more than two or three hesitations, repetitions or false starts. There may be one long pause, but not two or more.`,
            `Speech is at an acceptable speed but may be uneven. There may be more than one hesitation, but most words are spoken in continuous phrases. There are few repetitions or false starts. There are no long pauses and speech does not sound staccato.`,
            `Speech has an acceptable rhythm with appropriate phrasing and word emphasis. There is no more than one hesitation, one repetition or a false start. There are no significant non-native phonological simplifications.`,
            `Speech shows smooth rhythm and phrasing. There are no hesitations, repetitions, false starts or non-native phonological simplifications.`
          ]
        }
      },
      spelllangexplanations: {
        title: 'If editor spelling mistakes are not working properly',
        explanations: [
          '1- On your chrome browser at the top right, click three dots > Settings',
          '2- At the bottom, click Advanced.',
          '3- You can now turn on spell check for the language you will use',
          '4- You can add the language if the language is not listed',
          '4.1- Under "Languages," click Language.',
          '4.2- Add it by clicking Add languages.'
        ]
      }
    },

    exam: {
      name: 'exam',
      label: 'Exams',
      menu: 'Exams',
      menuTests: 'Tests',
      exporterFileName: 'exam_export',
      list: {
        menu: 'Exams',
        title: 'Exams'
      },
      create: {
        success: 'Exam saved successfully',
        saveQuestions: 'Save Questions',
        totalPoint: 'Total Point'
      },
      update: {
        success: 'Exam saved successfully',
        questions: 'Exam Questions saved successfully',
      },
      destroy: {
        success: 'Exam deleted successfully'
      },
      destroyAll: {
        success: 'Exam(s) deleted successfully'
      },
      edit: {
        title: 'Edit Exam'
      },
      fields: {
        id: 'Id',
        name: 'Name',
        examUpperLimitRange: 'Upper Date Limit Range',
        examUpperLimit: 'Upper Date Limit',
        examLowerLimitRange: 'Lower Date Limit Range',
        examLowerLimit: 'Lower Date Limit',
        status: 'Status',
        durationRange: 'Duration (min)',
        duration: 'Duration (min)',
        examType: 'Exam Type',
        examData: 'Exam Data',
        structure: 'Structure',
        students: 'Students',
        readers: 'Teachers',
        examLanguage: 'Language',
        questionPools: 'Question Sub-pool',
        isFree: 'Is Free',
        price: 'Price (TL)',
        unScored: 'Unscored',
        fixQuestions: 'Fix Questions',
        questions: 'Questions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        status: {
          draft: 'Draft',
          published: 'Published'
        },
        examType: {
          structured: 'Structured',
          manual: 'Manual'
        }
      },
      new: {
        title: 'New Exam'
      },
      view: {
        title: 'View Exam'
      },
      importer: {
        title: 'Import Exams',
        fileName: 'exam_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      },
      expDate: 'Expire Date',
      noTimeLimit: 'No Expiration Date',
      testListPage: {
        btnStartTest: 'START TEST',
        btnContinueTest: 'CONTINUE TEST',
        btnUnlockTest: 'UNLOCK TEST',
        btnLoadingTest: 'LOADING',
        downloadAppWarning: {
          warningText: 'You need to download our app to continue',
          confirm: 'Confirm',
          cancel: 'Cancel'
        },
        useAppWarning: "You need to use our 'ITEX' app to continue"
      }
    },
    questionType: {
      name: 'questionType',
      label: 'Question Types',
      menu: 'Question Types',
      exporterFileName: 'questionType_export',
      list: {
        menu: 'Question Types',
        title: 'Question Types'
      },
      create: {
        success: 'Question Type saved successfully'
      },
      update: {
        success: 'Question Type saved successfully'
      },
      destroy: {
        success: 'Question Type deleted successfully'
      },
      destroyAll: {
        success: 'Question Type(s) deleted successfully'
      },
      edit: {
        title: 'Edit Question Type'
      },
      fields: {
        id: 'Id',
        questionType: 'Question Type',
        autoScore: 'Auto Score',
        section: 'Section',
        title: 'Title',
        description: 'Description',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        section: {
          reading: 'Reading',
          speaking: 'Speaking',
          listening: 'Listening',
          writing: 'Writing'
        },
        questionType: {
          'Read Aloud': 'Read Aloud',
          'Repeat Sentence': 'Repeat Sentence',
          'Describe Image': 'Describe Image',
          'Re-tell Lecture': 'Re-tell Lecture',
          'Answer Short Question': 'Answer Short Question',
          'Summarize Written Text': 'Summarize Written Text',
          'Write Essay': 'Write Essay',
          'Multiple Choice, Choose Single Option':
            'Multiple Choice, Choose Single Option',
          'Multiple Choice, Choose Multiple Option':
            'Multiple Choice, Choose Multiple Option',
          'Re-order Paragraph': 'Re-order Paragraph',
          'Fill In The Blanks': 'Fill In The Blanks',
          'Selected Fill In The Blanks': 'Selected Fill In The Blanks',
          'Highlight Correct Summary': 'Highlight Correct Summary',
          'Summarize Spoken Text': 'Summarize Spoken Text',
          'Multiple Choice, Choose Multiple Answer':
            'Multiple Choice, Choose Multiple Answer',
          'Write In The Blanks': 'Write In The Blanks',
          'Multiple Choice, Choose Single Answer':
            'Multiple Choice, Choose Single Answer',
          'Select Missing Word': 'Select Missing Word',
          'Highlight Incorrect Words': 'Highlight Incorrect Words',
          'Write From The Dictation': 'Write From The Dictation'
        }
      },
      new: {
        title: 'New Question Type'
      },
      view: {
        title: 'View Question Type'
      },
      importer: {
        title: 'Import Question Types',
        fileName: 'questionType_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },
    questionGroup: {
      name: 'questionGroup',
      label: 'Question Group',
      menu: 'Question Groups',
      exporterFileName: 'questionGroup_export',
      list: {
        menu: 'Question Groups',
        title: 'Question Groups'
      },
      create: {
        success: 'QuestionGroup saved successfully'
      },
      update: {
        success: 'QuestionGroup saved successfully'
      },
      destroy: {
        success: 'QuestionGroup deleted successfully'
      },
      destroyAll: {
        success: 'QuestionGroup(s) deleted successfully'
      },
      edit: {
        title: 'Edit Question Group'
      },
      fields: {
        id: 'Id',
        questionGroup: 'Question Group',
        groupStatus: 'Status',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        groupStatus: {
          default: 'Default'
        }
      },
      new: {
        title: 'New Question Group'
      },
      view: {
        title: 'View Question Group'
      }
    },
    examOutput: {
      name: 'examOutput',
      label: 'Exam Outputs',
      menu: 'Exam Outputs',
      exporterFileName: 'examOutput_export',
      list: {
        menu: 'Exam Outputs',
        title: 'Exam Outputs'
      },
      create: {
        success: 'Exam Output saved successfully'
      },
      update: {
        success: 'Exam Output saved successfully'
      },
      destroy: {
        success: 'Exam Output deleted successfully'
      },
      destroyAll: {
        success: 'Exam Output(s) deleted successfully'
      },
      edit: {
        title: 'Edit Exam Output'
      },
      fields: {
        id: 'Id',
        exam: 'Exam',
        student: 'Student',
        questionNumberRange: 'Question Number',
        questionNumber: 'Number',
        outputStatus: 'Status',
        question: 'Question',
        answer: 'Answer',
        timeSpentRange: 'TimeSpent (seconds)',
        timeSpent: 'TimeSpent (seconds)',
        mouseMovements: 'Mouse Movements',
        screenShots: 'Screen Shots',
        violationCountRange: 'Violation Count',
        violationCount: 'Violation Count',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        outputStatus: {
          default: 'Default',
          fetched: 'Fetched',
          answered: 'Answered',
          examFinished: 'Exam Finished',
          assigned: 'Assigned Teachers',
          autoScore: 'Auto Score',
          resulted: 'Resulted',
          autoResulted: 'Resulted'
        }
      },
      new: {
        title: 'New Exam Output'
      },
      view: {
        title: 'View Exam Output'
      },
      importer: {
        title: 'Import Exam Outputs',
        fileName: 'examOutput_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },

    unlocktest: {
      card: {
        cardholder: 'Card Holder',
        fullname: 'Full Name',
        expires: 'Expires'
      },
      form: {
        cardnumber: 'Card Number',
        fullname: 'Full Name',
        expirationdate: 'Expiration Date',
        month: 'Month',
        year: 'Year',
        submit: 'Unlock',
        invalidCardNumber: 'Invalid Card Number'
      }
    },

    checkbrowser: {
      title: 'Technical Requirements',
      description:
        'Please fulfill the following requirements below and use ITEX App to continue',
      adblockertext: 'Do not use extensions like AdBlocker, Grammarly',
      ischrometext: 'Use Chrome as browser',
      browserversiontext: 'Do not use older browser versions',
      checkPwa: 'Use ITEX App to continue'
    },

    introduction: {
      title: 'General Set of Instructions',
      description:
        'It is requested of you to read the below instructions properly and abide by them.',
      introductions: [
        `The test is confidential and you must not disclose
        it to third party as well as try to copy it for your
        own purpose or commercial use.`,
        `The exam consists of four sections Speaking,
        Writing, Reading and Listening curated according to
        the ITEX guidelines.`,
        `In case of a malfunction such as electricity or internet outage, you can re-enter the exam from where you left off.`,
        `When the break time comes in the exam, the relevant page will be displayed.`,
        `We request you to use an update system and browser
        along with a normal/good internet speed.`,
        `You should avoid taking multiple tests with the same
        id at the same time.`,
        `Although we provide the best industry standard,
        sometimes users tend to take the test in unreal test
        environment like low upload speed, internet lag,
        outdated browser, corrupt audio drives, unwanted
        cache in the browser which leads to certain issue in
        the test conductance. While we can not direct every
        users to fix that issue, we recommend that in case a
        user faces any issue while taking the test, they can
        simply close the test window, log out, restart the
        system and take the test. They can also try taking
        the test in incognito window.`,
        `In caseof any query, you can easily get in touch
        with our support at our homescreen as well send us
        the necessary feedbacks at the end of the test.`,
        `We wish you the best of luck for your ITEX Success.`
      ]
    },
    layoutintro: {
      title: 'Test Introduction',
      description:
        'This test measures the language skills in ${language} that you will need in an academic setting.',
      desclist: [
        `The timer is shown on the top right corner of the
        screen along with the number of items/questions in
        the test`,
        ` By clicking on the Next button at the bottom of each
        screen you confirm your answer and more to the next
        question. If you click on Next you will not be able
        to return to the previous question. You will not be
        able to revist any questions at the end of the test.`,
        `In case of a malfunction such as electricity or internet outage, you can re-enter the exam from where you left off.`,
        `When the break time comes in the exam, the relevant page will be displayed.`,
        `This is test makes use of different varieties of
        English, for example, British, American, Australian.
        You can answer in the standard English variety of
        your choice.`
      ]
    },
    audiocheck: {
      title: 'Audio Check',
      description:
        'We will now test your headset and microphone, so as to ensure that it functioning properly.',
      getmicrophone: 'Get Microphone',
      startrecord: 'Start Record',
      stoprecord: 'Stop',
      recording: 'Recording...',
      loading: 'Loading',
      tryagain: 'Try Again',
      desclist: [
        ` Place your headset and position your microphone near
        the mouth.`,
        `Click "Get Microphone" and Click Allow for
        permissions.`,
        `Once you are ready, click on "Record" and speak
        "Hello, I am going to succeed in ITEX exam."`,
        `Once you have finished spoken, click on Stop button.`,
        `Click on "Play" to hear yourself speaking.`,
        `If you are not able to hear the sound, please check
        your headset and system settings.`
      ]
    },
    takeabreak: {
      title: 'Take A Break - ${time} minutes',
      description:
        'You can continue answering exam questions within ${time} minutes',
      message: 'Break will finish in..'
    },
    onlineExam: {
      question: 'Question',
      audioRecorder: {
        recordingWillBegin: 'Recording will begin in $textTimer$ seconds',
        listeningWillBegin: 'Listening will begin in $textTimer$ seconds',
        message: 'Recording...',
        waitText: 'Wait',
        listeningText: 'Listening',
        titleText: 'Audio Recorder'
      },
      types: {
        readAloud: {
          type: 'Read Aloud',
          description:
            'Look at the text below. In $initialTime$ seconds, you must read this text aloud as naturally and clearly as possible. You have $readingTime$ seconds to read aloud.'
        },
        repeatSentence: {
          type: 'Repeat Sentence',
          description:
            'You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.'
        },
        describeImage: {
          type: 'Describe Image',
          description:
            'Look at the graph below. In $initialTime$ seconds, Please speak into the microphone and describe in detail what the map is showing. You will have $recordTime$ seconds to give your response.'
        },
        retellLecture: {
          type: 'Re-tell Lecture',
          description:
            'You will hear a lecture. After listening to the lecture in $initialTime$ seconds, please speak into the microphone and retell what you have just heard from the lecture in your own words. You will have $recordTime$ seconds to give your response.'
        },
        answerShortQuestion: {
          type: 'Answer Short Question',
          description:
            'You will hear a question. Please give a simple and short answer. Often just one or a few words is enough'
        },
        summarizeWrittenText: {
          type: 'Summarize Written Text',
          description:
            'Read the passage below and summarize it using one sentence. Type you response in the box at the bottom of the screen. You have $time$ minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points in the passage.'
        },
        writeEssay: {
          type: 'Write Essay',
          description:
            'You will have $time$ minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position. Organize your ideas. Present supporting details. And control the elements of standard written English. You should write 200-300 words.\n'
        },
        singleOption: {
          type: 'Multiple Choice, Choose Single Option',
          description:
            'Read the text and answer the multiple choice question by selecting the correct response. Only one response is correct.'
        },
        multipleOption: {
          type: 'Multiple Choice, Choose Multiple Option',
          description:
            'Read the text and answer the question by selecting all correct responses. More than one response is correct'
        },
        reorderParagraph: {
          type: 'Re-order Paragraph',
          description:
            'The text boxes have been placed in a random order. Restore the original order by dragging the them.'
        },
        fillInTheBlanks: {
          type: 'Fill In The Blanks',
          description:
            'In the text below some words are missing. Drag words from the box below to the appropriate place in the text. To undo an answer choice drag the word back to the box below the text.'
        },
        selectedFillInTheBlanks: {
          type: 'Selected Fill In The Blanks',
          description:
            'Below is a text with blanks. Click on each blank, a list of choice will appear. Select the appropriate answer choice for each blank'
        },
        highlightCorrectSummary: {
          type: 'Highlight Correct Summary',
          description:
            'After listening to a recording, select the paragraph that best summarizes the recording'
        },
        summarizeSpokenText: {
          type: 'Summarize Spoken Text',
          description:
            'You will hear a short report. Write a summary for a fellow student who was not present. You should write 50-70 words. You have $time$ minutes to finish this task. Your response will be judged on the quality on the quality of your writing and on how well your response presents the key points presented in the report.'
        },
        multipleAnswer: {
          type: 'Multiple Choice, Choose Multiple Answer',
          description:
            'Listen to the recording and answer the question by selectin all the correct responses. You will need to select more than once response'
        },
        writeInTheBlanks: {
          type: 'Write In The Blanks',
          description:
            'You will hear a recording. Type the missing words in each blank'
        },
        singleAnswer: {
          type: 'Multiple Choice, Choose Single Answer',
          description:
            'Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct'
        },
        selectMissingWord: {
          type: 'Select Missing Word',
          description:
            'You will hear a recording. At the end of the recording the last word or group of words has been replaced by a beep. Select the correct option to complete the recording.'
        },
        highlightIncorrectWords: {
          type: 'Highlight Incorrect Words',
          description:
            'You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.'
        },
        writeFromTheDictation: {
          type: 'Write From The Dictation',
          description:
            'You will hear a sentence. Type the sentence in the box below exactly as you hear it. Write as much of the sentence as you can. You will hear the sentence only once.'
        }
      },
      section: {
        writing: 'Writing',
        reading: 'Reading',
        listening: 'Listening',
        speaking: 'Speaking'
      },
      word: 'Word(s)',
      validations: {
        writeEssay: {
          lessWordsMessage: 'Word(s) left to reach 200',
          tooWordsMessage: 'Word(s) exceeded the limit'
        },
        summarizeWrittenText: {
          tooWordsMessage: 'Word(s) remove to avoid breaking the score'
        },
        summarizeSpokenText: {
          lessWordsMessage: 'Word(s) left to reach 50',
          tooWordsMessage: 'Word(s) exceeded the limit'
        },
        writeFromTheDictation: {
          wordlimitexceeded: 'Word limit exceeded!'
        }
      }
    },
    survey: {
      title: 'Survey',
      description: 'Congratulation! You have successfully completed your Test.',
      header: 'Rate your test',
      qualityTitle: 'Quality of Test',
      qualities: {
        poor: 'Poor',
        average: 'Average',
        good: 'Good',
        excellent: 'Excellent',
        outstanding: 'Outstanding'
      },
      difficultyTitle: 'Difficulty Level of Test',
      difficulties: {
        soEasy: 'Poor',
        easy: 'Easy',
        average: 'Average',
        difficult: 'Difficult',
        soDifficult: 'So Difficult'
      },
      placeholder: 'Please Share Your Test Experience'
    },
    supportRequests: {
      name: 'supportRequests',
      label: 'Support Requests',
      menu: 'Support Requests',
      exporterFileName: 'supportRequests_export',
      list: {
        menu: 'Support Requests',
        title: 'Support Requests'
      },
      create: {
        success: 'Support Requests saved successfully'
      },
      update: {
        success: 'Support Requests saved successfully'
      },
      destroy: {
        success: 'Support Requests deleted successfully'
      },
      destroyAll: {
        success: 'Support Requests(s) deleted successfully'
      },
      edit: {
        title: 'Edit Support Requests'
      },
      fields: {
        id: 'Id',
        type: 'Type',
        message: 'Message',
        images: 'Images',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        quality: 'Quality',
        difficulty: 'Difficulty',
        exam: 'Exam',
        comment: 'Comments',
        examname: 'Exam Name',
        username: 'User Name',
        useremail: 'User E-Mail'
      },
      enumerators: {
        type: {
          About_Payment: 'About_Payment',
          Technical_Issues: 'Technical_Issues',
          Comment: 'Comment'
        }
      },
      new: {
        title: 'New Support Requests'
      },
      view: {
        title: 'View Support Requests'
      },
      importer: {
        title: 'Import Support Requests',
        fileName: 'supportRequests_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },
    language: {
      name: 'language',
      label: 'Language',
      menu: 'Languages',
      exporterFileName: 'languages_export',
      list: {
        menu: 'Languages',
        title: 'Languages'
      },
      create: {
        success: 'Language saved successfully'
      },
      update: {
        success: 'Language saved successfully'
      },
      destroy: {
        success: 'Language deleted successfully'
      },
      destroyAll: {
        success: 'Language(s) deleted successfully'
      },
      edit: {
        title: 'Edit Language'
      },
      fields: {
        id: 'Id',
        code: 'Language',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        language: {
          en: 'English',
          ar: 'Arabic',
          tr: 'Turkish'
        }
      },
      new: {
        title: 'New Language'
      },
      view: {
        title: 'View Language'
      },
      importer: {
        title: 'Import Languages',
        fileName: 'languages_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },
    teacher: {
      menu: 'Teacher Menu',
    },
    student: {
      menu: 'Student Menu',
    },
    structure: {
      name: 'structure',
      label: 'Exam Structure',
      menu: 'Exam Structure',
      exporterFileName: 'exam_type_export',
      list: {
        menu: 'Exam Structure',
        title: 'Exam Structure'
      },
      create: {
        success: 'Exam Structure saved successfully',
        successSI: 'Struct Item saved successfully'
      },
      update: {
        success: 'Exam Structure saved successfully',
        successSI: 'Struct Item saved successfully'
      },
      destroy: {
        success: 'Exam Structure deleted successfully',
        successSI: 'Struct Item deleted successfully'
      },
      destroyAll: {
        success: 'Exam Structure(s) deleted successfully',
        successSI: 'Struct Item(s) deleted successfully'
      },
      edit: {
        title: 'Edit Exam Structure',
        titleSI: 'Edit Struct Item'
      },
      fields: {
        //structure
        id: 'Id',
        structureName: 'Structure Name',
        statusStructure: 'Status',
        hasBreak: 'Add Break',
        breakBefore: 'Break Before',
        breakLimit: 'Break Limit (mins)',
        students: 'Students',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        //structItems
        structItemId: 'Id',
        order: 'Order',
        count: 'Count',
        statusSI: 'Status',
        resultEffect: 'Result Effect',
        questionType: 'Question Type',
        structItemGroups: 'Groups',
        descriptions: "Descriptions",
        descEn: 'English',
        descAr: 'Arabic',
        descTr: 'Turkish',
        questionCount: 'Question Count'
      },
      enumerators: {
        statusStructure: {
          default: 'Default'
        },
        statusSI: {
          default: 'Default'
        }
      },
      new: {
        title: 'New Exam Structure',
        titleSI: 'New Struct Item'
      },
      view: {
        title: 'View Exam Structure',
        titleSI: 'View Struct Item'
      }
    },
    order: {
      name: 'order',
      label: 'Orders',
      menu: 'Orders',
      exporterFileName: 'orders_export',
      list: {
        menu: 'Orders',
        title: 'Orders'
      },
      create: {
        success: 'Exam unlocked successfully',
        failed: 'Unlock exam failed'
      },
      destroy: {
        success: 'Order deleted successfully'
      },
      destroyAll: {
        success: 'Order(s) deleted successfully'
      },
      fields: {
        id: 'Id',
        amount: 'Amount',
        paymentSystem: 'Payment System',
        psOrderNo: 'PS Order No',
        orderExpireAt: 'Expire At',
        description: 'Description',
        currency: 'Currency',
        cardData: 'Card Data',
        orderData: 'Order Data',
        orderStatus: 'Order Status',
        student: 'Student',
        exams: 'Exams',
        createdAt: 'Created At',
        updatedAt: 'Updated At'
      },
      enumerators: {
        orderStatus: {
          default: 'Default',
          started: 'Started',
          failed: 'Failed',
          successful: 'Successful'
        }
      },
      view: {
        title: 'View Order'
      },
      new: {
        title: 'Unlock Test'
      }
    },
    classroom: {
      name: 'classroom',
      label: 'Classrooms',
      menu: 'Classrooms',
      exporterFileName: 'classroom_export',
      list: {
        menu: 'Classrooms',
        title: 'Classrooms'
      },
      create: {
        success: 'Classroom saved successfully'
      },
      update: {
        success: 'Classroom saved successfully'
      },
      destroy: {
        success: 'Classroom deleted successfully'
      },
      destroyAll: {
        success: 'Classroom(s) deleted successfully'
      },
      edit: {
        title: 'Edit Classroom'
      },
      fields: {
        id: 'Id',
        className: 'ClassName',
        language: 'Language',
        classLevel: 'ClassLevel',
        status: 'Status',
        students: 'Students',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at'
      },
      enumerators: {
        language: {
          English: 'English',
          Arabic: 'Arabic',
          Turkish: 'Turkish'
        },
        classLevel: {
          Beginner: 'Beginner',
          Elementary: 'Elementary',
          Intermediate: 'Intermediate',
          Upper_Intermediate: 'Upper_Intermediate',
          Advanced: 'Advanced',
          Proficiency: 'Proficiency'
        }
      },
      new: {
        title: 'New Classroom'
      },
      view: {
        title: 'View Classroom'
      },
      importer: {
        title: 'Import Classrooms',
        fileName: 'classroom_import_template',
        hint:
          'Files/Images columns must be the URLs of the files separated by space.'
      }
    },
    examResult: {
      name: 'examResult',
      label: 'Exam Results',
      menu: 'Exam Results',
      exporterFileName: 'examResult_export',
      list: {
        menu: 'Exam Results',
        title: 'Exam Results',
        announceAll: 'Announce Results'
      },
      create: {
        success: 'Exam Result saved successfully'
      },
      update: {
        success: 'Exam Result saved successfully',
        announce: 'Results announced successfully'
      },
      reScore: {
        button: 'Rescore',
        success: 'Exam rescoring process started'
      },
      destroy: {
        success: 'Exam Result deleted successfully'
      },
      destroyAll: {
        success: 'Exam Result(s) deleted successfully'
      },
      edit: {
        title: 'Edit Exam Result'
      },
      fields: {
        id: 'Id',
        reading: 'Reading',
        readingNumberRange: 'Reading',
        speaking: 'Speaking',
        speakingNumberRange: 'Speaking',
        writing: 'Writing',
        writingNumberRange: 'Writing',
        listening: 'Listening',
        listeningNumberRange: 'Listening',
        grammar: 'Grammar',
        oralFluency: 'Oral Fluency',
        pronunciation: 'Pronunciation',
        spelling: 'Spelling',
        vocabulary: 'Vocabulary',
        writtenDiscourse: 'Written Discourse',
        aftermath: 'Overall Score',
        aftermathNumberRange: 'Overall Score',
        results: 'Other Scores',
        resultStatus: 'Status',
        exam: 'Exam',
        examName: 'Exam Name',
        studentName: 'Student Name',
        studentEmail: 'Student Email',
        student: 'Student',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        createdAtRange: 'Created at',
        totalQC: 'Question',
        answeredQC: 'Answered Question',
        examSessionDate: 'Exam Date',
        totalTimeSpent: 'Time Spent'
      },
      enumerators: {
        resultStatus: {
          default: 'Default',
          calculated: 'Calculated',
          updated: 'Updated',
          announced: 'Announced',
          oldVersion: 'Old Version'
        }
      },
      new: {
        title: 'New Exam Result'
      },
      view: {
        title: 'View Exam Result'
      },
      analyze: {
        title: 'Analyze Exam Result',
        prev: 'Prev',
        next: 'Next',
        yourResponse: 'Your Response'
      },
      questionPreview: {
        listening: 'Listening',
        yourResponse: 'Your Response',
        audioRecorder: 'Audio Recorder'
      },
      scorePage: {
        headerTitle: 'ITEX Score Report',
        scoreReportCode: 'Score Report Code',
        testId: 'Test ID',
        registrationId: 'Registration ID',
        overallScore: 'Overall Score',
        communicativeSkills: 'Communicative Skills',
        enablingskills: 'Enabling Skills',
        skillsBreakdown: 'Skills Breakdown',
        testCentreInformation: {
          title: 'Test Centre Information',
          testDate: 'Test Date',
          validUntil: 'Valid Until',
          issueDate: 'Issue Date',
          testCentreCountry: 'Test Centre Country',
          testCentreId: 'Test Centre ID',
          testCentre: 'Test Centre'
        },
        candidateInformation: {
          title: 'Candidate Information',
          dateofBirth: 'Date of Birth',
          countryofCitizenship: 'Country of Citizenship',
          countryofResidence: 'Country of Residence',
          gender: 'Gender',
        },
        descriptiontext: "The Overall Score for the ITEX Academic is based on the test takers performance on all items in the test. The scores for Communicative Skills and Enabling Skills are based on the test taker's performance on only those items that pertain to these skills specifically. As many items contribute to more than one Communicative or Enabling Skills, the Overall Score cannot be computed directly from the Communicative Skill scores or from the Enabling Skill scores. The graphs below indicates this test taker's Communicative Skills and Enabling Skills. When comparing the Overall Score and the scores for Communication Skills and Enabling Skills, please be aware that there is some imprecision in all assessment, depending on a variety of factors.",
      }
    }
  },

  auth: {
    profile: {
      title: 'Edit Profile',
      success: 'Profile updated successfully'
    },
    createAnAccount: 'Create an account',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot password',
    signin: 'Sign in',
    signup: 'Sign up',
    signout: 'Sign out',
    alreadyHaveAnAccount: 'Already have an account? Sign in.',
    signinWithAnotherAccount: 'Sign in with another account',
    emailUnverified: {
      message: `Please confirm your email at <strong>{0}</strong> to continue.`,
      submit: `Resend email verification`
    },
    emptyPermissions: {
      message: `You have no permissions yet. Wait for the approval process.`
    },
    passwordResetEmail: {
      message: 'Send password reset email',
      error: `Email not recognized`
    },
    passwordReset: {
      message: 'Reset password'
    },
    emailAddressVerificationEmail: {
      error: `Email not recognized`
    },
    verificationEmailSuccess: `Verification email sent successfully`,
    passwordResetEmailSuccess: `Password reset email sent successfully`,
    passwordResetSuccess: `Password changed successfully`,
    verifyEmail: {
      success: 'Email successfully verified',
      message: 'Just a moment, your email is being verified...'
    }
  },

  roles: {
    owner: {
      label: 'Owner',
      description: 'Full access to all resources'
    },
    admin: {
      label: 'Admin',
      description: 'Administrator of system'
    },
    teacher: {
      label: 'Teacher',
      description: 'Prepare and Mark Exams'
    },
    student: {
      label: 'Student',
      description: 'Get Exams and Outputs'
    },
    canCreateExam: {
      label: 'Create Exam',
      description: 'Create and Edit Exams'
    },
    canListQEs: {
      label: 'List Questions and Exams',
      description: 'Can List Questions and Exams'
    }
  },

  iam: {
    title: 'Identity and Access Management',
    menu: 'Users',
    disable: 'Disable',
    disabled: 'Disabled',
    enabled: 'Enabled',
    enable: 'Enable',
    doEnableSuccess: 'User enabled successfully',
    doDisableSuccess: 'User disabled successfully',
    doDisableAllSuccess: 'User(s) disabled successfully',
    doDestroyAllSuccess: 'User(s) deleted successfully',
    doEnableAllSuccess: 'User(s) enabled successfully',
    doAddSuccess: 'User(s) saved successfully',
    doUpdateSuccess: 'User saved successfully',
    viewBy: 'View By',
    users: {
      name: 'users',
      label: 'Users',
      exporterFileName: 'users_export',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully'
    },
    roles: {
      label: 'Roles',
      doRemoveAllSelectedSuccess: 'Permissions removed successfully'
    },
    edit: {
      title: 'Edit User'
    },
    new: {
      title: 'New User(s)',
      titleModal: 'New User',
      emailsHint: 'Separate multiple email addresses using the comma character.'
    },
    view: {
      title: 'View User',
      activity: 'Activity'
    },
    importer: {
      studentTitle: 'Import Students',
      teacherTitle: 'Import Teachers',
      studentFilename: 'students_import_template',
      teacherFilename: 'teachers_import_template',
      hint: ''
    },
    errors: {
      userAlreadyExists: 'User with this email already exists',
      userNotFound: 'User not found',
      disablingHimself: `You can't disable yourself`,
      revokingOwnPermission: `You can't revoke your own owner permission`
    }
  },

  user: {
    fields: {
      id: 'Id',
      authenticationUid: 'Authentication Uid',
      avatars: 'Image',
      email: 'Email',
      emails: 'Email(s)',
      fullName: 'Name',
      firstName: 'First Name',
      lastName: 'Last Name',
      status: 'Status',
      disabled: 'Disabled',
      emailVerified: 'Email Verified',
      phoneNumber: 'Phone Number',
      role: 'Role',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      roleUser: 'Role/User',
      languages: 'Languages',
      roles: 'Roles',
      createdAtRange: 'Created at',
      password: 'Password',
      rememberMe: 'Remember me'
    },
    items: {
      removeRoles: "Remove Roles",
      permanentDelete: "Permanent Delete"
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    verified: 'Verified',
    notVerified: 'Not Verified',
    validations: {
      // eslint-disable-next-line
      email: 'Email ${value} is invalid'
    }
  },

  assignPapers: {
    menu: 'Assign Papers',
    title: 'Assign Exam Papers to Teachers',
    fields: {
      teachers: 'Teachers',
      language: 'Language',
      examPapers: 'Exam Papers',
      gradingCount: 'Grading Count per Answer',
      unscoredPapers: 'Unscored Papers',
      uncompletedPapers: 'Uncompleted Papers',
      assignedScores: 'Assigned Scores'
    },
    button: {
      examPapers: 'Assign Exam Papers',
      unscoredPapers: 'Assign Unscored Papers',
      assignedScores: 'Re-Assign Scores',
      unscore: 'Set Papers as Unscored',
      assignUncompleted: 'Assign Uncompleted Papers',
      deleteUncompleted: 'Delete Uncompleted Papers',
      deleteAll: 'Delete All'
    },
    reassignScores: {
      onSuccess: "Papers Re-Assigned"
    },
    setPapersAsUnScored: {
      onSuccess: "Papers set as unscored"
    },
    assignPapers: {
      onSuccess: "Papers Assigned"
    },
    assignUncompletedPapers: {
      onSuccess: "Uncompleted papers assigned"
    },
    deleteUnScoredPapers: {
      onSuccess: "Unscored Papers Deleted"
    },
    deleteUncompletedPapers: {
      onSuccess: "Uncompleted Papers Deleted"
    },
    placeholders: {
      selectteacher: "Select Teachers",
      selectpaper: "Select Paper",
      selectitem: "Select Item"
    }
  },
  auditLog: {
    menu: 'Audit Logs',
    title: 'Audit Logs',
    exporterFileName: 'audit_log_export',
    entityNamesHint: 'Separate multiple entities using the comma character.',
    fields: {
      id: 'Id',
      timestampRange: 'Period',
      entityName: 'Entity',
      entityNames: 'Entities',
      entityId: 'Entity ID',
      action: 'Action',
      values: 'Values',
      timestamp: 'Date',
      createdByEmail: 'User Email'
    }
  },
  tests: { menu: 'Tests' },
  settings: {
    title: 'Settings',
    menu: 'Settings',
    save: {
      success:
        'Settings saved successfully. The page will reload in {0} seconds for changes to take effect.'
    },
    fields: {
      theme: 'Theme'
    },
    colors: {
      'itex-theme': 'itex-theme'
    }
  },
  home: {
    menu: 'Dashboard',
    message: `This page uses fake data for demonstration purposes only.`,
    charts: {
      score: 'Total Score',
      day: 'Day',
      totalscores: 'Exam Total Score',
      totalscore: 'Total Score',
      red: 'Red',
      green: 'Green',
      yellow: 'Yellow',
      grey: 'Grey',
      blue: 'Blue',
      orange: 'Orange',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July'
      },
      eating: 'Eating',
      drinking: 'Drinking',
      sleeping: 'Sleeping',
      designing: 'Designing',
      coding: 'Coding',
      cycling: 'Cycling',
      running: 'Running',
      customer: 'Customer',
      lastfiveexam: 'Last 5 Exam',
      exam: {
        reading: 'Reading',
        writing: 'Writing',
        listening: 'Listening',
        speaking: 'Speaking',
        grammar: 'Grammar',
        oralFluency: 'Oral Fluency',
        pronunciation: 'Pronunciation',
        spelling: 'Spelling',
        vocabulary: 'Vocabulary',
        writtenDiscourse: 'Written Discourse'
      }
    }
  },
  errors: {
    backToHome: 'Back to home',
    403: `Sorry, you don't have access to this page`,
    404: 'Sorry, the page you visited does not exist',
    400: 'Sorry, the request you sent is not correct form',
    500: 'Sorry, the server is reporting an error',
    examExpired: `Sorry, you don't have access to expired exam`,
    forbidden: {
      message: 'Forbidden'
    },
    validation: {
      message: 'An error occurred'
    },
    defaultErrorMessage: 'Ops, an error occurred'
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer',
      invalid: '${path} must be a number'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage:
      'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors:
        'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.'
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue'
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.'
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload:
        'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported'
    }
  },

  autocomplete: {
    loading: 'Loading...'
  },

  imagesViewer: {
    noImage: 'No image'
  }
};

export default en;
