<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      :class="checkRTL"
      v-if="model"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            :disabled="true"
            v-model="model[fields.id.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.structureName.label"
        :prop="fields.structureName.name"
        :required="fields.structureName.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            v-model="model[fields.structureName.name]"
            ref="focus"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        :label="fields.hasBreak.label"
        :prop="fields.hasBreak.name"
        :required="fields.hasBreak.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-switch
            v-model="model[fields.hasBreak.name]"
          ></el-switch>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.breakBefore.label"
        :prop="fields.breakBefore.name"
        :required="fields.breakBefore.required"
        v-if="model[fields.hasBreak.name]"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.breakBefore.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.breakLimit.label"
        :prop="fields.breakLimit.name"
        :required="fields.breakLimit.required"
        v-if="model[fields.hasBreak.name]"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.breakLimit.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item>
        <div class="form-buttons" :class="rtlAndPadding">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
           <span class="card-title"> <app-i18n code="common.save"></app-i18n> </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
          >
           <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
           <span class="card-title"> <app-i18n code="common.cancel"></app-i18n> </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
    <div v-if="record">
      <app-struct-item-list-toolbar
        v-if="isEditing"
      ></app-struct-item-list-toolbar>
      <app-struct-item-list-table
        v-if="isEditing"
      ></app-struct-item-list-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { StructureModel } from '@/modules/structure/structure-model';
import StructItemListTable from '@/modules/structure/components/struct-item-list-table.vue';
import StructItemListToolbar from '@/modules/structure/components/struct-item-list-toolbar.vue';
const { fields } = StructureModel;
const formSchema = new FormSchema([
  fields.id,
  fields.structureName,
  fields.statusStructure,
  fields.breakLimit,
  fields.breakBefore,
  fields.hasBreak,
]);

export default {
  name: 'app-structure-form',
  components: {
    [StructItemListTable.name]: StructItemListTable,
    [StructItemListToolbar.name]: StructItemListToolbar,
  },
  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      hasBreak: false,
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(
      this.record || { breakBefore: 55, breakLimit: 10 },
    );
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'structure/form/checkRTL',
      rtlAndPadding: 'structure/form/rtlAndPadding',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doFind: 'structure/form/doFind',
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style></style>
