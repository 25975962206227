<template>
  <span v-html="message"></span>
</template>

<script>
import { i18nWL } from '@/i18n';

export default {
  name: 'app-i18n-wl',
  props: ['code', 'lang' , 'args'],
  computed: {
    message: function() {
      return i18nWL(this.code, this.lang , ...(this.args || []));
    },
  },
};
</script>
