import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateField from '@/shared/fields/date-field';
import DateRangeField from '@/shared/fields/date-range-field';
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.userDetail.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.userDetail.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  userName: UserField.relationToOne(
    'userName',
    label('userName'),
    {
      required: true,
    },
  ),
  studentNumber: new StringField(
    'studentNumber',
    label('studentNumber'),
    {},
  ),
  gender: new EnumeratorField(
    'gender',
    label('gender'),
    [
      {
        id: 'male',
        label: enumeratorLabel('gender', 'male'),
      },
      {
        id: 'female',
        label: enumeratorLabel('gender', 'female'),
      },
    ],
    {},
  ),
  birthdate: new DateField(
    'birthdate',
    label('birthdate'),
    {},
  ),
  userType: new EnumeratorField(
    'userType',
    label('userType'),
    [
      {
        id: 'passive',
        label: enumeratorLabel('userType', 'passive'),
      },
      {
        id: 'student',
        label: enumeratorLabel('userType', 'student'),
      },
      {
        id: 'teacher',
        label: enumeratorLabel('userType', 'teacher'),
      },
      {
        id: 'admin',
        label: enumeratorLabel('userType', 'admin'),
      },
    ],
    {
      required: true,
    },
  ),
  nationality: new StringField(
    'nationality',
    label('nationality'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  birthdateRange: new DateRangeField(
    'birthdateRange',
    label('birthdateRange'),
  ),
};

export class UserDetailModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
