import scoreOutputListStore from '@/modules/score-output/score-output-list-store';
import scoreOutputViewStore from '@/modules/score-output/score-output-view-store';
import scoreOutputFormStore from '@/modules/score-output/score-output-form-store';
import scoreOutputDestroyStore from '@/modules/score-output/score-output-destroy-store';
import scoreOutputMainStore from '@/modules/score-output/score-output-main-store';
import assignPapersStore from '@/modules/score-output/assign-papers-store';
export default {
  namespaced: true,

  modules: {
    score: scoreOutputMainStore,
    destroy: scoreOutputDestroyStore,
    form: scoreOutputFormStore,
    list: scoreOutputListStore,
    view: scoreOutputViewStore,
    assign: assignPapersStore,
  },
};
