import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const QuestionGroupListPage = () =>
  import('@/modules/question-group/components/question-group-list-page.vue');
const QuestionGroupFormPage = () =>
  import('@/modules/question-group/components/question-group-form-page.vue');
const QuestionGroupViewPage = () =>
  import('@/modules/question-group/components/question-group-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'questionGroup',
        path: '/question-group',
        component: QuestionGroupListPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionGroupView
        }
      },
      {
        name: 'questionGroupNew',
        path: '/question-group/new',
        component: QuestionGroupFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionGroupCreate
        }
      },
      {
        name: 'questionGroupEdit',
        path: '/question-group/:id/edit',
        component: QuestionGroupFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionGroupEdit
        },
        props: true
      },
      {
        name: 'questionGroupView',
        path: '/question-group/:id',
        component: QuestionGroupViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionGroupView
        },
        props: true
      }
    ]
  }
];
