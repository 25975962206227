import Vue from 'vue';
import Vuex from 'vuex';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import Router from 'vue-router';
import shared from '@/shared/shared-module';
import auth from '@/modules/auth/auth-module';
import layout from '@/modules/layout/layout-module';
import home from '@/modules/home/home-module';
import iam from '@/modules/iam/iam-module';
import settings from '@/modules/settings/settings-module';
import auditLog from '@/modules/audit-log/audit-log-module';
import userDetail from '@/modules/user-detail/user-detail-module';
import question from '@/modules/question/question-module';
import exam from '@/modules/exam/exam-module';
import questionType from '@/modules/question-type/question-type-module';
import questionGroup from '@/modules/question-group/question-group-module';
import examOutput from '@/modules/exam-output/exam-output-module';
import supportRequests from '@/modules/support-requests/support-requests-module';
import language from '@/modules/language/language-module';
import classroom from '@/modules/classroom/classroom-module';
import order from '@/modules/order/order-module';
import structure from '@/modules/structure/structure-module';
import onlineExam from '@/modules/online-exam/online-exam-module';
import scoreOutput from '@/modules/score-output/score-output-module';
import examResult from '@/modules/exam-result/exam-result-module';
/*Bu dosya asagidaki modul icindeki store, routes, mixins, filters, component gibi
bilesenlerinin Vue'ya tanitilmasini saglar*/
const modules = {
  shared,
  home,
  settings,
  auth,
  iam,
  auditLog,
  layout,
  userDetail,
  question,
  exam,
  questionType,
  questionGroup,
  examOutput,
  supportRequests,
  language,
  classroom,
  structure,
  onlineExam,
  scoreOutput,
  examResult,
  order
};

const exists = el => !!el;

function setupComponentsFiltersDirectivesAndMixins() {
  Object.keys(modules)
    .map(key => modules[key].components)
    .filter(exists)
    .forEach(components => {
      components.forEach(component => {
        Vue.component(component.name, component);
      });
    });

  Object.keys(modules)
    .map(key => modules[key].filters)
    .filter(exists)
    .forEach(components => {
      components.forEach(filter => {
        Vue.filter(filter.name, filter.implementation);
      });
    });

  Object.keys(modules)
    .map(key => modules[key].directives)
    .filter(exists)
    .forEach(directives => {
      directives.forEach(directive => {
        Vue.directive(directive.name, directive.implementation);
      });
    });

  Object.keys(modules)
    .map(key => modules[key].mixins)
    .filter(exists)
    .forEach(mixins => {
      mixins.forEach(mixin => {
        Vue.mixin(mixin);
      });
    });
}

//Route kurallarinin toplandigi array
const routes = [
  ...Object.keys(modules)
    .filter(key => !!modules[key].routes)
    .map(key => modules[key].routes)
    .reduce((a, b) => a.concat(b), []),
  { path: '*', redirect: '/404' }
];

//console.log("Routes: ", routes);
let router = null;

const routerAsync = () => {
  if (!router) {
    //console.log("ROUTERS",routes);
    router = new Router({
      mode: 'history',
      routes,
      scrollBehavior() {
        return { x: 0, y: 0 };
      }
    });

    router.beforeEach((to, from, next) => {
      if (to.name) {
        ProgressBar.start();
      }

      next();
    });

    router.afterEach(() => {
      ProgressBar.done();
    });
  }

  return router;
};

const buildStores = () => {
  const output = {};

  Object.keys(modules)
    .filter(key => !!modules[key].store)
    .forEach(key => {
      output[key] = modules[key].store;
    });

  return output;
};

let store = null;

const storeAsync = () => {
  if (!store) {
    store = new Vuex.Store({ modules: buildStores() });
  }

  return store;
};

export { setupComponentsFiltersDirectivesAndMixins, routerAsync, storeAsync };
