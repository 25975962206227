import structureListStore from '@/modules/structure/structure-list-store';
import structItemListStore from '@/modules/structure/struct-item-list-store';
import structureViewStore from '@/modules/structure/structure-view-store';
import structItemViewStore from '@/modules/structure/struct-item-view-store';
import structureImporterStore from '@/modules/structure/structure-importer-store';
import structureFormStore from '@/modules/structure/structure-form-store';
import structItemFormStore from '@/modules/structure/struct-item-form-store';
import structureDestroyStore from '@/modules/structure/structure-destroy-store';
import structItemDestroyStore from '@/modules/structure/struct-item-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: structureDestroyStore,
    destroySI: structItemDestroyStore,
    form: structureFormStore,
    formSI: structItemFormStore,
    list: structureListStore,
    listSI: structItemListStore,
    view: structureViewStore,
    viewSI: structItemViewStore,
    importer: structureImporterStore,
  },
};
