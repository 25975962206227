import { ScoreOutputService } from '@/modules/score-output/score-output-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    saveLoading: false, //for publish all
    table: null
  },

  getters: {
    loading: state => state.loading,

    rows: state => state.rows || [],

    count: state => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: state => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: state => state.filter,

    limit: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true
      };
    },

    selectedRows: state => {
      return state.table ? state.table.selection : [];
    },
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    SET_SAVE_LOADING(state, value) {
      state.saveLoading = value;
    },
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch({ commit, getters }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await ScoreOutputService.list(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset
        );

        //Score Status scoreStatus fetched answered vs
        response.rows.map(r => {
          r.scoreStatus = r.scoreStatus.toString();
        });
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doPublishAll({ commit, dispatch }) {
      try {
        commit('SET_SAVE_LOADING', true);

        const response = await ScoreOutputService.publishAll();

        if (response.messageKey === 'success') {
          Message.success(i18n('entities.scoreOutput.updateAll.success'));
          routerAsync().push('/exam-result');
        } else {
          Message.error(
            i18n('entities.scoreOutput.updateAll.error') + response.message
          );
          const filter = {};
          await dispatch('doFetch', {
            filter,
            keepPagination: true
          });
        }
        commit('SET_SAVE_LOADING', false);

      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    }
  }
};
