import { OrderModel } from '@/modules/order/order-model';

const { fields } = OrderModel;

export default [
  fields.id,
  fields.className,
  fields.language,
  fields.classLevel,
  fields.status,
  fields.students,
  fields.createdAt
];
