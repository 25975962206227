import * as yup from 'yup';

export class FormSchema {
  constructor(fields) {
    //formda oluşturulacak alanlar verilir.
    this.fields = fields;
    this.schema = this.buildSchema(); //bu alanlara gore schema olusturulur.
  }

  //Edit durumundaki form icin initial degerleri atar. Bu degerler form icinde model'e atanacak.
  initialValues(record) {
    record = record || {};

    const intialValues = {};

    this.fields.forEach((field) => {
      intialValues[field.name] = field.forFormInitialValue(
        record[field.name],
      );
    });

    return intialValues;
  }

  //Tum field'lar icin Yup schemasini olusturur
  buildSchema() {
    const shape = {};
    //her field ornegin name: newStringField gibi siniflardan olusturulur
    //bu siniflar genericField'dan turer. hepsinde forFormCast, forFormRules gibi metotlar tanimlidir.
    this.fields.forEach((field) => {
      shape[field.name] = field.forFormCast();
    });

    return yup.object().shape(shape);
  }
  //el-form validation rules'lari veriliyor.
  rules() {
    const rules = {};

    this.fields.forEach((field) => {
      rules[field.name] = field.forFormRules();
    });

    return rules;
  }

  cast(values) {
    return { ...this.schema.cast(values) };
  }
}
