import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const TestListPage = () =>
  import('@/modules/exam/views/test-list-page.vue');
const Unlock = () =>
  import('@/modules/exam/views/unlock.vue');
const ExamListPage = () =>
  import('@/modules/exam/views/exam-list-page.vue');
const ExamFormPage = () =>
  import('@/modules/exam/views/exam-form-page.vue');
const ExamViewPage = () =>
  import('@/modules/exam/views/exam-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'test-list-page',
        path: '/tests',
        component: TestListPage,
        meta: {
          auth: true,
          permission: Permissions.values.examView,
        },
      },
      {
        name: 'unlock',
        path: '/unlock/:id',
        component: Unlock,
        meta: {
          auth: true,
          permission: Permissions.values.examView,
        },
        props: true,
      },
      {
        name: 'exam',
        path: '/exam',
        component: ExamListPage,
        meta: {
          auth: true,
          permission: Permissions.values.examView,
        },
      },
      {
        name: 'examNew',
        path: '/exam/new',
        component: ExamFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.examCreate,
        },
      },
      {
        name: 'examEdit',
        path: '/exam/:id/edit',
        component: ExamFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.examEdit,
        },
        props: true,
      },
      {
        name: 'examView',
        path: '/exam/:id',
        component: ExamViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.examView,
        },
        props: true,
      }
    ],
  },
];
