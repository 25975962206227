<template>
  <div class="q-footer text-center">
    Ibn Haldun Test Of English Academic
  </div>
</template>

<script>
export default {
  name: 'question-footer',
};
</script>

<style scoped>
.q-footer {
  padding: 0.5rem 0;
  color: #3c4858;
  font-size: 0.875rem;
}
</style>
