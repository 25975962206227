import { ExamService } from '@/modules/exam/exam-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import { getLanguageCode } from '@/i18n';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading,
    isRTL() {
      return UtilsModules.isRTL(getLanguageCode());
    },
    isExamManual(state,getters){
      return getters.record && getters.record.examType === "manual"
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await ExamService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/exam');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const created = await ExamService.create(values);
        commit('CREATE_SUCCESS', created);
        Message.success(i18n('entities.exam.create.success'));
        if (created) {
          if (created.examType === 'manual')
            routerAsync().push('/exam/' + created.id + '/edit');
          else if (created.status === 'published') {
            routerAsync().push('/exam');
          } else if (created.id) {
            routerAsync().push('/exam/' + created.id + '/edit');
          }
        }
      } catch (error) {
        Errors.showMessage(error);
        //Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await ExamService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.exam.update.success'));
        routerAsync().push('/exam');
      } catch (error) {
        Errors.showMessage(error);
        //Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doUpdateManualQuestions({ commit }, { id, values, deleted }) {
      // console.log("id: " + id)
      // console.log("values: " + JSON.stringify(values))
      try {
        commit('UPDATE_STARTED');

        await ExamService.updateManualQuestions(id, values, deleted);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.exam.update.questions'));
      } catch (error) {
        Errors.showMessage(error);
        //Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    }
  }
};
