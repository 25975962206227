import importerStore from '@/shared/importer/importer-store';
import { ExamOutputService } from '@/modules/exam-output/exam-output-service';
import examOutputImporterFields from '@/modules/exam-output/exam-output-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  ExamOutputService.import,
  examOutputImporterFields,
  i18n('entities.examOutput.importer.fileName'),
  i18n('entities.examOutput.importer.hint'),
);
