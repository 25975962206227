import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class StructurePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.structureRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.structureImport,
    );
    this.structureAutocomplete = permissionChecker.match(
      Permissions.values.structureAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.structureCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.structureEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.structureDestroy,
    );
  }
}
