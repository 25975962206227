import Roles from '@/security/roles';
const roles = Roles.values;

class UserDetailPermissions {
  static get values() {
    return {
      userDetailImport: {
        id: 'userDetailImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      userDetailCreate: {
        id: 'userDetailCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['userDetail'],
      },
      userDetailEdit: {
        id: 'userDetailEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['userDetail'],
      },
      userDetailDestroy: {
        id: 'userDetailDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['userDetail'],
      },
      userDetailRead: {
        id: 'userDetailRead',
        allowedRoles: [roles.owner, roles.admin],
      },
      userDetailAutocomplete: {
        id: 'userDetailAutocomplete',
        allowedRoles: [roles.owner, roles.admin],
      },
    };
  }
}

export default UserDetailPermissions;
