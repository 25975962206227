export default {
  //backend'de aynisi var
  readAloud: 'Read Aloud',
  repeatSentence: 'Repeat Sentence',
  describeImage: 'Describe Image',
  retellLecture: 'Re-tell Lecture',
  answerShortQuestion: 'Answer Short Question',
  summarizeWrittenText: 'Summarize Written Text',
  writeEssay: 'Write Essay',
  singleOption: 'Multiple Choice, Choose Single Option',
  multipleOption: 'Multiple Choice, Choose Multiple Option',
  reorderParagraph: 'Re-order Paragraph',
  fillInTheBlanks: 'Fill In The Blanks',
  selectedFillInTheBlanks: 'Selected Fill In The Blanks',
  highlightCorrectSummary: 'Highlight Correct Summary',
  summarizeSpokenText: 'Summarize Spoken Text',
  multipleAnswer: 'Multiple Choice, Choose Multiple Answer',
  writeInTheBlanks: 'Write In The Blanks',
  singleAnswer: 'Multiple Choice, Choose Single Answer',
  selectMissingWord: 'Select Missing Word',
  highlightIncorrectWords: 'Highlight Incorrect Words',
  writeFromTheDictation: 'Write From The Dictation',
};
