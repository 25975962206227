import { ExamService } from '@/modules/exam/exam-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class ExamField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/exam',
      Permissions.values.examRead,
      ExamService.listAutocomplete,
      record => {
        if (!record || !record.id) {
          return null;
        }

        return {
          id: record.id,
          //Relation yapan yerin tablosunda ne olarak gorunecegi baska alanlar eklenebilir.
          label: record.name
        };
      },
      options
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/exam',
      Permissions.values.examRead,
      ExamService.listAutocomplete,
      record => {
        if (!record || !record.id) {
          return null;
        }

        return {
          id: record.id,
          label: record.name
        };
      },
      options
    );
  }
}
