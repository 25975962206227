<template>
  <div class="header">
    <div>
      <h3>{{ questionTitle }}</h3>
    </div>
    <div>
      <el-button round :class="checkRTL.trim()">{{
        getQuestionLabel
      }}</el-button>
      <el-button
        round
        :class="('top-timer' + checkRTL).trim()"
      >
        <topTimer :key="1" :duration="timePassed" />
        <span>/</span>
        <span>{{ duration | formatDurationFilter }}</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import topTimer from './topTimer';

export default {
  name: 'question-header',
  components: {
    topTimer,
  },
  computed: {
    ...mapGetters({
      questionTitle: 'onlineExam/main/questionTitle',
      getQuestionLabel: 'onlineExam/main/getQuestionLabel',
      duration: 'onlineExam/main/duration',
      timePassed: 'onlineExam/main/timePassed',
      checkRTL: 'onlineExam/main/checkRTL',
    }),
  },
  props: {
    title: String,
    iconType: String,
  },
};
</script>

<style>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(60deg, #da3553, #8f1a2f);
  border-radius: 0.5rem;
  color: white;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
}

.header > div {
  padding: 0.75rem;
}

.top-timer > span {
  display: flex;
  justify-content: space-between;
}
.top-timer > span > div {
  width: 55px;
}

.top-timer > span > div + span {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.header h3 {
  margin-top: 0;
  margin-bottom: 0;
  text-transform: capitalize;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}
</style>
