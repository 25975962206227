<template>
  <div>
    <div class="struct-item-list-main">
      <div class="container-side">
        <Container class="w-100" @drop="onDrop">
          <Draggable v-for="(value, index) in rowsList" :key="index">
            <div class="single-drag">
              <span class="drag-number">{{index+1}}</span>
              <div class="drag-content">
                <div class="grid-cell">
                  <app-list-item-relation-to-one
                    :permission="fields.questionType.readPermission"
                    :url="fields.questionType.viewUrl"
                    :value="presenter(value, 'questionType')"
                  ></app-list-item-relation-to-one>
                </div>
                <div class="grid-cell">
                  <app-list-item-relation-to-many
                    :permission="fields.structItemGroups.readPermission"
                    :url="fields.structItemGroups.viewUrl"
                    :value="presenter(value, 'structItemGroups')"
                  ></app-list-item-relation-to-many>
                </div>
                <div class="grid-cell">
                  <span class="moreStr">{{ value.count }}</span>
                  <span class="lessStr"
                    ><app-i18n
                      code="entities.structure.fields.questionCount"
                    ></app-i18n
                  ></span>
                </div>
              </div>
              <router-link
                :to="
                  `/struct-item/${structureId}/${value.id}/${getPathViaMode}`
                "
              >
                <span class="drag-button"><i class="el-icon-view"></i></span>
              </router-link>
              <router-link
                :to="`/struct-item/${structureId}/${value.id}/edit`"
                v-if="hasPermissionToEdit && !IsViewMode"
              >
                <span class="drag-button"><i class="el-icon-edit"></i></span>
              </router-link>
              <span
                class="drag-button"
                :disabled="destroyLoading"
                @click="doDestroyWithConfirm(value.id)"
                v-if="hasPermissionToDestroy && !IsViewMode"
                ><i class="el-icon-delete"></i>
              </span>
            </div>
          </Draggable>
        </Container>
      </div>
    </div>
  </div>
</template>

<script>
import { StructItemModel } from '@/modules/structure/struct-item-model';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { StructurePermissions } from '@/modules/structure/structure-permissions';
import { i18n } from '@/i18n';
import { Container, Draggable } from 'vue-smooth-dnd';

const { fields } = StructItemModel;

export default {
  name: 'app-struct-item-list-table',
  components: {
    Draggable,
    Container
  },
  props: {
    IsViewMode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rowsList: []
    }
  },
  mounted() {
    //table set edilir.
    const structureSource = this.IsViewMode === true ? 'view' : 'form';
    this.setStructureSource(structureSource);
    this.doFetch();
    // this.doMountTable(this.$refs.table);
  },

  computed: {
    ...mapGetters({
      rows: 'structure/listSI/rows',
      structureId: 'structure/listSI/structureId',
      count: 'structure/listSI/count',
      loading: 'structure/listSI/loading',
      pagination: 'structure/listSI/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      destroyLoading: 'structure/destroySI/loading',
      paginationLayout: 'layout/paginationLayout'
    }),
    getPathViaMode() {
      return this.IsViewMode === true ? 'vm' : 'um';
    },

    hasPermissionToEdit() {
      return new StructurePermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new StructurePermissions(this.currentUser).destroy;
    },

    fields() {
      return fields;
    }
  },

  methods: {
    ...mapActions({
      doMountTable: 'structure/listSI/doMountTable',
      doFetch: 'structure/listSI/doFetch',
      doFind: 'structure/listSI/doFind',
      doDestroy: 'structure/destroySI/doDestroy',
      doUpdateOrder: 'structure/formSI/doUpdateOrder',
    }),
    ...mapMutations({
      setStructureSource: 'structure/listSI/STRUCTURE_SOURCE'
    }),
    presenter(row, fieldName) {
      return StructItemModel.presenter(row, fieldName);
    },

    async onDrop(dropResult) {
      this.rowsList = this.applyDrag(this.rowsList, dropResult);
      await this.updateOrder(this.structureId, this.rowsList);
      await this.doFind(this.structureId);
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = null;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },

    async updateOrder(id, values) {
      await this.doUpdateOrder({ id, values });
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning'
        });
        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    }
  },
  watch: {
    rows() {
      this.rowsList = this.rows;
    }
  }
};
</script>

<style scoped>
.struct-item-list-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container-side {
  flex: 3;
  display: flex;
  justify-content: center;
  padding: 40px;
}
.single-drag {
  padding: 0.5rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  margin: 0 1rem 1rem 1rem;
  display: flex;
  align-items: center;
  cursor: move;
}
.router-btns {
  float: right;
}
.drag-content {
  width: 83%;
  display: grid;
  grid-template-columns: [col1] 45% [col2] 25% [col3] 29%;
  text-align: center;
}
.grid-cell {
  padding: 5px;
  border-right: rgb(173, 173, 173) 0.5px solid;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.drag-number {
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
  margin: 0 0.5rem;
}
.drag-button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  margin: 0px 3px 0px 3px;
}
.drag-button:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}
.drag-button-disabled {
  opacity: 0.5;
}
.drag-button-disabled:hover {
  cursor: no-drop !important;
  background-color: transparent !important;
}
.m-top {
  margin-top: 1rem;
}
.moreStr {
  display: flex;
  justify-content: center;
}
.lessStr {
  font-size: 12px;
  display: flex;
  justify-content: center;
}
</style>
