import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import { ExamField } from '@/modules/exam/exam-field';
import { QuestionField } from '@/modules/question/question-field';
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.examOutput.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.examOutput.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  exam: ExamField.relationToOne('exam', label('exam'), {
    required: true,
  }),
  student: UserField.relationToOne(
    'student',
    label('student'),
    {
      required: true,
    },
  ),
  questionNumber: new IntegerField(
    'questionNumber',
    label('questionNumber'),
    {
      required: true,
    },
  ),
  outputStatus: new EnumeratorField(
    'outputStatus',
    label('outputStatus'),
    [
      {
        id: '0',
        label: enumeratorLabel('outputStatus', 'default'),
      },
      {
        id: '1',
        label: enumeratorLabel('outputStatus', 'fetched'),
      },
      {
        id: '2',
        label: enumeratorLabel('outputStatus', 'answered'),
      },
      {
        id: '3',
        label: enumeratorLabel(
          'outputStatus',
          'examFinished',
        ),
      },
      {
        id: '5',
        label: enumeratorLabel('outputStatus', 'assigned'),
      },
      {
        id: '6',
        label: enumeratorLabel('outputStatus', 'autoScore'),
      },
      {
        id: '7',
        label: enumeratorLabel('outputStatus', 'resulted'),
      },
      {
        id: '8',
        label: enumeratorLabel(
          'outputStatus',
          'autoResulted',
        ),
      },
    ],
  ),
  question: QuestionField.relationToOne(
    'questionId',
    label('question'),
    {
      required: true,
    },
  ),
  answer: new StringField('answer', label('answer'), {
    required: true,
  }),
  timeSpent: new IntegerField(
    'timeSpent',
    label('timeSpent'),
    {
      required: true,
      min: 0,
    },
  ),
  mouseMovements: new StringField(
    'mouseMovements',
    label('mouseMovements'),
    {},
  ),
  screenShots: new StringField(
    'screenShots',
    label('screenShots'),
    {},
  ),
  violationCount: new IntegerField(
    'violationCount',
    label('violationCount'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
  questionNumberRange: new IntegerRangeField(
    'questionNumberRange',
    label('questionNumberRange'),
  ),
  timeSpentRange: new IntegerRangeField(
    'timeSpentRange',
    label('timeSpentRange'),
  ),
  violationCountRange: new IntegerRangeField(
    'violationCountRange',
    label('violationCountRange'),
  ),
};

export class ExamOutputModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
