import { UserModel } from '@/modules/auth/user-model';

const { fields } = UserModel;

//IAM tablosunda hangi verilerin excel export/import edilecegi
export default [
  fields.email,
  fields.firstName,
  fields.lastName,
  //fields.phoneNumber,
  //fields.avatarsIam,
  //Asagidakileri excel'in import edildigi yere gore ata
  //fields.roles,
  //fields.languages
];
