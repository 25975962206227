import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import IntegerField from '../../shared/fields/integer-field';
import EnumeratorField from '../../shared/fields/enumerator-field';
import StringField from '../../shared/fields/string-field';
import { QuestionTypeField } from '../question-type/question-type-field';
import { QuestionGroupField } from '../question-group/question-group-field';
//import JsonField from "../../shared/fields/json-field";

function label(name) {
  return i18n(`entities.structure.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.structures.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('structItemId')),
  order: new IntegerField('order', label('order'), {
    required: true
  }),
  count: new IntegerField('count', label('count'), {
    required: true
  }),
  statusSI: new EnumeratorField('statusSI', label('statusSI'), [
    {
      id: '0',
      label: enumeratorLabel('statusSI', 'default')
    }
  ]),
  resultEffect: new StringField('resultEffect', label('resultEffect')),
  descEn: new StringField('descEn', label('descEn'), {required: true}),
  descAr: new StringField('descAr', label('descAr'), {required: true}),
  descTr: new StringField('descTr', label('descTr'), {required: true}),
  descriptions: new StringField('descriptions', label('descriptions')),
  //ID ile kendisi de geliyor tabloda string olarak goster, formda selectBox olarak sectir.
  //questionType: new StringField('questionType', label('questionType')),
  questionType: QuestionTypeField.relationToOne(
    'questionType',
    label('questionType'),
    {
      required: true
    }
  ),
  structItemGroups: QuestionGroupField.relationToMany(
    'structItemGroups',
    label('structItemGroups'),
    {
      required: true
    }
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange')
  )
};

export class StructItemModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
