import Permissions from '@/security/permissions';

const OnlineExamCheckBrowser = () =>
  import(
    '@/modules/online-exam/view/landing/checkbrowser/checkbrowser.vue'
  );

const OnlineExamInstructions = () =>
  import(
    '@/modules/online-exam/view/landing/instructions.vue'
  );

const TakeABreak = () =>
  import(
    '@/modules/online-exam/view/landing/takeABreak.vue'
  );

const Survey = () =>
  import('@/modules/online-exam/view/landing/survey.vue');

const OnlineExamIntro = () =>
  import('@/modules/online-exam/view/landing/intro.vue');

const AudioCheck = () =>
  import(
    '@/modules/online-exam/view/landing/audio-check.vue'
  );

const QuestionBase = () =>
  import('@/modules/online-exam/view/question/base.vue');

export default [
  {
    name: 'onlineExamCheckBrowser',
    path: '/checkbrowser/:id/:lang',
    component: OnlineExamCheckBrowser,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'onlineExamInstructions',
    path: '/instructions/:id/:lang',
    component: OnlineExamInstructions,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'onlineExamSurvey',
    path: '/survey/:examId',
    component: Survey,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'takeABreak',
    path: '/break/:examId/:qNum',
    component: TakeABreak,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'onlineExamQuestions',
    path: '/online-exam/:id/:index',
    component: QuestionBase,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'onlineExamIntro',
    path: '/intro/:id/:lang',
    component: OnlineExamIntro,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
  {
    name: 'audioCheck',
    path: '/audio-check/:id/:lang',
    component: AudioCheck,
    meta: {
      auth: true,
      permission: Permissions.values.examView,
    },
    props: true,
  },
];
