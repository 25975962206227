<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            :disabled="true"
            v-model="model[fields.id.name]"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        :label="fields.exam.label"
        :prop="fields.exam.name"
        :required="fields.exam.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-exam-autocomplete-input
            :fetchFn="fields.exam.fetchFn"
            :mapperFn="fields.exam.mapperFn"
            :showCreate="!modal"
            v-model="model[fields.exam.name]"
            mode="single"
          ></app-exam-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.student.label"
        :prop="fields.student.name"
        :required="fields.student.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-iam-user-autocomplete-input
            :fetchFn="fields.student.fetchFn"
            :mapperFn="fields.student.mapperFn"
            :showCreate="!modal"
            v-model="model[fields.student.name]"
            mode="single"
          ></app-iam-user-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.questionNumber.label"
        :prop="fields.questionNumber.name"
        :required="fields.questionNumber.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.questionNumber.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.outputStatus.label"
        :prop="fields.outputStatus.name"
        :required="true"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.outputStatus.name]"
          >
            <el-option
              :key="option.value"
              :label="option.label"
              :value="option.value"
              v-for="option in fields.outputStatus.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.question.label"
        :prop="fields.question.name"
        :required="fields.question.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-question-autocomplete-input
            :fetchFn="fields.question.fetchFn"
            :mapperFn="fields.question.mapperFn"
            :showCreate="!modal"
            v-model="model[fields.question.name]"
            mode="single"
          ></app-question-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.answer.label"
        :prop="fields.answer.name"
        :required="fields.answer.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input v-model="model[fields.answer.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.timeSpent.label"
        :prop="fields.timeSpent.name"
        :required="fields.timeSpent.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.timeSpent.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.mouseMovements.label"
        :prop="fields.mouseMovements.name"
        :required="fields.mouseMovements.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            v-model="model[fields.mouseMovements.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.screenShots.label"
        :prop="fields.screenShots.name"
        :required="fields.screenShots.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            v-model="model[fields.screenShots.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.violationCount.label"
        :prop="fields.violationCount.name"
        :required="fields.violationCount.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input-number
            :precision="0"
            :step="1"
            v-model="model[fields.violationCount.name]"
          ></el-input-number>
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ExamOutputModel } from '@/modules/exam-output/exam-output-model';

const { fields } = ExamOutputModel;
const formSchema = new FormSchema([
  fields.id,
  fields.exam,
  fields.student,
  fields.questionNumber,
  fields.outputStatus,
  fields.question,
  fields.answer,
  fields.timeSpent,
  fields.mouseMovements,
  fields.screenShots,
  fields.violationCount,
]);

export default {
  name: 'app-exam-output-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(
      this.record || {},
    );
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style></style>
