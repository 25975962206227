import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import { IamService } from '@/modules/iam/iam-service';
import { getLanguageCode } from '@/i18n';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    userType: {
      type: '',
      addUser: IamService.create,
      data: {},
    }, //student, teacher, user
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    // IsTeacher: (state) => state.userType.type === "teacher",
    // IsStudent: (state) => state.userType.type === "student",
    userType: (state) => state.userType,

    isRTL() {
      return UtilsModules.isRTL(getLanguageCode());
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    },

  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    ADD_STARTED(state) {
      state.saveLoading = true;
    },

    ADD_SUCCESS(state) {
      state.saveLoading = false;
    },

    ADD_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    SET_USER_AS_STUDENT(state, data) {
      //data -> examId, languages
      state.userType = {
        type: 'student',
        addUser: IamService.createStudent,
        data,
      };
    },
    SET_USER_AS_TEACHER(state, data) {
      state.userType = {
        type: 'teacher',
        addUser: IamService.createTeacher,
        data,
      };
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await IamService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/iam');
      }
    },

    async doAdd({ commit, getters }, values) {
      try {
        commit('ADD_STARTED');
        const data = Object.assign(
          values,
          getters.userType.data,
        ); //todo userType.data bos gelirse ne olur test et
        await getters.userType.addUser(data); //IamService -> create,createStudent or createTeacher
        commit('ADD_SUCCESS');
        Message.success(i18n('iam.doAddSuccess'));
        routerAsync().push('/iam');
      } catch (error) {
        Errors.handle(error);
        commit('ADD_ERROR');
      }
    },

    async doUpdate(
      { commit, rootGetters, dispatch },
      values,
    ) {
      try {
        commit('UPDATE_STARTED');
        await IamService.edit(values);
        commit('UPDATE_SUCCESS');

        const currentUser = rootGetters['auth/currentUser'];

        if (currentUser.id === values.id) {
          dispatch('auth/doRefreshCurrentUser');
        }

        Message.success(i18n('iam.doUpdateSuccess'));

        routerAsync().push('/iam');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
