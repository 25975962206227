import examOutputListStore from '@/modules/exam-output/exam-output-list-store';
import examOutputViewStore from '@/modules/exam-output/exam-output-view-store';
import examOutputAnalyzeStore from '@/modules/exam-output/exam-output-analyze-store';
import examOutputImporterStore from '@/modules/exam-output/exam-output-importer-store';
import examOutputFormStore from '@/modules/exam-output/exam-output-form-store';
import examOutputDestroyStore from '@/modules/exam-output/exam-output-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: examOutputDestroyStore,
    form: examOutputFormStore,
    list: examOutputListStore,
    view: examOutputViewStore,
    analyze: examOutputAnalyzeStore,
    importer: examOutputImporterStore
  }
};
