<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: `/struct-item/${structureId}/new` }"
      v-if="hasPermissionToCreate && structureId"
    >
      <el-button icon="el-icon-fa-plus" type="primary" :class="rtlAndPadding">
       <span class="card-title"> <app-i18n code="common.new"></app-i18n> </span>
      </el-button>
    </router-link>

    <!--    <el-tooltip-->
    <!--      :content="exportButtonTooltip"-->
    <!--      :disabled="!exportButtonTooltip"-->
    <!--    >-->
    <!--      <span>-->
    <!--        <el-button-->
    <!--          :disabled="exportButtonDisabled"-->
    <!--          @click="doExport()"-->
    <!--          icon="el-icon-fa-file-excel-o"-->
    <!--        >-->
    <!--          <app-i18n code="common.export"></app-i18n>-->
    <!--        </el-button>-->
    <!--      </span>-->
    <!--    </el-tooltip>-->
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { StructurePermissions } from '@/modules/structure/structure-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-struct-item-list-toolbar',
  //props: ['structureId'],
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      structureId: 'structure/listSI/structureId',
      hasRows: 'structure/listSI/hasRows',
      loading: 'structure/listSI/loading',
      exportLoading: 'structure/listSI/exportLoading',
      selectedRows: 'structure/listSI/selectedRows',
      destroyLoading: 'structure/destroySI/loading',
      rtlAndPadding: 'structure/form/rtlAndPadding',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new StructurePermissions(this.currentUser)
        .create;
    },

    hasPermissionToEdit() {
      return new StructurePermissions(this.currentUser)
        .edit;
    },

    hasPermissionToImport() {
      return new StructurePermissions(this.currentUser)
        .import;
    },

    hasPermissionToDestroy() {
      return new StructurePermissions(this.currentUser)
        .destroy;
    },

    exportButtonDisabled() {
      return (
        !this.hasRows || this.loading || this.exportLoading
      );
    },

    exportButtonTooltip() {
      return !this.hasRows
        ? i18n('common.noDataToExport')
        : null;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length
        ? i18n('common.mustSelectARow')
        : null;
    },

    destroyButtonDisabled() {
      return (
        !this.selectedRows.length ||
        this.loading ||
        this.destroyLoading
      );
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },
  methods: {
    ...mapActions({
      doExport: 'structure/listSI/doExport',
      doRemoveAllSelected:
        'structure/listSI/doRemoveAllSelected',
      doDisableAllSelected:
        'structure/listSI/doDisableAllSelected',
      doEnableAllSelected:
        'structure/listSI/doEnableAllSelected',
      doDestroyAll: 'structure/destroySI/doDestroyAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(
          i18n('common.areYouSure'),
          i18n('common.confirm'),
          {
            confirmButtonText: i18n('common.yes'),
            cancelButtonText: i18n('common.no'),
            type: 'warning',
          },
        );

        return this.doDestroyAll(
          // this.selectedRows.map((item) => item.id),
          this.structureId
        );
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
