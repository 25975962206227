import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ExamOutputListPage = () =>
  import(
    '@/modules/exam-output/components/exam-output-list-page.vue'
  );
// const ExamOutputFormPage = () =>
//   import(
//     '@/modules/exam-output/components/exam-output-form-page.vue'
//   );
const ExamOutputViewPage = () =>
  import(
    '@/modules/exam-output/components/exam-output-view-page.vue'
  );
// const ExamOutputImporterPage = () =>
//   import(
//     '@/modules/exam-output/components/exam-output-importer-page.vue'
//   );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'examOutput',
        path: '/exam-output',
        component: ExamOutputListPage,
        meta: {
          auth: true,
          permission: Permissions.values.examOutputView,
        },
      },
      // {
      //   name: 'examOutputNew',
      //   path: '/exam-output/new',
      //   component: ExamOutputFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.examOutputCreate,
      //   },
      // },
      // {
      //   name: 'examOutputImporter',
      //   path: '/exam-output/import',
      //   component: ExamOutputImporterPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.examOutputImport,
      //   },
      // },
      // {
      //   name: 'examOutputEdit',
      //   path: '/exam-output/:id/edit',
      //   component: ExamOutputFormPage,
      //   meta: {
      //     auth: true,
      //     permission: Permissions.values.examOutputEdit,
      //   },
      //   props: true,
      // },
      {
        name: 'examOutputView',
        path: '/exam-output/:id',
        component: ExamOutputViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.examOutputView,
        },
        props: true,
      },
    ],
  },
];
