import { StructureService } from '@/modules/structure/structure-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    }
  },

  actions: {
    async doFind({ commit }, id, structureId) {
      try {
        commit('FIND_STARTED');
        const record = await StructureService.findItem(id);
        // let structure = rootGetters['structure/form/record'];
        // console.log("STRUCTURE: ", structure);
        // const record = structure.structItemGroups.filter(si => si.id === id)[0];
        // console.log("RECORD ITEM VIEW: ", record);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push(`/structure/${structureId}/edit`);
      }
    }
  }
};
