import { StructureService } from '@/modules/structure/structure-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id, structureId) {
      try {
        commit('FIND_STARTED');
        // const structure = rootGetters['structure/form/record'];
        // const record = structure.structItemGroups.filter(si => si.id === id);
        const record = await StructureService.findItem(id);
        //Veri JSON frontend'de string olarak gosteriliyor.
        //Veriler netlestikten sonra ayri alanlara bolunmeli
        record.resultEffect = JSON.stringify(record.resultEffect);
        record.descEn = record.descriptions.en;
        record.descAr = record.descriptions.ar;
        record.descTr = record.descriptions.tr;
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push(`/structure/${structureId}/edit`);
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        values.resultEffect = JSON.parse(values.resultEffect);
        values.descriptions = {
          en: values.descEn,
          ar: values.descAr,
          tr: values.descTr
        };
        delete values.descEn;
        delete values.descAr;
        delete values.descTr;
        const created = await StructureService.addItem(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.structure.create.successSI'));
        routerAsync().push(`/structure/${created.structureId}/edit`);
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, {id, values }) {
      try {
        commit('UPDATE_STARTED');

        values.resultEffect = JSON.parse(values.resultEffect);
        values.descriptions = {
          en: values.descEn,
          ar: values.descAr,
          tr: values.descTr
        };
        delete values.descEn;
        delete values.descAr;
        delete values.descTr;
        const updated = await StructureService.updateItem(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.structure.update.successSI'));
        routerAsync().push(`/structure/${updated.structureId}/edit`);
      } catch (error) {
        if (error.message.includes('JSON')) {
          Message.error(i18n('common.failedJSON'));
        } else {
          Errors.handle(error);
        }
        commit('UPDATE_ERROR');
      }
    },

    async doUpdateOrder({ commit },{ id ,values }) {
      try {
        commit('UPDATE_STARTED');

        await StructureService.updateItemOrder(id, values);
        
        commit('UPDATE_SUCCESS');
      } catch (error) {
        if (error.message.includes('JSON')) {
          Message.error(i18n('common.failedJSON'));
        } else {
          Errors.handle(error);
        }
        commit('UPDATE_ERROR');
      }
    }
  }
};
