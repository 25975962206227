const backendUrl = `http://localhost:8080/api`;
const ihuPaymentService = 'http://localhost:8081/api';
const RECAPTCHA_SITEKEY = '6LdkxtkbAAAAAExLqqUDQ4Md8GCVQSC2XsuFwGMt';
const logo = { id:'ls', path: '/images/ls-logo.svg' };
export default {
  backendUrl,
  ihuPaymentService,
  RECAPTCHA_SITEKEY,
  logo
};
