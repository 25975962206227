import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const QuestionTypeListPage = () =>
  import(
    '@/modules/question-type/components/question-type-list-page.vue'
  );
const QuestionTypeFormPage = () =>
  import(
    '@/modules/question-type/components/question-type-form-page.vue'
  );
const QuestionTypeViewPage = () =>
  import(
    '@/modules/question-type/components/question-type-view-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'questionType',
        path: '/question-type',
        component: QuestionTypeListPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionTypeView,
        },
      },
      {
        name: 'questionTypeNew',
        path: '/question-type/new',
        component: QuestionTypeFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionTypeCreate,
        },
      },
      {
        name: 'questionTypeEdit',
        path: '/question-type/:id/edit',
        component: QuestionTypeFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionTypeEdit,
        },
        props: true,
      },
      {
        name: 'questionTypeView',
        path: '/question-type/:id',
        component: QuestionTypeViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionTypeView,
        },
        props: true,
      },
    ],
  },
];
