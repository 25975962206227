<template>
  <div class="edit-form">
    <div class="edit-fixed-buttons">
      <el-button
        :disabled="saveLoading"
        @click="doReset"
        icon="el-icon-fa-undo"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.reset"></app-i18n> </span>
      </el-button>

      <el-button
        :disabled="saveLoading"
        @click="doCancel"
        icon="el-icon-fa-close"
        :class="rtlAndPadding"
      >
       <span class="card-title"> <app-i18n code="common.cancel"></app-i18n> </span>
      </el-button>
    </div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model && settingCompleted === true"
    >
      <div>
        <el-steps
          v-if="isMultiStep"
          class="edit-steps"
          :active="activeStepIndex + 1"
        >
          <el-step
            title="Exam Info"
            icon="el-icon-info"
          ></el-step>
          <el-step
                  v-if="showQuestion"
                  title="Questions"
                  icon="el-icon-fa-tasks"
          ></el-step>
          <el-step
            v-if="showStudent"
            title="Students"
            icon="el-icon-user"
          ></el-step>
          <el-step
            title="Status"
            icon="el-icon-edit"
          ></el-step>
        </el-steps>
        <manage-exam-info
                v-if="steps[activeStepIndex] === 'examInfo'"
                :model="model"
                :is-editing="isEditing"
                :record="record"
                @setStepsVisibility="setSteps"
                :showStatusInFirstStep="isOneStep"
        />
        <div v-if="isEditing">
          <div v-if="steps[activeStepIndex] === 'questions'">
            <manage-questions
                    :model="model"
                    :record="record"
            />
          </div>
          <manage-students
                  v-if="steps[activeStepIndex] === 'students'"
                  :modal="modal"
                  :model="model"
                  :record="record"
          />
          <div v-if="steps[activeStepIndex] === 'status'">
            <el-form-item
              :label="fields.status.label"
              :prop="fields.status.name"
              :required="fields.status.required"
              key="step3"
            >
              <el-col :lg="11" :md="16" :sm="24">
                <el-select
                  v-model="model[fields.status.name]"
                  :disabled="lockPublished"
                >
                  <el-option
                    :key="option.id"
                    :label="option.label"
                    :value="option.id"
                    v-for="option in fields.status.options"
                  ></el-option>
                </el-select>
              </el-col>
            </el-form-item>
          </div>
        </div>
        <div class="multi edit-nav">
          <el-form-item v-show="activeStepIndex !== 0">
            <div class="form-buttons">
              <el-button
                icon="el-icon-fa-chevron-left"
                type="primary"
                @click="prev"
              >
                <app-i18n code="common.prev"></app-i18n>
              </el-button>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="form-buttons">
              <el-button
                v-if="activeStepIndex === (steps.length - 1)"
                :disabled="saveLoading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
                :class="rtlAndPadding"
              >
               <span class="card-title"> <app-i18n code="common.save"></app-i18n> </span>
              </el-button>
              <el-button
                icon="el-icon-fa-chevron-right"
                type="primary"
                @click="next"
                :class="rtlAndPadding"
                v-if="activeStepIndex !== (steps.length - 1)"
                :disabled="allowSaveWithoutStudents()"
              >
               <span class="card-title"> <app-i18n code="common.next"></app-i18n> </span>
              </el-button>
            </div>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ExamModel } from '@/modules/exam/exam-model';
import moment from 'moment';
import MemoryKeys from "../../../shared/memory-keys";
import ManageQuestions from "./manage-questions";
import ManageExamInfo from "./manage-exam-info";
import ManageStudents from "./manage-students";
const { fields } = ExamModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.examLowerLimit,
  fields.examUpperLimit,
  fields.status,
  fields.duration,
  fields.examType,
  fields.questionPools,
  fields.examLanguage,
  fields.isFree,
  fields.fixQuestions,
  fields.unScored,
  fields.structure,
  fields.examData,
  fields.students,
  fields.readers,
  fields.questions,
  fields.price,
]);

export default {
  name: 'app-exam-form',
  components: {ManageStudents, ManageExamInfo, ManageQuestions},
  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      activeStepIndex: 0,
      steps: ["examInfo"],
      showStudent: false,
      showQuestion: false,
      isMultiStep: false,
      isOneStep: true,
      settingCompleted: false,
      lockPublished:
        this.isEditing &&
        this.record.status === 'published',
    };
  },

  created() {
    this.model = formSchema.initialValues(
      this.record || this.getInitialValues(),
    );

    this.setSteps();
    this.settingCompleted = true
    if (this.record) {
      //Exam edit, add user fonksiyonu olarak ogrenci ekleme flagi
      this.setAsStudent({
        examId: this.record.id,
        languageId: this.record.examLanguageId,
        roles: ['student'],
      });
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      checkRTL: 'exam/form/checkRTL',
      rtlAndPadding: 'exam/form/rtlAndPadding',
      isExamRecordManual: 'exam/form/isExamManual'
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapMutations({
      setAsStudent: 'iam/form/SET_USER_AS_STUDENT',
    }),
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
    doCancel() {
      this.$emit('cancel');
    },
    async doSubmit() {
      try {
        //form rules'a gore validation yap
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      //yup ile schema'ya gore alanlar cast edilir
      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
    setStudentVisibility() { //todo computed olmasina engel nedir?
      //console.log("--- show Students Step")
      const showStudents =
              this.model[this.fields.fixQuestions.name] === false ||
              this.model[this.fields.isFree.name] === false;
      if (!showStudents) {
        this.model[this.fields.students.name] = [];
      }
      this.showStudent = showStudents;
    },
    setQuestionVisibility(){
      //console.log("--- show Questions Step")
      this.showQuestion = this.model[this.fields.examType.name] === "manual"
    },
    next() {
      this.activeStepIndex++;
      if(this.activeStepIndex >= this.steps.length){
        this.activeStepIndex = this.steps.length - 1
      }
    },
    prev() {
      this.activeStepIndex--;
      if(this.activeStepIndex < 0){
        this.activeStepIndex = 0
      }
    },
    getInitialValues(){
      const examRemembered = MemoryKeys.getExam();
      return {
        duration: (examRemembered && examRemembered.duration) ? examRemembered.duration : 180,
        price: (examRemembered && examRemembered.price) ? examRemembered.price : 99,
        name: moment().format('MMM Do YYYY'),
        examLanguage : (examRemembered && examRemembered.examLanguage) ? {id: examRemembered.examLanguage} : null,
        structure: (examRemembered && examRemembered.structure) ? {id: examRemembered.structure} : null,
        isFree: true,
        fixQuestions: true,
        unScored: false
        //examType: 'structured' Bunu acarsa data icindeki showStructures da default true olmali
      }
    },
    setSteps(options = null) {
      //console.log("--- setSteps")
      this.setQuestionVisibility();
      this.setStudentVisibility();
      this.isMultiStep = this.showStudent || this.showQuestion
      this.isOneStep = !this.isMultiStep
      //Edit formda student eklenecekse
      if (this.isEditing && this.isMultiStep) {
        if(this.showQuestion && this.showStudent){
          this.steps = ["examInfo","questions","students","status"];
        } else {
          if(this.showStudent){
            this.steps = ["examInfo","students","status"];
          }else{
            this.steps = ["examInfo","questions","status"];
          }
        }
        this.activeStepIndex = options
                ? options.activeStepIndex
                : 1;
      } else {
        this.activeStepIndex = 0;
      }
    },
    allowSaveWithoutStudents() {
      const atStudentsPage = this.steps[this.activeStepIndex] === 'students';
      const isNotSelected = this.model[this.fields.students.name].length < 1;
      const isFree = this.model[this.fields.isFree.name];
      const isNotFixed = !this.model[this.fields.fixQuestions.name];
      //Sadece free unfixed questions exam'de ogrenci eklemeden kayit etmesine izin vermiyoruz.
      return atStudentsPage && isNotSelected && isFree && isNotFixed;
    }
  },
};
</script>

<style scoped>


.single-drag {
  position: relative;
  border: 1px solid #efefef;
  padding: 1rem;
  font-size: 15px;
  line-height: 1.35;
  background-color: #f0f2f5;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 60px;
  margin-right: 70px;
}

.single-drag > span.drag-number {
  position: absolute;
  left: -2.5rem;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  color: white;
  background-color: #1c295c;
}
</style>