import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const LanguageListPage = () =>
  import(
    '@/modules/language/components/language-list-page.vue'
  );
const LanguageFormPage = () =>
  import(
    '@/modules/language/components/language-form-page.vue'
  );
const LanguageViewPage = () =>
  import(
    '@/modules/language/components/language-view-page.vue'
  );
const LanguageImporterPage = () =>
  import(
    '@/modules/language/components/language-importer-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'language',
        path: '/language',
        component: LanguageListPage,
        meta: {
          auth: true,
          permission: Permissions.values.languageView,
        },
      },
      {
        name: 'languageNew',
        path: '/language/new',
        component: LanguageFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.languageCreate,
        },
      },
      {
        name: 'languageImporter',
        path: '/language/import',
        component: LanguageImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.languageImport,
        },
      },
      {
        name: 'languageEdit',
        path: '/language/:id/edit',
        component: LanguageFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.languageEdit,
        },
        props: true,
      },
      {
        name: 'languageView',
        path: '/language/:id',
        component: LanguageViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.languageView,
        },
        props: true,
      },
    ],
  },
];
