import { StructureService } from '@/modules/structure/structure-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import { getLanguageCode } from '@/i18n';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading,
    isRTL() {
      return UtilsModules.isRTL(getLanguageCode());
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.structItems = [];
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.structItems = payload.structItems;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, payload) {
      state.record = payload;
      state.structItems = payload.structItems;
      state.findLoading = false;
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await StructureService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.showMessage(error);
        commit('FIND_ERROR');
        routerAsync().push('/structure');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const created = await StructureService.create(values);
        commit('CREATE_SUCCESS', created);
        Message.success(i18n('entities.structure.create.success'));
        routerAsync().push(`/structure/${created.id}/edit`);
      } catch (error) {
        Errors.showMessage(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await StructureService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.structure.update.success'));
        routerAsync().push('/structure');
      } catch (error) {
        Errors.showMessage(error);
        commit('UPDATE_ERROR');
      }
    }
  }
};
