import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);
export default {
  name: 'formatExamDurationFilter',
  implementation(value) {
    if (value) {
      return moment.duration(value, 'minutes').format(); //03 h 00 mins
    }
    return null;
  },
};
