import { ExamOutputModel } from '@/modules/exam-output/exam-output-model';

const { fields } = ExamOutputModel;

export default [
  fields.exam,
  fields.student,
  fields.questionNumber,
  fields.outputStatus,
  fields.question,
  fields.answer,
  fields.timeSpent,
  fields.mouseMovements,
  fields.screenShots,
  fields.violationCount,
];
