import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import ImagesField from '@/shared/fields/images-field';

function label(name) {
  return i18n(`entities.supportRequests.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.supportRequests.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'About_Payment',
        label: enumeratorLabel('type', 'About_Payment')
      },
      {
        id: 'Technical_Issues',
        label: enumeratorLabel('type', 'Technical_Issues')
      },
      {
        id: 'Comment',
        label: enumeratorLabel('type', 'Comment')
      }
    ],
    {
      required: true
    }
  ),
  message: new StringField('message', label('message'), {
    required: true,
    min: 0,
    max: 5000
  }),
  quality: new StringField('quality', label('quality')),
  difficulty: new StringField('difficulty', label('difficulty')),
  examname: new StringField('examname', label('examname')),
  username: new StringField('username', label('username')),
  useremail: new StringField('useremail', label('useremail')),
  images: new ImagesField('images', label('images'), 'supportRequests/images', {
    size: 3145728
  }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange')
  )
};

export class SupportRequestsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}