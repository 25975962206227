const tr = {
  lang: 'tr',
  common: {
    or: 'ya da',
    cancel: 'İptal',
    reset: 'Sıfırla',
    save: 'Kaydet',
    saveAs: 'Farklı Kaydet',
    update: 'Güncelle',
    publish: 'Kaydet ve Yayınla',
    publishAll: 'Skorları Yayınla',
    search: 'Ara',
    edit: 'Düzelt',
    scoreReport: 'Skor raporu',
    remove: 'Kaldır',
    new: 'Yeni',
    preview: 'Ön izleme',
    export: 'Excele aktar',
    noDataToExport: 'Aktarılacak data yok',
    import: 'Excelden aktar',
    discard: 'Vazgeç',
    yes: 'Evet',
    no: 'Hayır',
    pause: 'Duraklat',
    areYouSure: 'Emin misiniz?',
    view: 'Görüntüle',
    analyze: 'Analiz et',
    review: 'İncele',
    destroy: 'Sil',
    mustSelectARow: 'Aşağıdaki tablodan bir satır seçin',
    confirm: 'Onayla',
    next: 'İLERİ',
    prev: 'Önceki',
    saveQuestion: 'Soruyu kaydet',
    saveText: 'Metni kaydet',
    editor: 'Editör',
    add: 'Ekle',
    question: 'Soru',
    send: 'Gönder',
    failedJSON: 'JSON değeri doğru değil',
    breadcrumb: {
      noData: '--'
    }
  },

  app: {
    title: 'ITEX'
  },

  entities: {
    userDetail: {
      name: 'userDetail',
      label: 'Kullanıcı Detayları',
      menu: 'Kullanıcı Detayları',
      exporterFileName: 'kullaniciDetay_cikti',
      list: {
        menu: 'Kullanıcı Detayları',
        title: 'Kullanıcı Detayları'
      },
      create: {
        success: 'Kullanıcı detayı başarıyla kaydedildi'
      },
      update: {
        success: 'Kullanıcı detayı başarıyla kaydedildi'
      },
      destroy: {
        success: 'Kullanıcı detayı başarıyla silindi'
      },
      destroyAll: {
        success: 'Kullanıcı detayları başarıyla silindi'
      },
      edit: {
        title: 'Kullanıcı Detaylarını Düzenle'
      },
      fields: {
        id: 'No',
        userName: 'Kullanıcı Adı',
        studentNumber: 'Öğrenci Numarası',
        gender: 'Cinsiyet',
        birthdateRange: 'Doğum Tarihi',
        birthdate: 'Doğum Tarihi',
        userType: 'Kullanıcı Türü',
        nationality: 'Uyruk',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        gender: {
          male: 'Erkek',
          female: 'Kadın'
        },
        userType: {
          passive: 'Pasif',
          student: 'Öğrenci',
          teacher: 'Öğretmen',
          admin: 'Yönetici'
        }
      },
      new: {
        title: 'Yeni Kullanıcı Detayı'
      },
      view: {
        title: 'Kullanıcı Detayını Gör'
      },
      importer: {
        title: 'Kullanıcı Detaylarını Yükle',
        fileName: 'kullaniciDetaySablon',
        hint:
          'Dosya/Resim sütunları, boşlukla ayrılmış dosya URLleri olmalıdır.'
      }
    },

    question: {
      name: 'question',
      label: 'Sorular',
      menu: 'Sorular',
      exporterFileName: 'soru_aktar',
      list: {
        menu: 'Sorular',
        title: 'Sorular'
      },
      create: {
        success: 'Soru başarıyla kaydedildi'
      },
      update: {
        success: 'Soru başarıyla kaydedildi'
      },
      destroy: {
        success: 'Soru başarıyla silindi'
      },
      destroyAll: {
        success: 'Soru(lar) başarıyla silindi'
      },
      edit: {
        title: 'Soruyu Düzenle'
      },
      fields: {
        id: 'No',
        keywords: 'Anahtar Sözcük',
        createdById: 'Yazar',
        questionType: 'Soru Türü',
        section: 'Beceri',
        questionLanguage: 'Dil',
        initialTime: 'Hazırlık süresi (saniye)',
        recordTime: 'Kayıt süresi (saniye)',
        question: 'Soru',
        editor: 'Soru',
        optionsAnswer: 'Soru',
        readingTime: 'Okuma Süresi (saniye)',
        questionGroups: 'Soru Grubu',
        questionStatus: 'Durum',
        allowedUsers: 'Yetkili Öğretmenler',
        preVersions: 'Eski Versiyonlar',
        data: 'Veri',
        answer: 'Cevap',
        transcribe: 'Cevap',
        answerHint: 'Cevap İpucu',
        subtitle: 'Soru Cümlesi',
        lockUntil: 'Aktifleşme Tarihi',
        createdAt: 'Oluşturulma Zamanı',
        updatedAt: 'Güncellenme Zamanı',
        createdAtRange: 'Oluşturulma Zamanı',
        audio: 'Ses',
        image: 'Görsel',
        time: 'Süre (dk)',
        description: 'Tanım'
      },
      enumerators: {
        questionStatus: {
          default: 'Varsayılan',
          draft: 'Taslak',
          reviewed: 'Gözden Geçirilmiş',
          published: 'Yayınlanmış',
          locked: 'Kilitli',
          obsolete: 'Kullanım Dışı'
        },
        section: {
          writing: 'Yazma',
          reading: 'Okuma',
          speaking: 'Konuşma',
          listening: 'Dinleme'
        },
        questionGroup: {
          elementary: 'Elementary',
          preIntermediate: 'Pre Intermediate',
          intermediate: 'Intermediate',
          upperIntermediate: 'Upper Intermediate',
          advanced: 'Advanced',
          difficult: 'Difficult',
          medium: 'Medium',
          easy: 'Easy',
          itex: 'Itex',
          placement: 'Placement'
        }
      },
      new: {
        title: 'Yeni Soru'
      },
      view: {
        title: 'Soruyu Görüntüle'
      },
      importer: {
        title: 'Soru Yükle',
        fileName: 'soru_yukleme_sablonu',
        hint:
          'Dosya/ Resim sütunları, boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },

    readExam: {
      //examResult tablosuna veri girisi
      menu: 'Sınav Okuma',
      list: {
        menu: 'Sınav Okuma',
        title: 'Sınav Okuma'
      }
    },

    scoreOutput: {
      name: 'scoreOutput',
      label: 'Skorlar',
      menu: 'Skorlar',
      fields: {
        scoreStatus: 'Durum',
        teacher: 'Öğretmen',
        id: 'No',
        examOutput: 'Cevap No',
        score: 'Puan',
        content: 'İçerik',
        pronunciation: 'Telaffuz',
        oralFluency: 'Akıcılık',
        form: 'Biçim',
        grammar: 'Dil Bilgisi',
        vocabulary: 'Kelime Bilgisi',
        development: 'Gelişmişlik, Yapı ve Tutarlılık',
        linguistic: 'Genel Sözel Düzen',
        spelling: 'Yazım',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        questionTitle: 'Soru',
        responseTitle: 'Cevap',
        answerStatisticsTitle: "Cevap İstatistikleri",
        wordCountTitle: "Kelime Sayısı",
        timeSpentTitle: "Harcanan Zaman",
        timeSpentUnit: "Saniye",
        timeSpentUnitMinutes: "Dakika",
        answerHint: 'Cevap Ipucu'
      },
      list: {
        menu: 'Skorlar',
        title: 'Skorlar'
      },
      edit: {
        title: 'Puanı Düzenle'
      },
      view: {
        title: 'Puanı Görüntüle'
      },
      new: {
        title: 'Yeni Puan'
      },
      publish: 'Skorları Yayınla',
      enumerators: {
        scoreStatus: {
          draft: 'Taslak',
          saved: 'Kaydedildi',
          published: 'Yayınlandı',
          evaluated: 'Hesaplandı'
        }
      },
      create: {
        success: 'Cevaplar başarıyla atandı'
      },
      update: {
        success: 'Cevap başarıyla kaydedildi'
      },
      updateAll: {
        success: 'Cevaplar başarıyla kaydedildi'
      },
      breadcrumb: {
        scoreReadAloud: 'Sesli Okuma Puanla',
        scoreRepeatSentence: 'Cümleyi Tekrarı Puanla',
        scoreDescribeImage: 'Resim Betimleme Puanla',
        scoreRetellLecture: 'Tekrar Anlatma Puanla',
        scoreAnswerShortQuestion: 'Kısa Soru Cevaplama Puanla',
        scoreSummarizeWrittenText: 'Yazılı Metni Özetleme Puanla',
        scoreWriteEssay: 'Kompozisyon Yazma Puanla',
        scoreSummarizeSpokenText: 'Sözlü Metni Özetleme Puanla'
      },
      noRecord: 'Puanlanacak bir cevap yok',
      publishRecord: 'Yayınlamak için Skorlar sayfasına gidin',
      types: {
        answerShortQuestion: {
          content: {
            description: `Hatalar = Değiştirmeler, gözden kaçırmalar ve eklemeler, duraksamalar, doldurulmuş veya doldurulmamış duraklamalar, önde gelen veya sondaki materyal İçeriğinin puanlamasında yok sayılır`,
            explanations: [
              `Konunun bazı alakasız parçalarından bahseder`,
              `Konunun bazı temel öğelerini açıklar, ancak bunların kendi aralarındaki ilişkileri veya çıkarımlarını netleştirmez `
            ]
          }
        },
        describeImage: {
          content: {
            description: ` Hatalar = Değiştirmeler, gözden kaçırmalar ve eklemeler, duraksamalar, doldurulmuş veya doldurulmamış duraklamalar, önde gelen veya sondaki materyal içeriğinin puanlamasında yok sayılır`,
            explanations: [
              `Görselin bazı alakasız parçalarından bahseder`,
              `Görselin bazı temel öğelerini açıklar, ancak bunların kendi aralarındaki ilişkileri veya çıkarımlarını netleştirmez`,
              `Görseldeki tek bir anahtar öğeyle ilgilenir ve bir çıkarım veya sonuca atıfta bulunur. Görüntünün birkaç temel öğesine odaklanır`,
              `Görselin en önemli unsurlarıyla ilgilenir ve bunların çıktılarına veya sonuçlarına atıfta bulunur`,
              `Çıktılarına veya sonuçlarına atıfta bulunarak, görselin tüm kilit unsurlarını ve ilişkilerini açıklar.`,
              `Görüntünün tüm unsurlarını ve ilişkilerini, olası gelişimini ve sonucunu veya çıkarımlarını açıklar`
            ]
          }
        },

        readAloud: {
          content: {
            description: `Değiştirilen, atlanan veya fazladan eklenen her bir sözcük/ek hata olarak sayılır. Maksimum puan:okunan metnin uzunluğuna bağlıdır`
          }
        },

        repeatSentence: {
          content: {
            description: `Hatalar = Değiştirmeler, gözden kaçırmalar ve eklemeler, duraksamalar, doldurulmuş veya doldurulmamış duraklamalar, önde gelen veya sondaki materyal içeriğinin puanlamasında yok sayılır`,
            explanations: [
              `Cevapla dinletilen arasında neredeyse hiçbir benzerlik yok`,
              `Cevaptaki kelimelerin yarısından azı doğru sıralamaya sahip`,
              `Cevaptaki kelimelerin yarısı doğru sıralamaya sahip`,
              `Cevaptaki bütün kelimeler doğru sıralamaya sahip`
            ]
          }
        },

        retellLecture: {
          content: {
            description: `Hatalar = Değiştirmeler, gözden kaçırmalar ve eklemeler, duraksamalar, doldurulmuş veya doldurulmamış duraklamalar, önde gelen veya sondaki materyal içeriğinin puanlamasında yok sayılır`,
            explanations: [
              `Dinletilen metnin bazı alakasız parçalarından bahseder`,
              `Dinletilen metnin bazı temel öğelerini açıklar, ancak bunların kendi aralarındaki ilişkileri veya çıkarımlarını netleştirme`,
              `Dinletilen metnin tek bir anahtar öğeyle ilgilenir ve bir çıkarım veya sonuca atıfta bulunur. Görüntünün birkaç temel öğesine odaklanır`,
              `Dinletilen metnin en önemli unsurlarıyla ilgilenir ve bunların çıktılarına veya sonuçlarına atıfta bulunur`,
              `Çıktılarına veya sonuçlarına atıfta bulunarak dinletilen metnin tüm kilit unsurlarını ve ilişkilerini açıklar`,
              `Dinletilen metnin tüm noktalarını yeniden anlatır ve karakterleri, yönleri ve eylemleri, ilişkilerini, temeldeki gelişimi, çıkarımları ve sonuçları açıklar`
            ]
          }
        },

        summarizeSpokenText: {
          content: {
            description: 'İçerik',
            explanations: [
              `Ana noktaları atlar veya yanlış ifade eder`,
              `Dinletilenin bütümcül bir özetini sağlar ancak bir veya iki nokta eksiktir`,
              `Dinletilenin tam  bir özetini sağlar. Bütün noktalara değinir`
            ]
          },
          form: {
            explanations: [
              `40 kelimeden az ya da 100 kelimeden fazla içerir. Özet büyük harflerle yazılmıştır, noktalama işareti içermez veya yalnızca konu başlıkları veya çok kısa cümlelerden oluşur`,
              `40-49 ya da 71-100 kelime içerir`,
              `50-70 içerir`
            ]
          },
          grammar: {
            explanations: [
              `İletişimi engelleyebilecek hatalı dilbilgisi yapısı`,
              `İletişimi engellemeyen dilbilgisi hataları`,
              `Neredeyse tümüyle doğru dilbilgisi yapıları`
            ]
          },
          vocabulary: {
            explanations: [
              `İletişimi engelleyebilecek hatalı kelime kullanımları`,
              `İletişimi engellemeyen kelime kullanım hataları`,
              `Neredeyse tümüyle doğru kelime kullanımları`
            ]
          },
          spelling: {
            explanations: [
              `Birden fazla sesletim hatası`,
              `Bir sesletim hatası`,
              `Hatasız sesletim`
            ]
          }
        },

        summarizeWrittenText: {
          content: {
            description: 'Okuma & Yazma',
            explanations: [
              `Metnin ana noktaları atlar veya yanlış ifade eder`,
              `Metnin bütümcül bir özetini sağlar ancak bir veya iki nokta eksiktir`,
              `Metnin tam  bir özetini sağlar. Bütün noktalara değinir`
            ]
          },
          form: {
            description: 'Biçim, Dilbilgisi, Kelime',
            explanations: [
              `Doğru yazılmış tam bir cümle yok ya 5ten az 75ten fazla kelime içeriyor.. Özet büyük harflerle yazılmıştır`,
              `Bir tam, bütüncül cümle halinde yazılmış`
            ]
          },
          grammar: {
            explanations: [
              `Anlamı engelleyebilecek hatalı dilbilgisi yapısına sahiptir`,
              `Anlama zarar vermeyen dilbilgisi hataları içerir`,
              `Neredeyse tümüyle doğru dilbilgisi yapıları`
            ]
          },
          vocabulary: {
            explanations: [
              `İletişimi engelleyebilecek hatalı kelime kullanımları`,
              `İletişimi engellemeyen kelime kullanım hataları`,
              `Neredeyse tümüyle doğru kelime kullanımları`
            ]
          }
        },

        writeEssay: {
          content: {
            description:
              'İçerik; biçim; geliştirme, yapı, uyum; dibilgisi; genel dilbilimsel aralığı; kelime aralığı; sesletim',
            explanations: [
              `Yönergeye tam olarak uymayan içerik`,
              `Yönergeyle kısmen uyumlu fakat büyük bir noktanın ya da küçük birkaç noktanın atlandığı bir içerik`,
              `Yönergeyle kısmen uyumlu fakat küçük bir noktanın atlandığı bir içerik`,
              `Tüm yönleriyle yönergeye uyan bir içerik`
            ]
          },
          form: {
            explanations: [
              `Uzunluk 120 kelimeden az ya da 380 kelimeden fazla . Kompozisyon büyük harflerle yazılmış, noktalama işareti içermez veya yalnızca madde başlıkları veya çok kısa cümlelerden oluşur`,
              `Uzunluk  120-199 kelime arasında ya da 301-380 kelime arasında`,
              `Uzunluk 200-300 kelime arasında`
            ]
          },
          grammar: {
            explanations: [
              `Temel olarak basit yapılar ve / veya birkaç temel hata içerir`,
              `Görece ileri düzeyde dilbilgisi yeterliliği gösterir. Yanlış anlamalara neden olacak hata yoktur`,
              `İleri düzeyde komplike dilbilgisi kullanımları gösterir . Hatalar nadirdir ve fark edilmesi zordur`
            ]
          },
          vocabulary: {
            explanations: [
              `Konuyu gerekli düzeyde ifade etmek için yeterli olmayan temel kelimeleri içerir`,
              `Genel akademik konularla ilgili konularda iyi bir kelime dağarcığı gösterir. Kelime hataları kesintiye veya bir miktar belirsizliğe yol açar`,
              `Geniş bir kelime haznesine, deyimsel ifadelere ve konuşma diline oldukça hakim`
            ]
          },
          development: {
            explanations: [
              `Tutarsızlık yoktur ve temel olarak ana başlıklardan veya bağımsız öğelerden oluşur`,
              `Görece zayıf bir yapılandırma vardır. Bazı öğeler ve paragraflar zayıf bir şekilde bağlanmış`,
              `İyi bir anlatım ve bütüncül bir yapı içerir`
            ]
          },
          linguistic: {
            explanations: [
              `Temel olarak basit bir dil içerir ve hassasiyetten yoksundur`,
              `Net açıklamalar sağlamak, bakış açılarını ifade etmek ve argümanlar geliştirmek için yeterli dil aralığı`,
              `Düşünceleri doğru bir şekilde formüle etmek, vurgu yapmak, farklılaştırmak ve belirsizliği ortadan kaldırmak için çok çeşitli dil ustalıkları sergiler. Sınava giren kişinin iletişim kurmak istediğinde kısıtlandığına dair işaret yok`
            ]
          },
          spelling: {
            explanations: [
              `Birden fazla yazım hatası`,
              `Bir yazım hatası`,
              `Doğru yazım`
            ]
          }
        },

        writeFromDictation: {
          content: {
            description: 'Dinleme ve yazma',
            explanations: [
              `Kelimeler yanlış ya da eksik yazılmış`,
              `Her doğru kelime doğru yazılmış`
            ]
          }
        },

        pronunciation: {
          titles: [
            'Anlaşılmaz',
            'Zayıf',
            `Orta seviye`,
            `İyi`,
            `Çok iyi`,
            `Anadil seviyesi`
          ],
          explanations: [
            `Sesletim başka bir dilin özelliklerini taşıyor. Birçok sessiz ve sesli harf yanlış anlaşılmış, yanlış düzenlenmiş veya atlanmıştır. Dinleyiciler konuşmanın yarısından fazlasını anlaşılmaz bulabilir. Vurgulu ve vurgusuz heceler Türkçeye uygun olmayan bir şekilde söylenir. Birkaç kelime yanlış sayıda heceye sahip olabilir`,
            `Birçok sessiz ve sesli harf yanlış sesletilir, bu da güçlü bir müdahaleci dış aksanla sonuçlanır. Dinleyiciler kelimelerin 1 / 3'ünü anlamakta güçlük çekebilirler. Birçok sessiz harf çarpıtılmış veya atlanmış olabilir. Sessiz harfler  Türkçe olmayabilir. Vurgu Türkçeye uygun olmayan bir şekilde uygulanır; vurgulanmamış kelimeler azaltılabilir veya atlanabilir ve birkaç hece eklenebilir veya gözden kaçırılabilir`,
            `Bazı harfler tutarlı olmayan bir şekilde sürekli yanlış anlaşılır. Konuşmanın en az 2 / 3'ü anlaşılabilir, ancak dinleyicilerin aksana uyum sağlaması gerekebilir. Bazı sessiz harfler düzenli olarak çıkarılır ve sessiz harf dizileri basitleştirilebilir. Vurgu bazı kelimelerde yanlış uygulanmış veya belirsiz olabilir.`,
            `Sesli harflerin ve sessizlerin çoğu doğru sesletilir. Bazı tutarlı hatalar birkaç kelimeyi belirsizleştirebilir. Belirli bağlamlardaki birkaç sessiz harf düzenli olarak bozulabilir, atlanabilir veya yanlış anlaşılabilir. Birkaç kelimede vurguya bağlı sesli harf azalması meydana gelebilir.`,
            `Harfler düzgün ve açık bir şekilde telaffuz edilir. Birkaç küçük harf veya vurgu hatası anlaşılabilirliği etkilemez. Tüm kelimeler kolayca anlaşılabilir. Birkaç sessiz harf veya sessiz harf dizisi çarpıtılmış olabilir. Vurgu, tüm yaygın kelimelerde doğru uygulanır ve cümle düzeyinde vurgu mantıklıdır.`,
            `Tüm harfler, dilin normal konuşmacıları tarafından kolayca anlaşılabilecek şekilde sesletilir. Vurgu tüm kelimelerlde doğru bir şekilde uygulanır ve cümle düzeyinde vurgu tamamen uygundur.`
          ]
        },
        oralFluency: {
          titles: [
            `Tutuk`,
            `Sınırlı`,
            `Orta seviye`,
            `İyi`,
            `Çok iyi`,
            `Anadil seviyesi`
          ],
          explanations: [
            `Konuşma yavaş ve zahmetli bir şekilde ifade edilebilir gruplama, çoklu duraksamalar, duraklamalar, yanlış başlangıçlar ve / veya büyük fonolojik basitleştirmelerle zor anlaşılır haldedir. Çoğu kelime yutulmuştur ve birden fazla uzun duraklama olabilir.`,
            `Konuşma düzensiz ifadeler veya cümle ritmine sahiptir. Kötü ifade, kesintili veya hece zamanlaması ve / veya çoklu duraksamalar, tekrarlar ve / veya yanlış başlangıçlar, sözlü performansı önemli ölçüde dengesiz veya süreksiz yapar. Uzun ifadeler bir veya iki uzun duraklamaya ve uygunsuz cümle/ kelime vurgusuna sahip olabilir.`,
            `Konuşma düzensiz veya kesik kesik olabilir. Konuşma (> = 6 kelime ise) en az üç düzgün kelimeden oluşur ve en fazla iki veya üç duraksama, tekrar veya yanlış başlatma içerir. Bir uzun duraklama olabilir, ancak iki veya daha fazla olamaz.`,
            `Konuşma kabul edilebilir bir hızda ancak düzensiz olabilir. Birden fazla duraksama olabilir, ancak çoğu kelime sürekli tekrar edilir. Birkaç tekrarlama veya yanlış başlatma vardır. Uzun duraklamalar yoktur ve konuşma kesik kesik değildir.`,
            `Konuşma, uygun kelime öbeği ve kelime vurgusu ile kabul edilebilir bir ritme sahiptir. Birden fazla duraksama, bir tekrar veya yanlış başlangıç yoktur. Türkçeye uygun olmayan önemli fonolojik sadeleştirmeler yoktur.`,
            'Konuşma düzgün bir ritim ve cümle yapısına sahiptir. Duraksama, tekrar, yanlış başlangıç ​​veya Türkçeye uygun olmayan önemli fonolojik sadeleştirmeler yoktur.'
          ]
        }
      },
      spelllangexplanations: {
        title: 'If editor spelling mistakes are not working properly',
        explanations: [
          '1- On your chrome browser at the top right, click three dots > Settings',
          '2- At the bottom, click Advanced.',
          '3- You can now turn on spell check for the language you will use',
          '4- You can add the language if the language is not listed',
          '4.1- Under "Languages," click Language.',
          '4.2- Add it by clicking Add languages.'
        ]
      }
    },

    exam: {
      name: 'exam',
      label: 'Sınavlar',
      menu: 'Sınavlar',
      menuTests: 'Sınavlar',
      exporterFileName: 'sinavi_aktar',
      list: {
        menu: 'Sınavlar',
        title: 'Sınavlar'
      },
      create: {
        success: 'Sınav başarıyla kaydedildi',
        saveQuestions: 'Soruları Kaydet',
        totalPoint: 'Toplam Puan'
      },
      update: {
        success: 'Sınav başarıyla kaydedildi',
        questions: 'Sınav soruları başarıyla kaydedildi',
      },
      destroy: {
        success: 'Sınav başarıyla silindi'
      },
      destroyAll: {
        success: 'Sınav(lar) başarıyla silindi'
      },
      edit: {
        title: 'Sınavı Düzenle'
      },
      fields: {
        id: 'No',
        name: 'İsim',
        examUpperLimitRange: 'Üst Tarih Sınır Aralığı',
        examUpperLimit: 'Üst Tarih Sınırı',
        examLowerLimitRange: 'Alt Tarih Sınır Aralığı',
        examLowerLimit: 'Alt Tarih Sınırı',
        status: 'Durum',
        durationRange: 'Süre (dakika)',
        duration: 'Süre (dakika)',
        examType: 'Sınav Türü',
        examData: 'Sınav Verisi',
        structure: 'Yapı',
        students: 'Öğrenciler',
        readers: 'Değerlendirenler',
        examLanguage: 'Dil',
        isFree: 'Ücretsiz',
        price: 'Ücret (TL)',
        unScored: 'Okuma Yapılmasın',
        fixQuestions: 'Sabit Sorular',
        questions: 'Sorular',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        status: {
          draft: 'Taslak',
          published: 'Yayınlandı'
        },
        examType: {
          structured: "Yapılandırılmış",
          manual: 'Manuel'
        }
      },
      new: {
        title: 'Yeni Sınav'
      },
      view: {
        title: 'Sınavı Görüntüle'
      },
      importer: {
        title: 'Sınavları İçe Aktar',
        fileName: 'sinav_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      },
      expDate: 'Bitiş Tarihi',
      noTimeLimit: 'Bitiş Tarihi Yok',
      testListPage: {
        btnStartTest: 'TESTE BAŞLA',
        btnContinueTest: 'TESTE DEVAM ET',
        btnUnlockTest: 'TESTİ AÇ',
        btnLoadingTest: 'ÇOK YAKINDA',
        downloadAppWarning: {
          warningText: 'Devam edebilmek için ITEX uygulamasını indiriniz.',
          confirm: 'Onay',
          cancel: 'İptal'
        },
        useAppWarning: "Devam edebilmek için ITEX uygulamasını kullanınız"
      }
    },
    questionType: {
      name: 'questionType',
      label: 'Soru Türleri',
      menu: 'Soru Türleri',
      exporterFileName: 'soruTurleri_aktar',
      list: {
        menu: 'Soru Türleri',
        title: 'Soru Türleri'
      },
      create: {
        success: 'Soru Türü başarıyla kaydedildi'
      },
      update: {
        success: 'Soru Türü başarıyla kaydedildi'
      },
      destroy: {
        success: 'Soru Türü başarıyla silindi'
      },
      destroyAll: {
        success: 'Soru Türleri başarıyla silindi'
      },
      edit: {
        title: 'Soru Türünü Düzenle'
      },
      fields: {
        id: 'No',
        questionType: 'Soru Türü',
        autoScore: 'Otomatik Puanla',
        section: 'Bölüm',
        title: 'Başlık',
        description: 'Açıklama',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        section: {
          reading: 'Okuma',
          speaking: 'Konuşma',
          listening: 'Dinleme',
          writing: 'Yazma'
        },
        questionType: {
          'Read Aloud': 'Sesli Okuma',
          'Repeat Sentence': 'Cümle Tekrarı',
          'Describe Image': 'Resim Betimleme',
          'Re-tell Lecture': 'Tekrar Anlatma',
          'Answer Short Question': 'Kısa Soru Cevaplama',
          'Summarize Written Text': 'Yazılı Metni Özetleme',
          'Write Essay': 'Kompozisyon Yazma',
          'Multiple Choice, Choose Single Option': 'Çoktan Seçmeli Tek Yanıt',
          'Multiple Choice, Choose Multiple Option':
            'Çoktan Seçmeli Çoklu Yanıt',
          'Re-order Paragraph': 'Paragraf Sıralama',
          'Fill In The Blanks': 'Boşluk Doldurma',
          'Selected Fill In The Blanks': 'Seçmeli Boşluk Doldurma',
          'Highlight Correct Summary': 'Doğru Özeti Seçme',
          'Summarize Spoken Text': 'Sözlü Metni Özetleme',
          'Multiple Choice, Choose Multiple Answer':
            'Çoktan Seçmeli Çoklu Cevap',
          'Write In The Blanks': 'Yazarak Boşluk Doldurma',
          'Multiple Choice, Choose Single Answer': 'Çoktan Seçmeli Tekli Cevap',
          'Select Missing Word': 'Eksik Kelimeyi Seçme',
          'Highlight Incorrect Words': 'Yanlış Kelimeleri Seçme',
          'Write From The Dictation': 'Söyleneni Yazma'
        }
      },
      new: {
        title: 'Yeni Soru Türü'
      },
      view: {
        title: 'Soru Türünü Görüntüle'
      },
      importer: {
        title: 'Soru Türlerini İçe Aktar',
        fileName: 'soruturu_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },
    questionGroup: {
      name: 'questionGroup',
      label: 'Soru Seviyesi',
      menu: 'Soru Seviyeleri',
      exporterFileName: 'soruseviyeleri_aktar',
      list: {
        menu: 'Soru Seviyeleri',
        title: 'Soru Seviyeleri'
      },
      create: {
        success: 'Soru Seviyesi başarıyla kaydedildi.'
      },
      update: {
        success: 'Soru Seviyesi başarıyla kaydedildi.'
      },
      destroy: {
        success: 'Soru Seviyesi başarıyla silindi.'
      },
      destroyAll: {
        success: 'Soru Seviyeleri başarıyla silindi.'
      },
      edit: {
        title: 'Soru Seviyesini Düzenle'
      },
      fields: {
        id: 'No',
        questionGroup: 'Soru Grubu',
        groupStatus: 'Durum',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        groupStatus: {
          default: 'Varsayılan'
        }
      },
      new: {
        title: 'Soru Seviyesi Oluştur'
      },
      view: {
        title: 'Soru Seviyesini Görüntüle'
      }
    },
    examOutput: {
      name: 'examOutput',
      label: 'Sınav Çıktıları',
      menu: 'Sınav Çıktıları',
      exporterFileName: 'sınavciktisi_aktar',
      list: {
        menu: 'Sınav Çıktıları',
        title: 'Sınav Çıktıları'
      },
      create: {
        success: 'Sınav Çıktısı başarıyla kaydedildi'
      },
      update: {
        success: 'Sınav Çıktısı başarıyla kaydedildi'
      },
      destroy: {
        success: 'Sınav Çıktısı başarıyla silindi'
      },
      destroyAll: {
        success: 'Sınav Çıktıları başarıyla silindi'
      },
      edit: {
        title: 'Sınav Çıktısını Düzenle'
      },
      fields: {
        id: 'No',
        exam: 'Sınav',
        student: 'Öğrenci',
        questionNumberRange: 'Soru Numarası',
        questionNumber: 'Numara',
        outputStatus: 'Durum',
        question: 'Soru',
        answer: 'Cevap',
        timeSpentRange: 'Harcanan Zaman (saniye)',
        timeSpent: 'Harcanan Zaman (saniye)',
        mouseMovements: 'Fare Hareketleri',
        screenShots: 'Ekran Görüntüleri',
        violationCountRange: 'İhlal Sayısı',
        violationCount: 'İhlal Sayısı',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        outputStatus: {
          default: 'Default',
          fetched: 'Okundu',
          answered: 'Cevaplandı',
          examFinished: 'Sınav Tamamlandı',
          assigned: 'Öğretmenlere Atandı',
          autoScore: 'Otomatik Notlandır',
          resulted: 'Sonuçlandı',
          autoResulted: 'Sonuçlandı'
        }
      },
      new: {
        title: 'Yeni Sınav Çıktısı'
      },
      view: {
        title: 'Sınav Çıktısı Görüntüle'
      },
      importer: {
        title: 'Sınav Çıktılarını İçe Aktar',
        fileName: 'sınavCiktisi_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },

    unlocktest: {
      card: {
        cardholder: 'Kart Sahibi',
        fullname: 'Ad Soyad',
        expires: 'Geçerlilik'
      },
      form: {
        cardnumber: 'Kart Numarası',
        fullname: 'Ad Soyad',
        expirationdate: 'Son Kullanım Tarihi',
        month: 'Ay',
        year: 'Yıl',
        submit: 'Kilidi Kaldır',
        invalidCardNumber: 'Geçersiz Kart Numarası'
      }
    },

    checkbrowser: {
      title: 'Teknik Gereklilikler',
      description:
        'Lütfen aşağıdaki gerekliliklere uyduğunuzdan emin olun ve devam etmek için ITEX Uygulamasını kullanın',
      adblockertext: 'AdBlocker, Grammarly gibi eklentileri kullanmayın',
      ischrometext: 'Tarayıcı olarak Chrome kullanın',
      browserversiontext: 'Tarayıcının eski versiyonlarını kullanmayın',
      checkPwa: 'Sınava devam etmek için ITEX Uygulamasını kullanın'
    },

    introduction: {
      title: 'Genel Yönerge Seti',
      description:
        'Aşağıdaki yönergeleri okuyunuz ve bu yönergelere bağlı kalınız.',
      introductions: [
        `Bu testin tüm hakları saklıdır. Ticari ve başka amaçlarla kullanılamaz.
      `,
        `Sınav ITEX yönergelerine göre 4 bölümden oluşur Konuşma, Yazma, Okuma ve Dinleme. `,
        ` Bir ara vermek isterseniz, lütfen sınav penceresini ve oturumunuzu kapatın. Sınav için tekrar hazır olduğunuzda
        sadece giriş yapın ve sınava devam edin. Sınav bıraktığınız yerden devam edecektir.`,
        `İyi bir internet hızına sahip olmanızı, güncellenmiş bir sistem ve tarayıcı kullanmanızı tavsiye ederiz.`,
        `Aynı süre içerisinde aynı kimlikle birden çok sınava giremezsiniz. `,
        `En iyi endüstri standardını sizlere sunmamıza rağmen, bazen kullanıcılar yavaş yükleme hızı, yavaş internet, geçersiz tarayıcı, bozuk ses sürücüleri, problemli önbellek gibi ortamlarda sınava girebiliyorlar.
        Bu problemleri çözmek için her kullanıcıyı yönlendiremesek de bu gibi problemlerle karşılaşan kullancılara, sınav pencerelerini ve oturumlarını kapatmalarını, sistemi tekrar açmalarını ve sınava girmelerini öneriyoruz.
        Ayrıca sınava gizli sekmede de girmeyi deneyebilirler.`,
        `Herhangi bir soru için, kolayca ana ekranda bulunan destek hattımızdan bizimle iletişime geçebilir veya sınav sonunda geri bildirimlerinizi gönderebilirsiniz.`,
        `ITEX sınavınızda başarılar diliyoruz.`
      ]
    },
    layoutintro: {
      title: 'Sınav Girişi',
      description:
        'Bu sınav akademide ihtiyaç duyacağınız ${language} dil becerilerinizi ölçer.',
      desclist: [
        `Zamanlayıcı, sınav soru sayısının yanında ekranın sağ üst köşesindedir.`,
        `Her ekrandaki İleri tuşuna basınca cevabınızı onaylamış olursunuz ve diğer soruya geçersiniz. İleri tuşuna basarsanız bir önceki soruya dönemezsiniz.Sınavın sonunda hiçbir soruya geri dönemeyeceksiniz.`,
        `Elektrik ya da internet kesintisi gibi bir aksaklık durumunda, tekrar giriş yaparak sınava kaldığınız yerden devam edebilirsiniz.`,
        `Sınavda ara vereceğiniz zaman ilgili sayfa size gösterilecektir`
      ]
    },
    audiocheck: {
      title: 'Ses Kontrolü',
      description:
        'Şimdi kulaklığınızı ve mikrofonunuzu düzgün çalıştığından emin olmak için test edeceğiz.',
      getmicrophone: 'Mikrofon İzni',
      startrecord: 'Kaydı Başlat',
      stoprecord: 'Dur',
      recording: 'Kaydediyor...',
      loading: 'Yükleniyor',
      tryagain: 'Tekrar Dene',
      desclist: [
        ` Kulaklığınızın mikrofonunu ağzınıza yakın yerleştiriniz.`,
        `"Mikrofonu getir" tuşuna ve izin için "İzin Al"a basınız.`,
        `Hazır olduğunuzda "Kayıt"a basınız ve konuşunuz
        "Merhaba, ben ITEX sınavında başarılı olacağım."`,
        `Konuşmanız bittiğinde "Dur" tuşuna basınız.`,
        `Konuşmanızı duymak için "Oynat" tuşuna basınız.`,
        `Sesinizi duyamazsanız kulaklığınızı ve sistem ayarlarınızı kontrol ediniz.`
      ]
    },
    takeabreak: {
      title: 'Sınav Arası - ${time} dakika',
      description:
        '${time} dakika içinde sınav sorularını cevaplamaya devam edebilirsiniz.',
      message: 'Kalan süre...'
    },
    onlineExam: {
      question: 'Soru',
      audioRecorder: {
        recordingWillBegin: 'Kayıt $textTimer$ saniye sonra başlayacaktır...',
        listeningWillBegin:
          'Dinlemeye $textTimer$ saniye sonra başlayacaksınız...',
        message: 'Kaydediliyor...',
        waitText: 'Bekleyiniz',
        listeningText: 'Dinleyiniz',
        titleText: 'Audio Recorder'
      },
      types: {
        readAloud: {
          type: 'Sesli Okuma',
          description:
            'Aşağıdaki metni inceleyiniz. $initialTime$ saniye sonra bu metni doğal ve açık şekilde okumalısınız. Sesli okumaya başlamak için $readingTime$ saniye hazırlık süreniz vardır.'
        },
        repeatSentence: {
          type: 'Cümle Tekrarı',
          description:
            'Bir cümle duyacaksınız. Bu cümleyi tam olarak duyduğunuz gibi tekrar ediniz.Cümleyi sadece bir kez duyacaksınız.'
        },
        describeImage: {
          type: 'Görsel Üzerine Konuşma',
          description:
            'Aşağıdaki grafiği inceleyeniz. $initialTime$ saniye sonra mikrofona doğru konuşarak bu grafiği detaylı bir şekilde açıklayınız. Cevaplamak için $recordTime$ saniye süreniz vardır.'
        },
        retellLecture: {
          type: 'Dinlediğini Yeniden Anlatma',
          description:
            'Bir konuşma dinleyeceksiniz. Konuşma bittikten $initialTime$ saniye sonra mikrofona doğru konuşarak dinlediklerinizi kendi sözcüklerinizle yeniden anlatın.  Cevaplama süreniz $recordTime$ saniyedir.'
        },
        answerShortQuestion: {
          type: 'Kısa Soru Cevaplama',
          description:
            'Bir soru duyacaksınız. Lütfen basit ve kısa bir cevap veriniz. Genellikle 1 ya da 2 kelime yeterli olacaktır.'
        },
        summarizeWrittenText: {
          type: 'Metin Özetleme',
          description:
            'Aşağıdaki paragrafı okuyunuz ve bir cümle ile özetleyiniz. Cevabınızı ekranın altındaki kutuya yazınız. Bitirmek için $time$ dakikanız vardır. Cevabınız, yazınızın kalitesine ve paragraftaki önemli noktaları ne kadar iyi açıkladığınıza göre değerlendirilecektir.'
        },
        writeEssay: {
          type: 'Metin Yazma',
          description:
            'Aşağıdaki konuda bir metin yazmak için $time$ dakikanız vardır. Yazdıklarınız fikrinizi nasıl geliştirdiğinize göre değerlendirilecektir. Fikirlerinizi düzenleyin. Destekleyici ayrıntılar sununuz ve kelimelerin yazımlarına dikkat ediniz. Yazılan metnin uzunluğu 200 ila 300 kelime arasında olmalıdır.\n'
        },
        singleOption: {
          type: 'Çoktan Seçmeli Tek Cevaplı',
          description:
            'Metni okuyunuz ve doğru seçeneği işaretleyiniz. Sadece bir cevap doğrudur.'
        },
        multipleOption: {
          type: 'Çoktan Seçmeli Çok Cevaplı',
          description:
            'Metni okuyunuz ve seçeneklerdeki doğru cevapları işaretleyiniz. Seçeneklerde birden fazla doğru cevap bulunabilir.'
        },
        reorderParagraph: {
          type: 'Paragraf Sıralama',
          description:
            'Aşağıdaki metin kutuları karışık bir şekilde verilmiştir. Bu metin kutularını anlamlı bir metin oluşturacak şekilde sürükleyerek sıralayınız.'
        },
        fillInTheBlanks: {
          type: 'Boşluk Doldurma',
          description:
            'Aşağıdaki metinde bazı sözcükler eksiktir. Metnin altındaki sözcük kutularını metindeki uygun yerlere sürükleyiniz. Cevabınızı geri almak için sözcüğü metnin altındaki kutuya sürükleyiniz.'
        },
        selectedFillInTheBlanks: {
          type: 'Şıklı Boşluk Doldurma',
          description:
            'Aşağıda boşluklu bir metin vardır. Her boşluğa tıklayınız, seçenekler listesi görünecektir. Her boşluk için uygun cevabı seçiniz.'
        },
        highlightCorrectSummary: {
          type: 'Özet Seçme',
          description:
            'Ses kaydını dinleyiniz ve kaydı en kapsamlı şekilde özetleyen paragrafı seçiniz.'
        },
        summarizeSpokenText: {
          type: 'Özet Yazma',
          description:
            'Bir metin dinleyeceksiniz.  Dinlediğiniz bu metni 50-70 sözcük ile özetleyiniz. Süreniz $time$ dakikadır. Yanıtınız, yazınızın kalitesine ve yönergede sunulan önemli noktaları ne kadar iyi sunduğuna değerlendirilecektir.'
        },
        multipleAnswer: {
          type: 'Çoktan Seçmeli Çok Cevaplı',
          description:
            'Kaydı dinleyiniz ve mümkün olan tüm cevapları işaretleyiniz. Birden fazla cevap seçmelisiniz.'
        },
        writeInTheBlanks: {
          type: 'Boşluğa Yazma',
          description:
            'Bir ses kaydı dinleyeceksiniz. Her boşluktaki eksik kelimeleri yazınız.'
        },
        singleAnswer: {
          type: 'Çoktan Seçmeli Tek Cevaplı',
          description:
            'Kaydı dinleyiniz ve doğru cevabı seçerek çoktan seçmeli soruyu cevaplayınız. Sadece bir cevap doğrudur.'
        },
        selectMissingWord: {
          type: 'Eksik İfadeyi Seçme',
          description:
            'Bir ses kaydı dinleyeceksiniz. Kaydın sonunda son kelime veya kelime grubu bir ses ile değiştirildi. Kaydı tamamlamak için doğru seçeneği işaretleyiniz.'
        },
        highlightIncorrectWords: {
          type: 'Yanlış İfadeyi Seçme',
          description:
            'Bir ses kaydı dinleyeceksiniz. Aşağıda bu kayıtta okunan metin vardır. Bu metindes bazı kelimeler konuşmacının söylediğinden farklıdır. Farklı olan kelimelere tıklayınız.'
        },
        writeFromTheDictation: {
          type: 'Dikte',
          description:
            'Bir cümle duyacaksınız. Cümleyi duyduğunuz şekilde, aşağıdaki kutuya yazınız. Cümleyi mümkün olduğunca tam yazın. Cümleyi sadece bir kez duyacaksınız.'
        }
      },
      section: {
        writing: 'Yazma',
        reading: 'Okuma',
        listening: 'Dinleme',
        speaking: 'Konuşma'
      },
      word: 'Kelime(ler)',
      validations: {
        writeEssay: {
          lessWordsMessage: 'kelime sonra 200 kelimeye ulaşılacak',
          tooWordsMessage: 'Kelime sınırı aşıldı.'
        },
        summarizeWrittenText: {
          tooWordsMessage: 'kelime kaldırırsanız sınırı aşmamış olacaksınız'
        },
        summarizeSpokenText: {
          lessWordsMessage: 'kelime sonra 50 kelimeye ulaşılacak',
          tooWordsMessage: 'Kelime sınırı aşıldı'
        },
        writeFromTheDictation: {
          wordlimitexceeded: 'Kelime sınırı aşıldı!'
        }
      }
    },
    survey: {
      title: 'Anket',
      description: 'Tebrikler! Sınavınızı başarıyla tamamladınız.',
      header: 'Sınavını değerlendir',
      qualityTitle: 'Sınavın Kalitesi',
      qualities: {
        poor: 'Düşük',
        average: 'Orta',
        good: 'İyi',
        excellent: 'Mükemmel',
        outstanding: 'Olağanüstü'
      },
      difficultyTitle: 'Sınavın Zorluk Seviyesi',
      difficulties: {
        soEasy: 'Düşük',
        easy: 'Basit',
        average: 'Orta',
        difficult: 'Zor',
        soDifficult: 'Çok Zor'
      },
      placeholder: 'Lütfen Sınav Deneyiminizi Paylaşınız'
    },
    supportRequests: {
      name: 'supportRequests',
      label: 'Destek Talepleri',
      menu: 'Destek Talepleri',
      exporterFileName: 'destekTalepleri_aktar',
      list: {
        menu: 'Destek Talepleri',
        title: 'Destek Talepleri'
      },
      create: {
        success: 'Destek Talebi başarıyla kaydedildi'
      },
      update: {
        success: 'Destek Talebi başarıyla kaydedildi'
      },
      destroy: {
        success: 'Destek Talebi başarıyla silindi'
      },
      destroyAll: {
        success: 'Destek Talepleri başarıyla kaydedildi'
      },
      edit: {
        title: 'Destek Taleplerini Düzenle'
      },
      fields: {
        id: 'No',
        type: 'Tür',
        message: 'Mesaj',
        images: 'Görsel',
        createdAt: 'Oluşturulma tarihi',
        updatedAt: 'Güncellenme tarihi',
        createdAtRange: 'Oluşturulma tarihi',
        quality: 'Kalite',
        difficulty: 'Zorluk',
        exam: 'Sınav',
        comment: 'Comments',
        examname: 'Exam Name',
        username: 'User Name',
        useremail: 'User E-Mail'
      },
      enumerators: {
        type: {
          About_Payment: 'Ödeme Hakkında',
          Technical_Issues: 'Teknik Problemler',
          Comment: 'Yorum'
        }
      },
      new: {
        title: 'Yeni Destek Talepleri'
      },
      view: {
        title: 'Destek Taleplerini Görüntüle'
      },
      importer: {
        title: 'Destek Taleplerini İçe Aktar',
        fileName: 'destekTalepleri_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },
    language: {
      name: 'language',
      label: 'Dil',
      menu: 'Diller',
      exporterFileName: 'dilleri_aktar',
      list: {
        menu: 'Diller',
        title: 'Diller'
      },
      create: {
        success: 'Dil başarıyla kaydedildi'
      },
      update: {
        success: 'Dil başarıyla kaydedildi'
      },
      destroy: {
        success: 'Dil başarıyla silindi'
      },
      destroyAll: {
        success: 'Dil(ler) başarıyla kaydedildi'
      },
      edit: {
        title: 'Dili Düzenle'
      },
      fields: {
        id: 'No',
        code: 'Dil',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi'
      },
      enumerators: {
        language: {
          en: 'İngilizce',
          ar: 'Arapça',
          tr: 'Türkçe'
        }
      },
      new: {
        title: 'Yeni Dil'
      },
      view: {
        title: 'Dili Görüntüle'
      },
      importer: {
        title: 'Dilleri İçe Aktar',
        fileName: 'diller_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },

    teacher: {
      menu: 'Öğretmen Menüsü',
    },

    student: {
      menu: 'Öğrenci Menüsü',
    },

    structure: {
      name: 'structure',
      label: 'Sınav Yapısı',
      menu: 'Sınav Yapısı',
      exporterFileName: 'sinav_yapisi_disaaktar',
      list: {
        menu: 'Sınav Yapısı',
        title: 'Sınav Yapısı'
      },
      create: {
        success: 'Sınav Yapısı başarıyla kaydedildi',
        successSI: 'Yapı Öğesi başarıyla kaydedildi'
      },
      update: {
        success: 'Sınav Yapısı başarıyla kaydedildi',
        successSI: 'Yapı Öğesi başarıyla kaydedildi'
      },
      destroy: {
        success: 'Sınav Yapısı başarıyla silindi',
        successSI: 'Yapı Öğesi başarıyla silindi'
      },
      destroyAll: {
        success: 'Sınav Yapıları başarıyla silindi',
        successSI: 'Yapı Öğeleri başarıyla silindi'
      },
      edit: {
        title: 'Sınav Yapısını Düzenle',
        titleSI: 'Yapı Öğesini Düzenle'
      },
      fields: {
        //structure
        id: 'No',
        structureName: 'Yapı İsmi',
        statusStructure: 'Durum',
        hasBreak: 'Ara Ekle',
        breakBefore: 'Öncesinde Ara',
        breakLimit: 'Ara Süresi (dakika)',
        students: 'Öğrenciler',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'GÜncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi',
        //structItems
        structItemId: 'No',
        order: 'Sıra',
        count: 'Sayı',
        statusSI: 'Durum',
        resultEffect: 'Sonuca Etkisi',
        questionType: 'Soru Türü',
        structItemGroups: 'Soru Grubu',
        descriptions: "Açıklamalar",
        descEn: 'İngilizce',
        descAr: 'Arapça',
        descTr: 'Türkçe',
        questionCount: 'Soru Sayısı'
      },
      enumerators: {
        statusStructure: {
          default: 'Default'
        },
        statusSI: {
          default: 'Default'
        }
      },
      new: {
        title: 'Yeni Sınav Yapısı',
        titleSI: 'Yeni Yapı Öğesi'
      },
      view: {
        title: 'Sınav Yapısını Görüntüle',
        titleSI: 'Yapı Öğesini Görüntüle'
      }
    },
    order: {
      name: 'order',
      label: 'Siparişler',
      menu: 'Siparişler',
      exporterFileName: 'siparis_export',
      list: {
        menu: 'Siparişler',
        title: 'Siparişler'
      },
      create: {
        success: 'Sipariş başarıyla kaydedildi'
      },
      destroy: {
        success: 'Sipariş başarıyla silindi'
      },
      destroyAll: {
        success: 'Sipariş(ler) başarıyla silindi'
      },
      fields: {
        id: 'Id',
        amount: 'Ücret',
        paymentSystem: 'Ödeme Sistemi',
        psOrderNo: 'PS Numarası',
        orderExpireAt: 'Bitiş Tarihi',
        description: 'Açıklama',
        currency: 'Birim',
        cardData: 'Kart Verisi',
        orderData: 'Sipariş Verisi',
        orderStatus: 'Sipariş Durumu',
        student: 'Öğrenci',
        exams: 'Sınavlar',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi'
      },
      enumerators: {},
      view: {
        title: 'Sipariş Görüntüle'
      },
      new: {
        title: 'Sınavı Aç'
      }
    },
    classroom: {
      name: 'classroom',
      label: 'Sınıflar',
      menu: 'Sınıflar',
      exporterFileName: 'sinifi_aktar',
      list: {
        menu: 'Sınıflar',
        title: 'Sınıflar'
      },
      create: {
        success: 'Sınıf başarıyla kaydedildi'
      },
      update: {
        success: 'Sınıf başarıyla kaydedildi'
      },
      destroy: {
        success: 'Sınıf başarıyla silindi'
      },
      destroyAll: {
        success: 'Sınıf(lar) başarıyla silindi'
      },
      edit: {
        title: 'Sınıfı Düzenle'
      },
      fields: {
        id: 'No',
        className: 'Sınıf İsmi',
        language: 'Dil',
        classLevel: 'Sınıf Seviyesi',
        status: 'Durum',
        students: 'Öğrenciler',
        createdAt: 'Oluşturulma Zamanı',
        updatedAt: 'Güncellenme Zamanı',
        createdAtRange: 'Oluşturulma Zamanı'
      },
      enumerators: {
        language: {
          English: 'İngilizce',
          Arabic: 'Arapça',
          Turkish: 'Türkçe'
        },
        classLevel: {
          Beginner: 'Başlangıç',
          Elementary: 'Temel',
          Intermediate: 'Orta',
          Upper_Intermediate: 'Orta Üstü',
          Advanced: 'İleri',
          Proficiency: 'Yeterlilik'
        }
      },
      new: {
        title: 'Yeni Sınıf'
      },
      view: {
        title: 'Sınıfı Görüntüle'
      },
      importer: {
        title: 'Sınıfları İçe Aktar',
        fileName: 'sinif_sablonu',
        hint:
          'Dosyalar/Görseller sütunu boşlukla ayrılmış dosyaların URLleri olmalıdır.'
      }
    },
    examResult: {
      name: 'examResult',
      label: 'Sınav Sonuçları',
      menu: 'Sınav Sonuçları',
      exporterFileName: 'sınavSonucu_aktar',
      list: {
        menu: 'Sınav Sonuçları',
        title: 'Sınav Sonuçları',
        announceAll: 'Sonuçları Duyur'
      },
      create: {
        success: 'Sınav Sonucu başarıyla kaydedildi.'
      },
      update: {
        success: 'Sınav Sonucu başarıyla kaydedildi.'
      },
      reScore: {
        button: 'Tekrar Notlandır',
        success: 'Sınav tekrar okumaya gönderildi'
      },
      destroy: {
        success: 'Sınav Sonucu başarıyla silindi.'
      },
      destroyAll: {
        success: 'Sınavç Sonuç(ları) başarıyla kaydedildi.'
      },
      edit: {
        title: 'Sınav Sonucunu Düzenle'
      },
      fields: {
        id: 'No',
        reading: 'Okuma',
        readingNumberRange: 'Okuma',
        speaking: 'Konuşma',
        speakingNumberRange: 'Konuşma',
        writing: 'Yazma',
        writingNumberRange: 'Yazma',
        listening: 'Dinleme',
        listeningNumberRange: 'Dinleme',
        grammar: 'Dilbilgisi',
        oralFluency: 'Konuşma Akışı',
        pronunciation: 'Telaffuz',
        spelling: 'Yazım',
        vocabulary: 'Kelime bilgisi',
        writtenDiscourse: 'Yazılı Söylem',
        aftermath: 'Sonuç',
        aftermathNumberRange: 'Sonuç',
        results: 'Sonuçlar',
        resultStatus: 'Durum',
        exam: 'Sınav',
        examName: 'Sınav Adı',
        studentName: 'Öğrenci Adı',
        studentEmail: 'Öğrenci E-posta',
        student: 'Öğrenci',
        createdAt: 'Oluşturulma Tarihi',
        updatedAt: 'Güncellenme Tarihi',
        createdAtRange: 'Oluşturulma Tarihi',
        totalQC: 'Soru',
        answeredQC: 'Cevaplanan',
        examSessionDate: 'Sınav Tarihi',
        totalTimeSpent: 'Harcanan Süre'
      },
      enumerators: {
        resultStatus: {
          default: 'Default',
          calculated: 'Hesaplandı',
          updated: 'Güncellendi',
          announced: 'Duyuruldu',
          oldVersion: 'Eski Versiyon'
        }
      },
      new: {
        title: 'Yeni Sınav Sonucu'
      },
      view: {
        title: 'Sınav Sonucunu Görüntüle'
      },
      analyze: {
        title: 'Sınav Sonucunu Analiz Et',
        prev: 'Önceki',
        next: 'Sonraki',
        yourResponse: 'Cevabınız'
      },
      questionPreview: {
        listening: 'Dinle',
        yourResponse: 'Cevabınız',
        audioRecorder: 'Ses kaydedici'
      },
      scorePage: {
        headerTitle: 'ITEX Puan Raporu',
        scoreReportCode: 'Puan Rapor Kodu',
        testId: 'Sınav ID',
        registrationId: 'Kayıt ID',
        overallScore: 'Genel puanı',
        communicativeSkills: 'İletişim Yetenekleri',
        enablingskills: 'Yeteneklerin Etkinleştirilmesi',
        skillsBreakdown: 'Beceri Dağılımı',
        testCentreInformation: {
          title: 'Sınav Merkezi Bilgileri',
          testDate: 'Sınav Tarihi',
          validUntil: 'Son Geçerlilik',
          issueDate: 'Veriliş tarihi',
          testCentreCountry: 'Test Merkezi Ülkesi',
          testCentreId: 'Test Merkezi ID',
          testCentre: 'Test Merkezi'
        },
        candidateInformation: {
          title: 'Aday Bilgileri',
          dateofBirth: 'Doğum Tarihi',
          countryofCitizenship: 'Vatandaşı olduğu ülke',
          countryofResidence: 'İkamet edilen ülke',
          gender: 'Cinsiyet'
        },
        descriptiontext: "The Overall Score for the ITEX Academic is based on the test takers performance on all items in the test. The scores for Communicative Skills and Enabling Skills are based on the test taker's performance on only those items that pertain to these skills specifically. As many items contribute to more than one Communicative or Enabling Skills, the Overall Score cannot be computed directly from the Communicative Skill scores or from the Enabling Skill scores. The graphs below indicates this test taker's Communicative Skills and Enabling Skills. When comparing the Overall Score and the scores for Communication Skills and Enabling Skills, please be aware that there is some imprecision in all assessment, depending on a variety of factors.",
      }
    }
  },
  auth: {
    profile: {
      title: 'Profili Düzenle',
      success: 'Profil başarıyla güncellendi'
    },
    createAnAccount: 'Bir hesap oluştur',
    rememberMe: 'Beni hatırla',
    forgotPassword: 'Şifreyi unuttum',
    signin: 'Giriş yap',
    signup: 'Kayıt ol',
    signout: 'Çıkış yap',
    alreadyHaveAnAccount: 'Zaten bir hesabınız var mı? Giriş yapın.',
    signinWithAnotherAccount: 'Farklı bir hesapla giriş yapın',
    emailUnverified: {
      message: `Lütfen devam etmek için <strong>{0}</strong> e-posta adresinizi onaylayın.`,
      submit: `Doğrulama e-postasını tekrar gönder`
    },
    emptyPermissions: {
      message: `Henüz izniniz yok. Onay sürecini bekleyiniz.`
    },
    passwordResetEmail: {
      message: 'Şifre sıfırlama e-postası gönder',
      error: `E-posta tanımlı değil`
    },
    passwordReset: {
      message: 'Şifre sıfırla'
    },
    emailAddressVerificationEmail: {
      error: `E-posta tanımlı değil`
    },
    verificationEmailSuccess: `Doğrulama e-postası başarıyla gönderildi`,
    passwordResetEmailSuccess: `Şifre sıfırlama e-postası başarıyla gönderildi`,
    passwordResetSuccess: `Şifre başarıyla değiştirildi`,
    verifyEmail: {
      success: 'E-posta başarıyla onaylandı',
      message: 'Bir dakika, e-postanız doğrulanıyor...'
    }
  },

  roles: {
    owner: {
      label: 'Sahip',
      description: 'Tüm kaynaklara tam erişim'
    },
    admin: {
      label: 'Yönetici',
      description: 'Sistem Yöneticisi'
    },
    teacher: {
      label: 'Öğretmen',
      description: 'Sınavları Hazırlama ve Okuma'
    },
    student: {
      label: 'Öğrenci',
      description: 'Sınav Olma ve Çıktıları Alma'
    },
    canCreateExam: {
      label: 'Sınav oluştur',
      description: 'Sınavları Oluştur ve Düzenle'
    },
    canListQEs: {
      label: 'Sorular ve Sınavlar Listesi',
      description: 'Soruları ve Sınavları Görüntüleyebilme'
    }
  },

  iam: {
    title: 'Kimlik ve Erişim Yönetimi',
    menu: 'Kullanıcılar',
    disable: 'Pasifleştir',
    disabled: 'Pasif',
    enabled: 'Etkin',
    enable: 'Etkinleştir',
    doEnableSuccess: 'Kullanıcı başarıyla etkinleştirildi',
    doDisableSuccess: 'Kullanıcı başarıyla pasifleştirildi',
    doDisableAllSuccess: 'Kullanıcı(lar) başarıyla pasifleştirildi',
    doDestroyAllSuccess: 'Kullanıcı(lar) başarıyla silindi',
    doEnableAllSuccess: 'Kullanıcı(lar) başarıyla etkinleştirildi',
    doAddSuccess: 'Kullanıcı(lar) başarıyla kaydedildi',
    doUpdateSuccess: 'Kullanıcı başarıyla kaydedildi',
    viewBy: 'Görüntüleyen',
    users: {
      name: 'users',
      label: 'Kullanıcılar',
      exporterFileName: 'kullanicilar_aktar',
      doRemoveAllSelectedSuccess: 'İzinler başarıyla kaldırıldı'
    },
    roles: {
      label: 'Görevler',
      doRemoveAllSelectedSuccess: 'İzinler başarıyla kaldırıldı'
    },
    edit: {
      title: 'Kullancıyı Düzenle'
    },
    new: {
      title: 'Yeni Kullanıcı(lar)',
      titleModal: 'Yeni Kullanıcı',
      emailsHint: 'Virgül kullanarak birden çok e-posta adresini ayırın.'
    },
    view: {
      title: 'Kullanıcı Görüntüle',
      activity: 'Aktivite'
    },
    importer: {
      studentTitle: 'Öğrencileri İçe Aktar',
      teacherTitle: 'Öğretmenleri İçe Aktar',
      studentFilename: 'ogrenciler_sablon',
      teacherFilename: 'ogretmenler_sablon',
      hint: ''
    },
    errors: {
      userAlreadyExists: 'Bu e-postaya sahip kullanıcı zaten var',
      userNotFound: 'Kullanıcı bulunamadı',
      disablingHimself: `Kendinizi devre dışı bırakamazsınız`,
      revokingOwnPermission: `Kendi sahip izninizi iptal edemezsiniz`
    }
  },

  user: {
    fields: {
      id: 'No',
      authenticationUid: 'Doğrulama No',
      avatars: 'Görsel',
      email: 'E-posta',
      emails: 'E-posta(lar)',
      fullName: 'Adı',
      firstName: 'Adı',
      lastName: 'Soyadı',
      status: 'Durum',
      disabled: 'Pasif',
      emailVerified: 'Email Doğrulandı',
      phoneNumber: 'Telefon Numarası',
      role: 'Rol',
      createdAt: 'Oluşturulma Tarihi',
      updatedAt: 'Güncellenme Tarihi',
      roleUser: 'Rol/Kullanıcı',
      languages: 'Diller',
      roles: 'Roller',
      createdAtRange: 'Oluşturulma Tarihi',
      password: 'Şifre',
      rememberMe: 'Beni Hatırla'
    },
    items: {
      removeRoles: "Rolleri Kaldır",
      permanentDelete: "Kalıcı Sil"
    },
    enabled: 'Etkin',
    disabled: 'Pasif',
    verified: 'Doğrulandı',
    notVerified: 'Doğrulanmadı',
    validations: {
      // eslint-disable-next-line
      email: 'E-posta ${value} geçersiz'
    }
  },

  assignPapers: {
    menu: 'Okuyucuları Ata',
    title: 'Sınav kağıtlarını okuyuculara ata',
    fields: {
      teachers: 'Öğretmenler',
      language: 'Dil',
      examPapers: 'Sınav Kağıtları',
      gradingCount: 'Bir Cevabın Okunma Sayısı',
      unscoredPapers: 'Okunmayacak Kağıtlar',
      uncompletedPapers: 'Tamamlanmayan Kağıtlar',
      assignedScores: 'Atanmış Kağıtlar'
    },
    button: {
      examPapers: 'Sınav Kağıtlarını Ata',
      unscoredPapers: 'Atanmamış Kağıtları Ata',
      assignedScores: 'Tekrar Atama Yap',
      unscore: 'Bu Sınavlar Okunmasın',
      assignUncompleted: 'Yarım Sınavları Ata',
      deleteUncompleted: 'Yarım Sınavları Sil',
      deleteAll: 'Hepsini Sil'
    },
    reassignScores: {
      onSuccess: "Sınavlar Atandı"
    },
    setPapersAsUnScored: {
      onSuccess: "Sınavlar Okunmayacak Olarak İşaretlendi"
    },
    assignPapers: {
      onSuccess: "Sınav kağıtları atandı"
    },
    assignUncompletedPapers: {
      onSuccess: "Yarım sınavlar atandı"
    },
    deleteUnScoredPapers: {
      onSuccess: "Okunmayacak kağıtlar silindi"
    },
    deleteUncompletedPapers: {
      onSuccess: "Yarım sınavlar silindi"
    },
    placeholders: {
      selectteacher: "Öğretmen Seç",
      selectpaper: "Sınav Kağıdı Seç",
      selectitem: "Öğe Seç"
    }
  },
  auditLog: {
    menu: 'Denetim Günlükleri',
    title: 'Denetim Günlükleri',
    exporterFileName: 'denetim_gunlugu_aktar',
    entityNamesHint: 'Virgül kullanarak birden çok objeyi ayırın.',
    fields: {
      id: 'No',
      timestampRange: 'Periyod',
      entityName: 'Varlık',
      entityNames: 'Varlıklar',
      entityId: 'Varlık No',
      action: 'Hareket',
      values: 'Değerler',
      timestamp: 'Tarih',
      createdByEmail: 'Kullanıcı E-Posta'
    }
  },
  tests: { menu: 'Sınavlar' },
  settings: {
    title: 'Ayarlar',
    menu: 'Ayarlar',
    save: {
      success:
        'Ayarlar başarıyla kaydedildi. Değişikliklerin geçerli olması için sayfa {0} saniye içinde yeniden yüklenecek.'
    },
    fields: {
      theme: 'Tema'
    },
    colors: {
      'itex-theme': 'itex-theme'
    }
  },
  home: {
    menu: 'Kontrol Paneli',
    message: `Bu sayfadaki veriler demo amaçlıdır.`,
    charts: {
      score: 'Toplam Puan',
      day: 'Gün',
      totalscores: 'Sınav Toplam Puanı',
      totalscore: 'Toplam Puan',
      red: 'Kırmızı',
      green: 'Yeşil',
      yellow: 'Sarı',
      grey: 'Gri',
      blue: 'Mavi',
      orange: 'Turuncu',
      months: {
        1: 'Ocak',
        2: 'Şubat',
        3: 'Mart',
        4: 'Nisan',
        5: 'Mayıs',
        6: 'Haziran',
        7: 'Temmuz'
      },
      eating: 'Yemek',
      drinking: 'İçmek',
      sleeping: 'Uyumak',
      designing: 'Tasarım yapmak',
      coding: 'Kodlamak',
      cycling: 'Bisiklet sürmek',
      running: 'Koşmak',
      customer: 'Müşteri',
      lastfiveexam: 'Son 5 Sınav',
      exam: {
        reading: 'Okuma',
        writing: 'Yazma',
        listening: 'Dinleme',
        speaking: 'Konuşma',
        grammar: 'Gramer',
        oralFluency: 'Sözlü Akıcılık',
        pronunciation: 'Telaffuz',
        spelling: 'Yazım',
        vocabulary: 'Kelime hazinesi',
        writtenDiscourse: 'Yazılı Söylem'
      }
    }
  },
  errors: {
    backToHome: 'Ana sayfaya dön',
    403: `Üzgünüm, bu sayfaya erişiminiz yok`,
    404: 'Üzgünüm, ziyaret ettiğiniz sayfa mevcut değil',
    500: 'Üzgünüm, sunucu bir hata bildiriyor',
    examExpired: `Üzgünüm, süresi dolmuş sınava erişiminiz yok`,
    forbidden: {
      message: 'Kısıtlandı'
    },
    validation: {
      message: 'Bir hata oluştu'
    },
    defaultErrorMessage: 'Bir hata oluştu'
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} geçersiz',
      required: '${path} gerekli',
      oneOf: '${path} şu değerlerden biri olmalıdır: ${values}',
      notOneOf: '${path} şu değerlerden biri olmamalıdır: ${values}',
      notType: ({ path, type }) => {
        return `${path}, ${type} olmalıdır`;
      }
    },
    string: {
      length: '${path} tam olarak ${length} karakter olmalıdır',
      min: '${path} en az ${min} karakter olmalıdır',
      max: '${path} en çok ${max} karakter olmalıdır',
      matches: '${path} şununla eşleşmelidir: "${regex}"',
      email: '${path} geçerli bir e-posta olmalı',
      url: '${path} geçerli bir URL olmalı',
      trim: '${path} kırpılmış bir metin olmalı',
      lowercase: '${path} küçük harfli bir metin olmalı',
      uppercase: '${path} büyük harfli bir metin olmalı',
      selected: '${path} seçilmeli'
    },
    number: {
      min: '${path} ${min} den büyük olmalı ya da eşit olmalı',
      max: '${path}  ${max} den küçük olmalı ya da eşit olmalı',
      lessThan: '${path} ${less} den az olmalı',
      moreThan: '${path} ${more} den büyük olmalı',
      notEqual: '${path} ${notEqual} eşit olmamalı',
      positive: '${path} pozitif sayı olmalı',
      negative: '${path} negatif sayı olmalı',
      integer: '${path} tam sayı olmalı',
      invalid: '${path} bir numara olmalı'
    },
    date: {
      min: '${path} alanı ${min} den sonra olmalıdır',
      max: '${path} alanı ${max} den önce olmalıdır'
    },
    boolean: {},
    object: {
      noUnknown:
        '${path} alanda nesne şeklinde belirtilmemiş anahtarlar olamaz.'
    },
    array: {
      min: '${path} alanında en az ${min} öğe olmalı',
      max: '${path} alanında ${max} dan daha az veya eşit öğe olmalı.'
    }
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Yükle',
    image: 'Görsel yüklemelisiniz',
    size: 'Dosya çok büyük. İzin verilen en yüksek boyut {0}',
    formats: `Geçersiz format.'{0}' olmalı.`
  },
  importer: {
    line: 'Satır',
    status: 'Durum',
    pending: 'Beklemede',
    imported: 'İçe aktarıldı',
    error: 'Hata',
    total: `{0} içe aktarıldı, {1} bekleniyor ve {2} hatalı`,
    importedMessage: `İşlenen {0} / {1}.`,
    noNavigateAwayMessage:
      'Bu sayfadan ayrılmanız durumunda içe aktarma durdurulur.',
    completed: {
      success: 'İşlem tamamlandı, bütün satırlar başarıyla içe aktarıldı.',
      someErrors: 'İşlem tamamlandı, ancak bazı satırlar içe aktarılamadı.',
      allErrors: 'İçe aktarım başarısız. Geçerli satır yok.'
    },
    form: {
      downloadTemplate: 'Şablonu İndir',
      hint: 'Devam etmek için dosyayı bu alana sürükleyin'
    },
    list: {
      discardConfirm: 'Emin misiniz? İçe aktarılmamış veriler kaybolacaktır.'
    },
    errors: {
      invalidFileEmpty: 'Dosya boş',
      invalidFileExcel: 'Sadece excel (.xlsx) dosyalarına izin veriliyor',
      invalidFileUpload:
        'Geçersiz dosya. Güncel şablonu kullandığınızdan emin olunuz.',
      importHashRequired: 'Import hash gerekli',
      importHashExistent: 'Veriler zaten içe aktarılmış durumda'
    }
  },

  autocomplete: {
    loading: 'Yükleniyor...'
  },

  imagesViewer: {
    noImage: 'Görsel Yok'
  }
};

export default tr;
