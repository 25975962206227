import { QuestionService } from '@/modules/question/question-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    loading: false,
    record: null
  },

  getters: {
    record: state => state.record,
    loading: state => !!state.loading,
    type: (state, getters) => {
      //Bunu metot yap, test kodunu yaz
      if (!getters.record) {
        return null;
      }
      const questionType = getters.record.questionType.questionType;
      return UtilsModules.convertQTypeForExam(questionType);
    },
    typeLabel: (state, getters) => {
      //Bunu metot yap, test kodunu yaz
      return UtilsModules.questionTypeLabel(getters.type);
    },
    typeDescription: (state, getters) => {
      return UtilsModules.questionDescription(getters.record, getters.type);
    }
  },

  mutations: {
    FIND_STARTED(state) {
      state.record = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.loading = false;
    }
  },

  actions: {
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await QuestionService.find(id);
        record.questionStatus = record.questionStatus.toString();
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/question');
      }
    }
  }
};
