import { ClassroomModel } from '@/modules/classroom/classroom-model';

const { fields } = ClassroomModel;

export default [
  fields.className,
  fields.language,
  fields.classLevel,
  fields.status,
  fields.students,
];
