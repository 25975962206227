import authAxios from '@/shared/axios/auth-axios';
import { i18n } from '@/i18n';
import Message from '@/shared/message/message';

export class QuestionTypeService {
  static async update(id, data) {
    const body = {
      id,
      data
    };

    const response = await authAxios.put(`/question-type/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/question-type`, {
      params
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/question-type`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash
    };

    const response = await authAxios.post(`/question-type/import`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/question-type/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/question-type`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit, filter = {}) {
    const params = {
      query,
      limit,
      filter
    };

    const response = await authAxios.get(`/question-type/autocomplete`, {
      params
    });

    try {
      const questionTypes = response.data.map(ql => {
        return {
          label: i18n(
            `entities.questionType.enumerators.questionType.${ql.label}`
          ),
          id: ql.id,
          qName: ql.label
        };
      });
      return questionTypes;
    } catch (e) {
      Message.error('Question Group language conversion error: ', e.message);
    }

    return response.data;
  }
}
