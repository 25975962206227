import authAxios from '@/shared/axios/auth-axios';

export class IamService {

  static async destroy(ids) {
    console.log("IDS in FE: " + JSON.stringify(ids));
    const params = {
      ids
    };

    const response = await authAxios.delete(
        '/iam/permanent',
        {params},
    );

    return response.data;
  }

  static async enable(ids) {
    return this._changeStatus(ids, false);
  }

  static async disable(ids) {
    return this._changeStatus(ids, true);
  }

  static async _changeStatus(ids, disabled) {
    const body = {
      ids,
      disabled: !!disabled,
    };

    const response = await authAxios.put(
      '/iam/status',
      body,
    );

    return response.data;
  }

  static async edit(data) {
    const body = {
      data,
    };
    const response = await authAxios.put('/iam', body);
    return response.data;
  }

  static async remove(emails, roles, all = false) {
    const params = {
      emails,
      roles,
      all,
    };

    const response = await authAxios.delete('/iam', {
      params,
    });

    return response.data;
  }

  //Disardan cagriliyor, bu metot icin this kullanilmamali
  static async createStudent(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(
      '/iamStudent',
      body,
    );
    return response.data;
  }

  static async createTeacher(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(
      '/iamTeacher',
      body,
    );
    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post('/iam', body);
    return response.data;
  }
  //excel import
  static async importStudent(
    values,
    importHash,
    defaultData = {},
  ) {
    const body = {
      data: {
        ...values,
        ...defaultData,
      },
      importHash,
    };

    const response = await authAxios.post(
      '/student/import',
      body,
    );
    return response.data;
  }
  //excel import
  static async importTeacher(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const response = await authAxios.post(
      '/teacher/import',
      body,
    );
    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/iam/${id}`);
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/iam/user`, {
      params,
    });
    return response.data;
  }

  static async fetchRoles(filter, orderBy) {
    const params = {
      filter,
      orderBy,
    };

    const response = await authAxios.get(`/iam/role`, {
      params,
    });

    return {
      rows: response.data,
      count: response.data.length,
    };
  }

  static fetchTeacherACwithLangs(languages) {
    return async (query, limit) => {
      const filter = { role: 'teacher', languages };
      return await this.fetchUserAutocomplete(
        query,
        limit,
        filter,
      );
    };
  }

  static async fetchUserAutocomplete(
    query,
    limit,
    filter = null,
  ) {
    const params = {
      query,
      limit,
      filter, ////filter = {role: "teacher", languages: {id: '42de13fa-78f1-4f56-8356-db0bf892926b'}}
    };

    const response = await authAxios.get(
      `/iam/user/autocomplete`,
      {
        params,
      },
    );
    return response.data;
  }
}
