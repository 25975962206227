import { UserDetailModel } from '@/modules/user-detail/user-detail-model';

const { fields } = UserDetailModel;

export default [
  fields.id,
  fields.userName,
  fields.studentNumber,
  fields.gender,
  fields.birthdate,
  fields.userType,
  fields.nationality,
  fields.createdAt,
];
