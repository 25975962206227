import importerStore from '@/shared/importer/importer-store';
import { LanguageService } from '@/modules/language/language-service';
import languageImporterFields from '@/modules/language/language-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  LanguageService.import,
  languageImporterFields,
  i18n('entities.language.importer.fileName'),
  i18n('entities.language.importer.hint'),
);
