<template>
  <div class="q-wrap">
    <div class="q-content">
      <div>
        <question-header />
        <question-body>
          <slot></slot>
        </question-body>
      </div>
    </div>
    <question-footer />
  </div>
</template>

<script>
export default {
  name: 'question-card',
};
</script>

<style lang="stylus" scoped>
.q-wrap {
  min-height: 100vh;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.q-content {
  display: flex;
  justify-content: center;
  align-items: stretch;

  > div {
    margin: 0rem -1.25rem 3rem -1.25rem;
    padding: 1rem;
    width: 1080px;
    min-height: 500px;
    background-color: white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 600px;
    position: relative;
  }
}
</style>
