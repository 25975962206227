import { QuestionTypeModel } from '@/modules/question-type/question-type-model';

const { fields } = QuestionTypeModel;

export default [
  fields.id,
  fields.title,
  fields.description,
  fields.section,
  fields.createdAt,
];
