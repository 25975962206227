import authAxios from '@/shared/axios/auth-axios';

export class ExamOutputService {
  static async update(id, data) {
    const body = {
      id,
      data
    };

    const response = await authAxios.put(`/exam-output/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/exam-output`, {
      params
    });

    return response.data;
  }

  // static async create(data) {
  //   const body = {
  //     data,
  //   };
  //
  //   const response = await authAxios.post(
  //     `/exam-output`,
  //     body,
  //   );
  //
  //   return response.data;
  // }

  static async find(id) {
    const response = await authAxios.get(`/exam-output/${id}`);
    return response.data;
  }

  static async fetchExamAnalysisData({ examId, studentId }) {
    const response = await authAxios.get(`/exam-output/${examId}/${studentId}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/exam-output`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit, filter = null) {
    const params = {
      query,
      limit,
      filter
    };

    const response = await authAxios.get(`/exam-output/autocomplete`, {
      params
    });
    //Gelen veri select item id olacagi icin string'e ceviriyorum.
    response.data.forEach(r => {
      r.idObj = r.id;
      r.id = JSON.stringify(r.id);
    });

    return response.data;
  }
}
