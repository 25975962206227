import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '../../shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.questionType.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(
    `entities.questionType.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  questionType: new StringField(
    'questionType',
    label('questionType'),
    {
      required: true,
    },
  ),
  autoScore: new BooleanField(
    'autoScore',
    label('autoScore'),
    {
      required: true,
    },
  ),
  title: new StringField('title', label('title'), {
    required: true,
  }),
  section: new EnumeratorField(
    'section',
    label('section'),
    [
      {
        id: 'reading',
        label: enumeratorLabel('section', 'reading'),
      },
      {
        id: 'speaking',
        label: enumeratorLabel('section', 'speaking'),
      },
      {
        id: 'listening',
        label: enumeratorLabel('section', 'listening'),
      },
      {
        id: 'writing',
        label: enumeratorLabel('section', 'writing'),
      },
    ],
    {},
  ),
  description: new StringField(
    'description',
    label('description'),
    {},
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class QuestionTypeModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
