import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import { UserField } from '@/modules/auth/user-field';

function label(name) {
  return i18n(`entities.classroom.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.classroom.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  className: new StringField(
    'className',
    label('className'),
    {
      required: true,
      max: 255,
    },
  ),
  language: new EnumeratorField(
    'language',
    label('language'),
    [
      {
        id: 'English',
        label: enumeratorLabel('language', 'English'),
      },
      {
        id: 'Arabic',
        label: enumeratorLabel('language', 'Arabic'),
      },
      {
        id: 'Turkish',
        label: enumeratorLabel('language', 'Turkish'),
      },
    ],
    {
      required: true,
    },
  ),
  classLevel: new EnumeratorField(
    'classLevel',
    label('classLevel'),
    [
      {
        id: 'Beginner',
        label: enumeratorLabel('classLevel', 'Beginner'),
      },
      {
        id: 'Elementary',
        label: enumeratorLabel('classLevel', 'Elementary'),
      },
      {
        id: 'Intermediate',
        label: enumeratorLabel(
          'classLevel',
          'Intermediate',
        ),
      },
      {
        id: 'Upper_Intermediate',
        label: enumeratorLabel(
          'classLevel',
          'Upper_Intermediate',
        ),
      },
      {
        id: 'Advanced',
        label: enumeratorLabel('classLevel', 'Advanced'),
      },
      {
        id: 'Proficiency',
        label: enumeratorLabel('classLevel', 'Proficiency'),
      },
    ],
    {
      required: true,
    },
  ),
  status: new BooleanField('status', label('status')),
  students: UserField.relationToMany(
    'students',
    label('students'),
    {
      required: true,
    },
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class ClassroomModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
