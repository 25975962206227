import { QuestionService } from '@/modules/question/question-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import { getLanguageCode } from '@/i18n';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    questionValues: null
  },

  getters: {
    record: state => state.record,
    findLoading: state => !!state.findLoading,
    saveLoading: state => !!state.saveLoading,
    questionValues: state => state.questionValues,
    questionType: (state, getters) => {
      //Bunu metot yap, test kodunu yaz
      if (!getters.record) {
        return null;
      }
      const questionType = getters.record.questionType.questionType;
      return UtilsModules.convertQTypeForExam(questionType);
    },
    
    isRTL(getters) {
      if (getters.record)
        return UtilsModules.isRTL(getters.record.questionLanguage.code);
      else 
        return UtilsModules.isRTL(getLanguageCode());
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    }
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state, values) {
      state.saveLoading = false;
      state.questionValues = values;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        const record = await QuestionService.find(id);
        record.questionStatus = record.questionStatus.toString();
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/question');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const response = await QuestionService.create(values);
        commit('CREATE_SUCCESS', values);
        Message.success(i18n('entities.question.create.success'));
        if(values.manualExamId){
          routerAsync().push('/question/' + response.id + '/edit/' + values.manualExamId);
        }else{
          routerAsync().push('/question/' + response.id + '/edit');
        }
      } catch (error) {
        Errors.showMessage(error);
        //Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, body) {
      try {
        commit('UPDATE_STARTED');
        await QuestionService.update(body);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.question.update.success'));
        if(body.manualExamId){
          routerAsync().push(`/exam/${body.manualExamId}/edit`);
        }else{
          routerAsync().push('/question');
        }
      } catch (error) {
        Errors.showMessage(error);
        //Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    }
  }
};
