import { ScoreOutputService } from '@/modules/score-output/score-output-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import UtilsModules from '../../shared/utils-for-modules';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    // editedAnswer: null,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    questionType: (state, getters) => {
      if (getters.record) {
        const questionType =
          getters.record.examOutput.question.questionType
            .questionType;
        return UtilsModules.convertQTypeForScore(
          questionType,
        );
      }
    },
    isRTL(state, getters) {
      return (
        getters.record.examOutput.question.questionLanguage
          .code === 'ar'
      );
    },
    checkRTL(state, getters) {
      return getters.isRTL === true ? ' rtl ' : '';
    },
    rtlAndPadding(state, getters) {
      return getters.isRTL === true
        ? getters.checkRTL + 'pRight '
        : ' pLeft ';
    },
    checkTextRight(state, getters) {
      return getters.isRTL === true ? ' text-right ' : '';
    },
    getEditedAnswer(state) {
      return state.editedAnswer;
    },
    textQuestion: (state, getters) => {
      if (getters.record) {
        // Editordeki degisiklikleri LocalStorage'den cekmek icin kullan
        // if (state.editedAnswer)
        //   return getters.getEditedAnswer.editedAnswer;
        // else
        return (
          getters.record.examOutput.question.data
            .question || '[There is no Question!]'
        );
      }
    },
    textAnswer: (state, getters) => {
      if (getters.record) {
        // Editordeki degisiklikleri LocalStorage'den cekmek icin kullan
        // if (state.editedAnswer)
        //   return getters.getEditedAnswer.editedAnswer;
        // else
        return (
          getters.record.examOutput.answer.input ||
          '[There is no Answer]'
        );
      }
    },
    srcAnswer: (state, getters) => {
      if (getters.record) {
        return getters.record.examOutput.answer.path;
      }
    },
    srcQuestion: (state, getters) => {
      if (getters.record) {
        return getters.record.examOutput.question.data.src;
      }
    },
    answerHint: (state, getters) => {
      if (getters.record && getters.record.examOutput.question.answer) {
        return getters.record.examOutput.question.answer.answerHint;
      }
    },
    answerStatistics: (state, getters) => {
      const _record = getters.record;
      //console.log("Record: " + JSON.stringify(_record))
      if (_record) {
        const _statistics = {}
        _statistics.timeSpent = getters.record.examOutput.timeSpent;
        return _statistics
      }
    },
    scorePath: (state, getters) => {
      return '/score-output/' + getters.record.id + '/edit';
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    SET_RECORD(state, payload){
      state.record = payload
    }
    // Editordeki degisiklikleri LocalStorage'den cekmek icin kullan
    // EDITED_ANSWER(state, payload) {
    //   state.editedAnswer = payload;
    // }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    //Edit icin kullaniliyor.
    async doFindWithQuestion({ commit, getters }, id) {
      try {
        commit('FIND_STARTED');
        const record = await ScoreOutputService.doFindWithQuestion(
          id,
        );
        commit('FIND_SUCCESS', record);
        if (location.href.indexOf(getters.scorePath) < 0) {
          routerAsync().push(getters.scorePath);
        }
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/score-output');
      }
    },

    //View icin kullaniliyor
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');
        let record = null;
        record = await ScoreOutputService.find(id);
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/score-output');
      }
    },

    async doUpdate({ commit, dispatch }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await ScoreOutputService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(
          i18n('entities.scoreOutput.update.success'),
        );
        await dispatch('doFindWithQuestion', 'one');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doPublish({ commit }) {
      try {
        commit('UPDATE_STARTED');

        await ScoreOutputService.publish();

        commit('UPDATE_SUCCESS');
        Message.success(
          i18n('entities.scoreOutput.update.success'),
        );
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    // Editordeki degisiklikleri LocalStorage'den cekmek icın kullan
    // async checkLocalStroge({ commit, getters }) {
    //   const answers = JSON.parse(localStorage.getItem('editedAnswers'));

    //   const edited = answers.find(edited => edited.scoreOutputId === getters.record.id);
    //   if (edited)
    //     commit('EDITED_ANSWER', edited)
    //   else
    //     commit('EDITED_ANSWER', null);
    // }

  },
};
