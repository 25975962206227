import { i18n } from '@/i18n';
import { ExamResultModel } from '@/modules/exam-result/exam-result-model';
//import IntegerField from '@/shared/fields/integer-field';
import StringField from '@/shared/fields/string-field';
const { fields } = ExamResultModel;

function label(name) {
  return i18n(`entities.examResult.fields.${name}`);
}

export default [
  new StringField('examName', label('examName')),
  new StringField('studentName', label('studentName')),
  new StringField('studentEmail', label('studentEmail')),
  fields.aftermath,
  fields.writing,
  fields.reading,
  fields.listening,
  fields.speaking,
  // new IntegerField('grammar',label('grammar'),),
  // new IntegerField('spelling',label('spelling'),),
  // new IntegerField('vocabulary',label('vocabulary'),),
  // new IntegerField('oralFluency',label('oralFluency'),),
  // new IntegerField('pronunciation',label('pronunciation'),),
  // new IntegerField('writtenDiscourse',label('writtenDiscourse'),),
];
