import Error403Page from '@/modules/layout/components/error-403-page.vue';
import Error404Page from '@/modules/layout/components/error-404-page.vue';
import Error400Page from '@/modules/layout/components/error-400-page.vue';
import Error500Page from '@/modules/layout/components/error-500-page.vue';
import examExpired from '@/modules/layout/components/exam-expired-page.vue';

export default [
  {
    name: 'error403',
    path: '/403',
    component: Error403Page
  },
  {
    name: 'error404',
    path: '/404',
    component: Error404Page
  },
  {
    name: 'error400',
    path: '/400',
    component: Error400Page
  },
  {
    name: 'error500',
    path: '/500',
    component: Error500Page,
    meta: { auth: true }
  },
  {
    name: 'exam-expired',
    path: '/exam-expired',
    component: examExpired,
    meta: { auth: true }
  }
];
