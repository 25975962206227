import '@/shared/plugins/element'; //use Element UI
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from './App.vue';
//import { SettingsService } from '@/modules/settings/settings-service';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import { i18n } from '@/i18n';
import PortalVue from 'portal-vue';
import VueTimers from 'vue-timers';
import AudioVisual from 'vue-audio-visual'; //Soru sayfalarinda listening gorsel item olarak kullaniliyor.

(function() {
  document.title = i18n('app.title');
  ProgressBar.start();
  //SettingsService.applyTheme('itex-theme');
  //await SettingsService.fetchAndApply();

  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.use(VueTimers);
  Vue.use(AudioVisual);
  Vue.config.productionTip =
    process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // eslint-disable-next-line
  new Vue({
    router: routerAsync(), //modules icindeki route'lar
    store: storeAsync(), //modules icindeki store'lar
    render: (h) => h(app),
  }).$mount('#app');
})();
