import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class LanguagePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.languageRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.languageImport,
    );
    this.languageAutocomplete = permissionChecker.match(
      Permissions.values.languageAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.languageCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.languageEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.languageDestroy,
    );
  }
}
