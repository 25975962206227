import authAxios from '@/shared/axios/auth-axios';

export class ExamResultService {
  static async update(id, data) {
    const body = {
      id,
      data
    };

    const response = await authAxios.put(`/exam-result/${id}`, body);

    return response.data;
  }

  static async reScore(data){
    const body = {
      data
    }
    const response = await authAxios.put(`/exam-result/rescore`, body)
    return response.data
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/exam-result`, {
      params
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/exam-result`, body);

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash
    };

    const response = await authAxios.post(`/exam-result/import`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/exam-result/${id}`);
    const record = response.data;
    const changedRecord = {
      ...record,
      ...record.results,
      resultStatus: record.resultStatus.toString()
    };
    return changedRecord;
  }

  static async findScoreReport(examResultId) {
    const response = await authAxios.get(`/exam-result/${examResultId}`);
    return response.data;
  }

  static async announceAll() {
    const body = {};

    await authAxios.put(`/exam-result/announceAll`, body);
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/exam-result`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit
    };

    const response = await authAxios.get(`/exam-result/autocomplete`, {
      params
    });

    return response.data;
  }
}
