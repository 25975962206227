import { SupportRequestsModel } from '@/modules/support-requests/support-requests-model';

const { fields } = SupportRequestsModel;

export default [
  fields.id,
  fields.type,
  fields.message,
  fields.images,
  fields.createdAt,
];
