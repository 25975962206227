import Roles from '@/security/roles';
const roles = Roles.values;

class SettingsPermissions {
  static get values() {
    return {
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [
          roles.owner,
          roles.student,
          roles.teacher,
          roles.admin,
        ],
      },
    };
  }
}

export default SettingsPermissions;
