<template>
    <div :class="checkRTL" >
        <el-form-item
                :label="fields.id.label"
                :prop="fields.id.name"
                v-if="isEditing"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-input
                        :disabled="true"
                        v-model="model[fields.id.name]"
                />
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.name.label"
                :prop="fields.name.name"
                :required="fields.name.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-input
                        v-model="model[fields.name.name]"
                        ref="focus"
                />
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.examLanguage.label"
                :prop="fields.examLanguage.name"
                :required="fields.examLanguage.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <app-language-autocomplete-input
                        :fetchFn="fields.examLanguage.fetchFn"
                        :mapperFn="fields.examLanguage.mapperFn"
                        v-model="model[fields.examLanguage.name]"
                        mode="single"
                        :disabled="lockPublished"
                ></app-language-autocomplete-input>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.examLowerLimit.label"
                :prop="fields.examLowerLimit.name"
                :required="fields.examLowerLimit.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-date-picker
                        placeholder
                        type="datetime"
                        v-model="model[fields.examLowerLimit.name]"
                ></el-date-picker>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.examUpperLimit.label"
                :prop="fields.examUpperLimit.name"
                :required="fields.examUpperLimit.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-date-picker
                        placeholder
                        type="datetime"
                        v-model="model[fields.examUpperLimit.name]"
                ></el-date-picker>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.examType.label"
                :prop="fields.examType.name"
                :required="fields.examType.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-select
                        v-model="model[fields.examType.name]"
                        :disabled="lockPublished"
                        @change="onExamTypeChanged"
                >
                    <!--                <el-option :value="undefined">&#45;&#45;</el-option>-->
                    <el-option
                            :key="option.id"
                            :label="option.label"
                            :value="option.id"
                            v-for="option in fields.examType.options"
                    ></el-option>
                </el-select>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.structure.label"
                :prop="fields.structure.name"
                :required="showStructure"
                v-if="showStructure"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <app-structure-autocomplete-input
                        :fetchFn="fields.structure.fetchFn"
                        :mapperFn="fields.structure.mapperFn"
                        v-model="model[fields.structure.name]"
                        mode="single"
                        :disabled="lockPublished"
                ></app-structure-autocomplete-input>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.fixQuestions.label"
                :prop="fields.fixQuestions.name"
                :required="fields.fixQuestions.required"
        >
            <el-col :lg="6" :md="8" :sm="12">
                <el-switch
                        v-model="model[fields.fixQuestions.name]"
                        @change="onIsFixChanged"
                        :disabled="lockFixed"
                ></el-switch>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.unScored.label"
                :prop="fields.unScored.name"
                :required="fields.unScored.required"
        >
            <el-col :lg="6" :md="8" :sm="12">
                <el-switch
                        v-model="model[fields.unScored.name]"
                ></el-switch>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.duration.label"
                :prop="fields.duration.name"
                :required="fields.duration.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-input-number
                        :precision="0"
                        :step="1"
                        v-model="model[fields.duration.name]"
                ></el-input-number>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.isFree.label"
                :prop="fields.isFree.name"
                :required="fields.isFree.required"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-switch
                        v-model="model[fields.isFree.name]"
                        @change="onIsFreeChanged"
                ></el-switch>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.price.label"
                :prop="fields.price.name"
                :required="fields.price.required"
                v-if="showPrice"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-input-number
                        :precision="0"
                        :step="1"
                        v-model="model[fields.price.name]"
                ></el-input-number>
            </el-col>
        </el-form-item>
        <el-form-item
                :label="fields.status.label"
                :prop="fields.status.name"
                :required="fields.status.required"
                v-if="showStatus"
                key="step1"
        >
            <el-col :lg="11" :md="16" :sm="24">
                <el-select
                        v-model="model[fields.status.name]"
                        :disabled="lockPublished"
                >
                    <!--                <el-option :value="undefined">&#45;&#45;</el-option>-->
                    <el-option
                            :key="option.id"
                            :label="option.label"
                            :value="option.id"
                            v-for="option in fields.status.options"
                    ></el-option>
                </el-select>
            </el-col>
        </el-form-item>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import { ExamModel } from '@/modules/exam/exam-model';

    const { fields } = ExamModel;
    export default {
        name: "manage-exam-info",
        props: ['isEditing','model','showStatusInFirstStep'],
        data(){
            console.log("Data Exam Info")
            return {
                lockPublished: this.isEditing && this.model.status === 'published',
                showStructure: this.isEditing ? this.model.examType === 'structured' : false,
                lockFixed: this.isEditing && this.model.examType === 'manual',
                showPrice: this.isEditing ? this.model.isFree === false : false,
                showStatus: this.showStatusInFirstStep,
            }
        },
        computed: {
            ...mapGetters({
                checkRTL: 'exam/form/checkRTL',
            }),
            fields() {
                return fields;
            }
        },
        methods: {
            onExamTypeChanged(value) {
                const isExamStructured = value === 'structured';
                const isExamManual = value === 'manual'
                this.showStructure = isExamStructured
                this.model[this.fields.fixQuestions.name] = true //Iki Sinav Tipinde de bunu default vermek mantikli
                this.lockFixed = isExamManual
                if(this.isEditing){
                    this.$emit('setStepsVisibility',{
                        activeStepIndex: 0,
                    })
                }
            },
            onIsFreeChanged(value) {
                this.showPrice = !value;
                if(this.isEditing){
                    this.$emit('setStepsVisibility',{
                        activeStepIndex: 0,
                    })
                }else{
                    this.showStatus = value
                }
            },
            onIsFixChanged(value){
                if(this.isEditing){
                    this.$emit('setStepsVisibility',{
                        activeStepIndex: 0,
                    })
                }else{
                    this.showStatus = value
                }
            }

        }
    }
</script>

<style scoped>

</style>