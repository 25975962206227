import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class SupportRequestsPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.supportRequestsRead,
    );
    this.supportRequestsAutocomplete = permissionChecker.match(
      Permissions.values.supportRequestsAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.supportRequestsCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.supportRequestsEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.supportRequestsDestroy,
    );
  }
}
