export default class UtilsText {
  static wordCount(text) {
    if (text) {
      return text.trim().split(/\s+/).length;
    }
    return 0;
  }
  static OptionsLowerCase(options, lang) {
    var outOptions = [];
    if (options) {
      for (let i = 0; i < options.length; i++) {
        outOptions.push(this.LowerCase(options[i], lang));
      }
    }
    return outOptions;
  }
  static LowerCase(src, lang) {
    if (!src) {
      return src;
    }
    if (lang === 'en') {
      return src.toLowerCase();
    } else if (lang === 'tr') {
      return this.toTurkishToLower(src);
    }
    return src;
  }
  static toTurkishToLower(src) {
    if (!src) {
      return src;
    }
    const letters = {
      İ: 'i',
      I: 'ı',
      Ş: 'ş',
      Ğ: 'ğ',
      Ü: 'ü',
      Ö: 'ö',
      Ç: 'ç'
    };
    src = src.replace(/(([İIŞĞÜÇÖ]))/g, function(letter) {
      return letters[letter];
    });
    return src.toLowerCase();
  }

  static HandleEncoder(text) {
    if (!text) {
      return text;
    }
    return text.replace(/\u00A0/g, ' ');
  }
}
