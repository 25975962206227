import Roles from '@/security/roles';
const roles = Roles.values;

class ScoreOutputPermissions {
  static get values() {
    return {
      scoreOutputCreate: {
        id: 'scoreOutputCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['scoreOutput'],
      },
      scoreOutputEdit: {
        id: 'scoreOutputEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
        allowedStorageFolders: ['scoreOutput'],
      },
      scoreOutputDestroy: {
        id: 'scoreOutputDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['scoreOutput'],
      },
      scoreOutputRead: {
        id: 'scoreOutputRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
      },
      scoreOutputAutocomplete: {
        id: 'scoreOutputAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
      },
    };
  }
}

export default ScoreOutputPermissions;
