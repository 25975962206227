import questionTypeListStore from '@/modules/question-type/question-type-list-store';
import questionTypeViewStore from '@/modules/question-type/question-type-view-store';
import questionTypeFormStore from '@/modules/question-type/question-type-form-store';
import questionTypeDestroyStore from '@/modules/question-type/question-type-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: questionTypeDestroyStore,
    form: questionTypeFormStore,
    list: questionTypeListStore,
    view: questionTypeViewStore,
  },
};
