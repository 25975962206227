import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '../../shared/fields/enumerator-field';
import IntegerField from '../../shared/fields/integer-field';
import BooleanField from '../../shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.structure.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(
    `entities.structure.enumerators.${name}.${value}`,
  );
}

const fields = {
  id: new IdField('id', label('id')),
  structureName: new StringField(
    'structureName',
    label('structureName'),
    {
      required: true,
      max: 255,
    },
  ),
  statusStructure: new EnumeratorField(
    'statusStructure',
    label('statusStructure'),
    [
      {
        id: '0',
        label: enumeratorLabel(
          'statusStructure',
          'default',
        ),
      },
    ],
  ),
  hasBreak: new BooleanField('hasBreak', label('hasBreak')),
  breakBefore: new IntegerField(
    'breakBefore',
    label('breakBefore'),
  ),
  breakLimit: new IntegerField(
    'breakLimit',
    label('breakLimit'),
  ),
  createdAt: new DateTimeField(
    'createdAt',
    label('createdAt'),
  ),
  updatedAt: new DateTimeField(
    'updatedAt',
    label('updatedAt'),
  ),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class StructureModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
