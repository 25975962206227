import Roles from '@/security/roles';
const roles = Roles.values;

class OrderPermissions {
  static get values() {
    return{
      orderCreate: {
        id: 'orderCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['order'],
      },
      orderEdit: {
        id: 'orderEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['order'],
      },
      orderDestroy: {
        id: 'orderDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['order'],
      },
      orderRead: {
        id: 'orderRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
      orderAutocomplete: {
        id: 'orderAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
    }
  }
}

export default OrderPermissions;
