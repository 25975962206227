import Roles from '@/security/roles';
const roles = Roles.values;

class StructurePermissions {
  static get values() {
    return {
      structureImport: {
        id: 'structureImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      structureCreate: {
        id: 'structureCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['structure'],
      },
      structureEdit: {
        id: 'structureEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['structure'],
      },
      structureDestroy: {
        id: 'structureDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['structure'],
      },
      structureRead: {
        id: 'structureRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
      },
      structureAutocomplete: {
        id: 'structureAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.canCreateExam,
        ],
      },
    };
  }
}

export default StructurePermissions;
