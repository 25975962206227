import Roles from '@/security/roles';
const roles = Roles.values;

class ClassroomPermissions {
  static get values() {
    return {
      classroomImport: {
        id: 'classroomImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      classroomCreate: {
        id: 'classroomCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['classroom'],
      },
      classroomEdit: {
        id: 'classroomEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['classroom'],
      },
      classroomDestroy: {
        id: 'classroomDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['classroom'],
      },
      classroomRead: {
        id: 'classroomRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
      classroomAutocomplete: {
        id: 'classroomAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
    };
  }
}

export default ClassroomPermissions;
