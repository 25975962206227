import authAxios from '@/shared/axios/auth-axios';
import { i18n } from '@/i18n';
export class LanguageService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/language/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/language`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/language`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const response = await authAxios.post(
      `/language/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/language/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/language`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const response = await authAxios.get(
      `/language/autocomplete`,
      {
        params,
      },
    );

    let languages = [];
    try {
      //en,ar,tr codelarini English,Arabic gibi ceviriyor.
      languages = response.data.map((d) => {
        return {
          code: d.label,
          label: i18n(
            `entities.language.enumerators.language.${d.label}`,
          ),
          id: d.id,
        };
      });
    } catch (er) {
      er.message;
    }
    return languages;
    //return this.convertLangs(response.data);
  }
}
