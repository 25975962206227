import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const QuestionListPage = () =>
  import(
    '@/modules/question/components/question-list-page.vue'
  );
const QuestionFormPage = () =>
  import(
    '@/modules/question/components/question-form-page.vue'
  );
const QuestionEditFormPage = () =>
  import(
    '@/modules/question/components/question-edit-form-page.vue'
  );
const QuestionViewPage = () =>
  import(
    '@/modules/question/components/question-view-page.vue'
  );

const QuestionPreViewPage = () =>
  import(
    '@/modules/question/components/question-preview-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'question',
        path: '/question',
        component: QuestionListPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionView,
        },
      },
      {
        name: 'questionNew',
        path: '/question/new',
        component: QuestionFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionCreate,
        },
      },
      {
        name: 'questionNewForManual',
        path: '/question/new/:examId',
        component: QuestionFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionCreate,
        },
        props: true
      },
      {
        name: 'questionEdit1',
        path: '/question/:id/edit/:examId',
        component: QuestionEditFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionEdit,
        },
        props: true,
      },
      {
        name: 'questionEdit2',
        path: '/question/:id/edit/',
        component: QuestionEditFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionEdit,
        },
        props: true,
      },
      {
        name: 'questionPreview',
        path: '/question/preview/:id',
        component: QuestionPreViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionView,
        },
        props: true,
      },
      {
        name: 'questionView',
        path: '/question/:id',
        component: QuestionViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.questionView,
        },
        props: true,
      },
    ],
  },
];
