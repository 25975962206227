import routes from '@/modules/online-exam/online-exam-routes';
import store from '@/modules/online-exam/online-exam-store';
//components burasi siserse ayir bir dosya olarak
import questionHeader from '@/modules/online-exam/components/online-exam/header.vue';
import questionFooter from '@/modules/online-exam/components/online-exam/footer.vue';
import questionBody from '@/modules/online-exam/components/online-exam/body.vue';
import questionCard from '@/modules/online-exam/components/online-exam/card.vue';

import landingHeader from '@/modules/online-exam/components/landing/header.vue';
import landingFooter from '@/modules/online-exam/components/landing/footer.vue';
import landingBody from '@/modules/online-exam/components/landing/body.vue';
import landingCard from '@/modules/online-exam/components/landing/card.vue';

export default {
  routes,
  store,
  components: [
    questionHeader,
    questionFooter,
    questionBody,
    questionCard,
    landingHeader,
    landingFooter,
    landingBody,
    landingCard,
  ],
};
