import importerStore from '@/shared/importer/importer-store';
import { UserDetailService } from '@/modules/user-detail/user-detail-service';
import userDetailImporterFields from '@/modules/user-detail/user-detail-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  UserDetailService.import,
  userDetailImporterFields,
  i18n('entities.userDetail.importer.fileName'),
  i18n('entities.userDetail.importer.hint'),
);
