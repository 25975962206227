import moment from 'moment';
import { i18n } from '@/i18n';
export default {
  name: 'formatExamFilter',
  implementation(upperLimitDate) {
    if (upperLimitDate) {
      const lang = i18n('lang');
      moment.locale(lang ? lang : 'en');
      const date = moment(upperLimitDate).format(
        'MMM Do, H:HH',
      ); //'MMM Do YYYY, h:mm a'
      return i18n('entities.exam.expDate') + ' ' + date;
    }
    return i18n('entities.exam.noTimeLimit');
  },
};
