<template>
  <div>
    <el-menu-item
      :class="classFor('/iam')"
      :route="{ path: '/iam' }"
      index="/iam"
    >
      <i class="el-icon-fa-user-plus"></i>
      <span slot="title">
        <app-i18n code="iam.menu"></app-i18n>
      </span>
    </el-menu-item>
    <el-menu-item
      :class="classFor('/exam-output')"
      :route="{ path: '/exam-output' }"
      index="/exam-output"
      v-if="isOwner"
    >
      <i class="el-icon-fa-bar-chart"></i>
      <span slot="title">
        <app-i18n
          code="entities.examOutput.menu"
        ></app-i18n>
      </span>
    </el-menu-item>

    <el-menu-item
      :class="classFor('/assign-papers')"
      :route="{ path: '/assign-papers' }"
      index="/assign-papers"
    >
      <i class="el-icon-fa-external-link"></i>
      <span slot="title">
        <app-i18n code="assignPapers.menu"></app-i18n>
      </span>
    </el-menu-item>

    <el-menu-item
      :class="classFor('/exam-result')"
      :route="{ path: '/exam-result' }"
      index="/exam-result"
    >
      <i class="el-icon-s-order"></i>
      <span slot="title">
        <app-i18n code="entities.examResult.menu"></app-i18n>
      </span>
    </el-menu-item>

    <el-submenu index="teacher-tools">
      <template slot="title">
        <i class="el-icon-fa-yelp"></i>
        <span slot="title"><app-i18n code="entities.teacher.menu"></app-i18n></span>
      </template>
      <menu-teacher-base />
    </el-submenu>
    <el-submenu index="student-tools">
      <template slot="title">
        <i class="el-icon-fa-modx"></i>
        <span slot="title"><app-i18n code="entities.student.menu"></app-i18n></span>
      </template>
      <el-menu-item :class="classFor('/tests')" :route="{ path: '/tests' }" index="/tests">
        <i class="el-icon-s-order"></i>
        <span slot="title">
          <app-i18n code="tests.menu"></app-i18n>
        </span>
      </el-menu-item>
    </el-submenu>
    <el-menu-item
            :class="classFor('/order')"
            :route="{ path: '/order' }"
            index="/order"
    >
      <i class="el-icon-fa-credit-card"></i>
      <span slot="title">
          <app-i18n code="entities.order.menu"></app-i18n>
        </span>
    </el-menu-item>
    <el-menu-item
            :class="classFor('/support-requests')"
            :route="{ path: '/support-requests' }"
            index="/support-requests"
    >
      <i class="el-icon-bangzhu"></i>
      <span slot="title">
          <app-i18n code="entities.supportRequests.menu"></app-i18n>
        </span>
    </el-menu-item>
    <el-menu-item
      :class="classFor('/structure')"
      :route="{ path: '/structure' }"
      index="/structure"
    >
      <i class="el-icon-fa-asterisk"></i>
      <span slot="title">
        <app-i18n code="entities.structure.menu"></app-i18n>
      </span>
    </el-menu-item>
<!--    <el-menu-item-->
<!--      :class="classFor('/settings')"-->
<!--      :route="{ path: '/settings' }"-->
<!--      index="/settings"-->
<!--    >-->
<!--      <i class="el-icon-s-tools"></i>-->
<!--      <span slot="title">-->
<!--        <app-i18n code="settings.menu"></app-i18n>-->
<!--      </span>-->
<!--    </el-menu-item>-->
    <el-menu-item
      :class="classFor('/audit-logs')"
      :route="{ path: '/audit-logs' }"
      index="/audit-logs"
    >
      <i class="el-icon-fa-history"></i>
      <span slot="title">
        <app-i18n code="auditLog.menu"></app-i18n>
      </span>
    </el-menu-item>
<!--    <el-menu-item-->
<!--      :class="classFor('/language')"-->
<!--      :route="{ path: '/language' }"-->
<!--      index="/language"-->
<!--    >-->
<!--      <i class="el-icon-fa-bolt"></i>-->
<!--      <span slot="title">-->
<!--        <app-i18n code="entities.language.menu"></app-i18n>-->
<!--      </span>-->
<!--    </el-menu-item>-->
    <el-menu-item
      :class="classFor('/question-type')"
      :route="{ path: '/question-type' }"
      index="/question-type"
    >
      <i class="el-icon-fa-asterisk"></i>
      <span slot="title">
        <app-i18n
          code="entities.questionType.menu"
        ></app-i18n>
      </span>
    </el-menu-item>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MenuTeacherBase from './menu-teacher-base';
export default {
  name: 'menu-admin',
  components: { MenuTeacherBase },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      isOwner: 'auth/isOwner',
    }),

    asideWidth() {
      if (!this.collapsed) {
        return '200px'; //mobil icin 200px fazlasi iyi durmadi
      }

      return '0px';
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },
    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active =
        routePath === path ||
        routePath.startsWith(path + '/');

      return {
        active,
      };
    },
  },
};
</script>

<style scoped>
.el-menu.collapsed span {
  visibility: hidden;
}
.el-menu {
  background-color: #a51e36;
}
.el-menu-item,
.el-menu-item i,
.el-submenu__title,
.el-submenu__title i {
  color: white;
}

.el-menu-item:hover,
.el-submenu:hover {
  background-color: #c8c8c833;
}

.el-submenu__icon-arrow .el-icon-arrow-down {
  color: white !important;
}

.el-submenu .el-menu-item,
.el-submenu .el-menu-item i {
  color: #a51e36;
}

.el-submenu__title:hover {
  background-color: #c8c8c833;
}
</style>
