import Axios from 'axios';

import Qs from 'qs';
import * as moment from 'moment';
import config from '@/config';
//import { getLanguageCode } from '@/i18n';

//axios instance olusturma
const axiosInstance = Axios.create({
  baseURL: config.ihuPaymentService,
  paramsSerializer: function(params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (value instanceof moment || value instanceof Date) {
          return value.toISOString();
        }

        return value;
      }
    });
  }
});

// console.log('Access-Control-Allow-Origin set edildi 1');
// //isteklerde token ve dili header'a ekle
// axiosInstance.interceptors.request.use(
//   async function(options) {
//     console.log('Access-Control-Allow-Origin set edildi 2');
//     options.headers['Access-Control-Allow-Origin'] = 'true';
//     options.headers['Accept-Language'] = getLanguageCode();
//
//     return options;
//   },
//   function(error) {
//     return Promise.reject(error);
//   }
// );

export default axiosInstance;
