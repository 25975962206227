import examListStore from '@/modules/exam/exam-list-store';
import examViewStore from '@/modules/exam/exam-view-store';
import examFormStore from '@/modules/exam/exam-form-store';
import examDestroyStore from '@/modules/exam/exam-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: examDestroyStore,
    form: examFormStore,
    list: examListStore,
    view: examViewStore,
  },
};
