import { QuestionModel } from '@/modules/question/question-model';

const { fields } = QuestionModel;

export default [
  fields.id,
  fields.keywords,
  fields.questionType,
  fields.questionGroups,
  fields.questionLanguage,
  fields.data,
  fields.answer,
  fields.lockUntil,
  fields.questionStatus,
  fields.createdAt
];
