import { ExamOutputService } from '@/modules/exam-output/exam-output-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';

export default {
  namespaced: true,

  state: {
    loading: false,
    rows: []
  },

  getters: {
    rows: state => state.rows,
    loading: state => !!state.loading,
    examName: state => {
      if (state.rows && state.rows.length > 0) {
        return state.rows[0]['exam']['name'];
      }
      return '';
    }
  },

  mutations: {
    FIND_STARTED(state) {
      state.rows = null;
      state.loading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.rows = payload;
      state.loading = false;
    },

    FIND_ERROR(state) {
      state.rows = null;
      state.loading = false;
    }
  },

  actions: {
    async doFind({ commit }, { examId, studentId }) {
      try {
        commit('FIND_STARTED');
        const record = await ExamOutputService.fetchExamAnalysisData({
          examId,
          studentId
        });
        commit('FIND_SUCCESS', record['rows']);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/exam-result');
      }
    }
  }
};
