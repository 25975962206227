import Roles from '@/security/roles';
const roles = Roles.values;

class QuestionGroupPermissions {
  static get values() {
    return {
      questionGroupImport: {
        id: 'questionGroupImport',
        allowedRoles: [roles.owner, roles.admin]
      },
      questionGroupCreate: {
        id: 'questionGroupCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionGroup']
      },
      questionGroupEdit: {
        id: 'questionGroupEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionGroup']
      },
      questionGroupDestroy: {
        id: 'questionGroupDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionGroup']
      },
      questionGroupRead: {
        id: 'questionGroupRead',
        allowedRoles: [roles.owner, roles.admin, roles.teacher, roles.student]
      },
      questionGroupAutocomplete: {
        id: 'questionGroupAutocomplete',
        allowedRoles: [roles.owner, roles.admin, roles.teacher, roles.student]
      }
    };
  }
}

export default QuestionGroupPermissions;
