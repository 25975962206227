<template>
  <div class="header">
    <i class="text-contrast raw-icon" :class="iconType"></i>
    <h3>{{ title }}</h3>
  </div>
</template>

<script>
export default {
  name: 'landing-header',
  props: {
    title: String,
    iconType: String,
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(60deg, #da3553, #8f1a2f);
  border-radius: 0.5rem;
  color: white;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2),
    0 13px 24px -11px rgba(165, 30, 54, 0.6);
}

h3 {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.rtl > h3 {
  padding-right: 1rem;
}
</style>
