import authAxios from '@/shared/axios/auth-axios';
import { routerAsync } from '@/app-module';
import Errors from '@/shared/error/errors';

export class QuestionService {
  static async update(body) {

    const response = await authAxios.put(`/question/${body.id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids
    };

    const response = await authAxios.delete(`/question`, {
      params
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data
    };

    const response = await authAxios.post(`/question`, body);

    return response.data;
  }

  //Mevcut sorunun cevabini gonderir, bir sonraki sorunun cevabini alir.
  //data {examId, answer}
  static async getNext(data) {
    const body = { data };
    try {
      const response = await authAxios.post(`/question/next`, body);
      const resData = response.data; //data = {record, qNumber}
      if (resData.record === 'TAKE_A_BREAK') {
        routerAsync().push(`/break/${data.examId}/${resData.qNumber}`);
      } else {
        return resData;
      }
    } catch (error) {
      Errors.showMessage(error);
      // if (error.message.includes(404)) {
      //   //Exam is not activated
      //   routerAsync().push('/404');
      // } else if (error.message.includes(403)) {
      //   routerAsync().push('/403');
      // } else if (error.message.includes(480)) {
      //   //Exam expired
      //   routerAsync().push({ name: 'exam-expired' }); //404
      // } else {
      //   Errors.showMessage(error); //Hata durumunda ogrencinin cevabi gitmesin
      // }
    }
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash
    };

    const response = await authAxios.post(`/question/import`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/question/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset
    };

    const response = await authAxios.get(`/question`, {
      params
    });

    return response.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit
    };

    const response = await authAxios.get(`/question/autocomplete`, {
      params
    });

    return response.data;
  }
}
