import classroomListStore from '@/modules/classroom/classroom-list-store';
import classroomViewStore from '@/modules/classroom/classroom-view-store';
import classroomImporterStore from '@/modules/classroom/classroom-importer-store';
import classroomFormStore from '@/modules/classroom/classroom-form-store';
import classroomDestroyStore from '@/modules/classroom/classroom-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: classroomDestroyStore,
    form: classroomFormStore,
    list: classroomListStore,
    view: classroomViewStore,
    importer: classroomImporterStore,
  },
};
