import _get from 'lodash/get';
import moment from 'moment';
import 'moment/locale/ar';
import { setLocale as setYupLocale } from 'yup';
import ar from '@/i18n/ar';
import en from '@/i18n/en';
import tr from '@/i18n/tr';
import elementUIEn from 'element-ui/lib/locale/lang/en';
import elementUIAr from 'element-ui/lib/locale/lang/ar';
import elementUITr from 'element-ui/lib/locale/lang/tr-TR';

let currentLanguageCode = null;
//Mevcut diller
const languages = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/24/United-States.png',
    dictionary: en,
    elementUI: elementUIEn
  },
  ar: {
    id: 'ar',
    label: 'عربى',
    flag: '/images/flags/24/Saudi-Arabia.png',
    dictionary: ar,
    elementUI: elementUIAr
  },
  tr: {
    id: 'tr',
    label: 'Türkçe',
    flag: '/images/flags/24/Turkey.png',
    dictionary: tr,
    elementUI: elementUITr
  }
};

function init() {
  currentLanguageCode = localStorage.getItem('language') || 'en';
  setLanguageCode(currentLanguageCode);
}

function getLanguage() {
  return languages[getLanguageCode()];
}

function format(message, args) {
  if (!message) {
    return null;
  }

  try {
    return message.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  } catch (error) {
    throw error;
  }
}

export function getLanguages() {
  return Object.keys(languages).map(language => {
    return languages[language];
  });
}

export function getElementUILanguage() {
  return getLanguage().elementUI;
}

export function getLanguageCode() {
  if (!currentLanguageCode) {
    init();
  }

  return currentLanguageCode;
}

export function setLanguageCode(arg) {
  if (!languages[arg]) {
    throw new Error(`Invalid language ${arg}.`);
  }

  moment.locale(arg);
  localStorage.setItem('language', arg);

  if (getLanguage().dictionary.validation) {
    setYupLocale(getLanguage().dictionary.validation);
  }
}

export function i18nExists(key) {
  const message = _get(getLanguage().dictionary, key);
  return !!message;
}

export function i18nWL(key, lang, ...args) {
  const message = _get(languages[lang].dictionary, key);

  if (!message) {
    return key;
  }
  return format(message, args);
}

export function i18n(key, ...args) {
  const message = _get(getLanguage().dictionary, key);

  if (!message) {
    return key;
  }

  return format(message, args);
}
