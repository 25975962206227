import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '../../shared/fields/enumerator-field';

function label(name) {
  return i18n(`entities.questionGroup.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.questionGroup.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  questionGroup: new StringField('questionGroup', label('questionGroup'), {
    required: true
  }),
  groupStatus: new EnumeratorField('groupStatus', label('groupStatus'), [
    {
      id: '0',
      label: enumeratorLabel('groupStatus', 'default')
    }
  ]),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange')
  )
};

export class QuestionGroupModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
