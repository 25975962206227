import Roles from '@/security/roles';
const roles = Roles.values;

class SupportRequestsPermissions {
  static get values() {
    return {
      supportRequestsCreate: {
        id: 'supportRequestsCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
        allowedStorageFolders: ['supportRequests'],
      },
      supportRequestsEdit: {
        id: 'supportRequestsEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
        allowedStorageFolders: ['supportRequests'],
      },
      supportRequestsDestroy: {
        id: 'supportRequestsDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
        allowedStorageFolders: ['supportRequests'],
      },
      supportRequestsRead: {
        id: 'supportRequestsRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
      supportRequestsAutocomplete: {
        id: 'supportRequestsAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
    };
  }
}

export default SupportRequestsPermissions;
