import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import IntegerField from '@/shared/fields/integer-field';
import IntegerRangeField from '@/shared/fields/integer-range-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import { QuestionField } from '@/modules/question/question-field';
import { UserField } from '@/modules/auth/user-field';
import { StructureField } from '../structure/structure-field';
import { LanguageField } from '../language/language-field';
import { QuestionGroupField } from '../question-group/question-group-field';
import { QuestionModel } from '@/modules/question/question-model';

const { fields: questionModelFields } = QuestionModel;
function label(name) {
  return i18n(`entities.exam.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.exam.enumerators.${name}.${value}`);
  //return label.includes('entities.exam.enumerators') ? value : label;
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true
  }),
  examData: new StringField('examData', label('examData'), {
    required: true
  }),
  examUpperLimit: new DateTimeField('examUpperLimit', label('examUpperLimit'), {
    required: false
  }),
  examLowerLimit: new DateTimeField('examLowerLimit', label('examLowerLimit'), {
    required: false
  }),
  questionPools: QuestionGroupField.relationToMany(
    'questionPools',
    label('questionPools'),
    {
      required: false
    }
  ),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'draft',
        label: enumeratorLabel('status', 'draft')
      },
      {
        id: 'published',
        label: enumeratorLabel('status', 'published')
      }
    ],
    {}
  ),
  duration: new IntegerField('duration', label('duration'), {
    required: true
  }),
  questionPoint: new IntegerField('questionPoint', label('questionPoint'), {
    required: true
  }),
  price: new IntegerField('price', label('price'), {
    required: true
  }),
  examType: new EnumeratorField(
    'examType',
    label('examType'),
    [
      {
        id: 'structured',
        label: enumeratorLabel('examType', 'structured')
      },
      {
        id: 'manual',
        label: enumeratorLabel('examType', 'manual')
      },
    ],
    { required: true }
  ),
  examLanguage: LanguageField.relationToOne(
    'examLanguage',
    label('examLanguage')
  ),
  isFree: new BooleanField('isFree', label('isFree')),
  fixQuestions: new BooleanField('fixQuestions', label('fixQuestions')),
  unScored: new BooleanField('unScored', label('unScored')),
  structure: StructureField.relationToOne('structure', label('structure'), {
    required: true
  }),
  students: UserField.relationToMany('students', label('students'), {}),
  readers: UserField.relationToMany('readers', label('readers'), {}),
  questions: QuestionField.relationToMany('questions', label('questions'), { questionModelFields: questionModelFields}),

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange')
  ),
  examUpperLimitRange: new DateTimeRangeField(
    'examUpperLimitRange',
    label('examUpperLimitRange')
  ),
  examLowerLimitRange: new DateTimeRangeField(
    'examLowerLimitRange',
    label('examLowerLimitRange')
  ),
  durationRange: new IntegerRangeField('durationRange', label('durationRange')),
  priceRange: new IntegerRangeField('priceRange', label('priceRange'))
};

export class ExamModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
