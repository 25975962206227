import { QuestionGroupService } from '@/modules/question-group/question-group-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';
import { i18n } from '@/i18n';

function enumeratorLabel(value) {
  return i18n(`entities.question.enumerators.questionGroup.${value}`);
  // return label.includes('entities.question.enumerators.questionGroup')
  //   ? value
  //   : label;
}

export class QuestionGroupField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/question-group',
      Permissions.values.questionGroupRead,
      QuestionGroupService.listAutocomplete,
      record => {
        if (!record || !record.id) {
          return null;
        }

        return {
          id: record.id,
          label: enumeratorLabel(record.questionGroup)
        };
      },
      options
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/question-group',
      Permissions.values.questionGroupRead,
      QuestionGroupService.listAutocomplete,
      record => {
        if (!record || !record.id) {
          return null;
        }
        //console.log('Mapper Fn Record: ', record.questionGroup);
        return {
          id: record.id,
          label: record.questionGroup
        };
      },
      options
    );
  }
}
