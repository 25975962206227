import { ExamService } from '@/modules/exam/exam-service';
// import { StructureService } from '@/modules/structure/structure-service';
import { QuestionService } from '@/modules/question/question-service';
import { routerAsync } from '@/app-module';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { pick } from 'lodash';
import UtilsText from '@/shared/utils-text';
import { getRecording, ResetRecording } from './online-exam-recorder-store';
import UtilsModules from '../../shared/utils-for-modules';

const path = '/online-exam/';

export default {
  namespaced: true,
  state: {
    currentExam: null,
    currentQuestion: null,
    currentQuestionNumber: 0,
    currentAnswer: {},
    questions: [],
    isLoading: true,
    showNext: false,
    timePassed: 0,
    structItems: []
  },
  getters: {
    showNext: state => state.showNext,
    currentExam: state => state.currentExam,
    currentLanguage: state => state.currentExam.examLanguage.code,
    currentQuestion: state => state.currentQuestion,
    currentQuestionNumber: state => state.currentQuestionNumber,
    currentAnswer: state => state.currentAnswer,
    structItems: state => state.structItems,
    questionDescription: (state, getters) => {
      const currentQuestion = getters.currentQuestion;
      if (getters.structItems) {
        currentQuestion.descriptions =
          getters.structItems[getters.currentQuestion.questionTypeId];
      }
      return UtilsModules.questionDescription(
        currentQuestion,
        getters.currentLanguage,
        getters.questionType,
      );
    },
    questionTitle: (state, getters) => {
      if (!getters.currentQuestion) {
        return null;
      }
      const currentLanguage = getters.currentLanguage ? getters.currentLanguage : 'en';
      return UtilsModules.questionTitle(
        getters.currentExam.name,
        getters.currentQuestion.questionType.section,
        getters.questionType,
        currentLanguage
      );
    },
    questionTypeforView: (state, getters) => {
      const currentLanguage = getters.currentLanguage ? getters.currentLanguage : 'en';
      return UtilsModules.questionTypeLabel(getters.questionType, currentLanguage);
    },
    questionType: (state, getters) => {
      //Bunu metot yap, test kodunu yaz
      if (!getters.currentQuestion) {
        return null;
      }
      const questionType = getters.currentQuestion.questionType.questionType;
      return UtilsModules.convertQTypeForExam(questionType);
    },
    duration: (state, getters) => {
      return getters.currentExam.duration * 60;
    },
    timePassed: state => {
      return state.timePassed;
    },
    isLoading: state => state.isLoading,
    getQuestionLabel: (state, getters) => {
      return `${getters.currentQuestionNumber} / ${state.currentExam.qCount}`;
    }, // 1 / 25 = kaçıncı soruda olduğunu gösterir
    questionPath: (state, getters) => {
      return (
        path + getters.currentExam.id + '/' + getters.currentQuestionNumber
        //Path route'dan once question cekildigi icin +1 e gerek yok
      );
    },
    isRTL(state, getters) {
      return UtilsModules.isRTL(getters.currentExam.examLanguage.code);
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    },
    checkTextRight(state, getters) {
      return UtilsModules.getClassTextRight(getters.isRTL);
    }
  },
  mutations: {
    RESET_ALL(state) {
      state.currentExam = null;
      state.currentQuestion = null;
      state.currentQuestionNumber = null;
    },
    SET_SHOW_NEXT(state, payload) {
      state.showNext = payload;
    },
    SET_CURRENT_EXAM(state, payload) {
      state.currentExam = payload;
    },
    SET_CURRENT_QUESTION_NUMBER(state, index) {
      state.currentQuestionNumber = index;
    },
    SET_CURRENT_QUESTION(state, payload) {
      state.currentQuestion = payload;
    },
    DESTROY_AUDIO(state) {
      if (state.currentQuestion && state.currentQuestion.data) {
        state.currentQuestion.data.src = '';
      }
    },
    SET_CURRENT_ANSWER(state, payload) {
      state.currentAnswer = payload || {};
    },
    SET_FINISH_BREAK(state) {
      //Sunucunun istegin break'den sonra geldigini anlamasi icin
      state.currentAnswer.isAfterBreak = true;
    },
    SET_IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_TIME_PASSED(state, payload) {
      state.timePassed = payload;
    },
    SET_QUESTIONS: function(state, payload) {
      state.questions = payload.map(question => {
        return {
          ...pick(question, [
            'id',
            'data',
            'questionLanguage',
            'keywords',
            'section'
          ])
        };
      });
    },
    SET_STRUCTURE_ITEMS(state, payload) {
      state.structItems = payload;
    }
  },
  actions: {
    isInPWA() {
      const isLocalhost = window.location.hostname.includes('localhost');
      const inPwa = window.matchMedia('(display-mode: standalone)').matches;
      return inPwa || isLocalhost;
    },
    async fetchCurrentExam(
      { getters, dispatch, commit },
      id = getters.currentExam.id
    ) {
      commit('SET_IS_LOADING', true);
      await dispatch('fetchOnlyCurrentExam', id); //todo sadece sinavi cektigin senaryo gerekli mi? backend getNext question icinde sinav da cekiliyor aslinda
      await dispatch('fetchCurrentQuestion');
      if (location.href.indexOf(getters.questionPath) < 0) {
        routerAsync().push(getters.questionPath);
      }
      commit('SET_IS_LOADING', false);
    },

    fecthStructure({ getters, commit }) {
        const structArr = getters.currentExam.structure.structItems;
        let structureItems = {};
        for (let i = 0; i < structArr.length; i++) {
           const typeId = structArr[i].questionTypeId;
           structureItems[typeId] = structArr[i].descriptions;
        }
        commit('SET_STRUCTURE_ITEMS', structureItems);
    },

    async fetchCurrentQuestion({ commit, getters }) {
      const { currentExam } = getters;
      const answer = getters.currentAnswer;
      const inAudioCheck = getters.currentQuestionNumber === 0;

      if (getRecording() && !inAudioCheck) {
        answer.path = await FileUploader.uploadAudioToServer(
          getRecording(),
          'examOutput/files'
        );
      }
      if (!inAudioCheck) {
        // 2 soru tipinde input dile göre küçük harfe çevrilir
        const currentQuestionType =
          getters.currentQuestion.questionType.questionType;
        const examLanguageCode = currentExam.examLanguage.code;
        //todo alttaki if else'e gerek yok tiplerin kendi icinde kullan bu metotlari
        if (
          currentQuestionType === 'Write In The Blanks' ||
          currentQuestionType === 'Highlight Incorrect Words' ||
          currentQuestionType === 'Selected Fill In The Blanks' ||
          currentQuestionType === 'Fill In The Blanks' ||
          currentQuestionType === 'Re-order Paragraph'
        ) {
          answer.input = UtilsText.OptionsLowerCase(
            answer.input,
            examLanguageCode
          );
        } else if (currentQuestionType === 'Write From The Dictation') {
          answer.input = UtilsText.LowerCase(answer.input, examLanguageCode);
        }
      }

      const resData = await QuestionService.getNext({
        examId: currentExam.id,
        answer
      });
      if (resData != null) {
        if (resData.record === 'FINISH_EXAM') {
          const currentExamId = currentExam.id;
          commit('RESET_ALL');
          routerAsync().push('/survey/' + currentExamId);
          return;
        }
        const { nextQuestion, qNumber, timePassed } = resData;
        commit('SET_CURRENT_QUESTION_NUMBER', qNumber);
        commit('SET_CURRENT_QUESTION', nextQuestion);
        commit('SET_CURRENT_ANSWER', {}); //Onceki sorunun cevabini sifirla
        commit('SET_TIME_PASSED', timePassed);
      }
      ResetRecording();
    },
    async fetchOnlyCurrentExam(
      { commit, getters, dispatch },
      id = getters.currentExam.id
    ) {
      const { currentExam } = getters;
      if (currentExam == null) {
        //sinav yoksa sinav cekilir.
        const record = await ExamService.find(id);
        commit('SET_CURRENT_EXAM', record);
        if (record.examType != "manual")
          dispatch('fecthStructure');
      }
    },
    async fetchCurrentExamForSurvey(
      { commit, getters },
      id = getters.currentExam.id
    ) {
      commit('SET_IS_LOADING', true);
      if (getters.currentExam == null) {
        //sinav yoksa sinav cekilir.
        const record = await ExamService.find(id);
        commit('SET_CURRENT_EXAM', record);
      }
      commit('SET_IS_LOADING', false);
    },
    // setShowNext({ commit }) {
    //   commit('SET_SHOW_NEXT');
    // },
    async goNext({ rootState, dispatch, commit }) {
      // next butonuna basıldığında var olan bir kayıt varsa durdurulur
      commit('SET_SHOW_NEXT', false);
      if (rootState.onlineExam.recorder.step === 'RECORD_STARTED') {
        dispatch('onlineExam/recorder/stop', null, {
          root: true
        });
      } else {
        await dispatch('fetchCurrentExam');
      }
    }
  }
};
