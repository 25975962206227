import { ExamResultService } from '@/modules/exam-result/exam-result-service';
import examResultListExporterFields from '@/modules/exam-result/exam-result-list-exporter-fields';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    table: null,
    saveLoading: false //for
  },

  getters: {
    loading: state => state.loading,

    exportLoading: state => state.exportLoading,

    rows: state => state.rows || [],

    count: state => state.count,

    hasRows: (state, getters) => getters.count > 0,

    orderBy: state => {
      const sorter = state.sorter;

      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },

    filter: state => state.filter,

    limit: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }

      return pagination.pageSize;
    },

    offset: state => {
      const pagination = state.pagination;

      if (!pagination || !pagination.pageSize) {
        return 0;
      }

      const currentPage = pagination.currentPage || 1;

      return (currentPage - 1) * pagination.pageSize;
    },

    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true
      };
    },

    selectedRows: state => {
      return state.table ? state.table.selection : [];
    },
    saveLoading: state => !!state.saveLoading
  },

  mutations: {
    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },

    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },

    TABLE_MOUNTED(state, payload) {
      state.table = payload;
    },

    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },

    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE
      };
    },

    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE
      };
    },

    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },

    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },

    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },

    EXPORT_ERROR(state) {
      state.exportLoading = false;
    }
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },

    doMountTable({ commit }, table) {
      commit('TABLE_MOUNTED', table);
    },

    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },

    async doExport({ commit, getters }) {
      try {
        if (
          !examResultListExporterFields ||
          !examResultListExporterFields.length
        ) {
          throw new Error('examResultListExporterFields is required');
        }

        commit('EXPORT_STARTED');

        const filter = getters.filter;

        const response = await ExamResultService.list(
          filter,
          getters.orderBy,
          null,
          null
        );

        new Exporter(
          examResultListExporterFields,
          'examResult'
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },

    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch({ commit, getters }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await ExamResultService.list(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset
        );

        //Output Status outputStatus fetched answered vs
        response.rows.map(r => {
          r.resultStatus = r.resultStatus.toString();
          /*Kullanici exam result > dashboard gecisi yapinca rows sabit kaldigi
          * icin veri uyumsuzlugu oluyordu. Uyusmayan exam name'i bu sekilde
          * set ettim*/
          if(r.exam)
            r.name = r.exam.name
        });
        //console.log("rows: " + JSON.stringify(response.rows))
        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count
        });

        //console.log("basarıyla cekildi: " + JSON.stringify(response));
      } catch (error) {
        //console.log(`Error`, error);
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doFetchFive({ commit }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await ExamResultService.list(
          null,
          'updatedAt_ASC',
          5,
          0
        );

        let rows = response.rows.map(r => {
          const {
            reading,
            writing,
            speaking,
            listening,
            aftermath,
            exam,
            results
          } = r;
          return {
            reading,
            writing,
            speaking,
            listening,
            aftermath,
            results,
            name: exam.name
          };
        });

        commit('FETCH_SUCCESS', {
          rows,
          count: response.count
        });
      } catch (error) {
        commit('FETCH_ERROR');
      }
    },

    async doAnnounceAll({ commit, dispatch }) {
      try {
        commit('UPDATE_STARTED');

        await ExamResultService.announceAll();

        Message.success(i18n('entities.examResult.update.announce'));
        commit('UPDATE_SUCCESS');
        const filter = {};
        await dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    }
  }
};
