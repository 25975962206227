import { mapKeys } from 'lodash';
import ExporterSchema from '@/shared/exporter/exporter-schema';
import { Excel } from '@/shared/excel/excel';

export default class Exporter {
  constructor(fields, excelFileName) {
    this.schema = new ExporterSchema(fields);
    this.excelFileName = excelFileName;
  }

  transformAndExportAsExcelFile(rows) {
    const exportableData = rows.map((row) => {
      row = this.reMapData(row);
      const rowCasted = this.schema.cast(row);
      return this._makeNameHeadersIntoLabels(rowCasted);
    });

    return Excel.exportAsExcelFile(
      exportableData,
      this.schema.labels,
      this.excelFileName + '_' + new Date().getTime(),
    );
  }

  reMapData(row) {
    row.examName = row.exam.name  || "-";
    row.studentName = row.student.fullName || "-";
    row.studentEmail = row.student.email  || "-";
    // const skills = ["grammar","spelling","vocabulary","oralFluency","pronunciation","writtenDiscourse"]
    // skills.forEach(skill => {
    //   row[skill] = row.results ? row.results[skill] || 0 : 0;
    // })
    return row;
  }

  _makeNameHeadersIntoLabels(row) {
    return mapKeys(row, (value, key) => {
      return this.schema.labelOf(key);
    });
  }
}
