import languageListStore from '@/modules/language/language-list-store';
import languageViewStore from '@/modules/language/language-view-store';
import languageImporterStore from '@/modules/language/language-importer-store';
import languageFormStore from '@/modules/language/language-form-store';
import languageDestroyStore from '@/modules/language/language-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: languageDestroyStore,
    form: languageFormStore,
    list: languageListStore,
    view: languageViewStore,
    importer: languageImporterStore,
  },
};
