/*
 * status = []
 * */

const mimeType = 'audio/webm;codecs=pcm';
let chunks = [];
let gRecorder = null;
let recording = null;

/*
 * step = 1 => izin verildi = recordu göster
 * step = 2 => record başladı = stopu göster
 * step = 3 => record stop => yükleniyor
 * step = 4 => audio url alınır => player göster
 * step = 5 => listen record => player stop göster;
 * step = 6 => player durduruldu => sınava başla.
 * step = -1 => izin verilmedi veya başka hatalar
 * */

export default {
  namespaced: true,
  state: {
    step: 'INITIAL',
    audioUrl: '',
    errors: [],
    isDemoRecord: false,
  },
  getters: {
    audioUrl: (state) => state.audioUrl,
    hasError: (state) => state.errors.length > 0,
    errors: (state) => state.errors,
    step: (state) => state.step,
    getBlob: () => recording,
    isDemoRecord: (state) => state.isDemoRecord,
  },
  mutations: {
    PERMISSION_SUCCESS(state) {
      state.step = 'PERMISSION_SUCCESS';
    },
    PERMISSION_ERROR(state, { error }) {
      state.step = 'PERMISSION_ERROR';
      state.errors.push(error);
    },
    RECORD_STARTED(state) {
      state.audioUrl = '';
      if (gRecorder.state !== 'recording') {
        gRecorder.start();
      }
      state.step = 'RECORD_STARTED';
    },
    SET_DEMO_RECORD(state, isDemoRecord) {
      state.isDemoRecord = isDemoRecord;
    },
    RECORD_SUCCESS(state) {
      state.audioUrl = URL.createObjectURL(recording);
      state.step = 'RECORD_SUCCESS';
    },
    RECORD_STOP(state) {
      state.step = 'RECORD_STOP';
    },
    RECORD_RESET(state) {
      state.step = 'INITIAL';
    },

    UPLOAD_STARTED(state) {
      state.isUploading = true;
    },
    UPLOAD_SUCCESS(state) {
      state.isUploading = false;
    },
    UPLOAD_ERROR(state) {
      state.isUploading = false;
      state.errors.push('record error');
    },
  },
  actions: {
    async record({ commit, dispatch }) {
      commit('SET_DEMO_RECORD', false);
      if (gRecorder == null) {
        await dispatch('requestPermission');
      }
      commit('RECORD_STARTED');
    },

    recordDemo({ commit, dispatch }) {
      commit('SET_DEMO_RECORD', true);
      commit('RECORD_STARTED');
      setTimeout(() => {
        //demo kaydi 5 saniye suruyor
        dispatch('stop');
      }, 5000);
    },

    stop({ commit }) {
      if (gRecorder.state === 'recording') {
        gRecorder.stop();
        commit('RECORD_STOP');
      }
    },
    reset({ commit }) {
      commit('RECORD_RESET');
    },

    async requestPermission({ getters, commit, dispatch }) {
      if (navigator.mediaDevices == null) {
        commit('PERMISSION_ERROR', {
          error:
            'Your browser does not support the audio, check SSL certificates!',
        });
        return Promise.reject('error');
      }

      return navigator.mediaDevices
        .getUserMedia({
          audio: true,
          video: false,
        })
        .then(function(newStream) {
          commit('PERMISSION_SUCCESS');
          gRecorder = new MediaRecorder(newStream, {
            type: mimeType,
          });

          gRecorder.addEventListener(
            'dataavailable',
            (event) => {
              if (typeof event.data === 'undefined') return;
              if (event.data.size === 0) return;
              chunks.push(event.data);
            },
          );

          gRecorder.addEventListener('stop', () => {
            recording = new Blob(chunks, {
              type: mimeType,
            });

            // var mp3Data = [];
            // var mp3encoder = new lamejs.Mp3Encoder(
            //   1,
            //   44100,
            //   128,
            // );
            //
            // var mp3Tmp = mp3encoder.encodeBuffer(chunks); //encode mp3
            // mp3Data.push(mp3Tmp);
            // mp3Tmp = mp3encoder.flush();
            // mp3Data.push(mp3Tmp);

            // recording = new Blob(mp3Data, {
            //   //type: mimeType,
            //   type: 'audio/mpeg-3',
            // });

            // recording =
            commit('RECORD_SUCCESS');
            chunks = [];
            if (!getters.isDemoRecord) {
              dispatch(
                'onlineExam/main/fetchCurrentExam',
                null,
                {
                  root: true,
                },
              );
            }
          });
        })
        .catch((err) => {
          if (err.message === 'Permission denied') {
            commit('PERMISSION_ERROR', {
              error:
                'To Continue, unblock microphone usage and click GET MICROPHONE',
            });
          } else if (
            err.message.includes(
              'Requested device not found',
            )
          ) {
            commit('PERMISSION_ERROR', {
              error:
                'Microphone not found, please RESTART BROWSER',
            });
          } else {
            commit('PERMISSION_ERROR', {
              error: err.message,
            });
          }
        });
    },

    async upload({ commit }) {
      commit('UPLOAD_STARTED');
      // fetch işlemi

      //
      // formData.append('file', recording);
      // const response = await ExamService.uploadRecord(
      //   formData,
      // );
      // console.log('upload response');
      // console.log(response);
      commit('UPLOAD_SUCCESS');
    },
  },
};

export function getRecording() {
  return recording;
}

export function ResetRecording() {
  recording = null;
}
