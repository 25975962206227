<template>
  <div class="edit-form">
    <div class="edit-fixed-buttons">
      <el-button
        :disabled="saveLoading"
        @click="doReset"
        icon="el-icon-fa-undo"
        :class="rtlAndPadding"
      >
        <span class="card-title">
          <app-i18n code="common.reset"></app-i18n>
        </span>
      </el-button>

      <el-button
        :disabled="saveLoading"
        @click="doCancel"
        icon="el-icon-fa-close"
        :class="rtlAndPadding"
      >
        <span class="card-title">
          <app-i18n code="common.cancel"></app-i18n>
        </span>
      </el-button>
    </div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
      :class="checkRTL"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
      >
        <el-col :lg="9" :md="12" :sm="24">
          <el-input :disabled="true" v-model="model[fields.id.name]" />
        </el-col>
      </el-form-item>
      <el-form-item
        :label="fields.keywords.label"
        :prop="fields.keywords.name"
        :required="fields.keywords.required"
      >
        <el-col :lg="9" :md="12" :sm="24">
          <el-input v-model="model[fields.keywords.name]" ref="focus" />
        </el-col>
      </el-form-item>

      <el-form-item :label="fields.section.label" :prop="fields.section.name">
        <el-col :lg="9" :md="12" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.section.name]"
            @change="sectionChanged"
          >
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.section.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.questionType.label"
        :prop="fields.questionType.name"
        :required="fields.questionType.required"
      >
        <el-col :lg="9" :md="12" :sm="24">
          <app-question-type-autocomplete-input
            :fetchFn="fields.questionType.fetchFn"
            :mapperFn="fields.questionType.mapperFn"
            v-model="model[fields.questionType.name]"
            @input="selectedQuestionType"
            mode="single"
            :filter="questionTypeFilter"
            :key="questionTypeFilter.section"
          ></app-question-type-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="!isForMExam"
        :label="fields.questionLanguage.label"
        :prop="fields.questionLanguage.name"
        :required="fields.questionLanguage.required"
      >
        <el-col :lg="9" :md="12" :sm="24">
          <app-language-autocomplete-input
            :fetchFn="fields.questionLanguage.fetchFn"
            :mapperFn="fields.questionLanguage.mapperFn"
            v-model="model[fields.questionLanguage.name]"
            mode="single"
          ></app-language-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="isForMExam"
        :label="fields.description.label"
        :prop="fields.description.name"
        :required="fields.description.required"
      >
        <el-col :lg="9" :md="12" :sm="24">
          <el-input v-model="model[fields.description.name]" />
        </el-col>
      </el-form-item>

      <el-form-item
        v-if="!isForMExam"
        :label="fields.questionGroups.label"
        :prop="fields.questionGroups.name"
        :required="fields.questionGroups.required"
      >
        <el-col :lg="9" :md="12" :sm="24" style="color: red">
          <app-question-group-autocomplete-input
            :fetchFn="fields.questionGroups.fetchFn"
            :mapperFn="fields.questionGroups.mapperFn"
            v-model="model[fields.questionGroups.name]"
            mode="multiple"
          ></app-question-group-autocomplete-input>
        </el-col>
      </el-form-item>

      <!--      <el-form-item-->
      <!--              :label="fields.questionStatus.label"-->
      <!--              :prop="fields.questionStatus.name"-->
      <!--              :required="true"-->
      <!--      >-->
      <!--        <el-col :lg="11" :md="16" :sm="24">-->
      <!--          <el-select-->
      <!--                  placeholder-->
      <!--                  v-model="model[fields.questionStatus.name]"-->
      <!--          >-->
      <!--            <el-option-->
      <!--                    :key="option.value"-->
      <!--                    :label="option.label"-->
      <!--                    :value="option.value"-->
      <!--                    v-for="option in fields.questionStatus.options"-->
      <!--            ></el-option>-->
      <!--          </el-select>-->
      <!--        </el-col>-->
      <!--      </el-form-item>-->

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
            :class="rtlAndPadding"
          >
            <span class="card-title">
              <app-i18n code="common.save"></app-i18n>
            </span>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { QuestionModel } from '@/modules/question/question-model';
import MemoryKeys from '../../../shared/memory-keys';
import questionTypes from '@/shared/questionType';
import { i18nWL } from '@/i18n';

const { fields } = QuestionModel;
const formSchema = new FormSchema([
  fields.id,
  fields.keywords,
  fields.questionType,
  fields.section,
  fields.questionLanguage,
  fields.description,
  fields.questionGroups,
  fields.questionStatus,
  fields.allowedUsers,
  fields.preVersions,
  fields.data,
  fields.answer
]);

export default {
  name: 'app-question-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal', 'isForMExam', 'examRecord'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      questionTypeFilter: {},
      questionTypeImageNotify: null,
      qName: ''
    };
  },

  created() {
    let questionRemembered = MemoryKeys.getQuestion();
    if(this.isForMExam && questionRemembered && !questionRemembered.qName) 
      questionRemembered=null 
    this.model = formSchema.initialValues(
      this.record || {
        questionGroups:
          questionRemembered && questionRemembered.questionGroups
            ? questionRemembered.questionGroups.map(groupValue => {
                return { id: groupValue };
              })
            : null,
        questionLanguage:
          questionRemembered && questionRemembered.questionLanguage
            ? { id: questionRemembered.questionLanguage }
            : null,
        questionType:
          questionRemembered && questionRemembered.questionType
            ? { id: questionRemembered.questionType} 
            : null,
        section:
          questionRemembered && questionRemembered.section
            ? { id: questionRemembered.section }
            : null,
      }
    );
    if(questionRemembered && questionRemembered.qName) {
      this.selectedQuestionType({qName: questionRemembered.qName});
    }
    this.sectionChanged();
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      hasAdminRole: 'auth/hasAdminRole',
      checkRTL: 'question/form/checkRTL',
      rtlAndPadding: 'question/form/rtlAndPadding'
    }),

    fields() {
      return fields;
    }
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    showQuestionTypeImage(id) {
      this.questionTypeImageNotify = this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<img src="/images/questiontypes/${id}-min.png" width="100%" />`,
        position: 'top-right'
      });

      this.questionTypeImageNotify.$el.style.width = '42%';
      this.questionTypeImageNotify.$el.style.padding = '0px';
    },

    findQuestionType(qName) {
      for (let [key, value] of Object.entries(questionTypes)) {
        if (qName === value) return key;
      }
    },
    defaultDescription(qType, lang) {
      if (qType) {
        return i18nWL(`entities.onlineExam.types.${qType}.description`, lang);
      }
    },
    getDescription(value) {
      const qTypeName = this.findQuestionType(value.qName);
      this.model[fields.description.name] = this.defaultDescription(qTypeName, this.examRecord.examLanguage.code);
    },

    selectedQuestionType(value) {
      if (this.isForMExam)
         this.getDescription(value);
      if (this.questionTypeImageNotify) {
        this.questionTypeImageNotify.close();
      }
      if (value.id)
        this.showQuestionTypeImage(value.id);
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.qName = this.model.questionType.qName;

      return this.$emit('submit', {
        id,
        values
      });
    },

    sectionChanged() {
      if (
        this.questionTypeFilter.section &&
        this.questionTypeFilter.section != this.model[fields.section.name]
      ) {
        this.model[fields.questionType.name] = null;
      }
      this.questionTypeFilter = this.model[fields.section.name]
        ? { section: this.model[fields.section.name] }
        : {};
    }
  },

  watch: {
    examRecord() {
      if (this.examRecord)
        this.model[fields.questionLanguage.name] = { id: this.examRecord.examLanguageId };
    }
  },

  beforeDestroy() {
    if (this.questionTypeImageNotify)
      this.questionTypeImageNotify.close();
  }
};
</script>
<style></style>
