import { i18n } from '@/i18n';
import _values from 'lodash/values';

//User Types
class Roles {
  static get values() {
    return {
      owner: 'owner',
      admin: 'admin',
      teacher: 'teacher',
      student: 'student',
      canCreateExam: 'canCreateExam',
      canListQEs: 'canListQEs',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values) //object to array
      .filter((v) => v !== 'owner') //owner gorunmesin
      .map((value) => ({
        id: value,
        value: value,
        title: this.descriptionOf(value),
        label: this.labelOf(value),
      }));
  }
}

export default Roles;
