import questionGroupListStore from '@/modules/question-group/question-group-list-store';
import questionGroupViewStore from '@/modules/question-group/question-group-view-store';
import questionGroupFormStore from '@/modules/question-group/question-group-form-store';
import questionGroupDestroyStore from '@/modules/question-group/question-group-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: questionGroupDestroyStore,
    form: questionGroupFormStore,
    list: questionGroupListStore,
    view: questionGroupViewStore
  }
};
