import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ClassroomListPage = () =>
  import(
    '@/modules/classroom/components/classroom-list-page.vue'
  );
const ClassroomFormPage = () =>
  import(
    '@/modules/classroom/components/classroom-form-page.vue'
  );
const ClassroomViewPage = () =>
  import(
    '@/modules/classroom/components/classroom-view-page.vue'
  );
const ClassroomImporterPage = () =>
  import(
    '@/modules/classroom/components/classroom-importer-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'classroom',
        path: '/classroom',
        component: ClassroomListPage,
        meta: {
          auth: true,
          permission: Permissions.values.classroomView,
        },
      },
      {
        name: 'classroomNew',
        path: '/classroom/new',
        component: ClassroomFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.classroomCreate,
        },
      },
      {
        name: 'classroomImporter',
        path: '/classroom/import',
        component: ClassroomImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.classroomImport,
        },
      },
      {
        name: 'classroomEdit',
        path: '/classroom/:id/edit',
        component: ClassroomFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.classroomEdit,
        },
        props: true,
      },
      {
        name: 'classroomView',
        path: '/classroom/:id',
        component: ClassroomViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.classroomView,
        },
        props: true,
      },
    ],
  },
];
