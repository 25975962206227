import Roles from '@/security/roles';
const roles = Roles.values;

class IamPermissions {
  static get values() {
    return {
      iamEdit: {
        id: 'iamEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['user'],
      },
      iamCreate: {
        id: 'iamCreate',
        allowedRoles: [roles.owner, roles.admin],
      },
      iamImport: {
        id: 'iamImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      iamRead: {
        id: 'iamRead',
        allowedRoles: [roles.owner, roles.admin],
      },
      iamUserAutocomplete: {
        id: 'iamUserAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          //roles.student,
          roles.teacher,
        ],
      },
    };
  }
}

export default IamPermissions;
