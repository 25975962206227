import Roles from '@/security/roles';
const roles = Roles.values;

class ExamOutputPermissions {
  static get values() {
    return {
      examOutputImport: {
        id: 'examOutputImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      examOutputCreate: {
        id: 'examOutputCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['examOutput'],
      },
      examOutputEdit: {
        id: 'examOutputEdit',
        allowedRoles: [roles.owner],
        allowedStorageFolders: ['examOutput'],
      },
      examOutputDestroy: {
        id: 'examOutputDestroy',
        allowedRoles: [roles.owner],
        allowedStorageFolders: ['examOutput'],
      },
      examOutputRead: {
        id: 'examOutputRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          //roles.student,
        ],
      },
      examOutputAutocomplete: {
        id: 'examOutputAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          //roles.student,
        ],
      },
    };
  }
}

export default ExamOutputPermissions;
