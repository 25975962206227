import Roles from '@/security/roles';
const roles = Roles.values;

class QuestionPermissions {
  static get values() {
    return {
      questionCreate: {
        id: 'questionCreate',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
        allowedStorageFolders: ['question'],
      },
      questionEdit: {
        id: 'questionEdit',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
        allowedStorageFolders: ['question'],
      },
      questionDestroy: {
        id: 'questionDestroy',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
        allowedStorageFolders: ['question'],
      },
      questionRead: {
        id: 'questionRead', //questionList buna dahil
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
      },
      questionAutocomplete: {
        id: 'questionAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
        ],
      },
    };
  }
}

export default QuestionPermissions;
