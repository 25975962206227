<template>
  <el-aside :width="asideWidth">
    <h1 class="logo">
      <router-link to="/">
        <!-- <app-i18n code="app.title"></app-i18n> -->
        <img
          :src="getLogoFromConfig"
          alt="itex logo"
          id="itexLogo"
          :class="getClassName"
        />
      </router-link>
    </h1>
    <el-menu
      :class="{
        'el-menu-side-nav': true,
        collapsed: collapsed,
      }"
      :collapse="collapsed"
      :collapse-transition="false"
      :router="true"
      @select="collapseMenuIfMobile()"
    >
      <el-menu-item
        :class="classFor('/', true)"
        :route="{ path: '/' }"
        index="/"
      >
        <i class="el-icon-menu"></i>
        <!-- https://fontawesome.com/v4.7.0/icons/ -->
        <span slot="title">
          <app-i18n code="home.menu"></app-i18n>
        </span>
      </el-menu-item>
      <menu-admin v-if="hasAdminRole" />
      <menu-teacher v-if="isTeacher" />
      <menu-student v-if="isStudent" />


    </el-menu>
  </el-aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import menuAdmin from './menu-admin';
import menuTeacher from './menu-teacher';
import menuStudent from './menu-student';
import config from '@/config';
export default {
  name: 'app-menu',
  components: {
    menuAdmin,
    menuStudent,
    menuTeacher,
  },
  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      hasAdminRole: 'auth/hasAdminRole',
      isTeacher: 'auth/isTeacher',
      isStudent: 'auth/isStudent',
      isOwner: 'auth/isOwner',
    }),
    asideWidth() {
      if (!this.collapsed) {
        return '200px'; //mobil icin 200px fazlasi iyi durmadi
      }
      return '0px';
    },
    getLogoFromConfig() {
      return config.logo.path;
    },
    getClassName() {
      return config.logo.style;
    }
  },
  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },

    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active =
        routePath === path ||
        routePath.startsWith(path + '/');

      return {
        active,
      };
    },
  },
};
</script>

<style scoped>
.el-aside {
  background-color: #a51e36;
}
.el-menu.collapsed span {
  visibility: hidden;
}
.el-menu {
  background-color: #a51e36;
}
.el-menu-item,
.el-menu-item i,
.el-submenu__title,
.el-submenu__title i {
  color: white;
}

.el-menu-item:hover,
.el-submenu:hover {
  background-color: #c8c8c833;
}
</style>
