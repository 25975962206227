import Roles from '@/security/roles';
const roles = Roles.values;

class LanguagePermissions{
    static get values(){
        return{
            languageImport: {
                id: 'languageImport',
                allowedRoles: [roles.owner, roles.admin],
            },
            languageCreate: {
                id: 'languageCreate',
                allowedRoles: [roles.owner, roles.admin],
                allowedStorageFolders: ['language'],
            },
            languageEdit: {
                id: 'languageEdit',
                allowedRoles: [roles.owner, roles.admin],
                allowedStorageFolders: ['language'],
            },
            languageDestroy: {
                id: 'languageDestroy',
                allowedRoles: [roles.owner, roles.admin],
                allowedStorageFolders: ['language'],
            },
            languageRead: {
                id: 'languageRead',
                allowedRoles: [
                    roles.owner,
                    roles.admin,
                    roles.teacher,
                ],
            },
            languageAutocomplete: {
                id: 'languageAutocomplete',
                allowedRoles: [
                    roles.owner,
                    roles.admin,
                    roles.teacher, //question new icin gerekli
                ],
            },
        }
    }
}

export default LanguagePermissions;
