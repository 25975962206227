<template>
  <div class="card-body">
    <h5 :class="('subtitle' + checkRTL).trim()">
      {{ description }}
    </h5>
    <div :class="('body-inner' + checkRTL).trim()">
      <slot></slot>
    </div>
    <div class="body-nav text-right">
      <router-link
        class="el-button btn-next el-button--default is-round"
        :to="nextLink"
        tag="button"
        v-if="viewButton"
      >
        <app-i18n-wl code="common.next" :lang="examLanguage" />
        <i class="text-contrast raw-icon el-icon-arrow-right"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'landing-body',
  props: {
    description: String,
    nextLink: String,
    viewButton: { required: false, default: true },
    checkRTL: String,
    examLanguage: String
  },
};
</script>

<style>
.el-button.btn-next {
  color: white;
  font-weight: 500;
  background-color: #a51e36;
  border-color: #a51e36;
  box-shadow: 0 2px 2px 0 rgba(165, 30, 54, 0.14),
    0 3px 1px -2px rgba(165, 30, 54, 0.2),
    0 1px 5px 0 rgba(165, 30, 54, 0.12);
  text-transform: uppercase;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}

.el-button.btn-next:hover {
  background-color: #981c32;
  border-color: #711525;
  color: white;
  box-shadow: 0 14px 26px -12px rgba(165, 30, 54, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(165, 30, 54, 0.2);
}
</style>

<style scoped>
.card-body {
  color: rgba(0, 0, 0, 0.87);
}

.body-nav {
  padding-top: 1.2rem;
  padding-right: 3.5rem;
}

.body-inner {
  padding: 2rem 3rem;
  margin: 0rem 3rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
}

h5.subtitle {
  font-weight: 500;
  font-size: 1rem;
  margin: 1.5rem 1rem 1.2rem 1rem;
}
</style>
