import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class QuestionGroupPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.questionGroupRead);
    this.import = permissionChecker.match(
      Permissions.values.questionGroupImport
    );
    this.questionGroupAutocomplete = permissionChecker.match(
      Permissions.values.questionGroupAutocomplete
    );
    this.create = permissionChecker.match(
      Permissions.values.questionGroupCreate
    );
    this.edit = permissionChecker.match(Permissions.values.questionGroupEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.questionGroupDestroy
    );
  }
}
