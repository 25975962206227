import questionType from '../shared/questionType';
import { i18nWL } from '@/i18n';
export default class UtilsModules {
  static convertQTypeForExam(qTypeStr) {
    if (!qTypeStr) {
      return null;
    }
    const qType = Object.keys(questionType).find(
      key => questionType[key] === qTypeStr
    );
    return qType; //answerShortQuestion
  }

  static convertQTypeForScore(qTypeStr) {
    if (!qTypeStr) {
      return null;
    }
    let qType = Object.keys(questionType).find(
      key => questionType[key] === qTypeStr
    );
    qType = 'score' + qType.charAt(0).toUpperCase() + qType.slice(1);
    return qType; //answerShortQuestion
  }

  static sectionLabel(section, lang) {
    const language = lang ? lang : 'en';
    return i18nWL(`entities.onlineExam.section.${section}`, language);
  }

  static questionTitle(examName, section, questionTypeName, examLanguage) {
    const title =
      this.sectionLabel(section, examLanguage) +
      ' - ' +
      this.questionTypeLabel(questionTypeName, examLanguage);
    return `${examName} : ${title}`;
  }

  static questionTypeLabel(questionTypeName, lang) {
    const language = lang ? lang : 'en';
    return i18nWL(
      `entities.onlineExam.types.${questionTypeName}.type`,
      language
    );
  }

  static questionTypeDefaultDesc(questionTypeName, lang) {
    return i18nWL(`entities.onlineExam.types.${questionTypeName}.description`, lang);
  }

  static questionDescription(currentQuestion, language, questionTypeName) {
    let description = '';
    if (!currentQuestion) return description;
    if (currentQuestion.descriptions) {// Structured exam
      description = currentQuestion.descriptions[language] || '';
      if (description === 'getFromLanguageFile')
          description = this.questionTypeDefaultDesc(questionTypeName, language);
    } else {
      if (!currentQuestion.data.description) return description;
      else description = currentQuestion.data.description;
    }
    const customFields = ['initialTime', 'recordTime', 'readingTime'];
    customFields.forEach(f => {
      if (currentQuestion.data[f]) {
        description = description.replace(`$${f}$`, currentQuestion.data[f]);
      }
    });
    if (currentQuestion.data.time) {
      description = description.replace(
        '$time$',
        currentQuestion.data.time / 60
      );
    }
    return description;
  }

  static isRTL(languageCode) {
    return languageCode === 'ar';
  }
  static getClassIfRTL(isRTL) {
    return isRTL === true ? ' rtl ' : '';
  }
  static getClassIfRTLandPadding(isRTL) {
    return isRTL === true ? this.getClassIfRTL(isRTL) + 'pRight ' : ' pLeft ';
  }
  static getClassTextRight(isRTL) {
    return isRTL === true ? ' text-right ' : '';
  }
}
