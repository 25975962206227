import Roles from '@/security/roles';
const roles = Roles.values;

class ExamResultPermissions {
  static get values() {
    return {
      examResultCreate: {
        id: 'examResultCreate',
        allowedRoles: [roles.owner],
        allowedStorageFolders: ['examResult'],
      },
      examResultEdit: {
        id: 'examResultEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['examResult'],
      },
      examResultDestroy: {
        id: 'examResultDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['examResult'],
      },
      examResultRead: {
        id: 'examResultRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
      examResultAutocomplete: {
        id: 'examResultAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
    };
  }
}

export default ExamResultPermissions;
