import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

export default {
  name: 'formatDurationFilter',
  implementation(duration) {
    //console.log('DURATION', duration);
    const formatted = moment
      .duration(duration, 'seconds')
      .format();
    //console.log('DURATION formatted', formatted);
    return formatted;
  },
};
