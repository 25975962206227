<template>
    <div>
        <el-form-item
                :label="fields.students.label"
                :prop="fields.students.name"
                :required="fields.students.required"
        >
            <el-col :lg="16" :md="16" :sm="24">
                <app-iam-user-autocomplete-input
                        :fetchFn="fields.students.fetchFn"
                        :mapperFn="fields.students.mapperFn"
                        :showCreate="!modal"
                        v-model="model[fields.students.name]"
                        mode="multiple"
                        :disabled="lockPubandNotFixed"
                        text="Create New"
                ></app-iam-user-autocomplete-input>
            </el-col>
            <router-link class="mar-left" :to="{ path: importPath }">
                <el-button
                        icon="el-icon-fa-upload"
                        type="primary"
                        :class="rtlAndPadding"
                >
                    <span class="card-title"> <app-i18n code="common.import"></app-i18n> </span>
                </el-button>
            </router-link>
        </el-form-item>
    </div>
</template>

<script>
    import { ExamModel } from '@/modules/exam/exam-model';
    import {mapGetters} from "vuex";
    const { fields } = ExamModel;
    export default {
        name: "manage-students",
        props: ['modal','model','record'],
        data() {
            return{
                lockPubandNotFixed:
                    this.isEditing &&
                    this.record.status === 'published' &&
                    this.record.fixQuestions === false,
            }
        },
        computed: {
            ...mapGetters({
                rtlAndPadding: 'exam/form/rtlAndPadding',
            }),
            importPath() {
                return `/iam/import/student/${this.record.examLanguageId}/${this.record.id}`;
            },
            fields(){
                return fields;
            }
        }
    }
</script>

<style scoped>
    .mar-left {
        margin-left: 1rem;
    }
</style>