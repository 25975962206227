import onlineExamRecorderStore from '@/modules/online-exam/online-exam-recorder-store';
import onlineExamMainStore from '@/modules/online-exam/online-exam-main-store';

export default {
  namespaced: true,

  modules: {
    recorder: onlineExamRecorderStore,
    main: onlineExamMainStore,
  },
};
