import auditLog from './permissions/auditLog';
import classroom from './permissions/classroom';
import order from './permissions/order';
import exam from './permissions/exam';
import examOutput from './permissions/examOutput';
import iam from './permissions/iam';
import question from './permissions/question';
import questionType from './permissions/questionType';
import questionGroup from './permissions/questionGroup';
import settings from './permissions/settings';
import language from './permissions/language';
import supportRequests from './permissions/supportRequests';
import userDetail from './permissions/userDetail';
import structure from './permissions/structure';
import scoreOutput from './permissions/scoreOutput';
import examResult from './permissions/examResult';

class Permissions {
  static get values() {
    return {
      ...auditLog.values,
      ...classroom.values,
      ...exam.values,
      ...examOutput.values,
      ...iam.values,
      ...question.values,
      ...questionType.values,
      ...questionGroup.values,
      ...settings.values,
      ...language.values,
      ...supportRequests.values,
      ...userDetail.values,
      ...structure.values,
      ...scoreOutput.values,
      ...examResult.values,
      ...order.values
    };
  }

  static get asArray() {
    return Object.keys(this.values).map(value => {
      return this.values[value];
    });
  }
}

export default Permissions;
