import { pick } from 'lodash';
export default class MemoryKeys {
  static memoryKeys = 'mk-';
  static exam = this.memoryKeys + 'exam';
  static question = this.memoryKeys + 'question';

  /**
   * Set Exam in Local Memory
   * @param {Object} value
   * **/
  static setExam(values) {
    if (values) {
      let valuesToSave = pick(values, [
        'duration',
        'examLanguage',
        'price',
        'structure'
      ]);

      localStorage.setItem(this.exam, JSON.stringify(valuesToSave));
    }
  }

  static getExam() {
    const value = localStorage.getItem(this.exam);
    //console.log('Memory get exam', value);
    return value != null ? JSON.parse(value) : null;
  }

  // Set and Get Question in Local Storage

  static setQuestion(values) {
    if (values) {
      let valuesToSave = pick(values, [
        'questionGroups',
        'questionLanguage',
        'questionType',
        'section',
        'qName'
      ]);

      localStorage.setItem(this.question, JSON.stringify(valuesToSave));
    }
  }

  static getQuestion() {
    const value = localStorage.getItem(this.question);
    return value != null ? JSON.parse(value) : null;
  }
}
