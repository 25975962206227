import importerStore from '@/shared/importer/importer-store';
import { ClassroomService } from '@/modules/classroom/classroom-service';
import classroomImporterFields from '@/modules/classroom/classroom-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  ClassroomService.import,
  classroomImporterFields,
  i18n('entities.classroom.importer.fileName'),
  i18n('entities.classroom.importer.hint'),
);
