<template>
  <el-form-item v-if="!isBlank">
    <el-col :lg="11" :md="16" :sm="24">
      <audio controls :src="src" />
    </el-col>
  </el-form-item>
</template>

<script>
export default {
  name: 'app-view-item-audio',

  props: ['src'],

  computed: {
    isBlank() {
      return this.src == null;
    },
  },
};
</script>
