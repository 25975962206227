import { OrderService } from '@/modules/order/order-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n,getLanguageCode } from '@/i18n';
import {IhuPaymentService} from "./ihu-service";
import UtilsModules from '../../shared/utils-for-modules';
const langForIHUService = "tr-en".includes(getLanguageCode()) ? getLanguageCode():'en';
export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    htmlContent: null,
    htmlContentVisible: false,
    dialogVisible: false,
    hasDialogClosed: false,
  },

  getters: {
    record: (state) => state.record,
    htmlContent: (state) => state.htmlContent,
    htmlContentVisible: (state) => !!state.htmlContentVisible,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    dialogVisible: (state) => !!state.dialogVisible,
    isRTL() {
      return UtilsModules.isRTL(getLanguageCode());
    },
    checkRTL(state, getters) {
      return UtilsModules.getClassIfRTL(getters.isRTL);
    },
    rtlAndPadding(state, getters) {
      return UtilsModules.getClassIfRTLandPadding(getters.isRTL);
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    SET_HTML_CONTENT(state, payload){
      state.htmlContent = payload;
    },

    SET_HTML_CONTENT_VISIBLE(state, payload){
      state.htmlContentVisible = payload;
    },

    SET_DIALOG_VISIBLE(state, payload){
      state.dialogVisible = payload;
    }
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await OrderService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/order');
      }
    },

    async doStartOrder({ commit, getters }, values) {
      try {
        commit('CREATE_STARTED');
        values.lang = langForIHUService;
        const startResult = await OrderService.start(values);
        commit('CREATE_SUCCESS');
        let paymentResultLocal = null;
        if(startResult.status === 1){
          startResult.htmlContent = await IhuPaymentService.getHTMLContent(startResult.psOrderNo);
          commit('SET_HTML_CONTENT', startResult.htmlContent);
          commit('SET_HTML_CONTENT_VISIBLE', true);
          paymentResultLocal = await IhuPaymentService.askPaymentResult(
               startResult.psOrderNo,
              () => {return !getters.dialogVisible;} );
          // console.log(getters); //test amacli
          // paymentResultLocal = {isSuccess: true}; //test amacli
          // startResult.psOrderNo = 'ihu-90d6ff0a-8'; //test amacli
        }
        else{
          commit('SET_DIALOG_VISIBLE', false);
          Message.error(startResult.message);
          commit('CREATE_ERROR');
          return;
        }

        commit('SET_HTML_CONTENT_VISIBLE', false);
        commit('SET_DIALOG_VISIBLE', false);
        commit('SET_HTML_CONTENT', null);

        if(paymentResultLocal && paymentResultLocal.isSuccess === true){
          commit('UPDATE_STARTED');
          const paymentResult = await OrderService.complete({
            psOrderNo: startResult.psOrderNo,
            lang: langForIHUService
          });
          if(paymentResult.status === 1){
            commit('UPDATE_SUCCESS');
            Message.success(i18n('entities.order.create.success'));
            routerAsync().push('/tests');
          }else{
            Message.error(paymentResult.message);
            commit('CREATE_ERROR');
          }
        }else{
          Message.error(paymentResultLocal.message);
        }
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
  },
};
