const ar = {
  lang: 'ar',
  common: {
    or: 'أو',
    cancel: 'إلغاء',
    reset: 'إعادة تعيين',
    save: 'حفظ',
    saveAs: 'حفظ باسم',
    update: 'تحديث',
    publish: 'نشر',
    publishAll: 'نشر الدرجات',
    search: 'بحث',
    edit: 'تعديل',
    scoreReport: 'تقرير النتيجة',
    remove: 'إزالة',
    new: 'جديد',
    preview: 'معاينة',
    export: 'تصدير إلى إكسل',
    noDataToExport: 'لا توجد بيانات للتصدير',
    import: 'استيراد إكسل',
    discard: 'إلغاء',
    yes: 'نعم',
    no: 'لا',
    pause: 'إيقاف مؤقت',
    areYouSure: 'هل أنت متأكد؟',
    view: 'عرض',
    analyze: 'تحليل',
    destroy: 'مسح',
    mustSelectARow: 'يجب اختيار صف',
    confirm: 'تأكيد',
    next: 'التالي',
    prev: 'السابق',
    saveQuestion: 'حفظ السؤال',
    saveText: 'حفظ النص',
    editor: 'المحرر',
    add: 'إضافة',
    question: 'السؤال',
    send: 'إرسال',
    failedJSON: 'قيمة JSON غير صحيحة',
    breadcrumb: {
      noData: '--'
    }
  },

  app: {
    title: 'ITEX'
  },

  entities: {
    userDetail: {
      name: 'تفصيل المستخدم',
      label: 'تفاصيل المستخدم',
      menu: 'تفاصيل المستخدم',
      exporterFileName: 'تصدير تفصيل المستخدم',
      list: {
        menu: 'تفاصيل المستخدم',
        title: 'تفاصيل المستخدم'
      },
      create: {
        success: 'تم حفظ تفصيل المستخدم بنجاح'
      },
      update: {
        success: 'تم حفظ تفصيل المستخدم بنجاح'
      },
      destroy: {
        success: 'تم حذف تفصيل المستخدم بنجاح'
      },
      destroyAll: {
        success: 'تم حذف تفصيل/تفاصيل المستخدم بنجاح'
      },
      edit: {
        title: 'تعديل تفصيل المستخدم'
      },
      fields: {
        id: 'الرقم المعرف',
        userName: 'اسم المستخدم',
        studentNumber: 'رقم الطالب',
        gender: 'الجنس',
        birthdateRange: 'تاريخ الميلاد',
        birthdate: 'تاريخ الميلاد',
        userType: 'نوع المستخدم',
        nationality: 'الجنسية',
        createdAt: 'تم إنشاؤه في',
        updatedAt: 'تم تحديثه في',
        createdAtRange: 'تم إنشاؤه في'
      },
      enumerators: {
        gender: {
          male: 'ذكر',
          female: 'أنثى'
        },
        userType: {
          passive: 'غير فعال',
          student: 'طالب',
          teacher: 'أستاذ',
          admin: 'مشرف'
        }
      },
      new: {
        title: 'تفصيل جديد للمستخدم'
      },
      view: {
        title: 'عرض تفصيل المستخدم'
      },
      importer: {
        title: 'استيراد تفاصيل المستخدم',
        fileName: 'userDetail_import_template',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },

    question: {
      name: 'سؤال',
      label: 'أسئلة',
      menu: 'أسئلة',
      exporterFileName: 'تصدير الأسئلة',
      list: {
        menu: 'أسئلة',
        title: 'أسئلة'
      },
      create: {
        success: 'تم حفظ السؤال بنجاح'
      },
      update: {
        success: 'تم حفظ السؤال بنجاح'
      },
      destroy: {
        success: 'تم حذف السؤال بنجاح'
      },
      destroyAll: {
        success: 'تم حذف السؤال/الأسئلة بنجاح'
      },
      edit: {
        title: 'تعديل السؤال'
      },
      fields: {
        id: 'الرقم المعرف',
        keywords: 'الكلمات المفتاحية',
        createdById: 'المؤلف',
        questionType: 'نوع السؤال',
        section: 'القسم',
        questionLanguage: 'اللغة',
        initialTime: 'مدة التمهيد (ثا)',
        recordTime: 'مدة التسجيل (ثا)',
        question: 'السؤال',
        optionsAnswer: 'السؤال',
        readingTime: 'مدة القراءة (ثا)',
        questionGroups: 'المستوى',
        questionStatus: 'الحالة',
        allowedUsers: 'المدرسون المسموح لهم',
        preVersions: 'إصدارات سابقة',
        data: 'بيانات',
        answer: 'الإجابة',
        transcribe: 'الإجابة',
        answerHint: 'إجابة تلميح',
        subtitle: 'جملة السؤال',
        lockUntil: 'إغلاق حتى',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
        audio: 'الاستماع',
        image: 'الصورة',
        time: 'الوقت (دقيقة)',
        description: 'الوصف'
      },
      enumerators: {
        questionStatus: {
          default: 'إفتراضي',
          draft: 'مسودة',
          reviewed: 'مراجع',
          published: 'منشور',
          locked: 'مقفل',
          obsolete: 'متروك'
        },
        section: {
          writing: 'كتابة',
          reading: 'قراءة',
          speaking: 'تحدث',
          listening: 'استماع'
        },
        questionGroup: {
          elementary: 'مبتدئ أدنى-أوسط',
          preIntermediate: 'مبتدئ أعلى',
          intermediate: 'متوسط أدنى-أوسط',
          upperIntermediate: 'متوسط أعلى',
          advanced: 'متقدم',
          difficult: 'Difficult',
          medium: 'Medium',
          easy: 'Easy',
          itex: 'Itex',
          placement: 'Placement'
        }
      },
      new: {
        title: 'سؤال جديد'
      },
      view: {
        title: 'عرض السؤال'
      },
      preview: {
        title: 'معاينة السؤال'
      },
      importer: {
        title: 'استيراد أسئلة',
        fileName: 'قالب استيراد سؤال',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },

    readExam: {
      //examResult tablosuna veri girisi
      menu: 'تصحيح الامتحانات',
      list: {
        menu: 'تصحيح الامتحانات',
        title: 'تصحيح الامتحانات'
      }
    },

    scoreOutput: {
      name: 'إصدار الدرجات',
      label: 'الدرجات',
      menu: 'الدرجات',
      fields: {
        scoreStatus: 'الحالة',
        teacher: 'الأستاذ',
        id: 'الرقم المعرف',
        examOutput: 'معرف إجابة الامتحان',
        score: 'الدرجة',
        content: 'المحتوى',
        pronunciation: 'اللفظ',
        oralFluency: 'الطلاقة',
        form: 'الشكل',
        grammar: 'القواعد',
        vocabulary: 'نطاق المفردات',
        development: 'التطور والبنية والتماسك',
        linguistic: 'النطاق اللغوي العام',
        spelling: 'الإملاء',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        questionTitle: 'السؤال',
        responseTitle: 'الاستجابة',
        answerStatisticsTitle: "إحصائيات الإجابة",
        wordCountTitle: "عدد الكلمات",
        timeSpentTitle: "قضاء الوقت",
        timeSpentUnit: "ثواني",
        timeSpentUnitMinutes: "دقائق",
        answerHint: 'إجابة تلميح'
      },
      list: {
        menu: 'مخرجات الدرجات',
        title: 'مخرجات الدرجات'
      },
      edit: {
        title: 'تعديل الدرجة'
      },
      view: {
        title: 'عرض الدرجة'
      },
      new: {
        title: 'درجة جديدة'
      },
      publish: 'نشر الدرجات',
      enumerators: {
        scoreStatus: {
          draft: 'مسودة',
          saved: 'تم الحفظ',
          published: 'تم النشر',
          evaluated: 'تم التقييم'
        }
      },
      create: {
        success: 'تم تعيين الإجابات بنجاح'
      },
      update: {
        success: 'تم حفظ الدرجة بنجاح'
      },
      updateAll: {
        success: 'تم حفظ الدرجة بنجاح'
      },
      breadcrumb: {
        scoreReadAloud: 'تقييم القراءة الجهرية',
        scoreRepeatSentence: 'تقييم تكرار الجملة',
        scoreDescribeImage: 'تقييم وصف الصورة',
        scoreRetellLecture: 'تقييم إعادة صياغة المحاضرة',
        scoreAnswerShortQuestion: 'تقييم إجابة السؤال القصير',
        scoreSummarizeWrittenText: 'تقييم تلخيص النص المكتوب',
        scoreWriteEssay: 'تقييم كتابة المقالة',
        scoreSummarizeSpokenText: 'تقييم تلخيص النص المنطوق'
      },
      noRecord: 'لا يوجد جواب لتقييمه',
      publishRecord: 'للنشر، اذهب إلى صفحة الدرجات',
      types: {
        answerShortQuestion: {
          content: {
            description: `في تقييم المحتوى، تُتجاهل الأخطاء = الاستبدالات، والحذف، والإدخالات، والتردد (التوقف عن الكلام سواء أمُلئ الفراغ أم لم يملأ) والمادة السابقة واللاحقة`,
            explanations: [
              `يذكر بعض العناصر غير المترابطة من التقديم `,
              `يصف عناصر أساسية من التقديم، لكنه لا يوضح مقتضياتها أو العلاقات فيما بينها `
            ]
          }
        },
        describeImage: {
          content: {
            description: `في تقييم المحتوى، تُتجاهل الأخطاء = الاستبدالات، والحذف، والإدخالات، والتردد (التوقف عن الكلام سواء أمُلئ الفراغ أم لم يملأ) والمادة السابقة واللاحقة`,
            explanations: [
              `يذكر بعض العناصر غير المترابطة للتقديم`,
              `يصف عناصر أساسية من التقديم، لكنه لا يوضح مقتضياتها أو العلاقات فيما بينها `,
              `يتعامل مع عنصر أساسي واحد فقط في الصورة ويشير إلى استنتاج أو مقتضى ما. يبدي فهماً أساسياً للعديد من العناصر الأساسية للصورة `,
              `يتعامل مع معظم العناصر الرئيسية للصورة ويشير إلى مقتضياتها أو خلاصاتها`,
              `يصف جميع العناصر الأساسية للصورة وعلاقاتها مشيراً إلى مقتضياتها أو خلاصاتها`,
              `يصف كل عناصر الصورة وعلاقاتها وتطوراتها (إن وجدت) وخلاصتها أو مقتضياتها`
            ]
          }
        },

        readAloud: {
          content: {
            description: `يعتبر كل استبدال أو حذف أو إدراج لكلمة ما خطأً واحداً. الدرجة العليا: تعتمد على طول الطلب.`
          }
        },

        repeatSentence: {
          content: {
            description: `في تقييم المحتوى، تُتجاهل الأخطاء = الاستبدالات، والحذف، والإدخالات، والتردد (التوقف عن الكلام سواء أمُلئ الفراغ أم لم يملأ) والمادة السابقة واللاحقة`,
            explanations: [
              `لا يكاد يذكر شيئاً من المطلوب`,
              `أقل من 50٪ من كلمات الإجابة المطلوبة بالتسلسل الصحيح`,
              `على الأقل 50% من كلمات الإجابة المطلوبة بالتسلسل الصحيح`,
              `جميع كلمات الإجابة المطلوبة بالتسلسل الصحيح`
            ]
          }
        },

        retellLecture: {
          content: {
            description: `في تقييم المحتوى، تُتجاهل الأخطاء = الاستبدالات، والحذف، والإدخالات، والتردد (التوقف عن الكلام سواء أمُلئ الفراغ أم لم يملأ) والمادة السابقة واللاحقة`,
            explanations: [
              `يذكر بعض العناصر غير المترابطة للتقديم`,
              `يصف بعض العناصر الأساسية للتقديم ولكنه لا يوضح مقتضياتها أو العلاقات المتبادلة فيما بينها`,
              `يتعامل مع نقطة رئيسية واحدة فقط ويشير إلى مقتضى أو استنتاج ما. يظهر فهماً أساسياً للعديد من العناصر الأساسية للتقديم`,
              `يتعامل مع معظم النقاط في التقديم ويشير إلى مقتضياتها وخلاصاتها`,
              `يصف جميع النقاط الرئيسية للتقديم وعلاقاتها، مشيراً إلى مقتضياتها واستنتاجاتها`,
              `يعيد سرد جميع نقاط التقديم ويصف الشخصيات والجوانب والأفعال وعلاقاتها والتطور الكامن والمقتضيات والاستنتاجات`
            ]
          }
        },

        summarizeSpokenText: {
          content: {
            description: 'المحتوى',
            explanations: [
              `يحذف أو يحرف الجوانب الرئيسية`,
              `يقدم ملخصًا مقبولاً للنص، ولكن هناك جانب أو جانبان مفقودان`,
              `يقدم ملخصًا جيدًا للنص، مع ذكر جميع الجوانب المتعلقة به.`
            ]
          },
          form: {
            explanations: [
              ` يحتوى على أقل من 40 كلمة أو أكثر من 100 كلمة. الملخص لا يحتوى على علامات ترقيم أو يتكون من قائمة أو جمل قصيرة جداً فقط`,
              `يحتوي على 40-49 كلمة أو 71-100 كلمة`,
              `يحتوي على 50-70 كلمة`
            ]
          },
          grammar: {
            explanations: [
              `البنية النحوية مشكلة يمكن أن تعيق التواصل`,
              `يحتوي على أخطاء نحوية لا تعيق التواصل`,
              `بنية نحوية سليمة`
            ]
          },
          vocabulary: {
            explanations: [
              `خلل في اختيار المفردات يمكن أن يعيق التواصل`,
              `بعض الأخطاء في المفردات، لكنها لا تعيق التواصل`,
              `اختيار مناسب للمفردات`
            ]
          },
          spelling: {
            explanations: [
              `أكثر من خطأ إملائي واحد`,
              `خطأ إملائي واحد`,
              `لا توجد أخطاء إملائية`
            ]
          }
        },

        summarizeWrittenText: {
          content: {
            description: 'القراءة والكتابة',
            explanations: [
              `يحذف أو يحرف الجوانب الرئيسية للنص`,
              `يقدم ملخصاً مقبولاً للنص ولكنه يفوّت جانباً أو جانبين`,
              `يقدم ملخصًا جيدًا للنص، مع ذكر جميع الجوانب ذات الصلة`
            ]
          },
          form: {
            description: 'الشكل والقواعد والمفردات',
            explanations: [
              `ليست مكتوبةً في جملة واحدة تامة، أو تحتوي على أقل من 5 أو أكثر من 75 كلمة.`,
              `مكتوبة في جملة واحدة تامة.`
            ]
          },
          grammar: {
            explanations: [
              ` البنية النحوية تحتوى أخطاء يمكن أن تعيق التواصل`,
              `أخطاء نحوية لا تعيق التواصل`,
              `بنية نحوية سليمة`
            ]
          },
          vocabulary: {
            explanations: [
              `خلل في اختيار الكلمات قد يعيق التواصل`,
              `أخطاء في االمفردات لا تعيق التواصل`,
              `اختيار مناسب للمفردات`
            ]
          }
        },

        writeEssay: {
          content: {
            description:
              'المحتوى، والشكل، والتطور، والبنية، والتماسك، والقواعد، والنطاق اللغوي العام، ونطاق المفردات، والإملاء',
            explanations: [
              `لا يتعامل بشكل صحيح مع الطلب`,
              `يتعامل مع الطلب، لكنه يتجاهل جانباً رئيسياً أو أكثر من جانب ثانوي`,
              `يتعامل مع الطلب، لكنه لا يتعامل مع أحد الجوانب الثانوية`,
              `يتعامل مع الطلب تعاملاً مناسباً`
            ]
          },
          form: {
            explanations: [
              `الطول أقل من 120 كلمة أو أكثر من 380 كلمة. المقال لا يحتوي على علامات ترقيم، أو يتكون من قائمة أو جمل قصيرة جداً فقط`,
              `يتراوح الطول بين 120 و199 كلمة أو بين 301 و 380`,
              `يتراوح الطول بين 200 و300 كلمة`
            ]
          },
          grammar: {
            explanations: [
              `يحتوي بشكل أساسي على بنية بسيطة أو/و عدة أخطاء أساسية`,
              `يبدي درجة عالية نسبياً من القدرة النحوية. لا أخطاء من شأنها أن تؤدي إلى سوء في الفهم`,
              `يبدي قدرة نحوية ثابتة للغة المعقدة. الأخطاء نادرة ويصعب اكتشافها`
            ]
          },
          vocabulary: {
            explanations: [
              `يحتوي أساساً على مفردات أساسية غير كافية للتعامل مع الموضوع بالمستوى المطلوب`,
              `يبدي مجموعة جيدة من المفردات لمسائل متعلقة بالموضوعات الأكاديمية العامة. ويؤدي نقص المفردات إلى الحشو أو فقدان الدقة.`,
              `قدرة جيدة على استخدام مخزون واسع من المفردات والتعبيرات الاصطلاحية وغير الرسمية`
            ]
          },
          development: {
            explanations: [
              `يفتقر إلى التماسك، ويتكون بشكل أساسي من قوائم أو عناصر متباعدة`,
              `أقل تنظيماً أحياناً، والترابط بين بعض العناصر أو الفقرات سيئ`,
              `يبدي تطورًا جيدًا وبنية منطقية`
            ]
          },
          linguistic: {
            explanations: [
              `يحتوي أساساً على لغة بسيطة ويفتقر إلى الدقة`,
              `نطاق لغوي كاف لتقديم وصف واضح والتعبير عن وجهات النظر وتطوير الحجج`,
              `يبدي إتقانًا سلسًا لنطاق لغوي واسع، فيصوغ الأفكار بدقة، ويؤكد، ويميّز، ويزيل الغموض. لا توجد علامة على أن المتقدم للاختبار مقيَّد فيما يريد إيصاله
`
            ]
          },
          spelling: {
            explanations: [
              `أكثر من خطأ إملائي`,
              `خطأ إملائي واحد`,
              `الإملاء سليم`
            ]
          }
        },

        writeFromDictation: {
          content: {
            description: 'الاستماع والكتابة',
            explanations: [
              `كل كلمة غير صحيحة أو فيها أخطاء إملائية`,
              `كل كلمة صحيحة مكتوبة بشكل صحيح`
            ]
          }
        },

        pronunciation: {
          titles: ['ليس عربياً', 'إقحامي', 'متوسط', 'جيد', 'متقدم', 'متميز'],
          explanations: [
            `يبدو النطق سمة للغة مختلفة تماماً. العديد من الحروف منطوقة نطقاً خاطئاً أو غير مرتبة أو ساقطة. قد يجد المستمعون أن أكثر من نصف الكلام غير مفهوم. المقاطع الصوتية التي فيها نبر أو التي ليس فيها نبر تلفظ بغير أسلوب اللغة العربية، وقد تحتوي عدة كلمات على عدد خاطئ من المقاطع الصوتية`,
            `العديد من الحروف منطوقة بشكل خاطئ، مما يؤدي إلى لهجة أجنبية جلية. قد يواجه المستمعون صعوبة في فهم ثلث الكلمات. العديد من الحروف محرفة أو ساقطة. وقد يكون تسلسل الحروف غير عربي. النبر ظاهر بغير أسلوب العربية؛ قد تكون الكلمات التي ليس فيها نبر قليلة أو محذوفة، مع زيادة بضع مقاطع صوتية أو فقدانها`,
            `بعض الحروف منطوقة بشكل خاطئ باستمرار بغير أسلوب الناطقين الأصليين. ما لا يقل عن 2/3 الكلام واضح، ولكن قد يحتاج المستمعون إلى التكيف مع اللهجة. تسقط بعض الحروف بين حين وآخر، وقد تكون بعض تسلسلات الحروف مبسطة. قد يكون النبر في غير موضعه في بعض الكلمات أو قد يكون غير واضح.`,
            `معظم الحروف منطوقة بشكل صحيح. قد تجعل بعض الأخطاء المتكررة بضع كلمات غير واضحة. قد يحرّف عدد قليل من الحروف في سياقات معينة أو تحذف أو يظهر خطأ في لفظها. قد يظهر تقصير للحركات الطويلة التي فيها نبر في بضع كلمات.`,
            `الحروف منطوقة بشكل واضح لا لبس فيه. لا تؤثر بعض التحويرات الطفيفة في لفظ الحروف أو النبر على الفهم. كل الكلمات مفهومة بسهولة. قد يتم تحوير عدد قليل من الحروف أو تسلسلات الحروف. النبر ظاهر في مواضعه الصحيحة على جميع الكلمات الشائعة، والنبر على مستوى الجملة مقبول.`,
            `جميع الحروف منطوقة بطريقة يسهل فهمها من قبل المتحدثين العاديين للغة. يستخدم المتحدث الإدغام الذي يلائم التحدث المتواصل. النبر في مواضعه الصحيحة في جميع الكلمات، والنبر على مستوى الجملة مناسب تمامًا.`
          ]
        },
        oralFluency: {
          titles: [`غير طليق`, `محدود`, `متوسط`, `جيد`, `متقدم`, `متميز`],
          explanations: [
            `الكلام بطيء ومجهد مع القليل من العبارات المجمعة، والعديد منا الترددات، والتوقفات، والبدايات الخاطئة، و / أو التبسيطات الصوتية الواضحة. معظم الكلمات معزولة بعضها عن بعض، وقد يكون هناك أكثر من وقفة واحدة طويلة.`,
            `الكلام غير منتظم في الصياغة أو إيقاع الجمل. الصياغة الضعيفة، والكلام المتقطع أو المعتمد على المقاطع الصوتية، و / أو العديد من الترددات، والتكرارات، و / أو البدايات الخاطئة تجعل أداء الكلام متقطعاً أو غير سويّ بشكل واضح. قد يحتوي الكلام الطويل على وقفة طويلة أو اثنتين وتأكيداً للكلمات غير مناسب على مستوى الجملة.`,
            `قد يكون الكلام متقطعًا أو متفاوتاً. يحتوي الكلام (إن كان يساوي 6 كلمات أو أكثر) على الأقل جرياناً واحداً لثلاث كلمات، وما لا يزيد عن اثنين أو ثلاثة من الترددات أو التكرارات أو البدايات الخاطئة. قد تكون هناك وقفة واحدة طويلة، ولكن ليس هناك وقفتان أو أكثر.`,
            `سرعة الكلام مقبولة ولكنها قد تكون متفاوتة. قد يكون هناك أكثر من تردد واحد، ولكن معظم الكلمات منطوقة ضمن عبارات متواصلة. هناك القليل من التكرار أو البدايات الخاطئة. لا توجد فترات توقف طويلة والكلام لا يبدو متقطعًا.`,
            `الكلام له إيقاع مقبول مع صياغة وتشديد على الكلمات مناسبين. ليس هناك أكثر من تردد واحد، أو تكرار واحد، أو بداية خاطئة. لا توجد تبسيطات صوتية غير أصلية مهمة.`,
            `يظهر الكلام إيقاعًا وصياغة سلسين. لا توجد أي ترددات أو تكرارات أو بدايات خاطئة أو تبسيطات صوتية غير أصلية.`
          ]
        }
      },
      spelllangexplanations: {
        title: 'If editor spelling mistakes are not working properly',
        explanations: [
          '1- On your chrome browser at the top right, click three dots > Settings',
          '2- At the bottom, click Advanced.',
          '3- You can now turn on spell check for the language you will use',
          '4- You can add the language if the language is not listed',
          '4.1- Under "Languages," click Language.',
          '4.2- Add it by clicking Add languages.'
        ]
      }
    },

    exam: {
      name: 'امتحان',
      label: 'امتحانات',
      menu: 'امتحانات',
      menuTests: 'اختبارات',
      exporterFileName: 'تصدير الامتحان',
      list: {
        menu: 'الامتحانات',
        title: 'الامتحانات'
      },
      create: {
        success: 'تم حفظ الامتحان بنجاح',
        saveQuestions: 'حفظ الأسئلة',
        totalPoint: 'مجموع النقاط'
      },
      update: {
        success: 'تم حفظ الامتحان بنجاح',
        questions: 'Exam Questions saved successfully',
      },
      destroy: {
        success: 'تم حذف الامتحان بنجاح'
      },
      destroyAll: {
        success: 'تم حذف الامتحان/الامتحانات بنجاح'
      },
      edit: {
        title: 'تعديل الامتحان'
      },
      fields: {
        id: 'الرقم المعرف',
        name: 'الاسم',
        examUpperLimitRange: 'نطاق حد التاريخ الأقصى',
        examUpperLimit: 'حد التاريخ الأقصى',
        examLowerLimitRange: 'نطاق حد التاريخ الأدنى',
        examLowerLimit: 'حد التاريخ الأدنى',
        status: 'الحالة',
        durationRange: 'المدة (دقيقة)',
        duration: 'المدة (دقيقة)',
        examType: 'نوع الامتحان',
        examData: 'بيانات الامتحان',
        structure: 'البنية',
        students: 'الطلاب',
        readers: 'الأساتذة',
        examLanguage: 'اللغة',
        isFree: 'مجاني',
        price: 'السعر (ل.ت)',
        fixQuestions: 'تثبيت الأسئلة',
        unScored: 'غير مسجل',
        questions: 'الأسئلة',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في'
      },
      enumerators: {
        status: {
          draft: 'المسودة',
          published: 'منشور'
        },
        examType: {
          structured: 'منظم',
          manual: 'يدوي'
        }
      },
      new: {
        title: 'امتحان جديد'
      },
      view: {
        title: 'عرض الامتحان'
      },
      importer: {
        title: 'استيراد امتحانات',
        fileName: 'قالب استيراد الامتحان',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      },
      expDate: 'تاريخ الانتهاء',
      noTimeLimit: 'لا تاريخ للانتهاء',
      testListPage: {
        btnStartTest: 'بدء الاختبار',
        btnContinueTest: 'مواصلة الاختبار',
        btnUnlockTest: 'اختبار فتح',
        btnLoadingTest: 'جار التحميل',
        downloadAppWarning: {
          warningText: 'تحتاج إلى تحميل التطبيق لدينا على مواصلة',
          confirm: 'تؤكد',
          cancel: 'إلغاء'
        },
        useAppWarning: "تحتاج إلى استخدام تطبيق 'ITEX' الخاص بنا للمتابعة"
      }
    },
    questionType: {
      name: 'نوع السؤال',
      label: 'أنواع الأسئلة',
      menu: 'أنواع الأسئلة',
      exporterFileName: 'تصدير أنواع الأسئلة',
      list: {
        menu: 'أنواع الأسئلة',
        title: 'أنواع الأسئلة'
      },
      create: {
        success: 'تم حفظ نوع السؤال بنجاح'
      },
      update: {
        success: 'تم حفظ نوع السؤال بنجاح'
      },
      destroy: {
        success: 'تم حذف نوع السؤال بنجاح'
      },
      destroyAll: {
        success: 'تم حذف نوع/أنواع السؤال/الأسئلة بنجاح'
      },
      edit: {
        title: 'تعديل نوع السؤال'
      },
      fields: {
        id: 'الرقم المعرف',
        questionType: 'نوع السؤال',
        autoScore: 'تصحيح تلقائي',
        section: 'القسم',
        title: 'العنوان',
        description: 'الوصف',
        createdAt: 'تم الإنشاء في ',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في'
      },
      enumerators: {
        section: {
          reading: 'القراءة',
          speaking: 'التحدث',
          listening: 'الاستماع',
          writing: 'الكتابة'
        },
        questionType: {
          'Read Aloud': 'قراءة جهرية',
          'Repeat Sentence': 'إعادة لفظ جملة',
          'Describe Image': 'وصف صورة',
          'Re-tell Lecture': 'إعادة صياغة المحاضرة',
          'Answer Short Question': 'إجابة عن سؤال قصير',
          'Summarize Written Text': 'تلخيص نص مكتوب',
          'Write Essay': 'كتابة مقالة',
          'Multiple Choice, Choose Single Option':
            'اختيار من متعدد، اختيار خيار واحد',
          'Multiple Choice, Choose Multiple Option':
            'اختيار من متعدد، اختيار خيارات متعددة',
          'Re-order Paragraph': 'إعادة ترتيب فقرة',
          'Fill In The Blanks': 'املأ الفراغات',
          'Selected Fill In The Blanks': 'املأ فراغات بالاختيار',
          'Highlight Correct Summary': 'حدد الملخص الصحيح',
          'Summarize Spoken Text': 'تلخيص النص المنطوق',
          'Multiple Choice, Choose Multiple Answer':
            'اختيار من متعدد, اختيار إجابات متعددة',
          'Write In The Blanks': 'اكتب في الفراغات',
          'Multiple Choice, Choose Single Answer':
            'اختيارات متعددة, اختيار إجابة واحدة',
          'Select Missing Word': 'اختر الكلمة المفقودة',
          'Highlight Incorrect Words': 'حدد الكلمات الخاطئة',
          'Write From The Dictation': 'إملاء'
        }
      },
      new: {
        title: 'نوع سؤال جديد'
      },
      view: {
        title: 'عرض نوع السؤال'
      },
      importer: {
        title: 'استيراد أنواع أسئلة',
        fileName: 'questionType_import_template',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },
    questionGroup: {
      name: 'مستوى السؤال',
      label: 'مستوى السؤال',
      menu: 'مستويات الأسئلة',
      exporterFileName: 'تصدير مستويات الأسئلة',
      list: {
        menu: 'مستويات الأسئلة',
        title: 'مستويات الأسئلة'
      },
      create: {
        success: 'تم حفظ مستوى السؤال بنجاح'
      },
      update: {
        success: 'تم حفظ مستوى السؤال بنجاح'
      },
      destroy: {
        success: 'تم حذف مستوى الأسئلة بنجاح'
      },
      destroyAll: {
        success: 'تم حذف مستوى/مستويات الأسئلة بنجاح'
      },
      edit: {
        title: 'تعديل مستوى السؤال'
      },
      fields: {
        id: 'الرقم المعرف',
        questionGroup: 'مستوى السؤال',
        groupStatus: 'الحالة',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في ',
        createdAtRange: 'تم الإنشاء في'
      },
      enumerators: {
        groupStatus: {
          default: 'افتراضي'
        }
      },
      new: {
        title: 'مستوى سؤال جديد'
      },
      view: {
        title: 'عرض مستوى السؤال'
      }
    },
    examOutput: {
      name: 'مخرجات الامتحان',
      label: 'مخرجات الامتحان',
      menu: 'مخرجات الامتحان',
      exporterFileName: 'تصدير مخرجات الامتحان',
      list: {
        menu: 'مخرجات الامتحان',
        title: 'مخرجات الامتحان'
      },
      create: {
        success: 'تم حفظ مخرجات الامتحان بنجاح'
      },
      update: {
        success: 'تم حفظ مخرجات الامتحان بنجاح'
      },
      destroy: {
        success: 'تم حذف مخرجات الامتحان بنجاح'
      },
      destroyAll: {
        success: 'تم حذف مخرجات الامتحان/الامتحانات بنجاح'
      },
      edit: {
        title: 'تعديل مخرجات الامتحان'
      },
      fields: {
        id: 'الرقم المعرف',
        exam: 'الامتحان',
        student: 'الطالب',
        questionNumberRange: 'رقم السؤال',
        questionNumber: 'الرقم',
        outputStatus: 'الحالة',
        question: 'السؤال',
        answer: 'الإجابة',
        timeSpentRange: 'الوقت المنقضي (ثا)',
        timeSpent: 'الوقت المنقضي (ثا)',
        mouseMovements: 'حركات الماوس',
        screenShots: 'لقطات شاشة',
        violationCountRange: 'عدد الانتهاكات',
        violationCount: 'عدد الانتهاكات',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في'
      },
      enumerators: {
        outputStatus: {
          default: 'افتراضي',
          fetched: 'جلب',
          answered: 'أجيب',
          examFinished: 'انتهى الامتحان',
          assigned: 'الأساتذة المكلفون',
          autoScore: 'تصحيح تلقائي',
          resulted: 'صدرت النتيجة',
          autoResulted: 'صدرت النتيجة'
        }
      },
      new: {
        title: 'مخرجات جديدة للامتحان'
      },
      view: {
        title: 'عرض مخرجات الامتحان'
      },
      importer: {
        title: 'استيراد مخرجات الامتحان',
        fileName: 'قالب استيراد مخرجات الامتحان',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },

    unlocktest: {
      card: {
        cardholder: 'حامل البطاقة',
        fullname: 'الاسم الكامل ',
        expires: 'الانتهاء'
      },
      form: {
        cardnumber: 'رقم البطاقة',
        fullname: 'الاسم الكامل',
        expirationdate: 'تاريخ الانتهاء',
        month: 'الشهر',
        year: 'السنة',
        submit: 'إلغاء القفل',
        invalidCardNumber: 'رقم البطاقة غير صالح'
      }
    },

    checkbrowser: {
      title: 'متطلبات تقنية',
      description:
        'للاستمرار يرجى تحقيق المتطلبات التالية واستخدام تطبيق ITEX:',
      adblockertext: 'لا تستخدم ملحقات مثل AdBlocker وGrammarly',
      ischrometext: 'استخدم متصفح Chrome',
      browserversiontext: 'لا تستخدم إصدارات قديمة للمتصفح',
      checkPwa: 'استخدم تطبيق ITEX للاستمرار'
    },

    introduction: {
      title: 'مجموعة التعليمات العامة',
      description:
        'يجب عليك قراءة التعليمات الواردة أدناه بتمعُّن والالتزام بها:',
      introductions: [
        `الاختبار سري، ويجب ألا تفصح عنه
         إلى طرف ثالث أو أن تحاول نسخه لأغراض خاصة
          أو للاستخدام التجاري.`,
        `يتكون الامتحان من أربعة أقسام:
        التحدث، والكتابة، والقراءة، والاستماع، مختارة حسب إرشادات
     ITEX.`,
        `إذا حدث عُطْل في الكهرباء مثلاً أو انقطاع الإنترنت، يمكنك أن تدخل الاختبار من المكان الذي توقفت عنده.`,
        `عندما يحين وقت الاستراحة في الامتحان، سيتم عرض صفحة الاستراحة.`,
        `نطلب منك استخدام نظام ومتصفّح حديثَين
         إلى جانب سرعة إنترنت عادية / جيدة.`,
        `يجب تجنّب إجراء اختبارات متعددة مع نفس 
        المعرف في نفس الوقت.`,
        `على الرغم من أننا نلتزم بأفضل المعايير في المجال،
        أحيانًا قد يقوم المستخدمون بإجراء الاختبار في 
       ظروف غير مناسبة كسرعة التحميل المنخفضة وتأخر الإنترنت،
        أو متصفح قديم، أو مشغلات صوت معطلة، أو أجزاء غير مرغوب فيها
       ، ضمن ذاكرة التخزين المؤقت في المتصفح، مما يؤدي إلى بعض المشكلات في
        إجراء الاختبار. على الرغم من أننا لا يمكننا توجيه كل
        المستخدمين لإصلاح تلك المشكلة، نوصي المستخدم في هذه الحالة بإغلاق نافذة الاختبار وتسجيل الخروج وإعادة تشغيل النظام وإجراء الاختبار. كما يمكنه أيضاً محاولة أخذ
        الاختبار في نافذة التصفح المتخفي Incognito.`,
        `في حالة وجود أي استفسار، يمكنك التواصل بسهولة مع فريق دعمنا على شاشتك الرئيسية وكذلك يمكنك إرسال الملاحظات الضرورية إلينا في نهاية الاختبار.`,
        `ITEX نتمنى لك كل التوفيق والنجاح في امتحان 
.`
      ]
    },
    layoutintro: {
      title: 'مقدمة الاختبار',
      description:
        'يقيس هذا الاختبار مهارات القراءة والكتابة والاستماع والتحدث باللغة ${language} التي ستحتاجها في بيئة أكاديمية.',
      desclist: [
        `يتم عرض المؤقت في الزاوية اليمنى العليا من
         الشاشة إلى جانب عدد العناصر/الأسئلة في
         الاختبار.`,
        ` بالنقر على الزر "التالي" في أسفل كل شاشة 
        تؤكد إجابتك، وتنتقل إلى السؤال التالي.
        ولكن هذا يعني أنك لن تتمكن من العودة إلى السؤال السابق. 
        ولن تتمكن من مراجعة أي سؤال في نهاية الاختبار.`,
        `في حالة حدوث عطل مثل انقطاع الكهرباء أو الإنترنت، يمكنك أن تدخل الاختبار مرة أخرى من حيث توقفت.`,
        `عندما يحين وقت الاستراحة في الامتحان، تظهر صفحة الاستراحة.`,
        `قد يستخدم هذا الاختبار لهجات عربية متنوعة. يمكنك أن تجيب باللهجة التي تريدها.`
      ]
    },
    audiocheck: {
      title: 'فحص الصوت',
      description:
        'سنقوم الآن باختبار سماعة الرأس والميكروفون، وذلك للتأكد من أنها تعمل بشكل صحيح.',
      getmicrophone: 'اكتشاف الميكروفون',
      startrecord: 'ابدأ التسجيل',
      stoprecord: 'توقف',
      recording: 'يسجل الآن...',
      loading: 'يحمّل الآن',
      tryagain: 'حاول مرة أخرى',
      desclist: [
        `ضع سماعة الرأس، وضع الميكروفون بالقرب من الفم.`,
        `انقر على "اكتشاف الميكروفون" وانقر على "السماح" بالصلاحيات Allow.`,
        `عندما تكون جاهزًا، انقر على "تسجيل" وقُل:
        "ITEX مرحبًا، سوف أنجح في اختبار."`,
        `بعد الانتهاء من التحدث، انقر على الزر "إيقاف".`,
        `انقر على "تشغيل" لتسمع نفسك تتحدث.`,
        `إذا لم تتمكن من سماع الصوت، يرجى التحقق
         من إعدادات سماعة الرأس والنظام.`
      ]
    },
    takeabreak: {
      title: 'استراحة لمدة ${time} دقائق',
      description:
        'يمكنك الاستمرار في الإجابة عن أسئلة الاختبار في غضون ${time} دقائق',
      message: 'الاستراحة ستنتهي بعد ..'
    },
    onlineExam: {
      question: 'السؤال',
      audioRecorder: {
        recordingWillBegin: 'Recording will begin in $textTimer$ seconds',
        listeningWillBegin: 'Listening will begin in $textTimer$ seconds',
        message: 'Recording...',
        waitText: 'Wait',
        listeningText: 'Listening',
        titleText: 'Audio Recorder'
      },
      types: {
        readAloud: {
          type: 'قراءة جهرية',
          description:
            'انظر إلى النص التالي. يجب أن تقرأ هذا النص قراءة جهرية واضحة وطبيعية قدر الإمكان خلال $initialTime$ ثانية. معك $readingTime$ ثانية لتقرأ قراءة جهرية.'
        },
        repeatSentence: {
          type: 'إعادة لفظ جملة',
          description:
            'سوف تسمع جملة مرة واحدة فقط. لو سمحت كرّر الجملة كما تسمعها تماماً.'
        },
        describeImage: {
          type: 'وصف صورة',
          description:
            'انظر إلى الرسم البياني التالي. خلال $initialTime$ ثانية يُرجى التحدث بالميكروفون ووصف ما تعرضه الخريطة بالتفصيل. سيكون معك $recordTime$ ثانية لتقديم الإجابة.'
        },
        retellLecture: {
          type: 'إعادة صياغة محاضرة',
          description:
            'سوف تسمع محاضرة. بعد الاستماع إلى المحاضرة في $initialTime$ ثانية قم بالتحدث الميكروفون وإعادة سَرْد ما سمعته للتوّ من المحاضرة بكلماتك أنت لو سمحت. سيكون معك $recordTime$ ثانية لتقديم الإجابة.'
        },
        answerShortQuestion: {
          type: 'إجابة عن سؤال قصير',
          description:
            'سوف تسمع سؤالاً. يرجى إعطاء إجابة بسيطة وقصيرة. غالباً تكون كلمة واحدة أو بضع كلمات كافية'
        },
        summarizeWrittenText: {
          type: 'تلخيص نص مكتوب',
          description:
            'اقرأ الفقرة التالية ولخصها باستخدام جملة واحدة. اكتب ردك في المربع الموجود أسفل الشاشة. معك $time$ دقيقة/دقائق لإنهاء هذه المهمة. ستقيَّم إجابتك بناءً على جودة كتابتك ومدى تقديمها النقاط الرئيسية في المقطع بشكل جيد.'
        },
        writeEssay: {
          type: 'كتابة مقالة',
          description:
            'سيكون معك $time$ دقيقة/دقائق للتخطيط والكتابة والمراجعة لمقال حول الموضوع التالي. ستقيَّم كتابتك بناءً على جودة اتخاذك موقفاً من المقال، وتنظيم أفكارك، وتقديم التفاصيل الداعمة، والتحكم في عناصر اللغة العربية الفصيحة المكتوبة. يجب أن تكتب 200-300 كلمة.\n'
        },
        singleOption: {
          type: 'اختيار من متعدد، اختيار خيار واحد',
          description:
            'اقرأ النص وأجب عن سؤال الاختيار من متعدد بتحديد الإجابة الصحيحة. إجابة واحدة فقط هي الصحيحة.'
        },
        multipleOption: {
          type: 'اختيار من متعدد، اختيار خيارات متعددة',
          description:
            'اقرأ النص وأجب عن السؤال باختيار جميع الإجابات الصحيحة. أكثر من إجابة واحدة صحيحة.'
        },
        reorderParagraph: {
          type: 'إعادة ترتيب الفقرة',
          description:
            'مربعات النص مرتَّبَة ترتيباً عشوائياً. قم باستعادة الترتيب الأصلي، وذلك بِسَحْبها.'
        },
        fillInTheBlanks: {
          type: 'إملأ الفراغات',
          description:
            'في النص التالي بعض الكلمات مفقودة. اسحب الكلمات من المربع الذي في الأسفل إلى المكان المناسب في النص. للتراجع عن خيار إجابة، اسحب الكلمة مرة أخرى إلى المربع الموجود أسفل النص.'
        },
        selectedFillInTheBlanks: {
          type: 'املأ فراغات بالاختيار',
          description:
            'يوجد فيما يلي نص فيه فراغات. انقر على كل فراغ لتظهر قائمة بالخيارات. اختر خيار الإجابة المناسب لكل فراغ.'
        },
        highlightCorrectSummary: {
          type: 'حدد الملخص الصحيح',
          description: 'بعد الاستماع إلى تسجيل، حدد أفضل الفقرة تلخص التسجيل.'
        },
        summarizeSpokenText: {
          type: 'تلخيص النص المنطوق',
          description:
            'سوف تسمع تقريرًا قصيرًا. اكتب ملخصًا لأحد زملائك الذي ما كان حاضرًا. يجب أن تكتب 50-70 كلمة. معك $time$ دقيقة/دقائق لإنهاء هذه المهمة. سيتم الحكم على إجابتك بناءً على جودة كتابتك ومدى جودة تقديمها للنقاط الرئيسية الواردة في التقرير.'
        },
        multipleAnswer: {
          type: 'اختيار من متعدد، اختيار إجابات متعددة',
          description:
            'استمع إلى التسجيل وأجب عن السؤال باختيار جميع الإجابات الصحيحة. يجب أن تختار أكثر من إجابة واحدة. '
        },
        writeInTheBlanks: {
          type: 'اكتب في الفراغات',
          description: 'سوف تسمع تسجيلاً. اكتب الكلمات المفقودة في كل فراغ.'
        },
        singleAnswer: {
          type: 'اختيار من متعدد، اختيار إجابة واحدة',
          description:
            'استمع إلى التسجيل وأجب عن سؤال الاختيار من متعدد باختيار الإجابة الصحيحة. إجابة واحدة فقط صحيحة. '
        },
        selectMissingWord: {
          type: 'اختر الكلمة المفقودة',
          description:
            'سوف تسمع تسجيلاً، في نهاية التسجيل تم استبدال الكلمة الأخيرة أو مجموعة الكلمات الأخيرة بصوت طنين. حدد الخيار الصحيح لإكمال التسجيل.'
        },
        highlightIncorrectWords: {
          type: 'حدد الكلمات الخاطئة',
          description:
            'سوف تسمع تسجيلاً. في ما يلي نص التسجيل مكتوباً. تختلف بعض الكلمات في النص المكتوب عمّا يقوله المتحدث/المتحدثون. لو سمحت انقر على الكلمات المختلفة.'
        },
        writeFromTheDictation: {
          type: 'إملاء',
          description:
            'سوف تسمع جملة. اكتب الجملة في المربع التالي كما تسمعها تماماً. اكتب أكبر قدر ممكن من الجملة، سوف تسمع الجملة مرة واحدة فقط.'
        }
      },
      section: {
        writing: 'كتابة',
        reading: 'قراءة',
        listening: 'استماع',
        speaking: 'تحدث'
      },
      word: 'كلمة/كلمات',
      validations: {
        writeEssay: {
          lessWordsMessage: 'كلمة/كلمات متبقية للوصول إلى 200',
          tooWordsMessage: 'كلمة/كلمات تجاوزت الحد'
        },
        summarizeWrittenText: {
          tooWordsMessage: 'كلمة/كلمات. امسحها لتجنب كسر الدرجة'
        },
        summarizeSpokenText: {
          lessWordsMessage: 'كلمة/كلمات متبقية للوصول إلى 50',
          tooWordsMessage: 'كلمة/كلمات تجاوزت الحد'
        },
        writeFromTheDictation: {
          wordlimitexceeded: 'تم تجاوز حد الكلمات!'
        }
      }
    },
    survey: {
      title: 'استبيان',
      description: 'مبارك! لقد أكملت اختبارك بنجاح.',
      header: 'قيّم اختبارك',
      qualityTitle: 'جودة الامتحان',
      qualities: {
        poor: 'ضعيف',
        average: 'متوسط',
        good: 'جيد',
        excellent: 'ممتاز',
        outstanding: 'رائع'
      },
      difficultyTitle: 'مستوى صعوبة الاختبار',
      difficulties: {
        soEasy: 'سهل جداً',
        easy: 'سهل',
        average: 'متوسط',
        difficult: 'صعب',
        soDifficult: 'صعب جداً'
      },
      placeholder: 'لو سمحت شارك تجربتك في الاختبار'
    },
    supportRequests: {
      name: 'طلبات الدعم',
      label: 'طلبات الدعم',
      menu: 'طلبات الدعم',
      exporterFileName: 'تصدير طلبات الدعم',
      list: {
        menu: 'طلبات الدعم',
        title: 'طلبات الدعم'
      },
      create: {
        success: 'تم حفظ طلب الدعم بنجاح'
      },
      update: {
        success: 'تم حفظ طلب الدعم بنجاح'
      },
      destroy: {
        success: 'تم حذف طلب الدعم بنجاح'
      },
      destroyAll: {
        success: 'تم حذف طلب/ـات الدعم بنجاح'
      },
      edit: {
        title: 'تعديل طلب الدعم'
      },
      fields: {
        id: 'الرقم المعرف',
        type: 'النوع',
        message: 'الرسالة',
        images: 'الصور',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في ',
        createdAtRange: 'تم الإنشاء في',
        quality: 'جودة',
        difficulty: 'صعوبة',
        exam: 'امتحان',
        comment: 'Comments',
        examname: 'Exam Name',
        username: 'User Name',
        useremail: 'User E-Mail'
      },
      enumerators: {
        type: {
          About_Payment: 'بخصوص الدفع',
          Technical_Issues: 'مشكلة تقنية',
          Comment: 'تعليق'
        }
      },
      new: {
        title: 'طلب دعم جديد'
      },
      view: {
        title: 'عرض طلب الدعم'
      },
      importer: {
        title: 'استيراد طلبات دعم',
        fileName: 'supportRequests_import_template',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط الملفات مفصولة بمسافة.'
      }
    },
    language: {
      name: 'اللغة',
      label: 'اللغة',
      menu: 'اللغات',
      exporterFileName: 'تصدير اللغات',
      list: {
        menu: 'اللغات',
        title: 'اللغات'
      },
      create: {
        success: 'تم حفظ اللغة بنجاح'
      },
      update: {
        success: 'تم حفظ اللغة بنجاح'
      },
      destroy: {
        success: 'تم حذف اللغة بنجاح'
      },
      destroyAll: {
        success: 'تم حذف اللغة/اللغات بنجاح'
      },
      edit: {
        title: 'تعديل اللغة'
      },
      fields: {
        id: 'الرقم المعرف',
        code: 'اللغة',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في'
      },
      enumerators: {
        language: {
          en: 'الإنكليزية',
          ar: 'العربية',
          tr: 'التركية'
        }
      },
      new: {
        title: 'لغة جديدة'
      },
      view: {
        title: 'عرض اللغة'
      },
      importer: {
        title: 'استيراد لغات',
        fileName: 'languages_import_template',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },

    teacher: {
      menu: 'قائمة المعلم',
    },

    student: {
      menu: 'قائمة الطالب',
    },

    structure: {
      name: 'البنية',
      label: 'بنية الامتحان',
      menu: 'بنية الامتحان',
      exporterFileName: 'تصدير نوع الامتحان',
      list: {
        menu: 'بنية الامتحان',
        title: 'بنية الامتحان'
      },
      create: {
        success: 'تم حفظ بنية الامتحان بنجاح',
        successSI: 'تم حفظ عنصر البنية بنجاح'
      },
      update: {
        success: 'تم حفظ بنية الامتحان بنجاح',
        successSI: 'تم حفظ عنصر البنية بنجاح'
      },
      destroy: {
        success: 'تم حذف بنية الامتحان بنجاح',
        successSI: 'تم حذف عنصر البنية بنجاح'
      },
      destroyAll: {
        success: 'تم حذف بنية/بنى الامتحان بنجاح',
        successSI: 'تم حذف عنصر/عناصر البنية بنجاح'
      },
      edit: {
        title: 'تعديل بنية الامتحان',
        titleSI: 'تعديل عنصر البنية'
      },
      fields: {
        //structure
        id: 'الرقم المعرف',
        structureName: 'اسم البنية',
        statusStructure: 'الحالة',
        hasBreak: 'إضافة استراحة',
        breakBefore: 'الاستراحة قبل',
        breakLimit: 'مدة الاستراحة (دقيقة)',
        students: 'الطلاب',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في ',
        //structItems
        structItemId: 'الرقم المعرف',
        order: 'الطلب',
        count: 'العدد',
        statusSI: 'الحالة',
        resultEffect: 'تأثير النتيجة',
        questionType: 'نوع السؤال',
        structItemGroups: 'مستوى السؤال',
        descriptions: "الأوصاف",
        descEn: 'إنجليزي',
        descAr: 'عربي',
        descTr: 'تركش',
        questionCount: 'عدد الأسئلة'
      },
      enumerators: {
        statusStructure: {
          default: 'افتراضي'
        },
        statusSI: {
          default: 'افتراضي'
        }
      },
      new: {
        title: 'بنية امتحان جديدة',
        titleSI: 'عنصر بنية جديد'
      },
      view: {
        title: 'عرض بنية الامتحان',
        titleSI: 'عرض عنصر البنية'
      }
    },
    order: {
      name: 'طلب',
      label: 'طلبات',
      menu: 'طلبات',
      exporterFileName: 'تصدير الطلبات',
      list: {
        menu: 'طلبات',
        title: 'طلبات'
      },
      create: {
        success: 'تم فتح الاختبار بنجاح',
        failed: 'فشل فتح الامتحان'
      },
      destroy: {
        success: 'تم حذف الطلب بنجاح'
      },
      destroyAll: {
        success: 'تم حذف الطلب/الطلبات بنجاح'
      },
      fields: {
        id: 'الرقم المعرف',
        amount: 'المبلغ',
        paymentSystem: 'نظام الدفع',
        psOrderNo: 'ملاحظة: رقم الطلب',
        orderExpireAt: 'ينتهي في ',
        description: 'وصف',
        currency: 'العملة',
        cardData: 'بيانات البطاقة',
        orderData: 'بيانات الطلب',
        orderStatus: 'حالة الطلب',
        student: 'الطالب',
        exams: 'الامتحانات',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في'
      },
      enumerators: {
        orderStatus: {
          default: 'افتراضي',
          started: 'بدأ',
          failed: 'فشل',
          successful: 'ناجح'
        }
      },
      view: {
        title: 'عرض الطلب'
      },
      new: {
        title: 'فتح الاختبار'
      }
    },
    classroom: {
      name: 'الصف',
      label: 'الصفوف',
      menu: 'الصفوف',
      exporterFileName: 'تصدير الصفوف',
      list: {
        menu: 'الصفوف',
        title: 'الصفوف'
      },
      create: {
        success: 'تم حفظ الصف بنجاح'
      },
      update: {
        success: 'تم حفظ الصف بنجاح'
      },
      destroy: {
        success: 'تم حذف الصف بنجاح'
      },
      destroyAll: {
        success: 'تم حذف الصف/الصفوف بنجاح'
      },
      edit: {
        title: 'تعديل الصف'
      },
      fields: {
        id: 'الرقم المعرف',
        className: 'اسم الصف',
        language: 'اللغة',
        classLevel: 'مستوى الصف',
        status: 'الحالة',
        students: 'الطلاب',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في ',
        createdAtRange: ' تم الإنشاء في'
      },
      enumerators: {
        language: {
          English: 'الإنكليزية',
          Arabic: 'العربية',
          Turkish: 'التركية'
        },
        classLevel: {
          Beginner: 'تمهيدي',
          Elementary: 'مبتدئ',
          Intermediate: 'متوسط',
          Upper_Intermediate: 'متوسط أعلى',
          Advanced: 'متقدم',
          Proficiency: 'كفاءة'
        }
      },
      new: {
        title: 'صف جديد'
      },
      view: {
        title: 'عرض الصف'
      },
      importer: {
        title: 'استيراد صفوف',
        fileName: 'classroom_import_template',
        hint:
          'يجب أن تكون أعمدة الملفات/الصور عناوين روابط للملفات مفصولة بمسافة.'
      }
    },
    examResult: {
      name: 'نتيجة الامتحان',
      label: 'نتائج الامتحان',
      menu: 'نتائج الامتحان',
      exporterFileName: 'تصدير نتيجة الامتحان',
      list: {
        menu: 'نتائج الامتحان',
        title: 'نتائج الامتحان',
        announceAll: 'إعلان النتائج'
      },
      create: {
        success: 'تم حفظ نتيجة الامتحان بنجاح'
      },
      update: {
        success: 'تم حفظ نتيجة الامتحان بنجاح',
        announce: 'تم إعلان النتائج بنجاح'
      },
      reScore: {
        button: 'إعادة الدرجة',
        success: 'بدأت عملية إنقاذ الامتحان'
      },
      destroy: {
        success: 'تم حذف نتيجة الامتحان بنجاح'
      },
      destroyAll: {
        success: 'تم حذف نتيجة/نتائج الامتحان بنجاح'
      },
      edit: {
        title: 'تعديل نتائح الامتحان'
      },
      fields: {
        id: 'الرقم المعرف',
        reading: 'القراءة',
        readingNumberRange: 'القراءة',
        speaking: 'التحدث',
        speakingNumberRange: 'التحدث',
        writing: 'الكتابة',
        writingNumberRange: 'الكتابة',
        listening: 'الاستماع',
        listeningNumberRange: 'الاستماع',
        grammar: 'القواعد',
        oralFluency: 'الطلاقة الشفهية',
        pronunciation: 'النطق',
        spelling: 'الإملاء',
        vocabulary: 'المفردات',
        writtenDiscourse: 'الخطاب الكتابي',
        aftermath: 'المجموع النهائي',
        aftermathNumberRange: 'المجموع النهائي',
        results: 'درجات أخرى',
        resultStatus: 'الحالة',
        exam: 'الامتحان',
        examName: 'اسم الامتحان',
        studentName: 'أسم الطالب',
        studentEmail: 'البريد الإلكتروني للطالب',
        student: 'الطالب',
        createdAt: 'تم الإنشاء في',
        updatedAt: 'تم التحديث في',
        createdAtRange: 'تم الإنشاء في',
        totalQC: 'السؤال',
        answeredQC: 'سؤال مجاب عنه',
        examSessionDate: 'تاريخ الامتحان',
        totalTimeSpent: 'الوقت المقضي'
      },
      enumerators: {
        resultStatus: {
          default: 'افتراضية',
          calculated: 'محسوبة',
          updated: 'محدثة',
          announced: 'معلنة',
          oldVersion: 'نسخة قديمة'
        }
      },
      new: {
        title: 'نتيجة امتحان جديدة'
      },
      view: {
        title: 'عرض نتيجة الامتحان'
      },
      analyze: {
        title: 'تحليل نتيجة الامتحان',
        prev: 'Prev',
        next: 'Next',
        yourResponse: 'ردك'
      },
      questionPreview: {
        listening: 'Listening',
        yourResponse: 'ردك',
        audioRecorder: 'Audio Recorder'
      },
      scorePage: {
        headerTitle: 'تقرير درجة ITEX',
        scoreReportCode: 'رمز تقرير النتيجة',
        testId: 'معرف الامتحان',
        registrationId: 'معرف التسجيل',
        overallScore: 'المجموع النهائي',
        communicativeSkills: 'مهارات التواصل',
        enablingskills: 'تمكين المهارات',
        skillsBreakdown: 'تفصيل المهارات',
        testCentreInformation: {
          title: 'معلومات مركز الاختبار',
          testDate: 'تاريخ الاختبار',
          validUntil: 'صالح حتى',
          issueDate: 'تاريخ الإصدار',
          testCentreCountry: 'بلد مركز الاختبار',
          testCentreId: 'الرقم المعرف لمركز الاختبار',
          testCentre: 'مركز الاختبار'
        },
        candidateInformation: {
          title: 'معلومات المرشح',
          dateofBirth: 'تاريخ الميلاد',
          countryofCitizenship: 'الجنسية',
          countryofResidence: 'بلد الإقامة',
          gender: 'الجنس'
        },
        descriptiontext: "تعتمد النتيجة الإجمالية لاختبار ITEX الأكاديمي على أداء الخاضعين للاختبار في جميع عناصره، وتعتمد نتائج المهارات التواصلية والمهارات التمكينية على أداء الخاضعين للاختبار في العناصر التي تتعلق بتلك المهارات حصراً.  وبما أن الكثير من العناصر تتداخل فيها المهارات التواصلية والتمكينية فلا يمكن حساب النتيجة الإجمالية مباشرة من نتائج المهارات التواصلية أو المهارات التمكينية. توضح الرسوم البيانية أدناه المهارات التواصلية والمهارات التمكينية لدى هذا الشخص الخاضع للاختبار. عند مقارنة النتيجة الإجمالية مع نتائج المهارات التواصلية والمهارات الإنتاجية يرجى الانتباه إلى أن هناك افتقاراً للدقة في القياس المعتمد على الذكاء الصنعي؛ وذلك حسب عوامل متنوعة.",
      }
    }
  },

  auth: {
    profile: {
      title: 'تعديل الملف الشخصي',
      success: 'تم تعديل الملف الشخصي بنجاح'
    },
    createAnAccount: 'إنشاء حساب',
    rememberMe: 'تذكرني',
    forgotPassword: 'نسيت كلمة المرور',
    signin: 'تسجيل الدخول',
    signup: 'تسجيل حساب',
    signout: 'تسجيل الخروج',
    alreadyHaveAnAccount: 'هل لديك حساب؟ قم بتسجيل الدخول.',
    signinWithAnotherAccount: 'تسجيل دخول بحساب أخر',
    emailUnverified: {
      message: `يرجى تأكيد بريدك الإلكتروني في <strong>{0}</strong> للمتابعة.`,
      submit: `إعادة إرسال التحقق من البريد الإلكتروني.`
    },
    emptyPermissions: {
      message: `ليس عندك إذن بعد. انتظر عملية الموافقة.`
    },
    passwordResetEmail: {
      message: 'إرسال بريد إلكتروني لإعادة تعيين كلمة المرور',
      error: `لم يتم التعرف على البريد الإلكتروني`
    },
    passwordReset: {
      message: 'إعادة تعيين كلمة المرور'
    },
    emailAddressVerificationEmail: {
      error: `لم يتم التعرف على البريد الإلكتروني`
    },
    verificationEmailSuccess: `تم إرسال رسالة التحقق من البريد الإلكتروني بنجاح`,
    passwordResetEmailSuccess: `تم إرسال بريد إلكتروني لإعادة تعيين كلمة المرور بنجاح`,
    passwordResetSuccess: `تم تغير كلمة المرور بنجاح`,
    verifyEmail: {
      success: 'تم التحقق من البريد الإلكتروني بنجاح',
      message: 'لحظة واحدة، يتم الآن التحقق من بريدك الإلكتروني ...'
    }
  },

  roles: {
    owner: {
      label: 'مالك',
      description: 'وصول كامل إلى جميع الموارد'
    },
    admin: {
      label: 'مشرف',
      description: 'مسؤول النظام'
    },
    teacher: {
      label: 'أستاذ',
      description: 'تحضير الامتحانات وتصحيحها'
    },
    student: {
      label: 'الطلاب',
      description: 'الحصول على الامتحانات والمخرجات'
    },
    canCreateExam: {
      label: 'إنشاء امتحان',
      description: 'إنشاء امتحان وتعديله'
    },
    canListQEs: {
      label: 'عرض الأسئلة والامتحانات',
      description: 'يستطيع عرض الأسئلة والامتحانات'
    }
  },

  iam: {
    title: 'إدارة الهوية والوصول',
    menu: 'المستخدمون',
    disable: 'تعطيل',
    disabled: 'معطل',
    enabled: 'مفعل',
    enable: 'تفعيل',
    doEnableSuccess: 'تم تفعيل المستخدم بنجاح',
    doDisableSuccess: 'تم تعطيل المستخدم بنجاح',
    doDisableAllSuccess: 'تم تعطيل المستخدم (المستخدمين) بنجاح',
    doDestroyAllSuccess: 'تم حذف المستخدمين بنجاح',
    doEnableAllSuccess: 'تم تفعيل المستخدم (المستخدمين) بنجاح',
    doAddSuccess: 'تم حفظ المستخدم (المستخدمين) بنجاح',
    doUpdateSuccess: 'تم حفظ المستخدم بنجاح',
    viewBy: 'عرض بوساطة',
    users: {
      name: 'المستخدمين ',
      label: 'المستخدمين',
      exporterFileName: 'تصدير المستخدمين',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح'
    },
    roles: {
      label: 'الأدوار',
      doRemoveAllSelectedSuccess: 'تمت إزالة الأذونات بنجاح'
    },
    edit: {
      title: 'تعديل المستخدم'
    },
    new: {
      title: 'مستخدم (مستخدمون) جدد',
      titleModal: 'مستخدم جديد',
      emailsHint:
        'افصل بين عناوين البريد الإلكتروني المتعددة باستخدام الفاصلة اللاتينية.'
    },
    view: {
      title: 'عرض المستخدم',
      activity: 'نشاط'
    },
    importer: {
      studentTitle: 'استيراد الطلاب',
      teacherTitle: 'استيراد الأساتذة',
      studentFilename: 'students_import_template',
      teacherFilename: 'teachers_import_template',
      hint: ''
    },
    errors: {
      userAlreadyExists: 'هناك مستخدم بهذا البريد الإلكتروني مسجل مسبقاً',
      userNotFound: 'المستخدم غير موجود',
      disablingHimself: `لا يمكنك تعطيل نفسك`,
      revokingOwnPermission: `لا يمكنك إبطال إذن المالك الخاص بك`
    }
  },

  user: {
    fields: {
      id: 'الرقم المعرف',
      authenticationUid: 'رقم المستخدم المعرف للمصادقة',
      avatars: 'الصورة',
      email: 'البريد الإلكتروني',
      emails: 'البريد/ات الإلكتروني',
      fullName: 'الاسم',
      firstName: 'الاسم الأول',
      lastName: 'الاسم الأخير',
      status: 'الحالة',
      disabled: 'معطل',
      emailVerified: 'تم التحقق من البريد الإلكتروني',
      phoneNumber: 'رقم الهاتف',
      role: 'الدور',
      createdAt: 'تم الإنشاء في',
      updatedAt: 'تم التحديث في',
      roleUser: 'الدور/المستخدم',
      languages: 'اللغات',
      roles: 'الأدوار',
      createdAtRange: 'تم الإنشاء في',
      password: 'كلمة المرور',
      rememberMe: 'تذكرني'
    },
    items: {
      removeRoles: "إزالة الأدوار",
      permanentDelete: "حذف دائم"
    },
    enabled: 'مفعل',
    disabled: 'معطل',
    verified: 'تم التحقق',
    notVerified: 'لم يتم التحقق منه',
    validations: {
      // eslint-disable-next-line
      email: 'البريد الإلكتروني ${value} غير صالح'
    }
  },

  assignPapers: {
    menu: 'تعيين الأوراق',
    title: 'تعيين أوراق اختبار للأساتذة',
    fields: {
      teachers: 'الأساتذة',
      language: 'اللغة',
      examPapers: 'أوراق الامتحان',
      gradingCount: 'عدد الدرجات لكل إجابة',
      unscoredPapers: 'الأوراق غير المصححة',
      uncompletedPapers: 'أوراق غير مكتملة',
      assignedScores: 'الدرجات المعطاة'
    },
    button: {
      examPapers: 'تعيين أوراق امتحان',
      unscoredPapers: 'تعيين أوراق غير مصححة',
      assignedScores: 'إعادة تعيين الدرجات',
      unscore: 'تعيين الأوراق على أنها غير مصححة',
      assignUncompleted: 'تعيين أوراق غير مكتملة',
      deleteUncompleted: 'احذف الأوراق غير المكتملة',
      deleteAll: 'حذف الكل'
    },
    reassignScores: {
      onSuccess: "إعادة تعيين الأوراق"
    },
    setPapersAsUnScored: {
      onSuccess: "تم تعيين الأوراق على أنها غير مسجلة"
    },
    assignPapers: {
      onSuccess: "تم تعيين الأوراق"
    },
    assignUncompletedPapers: {
      onSuccess: "تم تعيين أوراق غير مكتملة"
    },
    deleteUnScoredPapers: {
      onSuccess: "تم حذف الأوراق غير المصنفة"
    },
    deleteUncompletedPapers: {
      onSuccess: "تم حذف الأوراق غير المكتملة"
    },
    placeholders: {
      selectteacher: "Select Teachers",
      selectpaper: "Select Paper",
      selectitem: "Select Item"
    }
  },
  auditLog: {
    menu: 'سجلات التدقيق',
    title: 'سجلات التدقيق',
    exporterFileName: 'تصدير سجل التدقيق',
    entityNamesHint: 'افصل بين العناصر المتعددة باستخدام الفاصلة اللاتينية.',
    fields: {
      id: 'الرقم المعرف',
      timestampRange: 'المدة',
      entityName: 'العنصر',
      entityNames: 'العناصر',
      entityId: 'الرقم المعرف للعنصر',
      action: 'إجراء',
      values: 'القيم',
      timestamp: 'التاريخ',
      createdByEmail: 'البريد الإلكتروني للمستخدم'
    }
  },
  tests: { menu: 'الاختبارات' },
  settings: {
    title: 'إعدادات',
    menu: 'إعدادات',
    save: {
      success:
        'تم حفظ الإعدادات بنجاح. ستتم إعادة تحميل الصفحة خلال {0} ثانية حتى تدخل التغييرات حيز التنفيذ.'
    },
    fields: {
      theme: 'السمة'
    },
    colors: {
      'itex-سمة': 'itex-سمة'
    }
  },
  home: {
    menu: 'لوحة التحكم',
    message: `تستخدم هذه الصفحة بيانات وهمية لأغراض التوضيح فقط.`,
    charts: {
      score: 'النتيجة كاملة',
      day: 'اليوم',
      totalscores: 'النتيجة الكاملة للامتحان',
      totalscore: 'النتيجة الكاملة',
      red: 'أحمر',
      green: 'أخضر',
      yellow: 'أصفر',
      grey: 'رمادي',
      blue: 'أزرق',
      orange: 'برتقالي',
      months: {
        1: 'كانون الثاني',
        2: 'شباط',
        3: 'آذار',
        4: 'نيسان',
        5: 'أيار',
        6: 'حزيران',
        7: 'تموز'
      },
      eating: 'الأكل',
      drinking: 'الشرب',
      sleeping: 'النوم',
      designing: 'التصميم',
      coding: 'البرمجة',
      cycling: 'ركوب الدراجات',
      running: 'الركض',
      customer: 'زبون',
      lastfiveexam: 'آخر 5 امتحانات',
      exam: {
        reading: 'القراءة',
        writing: 'الكتابة',
        listening: 'الاستماع',
        speaking: 'التحدث',
        grammar: 'القواعد',
        oralFluency: 'الطلاقة الشفوية',
        pronunciation: 'اللفظ',
        spelling: 'الإملاء',
        vocabulary: 'المفردات',
        writtenDiscourse: 'الخطاب الكتابي'
      }
    }
  },
  errors: {
    backToHome: 'العودة إلى الصفحة الرئيسية',
    403: `عذراً، ليس لديك حق الوصول إلى هذه الصفحة`,
    404: 'عذراً، الصفحة التي زرتها غير موجودة',
    500: 'عذراً، حدث خطأ في المخدم',
    examExpired: `عذرًا، ليس لديك حق الوصول إلى اختبار منتهي الصلاحية`,
    forbidden: {
      message: 'ممنوع'
    },
    validation: {
      message: 'حدث خطأ'
    },
    defaultErrorMessage: 'عذراً، حدث خطأ'
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} غير صالح',
      required: '${path} مطلوب',
      oneOf: '${path} يجب أن يكون إحدى القيم التالية: ${values}',
      notOneOf: '${path} يجب ألا يكون إحدى القيم التالية: ${values}',
      notType: ({ path, type }) => {
        return `${path} يجب أن يكون ${type}`;
      }
    },
    string: {
      length: '${path} يجب أن يتألف من  ${length} حرفًا بالضبط',
      min: '${path} يجب أن يكون على الأقل ${min} حرفًا',
      max: '${path} يجب أن يكون على الأكثر ${max} حرفًا',
      matches: '${path} يجب أن تتطابق مع ما يلي: "${regex}"',
      email: '${path}يجب أن يكون بريدًا إلكترونيًا صالحًا',
      url: '${path} يجب أن يكون رابط URL صالحاً',
      trim: '${path} يجب أن يكون سلسلة نصية مقصوصة الجوانب',
      lowercase: '${path} يجب أن تكون بأحرف صغيرة',
      uppercase: '${path} يجب أن تكون بلأحرف كبيرة',
      selected: '${path} يجب تحديد'
    },
    number: {
      min: '${path} يجب أن يساوي ${min} أو أكثر',
      max: '${path} يجب أن يساوي ${max} أو أقل',
      lessThan: '${path} يجب أن يكون أقل من ${less}',
      moreThan: '${path} يجب أن يكون أكثر من ${more}',
      notEqual: '${path} يجب ألا يساوي ${notEqual}',
      positive: '${path} يجب أن يكون رقما موجباً',
      negative: '${path} يجب أن يكون رقمًا سالبًا',
      integer: '${path} يجب أن يكون عدداً صحيحاً',
      invalid: '${path} يجب أن يكون رقماً'
    },
    date: {
      min: ' الحقل ${path} يجب أن يكون بعد ${min}',
      max: ' الحقل ${path} يجب أن يكون قبل ${max}'
    },
    boolean: {},
    object: {
      noUnknown: 'الحقل ${path} لا يمكن أن يحتوي مفاتيح غير محددة في شكل العنصر'
    },
    array: {
      min: 'الحقل ${path} يجب أن يحتوي على ${min} من العناصر على الأقل ',
      max: 'الحقل ${path} يجب أن يحتوي على ${max} من العناصر أو أقل'
    }
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'تحميل',
    image: 'يجب عليك تحميل صورة',
    size: 'الملف كبير جداً. الحجم الأقصى المسموح به هو {0}',
    formats: `الصيغة غير صالحة. يجب أن تكون الصيغة '{0}'.`
  },
  importer: {
    line: 'خط',
    status: 'حالة',
    pending: 'قيد الانتظار',
    imported: 'مستورد',
    error: 'خطأ',
    total: `{0} مستورد، و{1} قيد الانتظار، و{2} فيه خطأ`,
    importedMessage: `تمت معالجة {0} من {1}.`,
    noNavigateAwayMessage: 'لا تنتقل من هذه الصفحة وإلا فسيتوقف الاستيراد.',
    completed: {
      success: 'اكتمل الاستيراد. تم استيراد جميع الصفوف بنجاح.',
      someErrors: 'اكتملت المعالجة، لكن تعذر تحميل بعض الصفوف.',
      allErrors: 'فشل التحميل. لا توجد صفوف صالحة.'
    },
    form: {
      downloadTemplate: 'تنزيل القالب',
      hint: 'انقر أو اسحب الملف إلى هذه المنطقة للمتابعة'
    },
    list: {
      discardConfirm: 'هل أنت متأكد؟ ستفقد البيانات غير المستوردة.'
    },
    errors: {
      invalidFileEmpty: 'الملف فارغ',
      invalidFileExcel: 'يُسمح بملفات إكسل (.xlsx) فقط',
      invalidFileUpload:
        'ملف غير صالح. تأكد من أنك تستخدم الإصدار الأخير من القالب.',
      importHashRequired: 'رمز تشفير الاستيراد مطلوب',
      importHashExistent: 'تم استيراد البيانات من قبل'
    }
  },

  autocomplete: {
    loading: 'جار التحميل ...'
  },

  imagesViewer: {
    noImage: 'لا توجد صورة'
  }
};

export default ar;
