<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.id.label"
        :prop="fields.id.name"
        v-if="isEditing"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            :disabled="true"
            v-model="model[fields.id.name]"
          />
        </el-col>
      </el-form-item>
      <el-form-item
        :label="fields.userName.label"
        :prop="fields.userName.name"
        :required="fields.userName.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <app-iam-user-autocomplete-input
            :fetchFn="fields.userName.fetchFn"
            :mapperFn="fields.userName.mapperFn"
            :showCreate="!modal"
            v-model="model[fields.userName.name]"
            mode="single"
          ></app-iam-user-autocomplete-input>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.studentNumber.label"
        :prop="fields.studentNumber.name"
        :required="fields.studentNumber.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            v-model="model[fields.studentNumber.name]"
          />
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.gender.label"
        :prop="fields.gender.name"
        :required="fields.gender.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-radio-group
            v-model="model[fields.gender.name]"
          >
            <el-radio
              :key="option.id"
              :label="option.id"
              v-for="option of fields.gender.options"
              >{{ option.label }}</el-radio
            >
          </el-radio-group>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.birthdate.label"
        :prop="fields.birthdate.name"
        :required="fields.birthdate.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-date-picker
            placeholder
            type="date"
            v-model="model[fields.birthdate.name]"
          ></el-date-picker>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.userType.label"
        :prop="fields.userType.name"
        :required="fields.userType.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-select
            placeholder
            v-model="model[fields.userType.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.userType.options"
            ></el-option>
          </el-select>
        </el-col>
      </el-form-item>

      <el-form-item
        :label="fields.nationality.label"
        :prop="fields.nationality.name"
        :required="fields.nationality.required"
      >
        <el-col :lg="11" :md="16" :sm="24">
          <el-input
            v-model="model[fields.nationality.name]"
          />
        </el-col>
      </el-form-item>
      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doReset"
            icon="el-icon-fa-undo"
          >
            <app-i18n code="common.reset"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserDetailModel } from '@/modules/user-detail/user-detail-model';

const { fields } = UserDetailModel;
const formSchema = new FormSchema([
  fields.id,
  fields.userName,
  fields.studentNumber,
  fields.gender,
  fields.birthdate,
  fields.userType,
  fields.nationality,
]);

export default {
  name: 'app-user-detail-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(
      this.record || {},
    );
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },

    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
};
</script>

<style></style>
