import { StructureModel } from '@/modules/structure/structure-model';

const { fields } = StructureModel;

export default [
  fields.id,
  fields.structureName,
  fields.statusStructure,
  fields.breakBefore,
  fields.breakLimit,
];
