import Roles from '@/security/roles';
const roles = Roles.values;

class QuestionTypePermissions {
  static get values() {
    return {
      questionTypeImport: {
        id: 'questionTypeImport',
        allowedRoles: [roles.owner, roles.admin],
      },
      questionTypeCreate: {
        id: 'questionTypeCreate',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionType'],
      },
      questionTypeEdit: {
        id: 'questionTypeEdit',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionType'],
      },
      questionTypeDestroy: {
        id: 'questionTypeDestroy',
        allowedRoles: [roles.owner, roles.admin],
        allowedStorageFolders: ['questionType'],
      },
      questionTypeRead: {
        id: 'questionTypeRead',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
      questionTypeAutocomplete: {
        id: 'questionTypeAutocomplete',
        allowedRoles: [
          roles.owner,
          roles.admin,
          roles.teacher,
          roles.student,
        ],
      },
    };
  }
}

export default QuestionTypePermissions;
