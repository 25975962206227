import importerStore from '@/shared/importer/importer-store';
import { IamService } from '@/modules/iam/iam-service';
import iamImporterFields from '@/modules/iam/iam-importer-fields';
import { i18n } from '@/i18n';

export default {
  //todo ilerde cok fark yoksa burayi teke dusurebilirsin?
  sImporterStore: importerStore(
    IamService.importStudent,
    iamImporterFields,
    i18n('iam.importer.studentFilename'), //users_import_template.xlsx gibi
    i18n('iam.importer.hint'), //excel'e girisle ilgili uyari notu
  ),
  tImporterStore: importerStore(
    IamService.importTeacher,
    iamImporterFields,
    i18n('iam.importer.teacherFilename'), //users_import_template.xlsx gibi
    i18n('iam.importer.hint'), //excel'e girisle ilgili uyari notu
  ),
};
