import orderListStore from '@/modules/order/order-list-store';
import orderViewStore from '@/modules/order/order-view-store';
import orderDestroyStore from '@/modules/order/order-destroy-store';
import orderFormStore from '@/modules/order/order-form-store';
export default {
  namespaced: true,
  modules: {
    destroy: orderDestroyStore,
    list: orderListStore,
    view: orderViewStore,
    form: orderFormStore
  },
};
