<template>
    <div class="manage-questions">
        <div class="side-left">
            <Container class="w-100" @drop="onDrop">
                <Draggable v-for="(value, index) in modelTemp.questions" :key="index">
                    <!-- <div class="single-drag" :class="checkRTL"> -->
                    <div class="single-drag">
                        <span class="drag-number">{{ index + 1 }}</span>
                        <div class="drag-content">
                            <span class="moreStr">{{modelTemp.questions[index].label}} - {{modelTemp.questions[index].questionType}}</span>
                            <span class="lessStr">{{modelTemp.questions[index].statusStr}} - {{modelTemp.questions[index].questionLanguage}}</span>
                        </div>
                        <div class="qPoint">
                            <el-input-number
                                    :controls="false"
                                    :value="modelTemp[fields.questions.name][index][fields.questionPoint.name]"
                                    @change="v=>QpSetF(index,v)"
                            ></el-input-number>
                        </div>
                        <router-link :to="{ path: editQuestionLink(index) }">
                            <span class="drag-button"><i class='el-icon-edit'></i></span>
                        </router-link>
                        <span
                                @click="removeExtra(index)"
                                class="drag-button"
                                :class="{ 'drag-button-disabled': isEditingIndex == index }"
                        ><i class="el-icon-delete"></i>
                        </span>
                    </div>
                </Draggable>
            </Container>
        </div>
        <div class="side-right">
            <router-link class="mar-left"
                         :to="{ path: newQuestionPage }">
                <el-button
                        icon="el-icon-plus"
                        type="primary"
                        :class="rtlAndPadding"
                >
                    <span class="card-title"> <app-i18n code="entities.question.new.title"></app-i18n> </span>
                </el-button>
            </router-link>
            <el-button
                    :disabled="saveLoading"
                    icon="el-icon-fa-floppy-o"
                    :type="setSaveBtnType"
                    :class="{'m-top': rtlAndPadding }"
                    @click="doSaveQuestions"
            >
                <span class="card-title"> <app-i18n code="entities.exam.create.saveQuestions"></app-i18n> </span>
            </el-button>
            <div class="total-point m-top" >
                    <div class="drag-content">
                        <span class="moreStr point-underline"> {{ getTotalPoint }} </span>
                        <span class="lessStr"><app-i18n code="entities.exam.create.totalPoint"></app-i18n></span>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {Container, Draggable} from 'vue-smooth-dnd';
    import {mapActions, mapGetters} from "vuex";
    import { ExamModel } from '@/modules/exam/exam-model';
    const { fields } = ExamModel;
    export default {
        name: "manage-questions",
        components: {
            Draggable,
            Container
        },
        props: ['model', 'record'],
        data() {
            return {
                modelTemp: this.model,
                isEditingIndex: null,
                deletedQuestions: [],
                warnForSaveQuestion: false
            }
        },
        computed: {
            ...mapGetters({
                rtlAndPadding: 'exam/form/rtlAndPadding',
                saveLoading: 'exam/form/saveLoading',
            }),
            newQuestionPage() {
                return `/question/new/${this.record.id}`;
            },
            fields() {
                return fields;
            },
            setSaveBtnType() {
                if (this.warnForSaveQuestion || this.deletedQuestions.length > 0)
                    return 'danger';
                else 
                    return 'primary';
            },
            getTotalPoint() {
                let total = 0;
                for (const key in this.modelTemp.questions) {
                   total += this.modelTemp.questions[key].questionPoint;
                }
                return total;
            }
        },
        methods: {
            ...mapActions({
                doUpdateManualQuestions: 'exam/form/doUpdateManualQuestions',
            }),
            editQuestionLink(index){
                return `/question/${this.modelTemp.questions[index].id}/edit/${this.record.id}`;
            },
            doSaveQuestions(){
                this.warnForSaveQuestion = false;
                const {id, questions} = this.modelTemp;
                this.doUpdateManualQuestions({id, values: questions, deleted: this.deletedQuestions})
                this.deletedQuestions = [];
            },
            QpSetF(index, value){
                this.warnForSaveQuestion = true;
                this.modelTemp[fields.questions.name][index][fields.questionPoint.name] = value
            },
            editInputLeave(index) {
                this.editExtra(index);
            },
            removeExtra(index) {
                if (this.isEditingIndex != index) {
                    this.deletedQuestions.push(this.model.questions[index].id);
                    this.model.questions.splice(index, 1);
                }
            },
            editExtra(index) {
                if (this.isEditingIndex === index) {
                    this.isEditingIndex = null;
                } else {
                    this.isEditingIndex = index;
                }
            },
            onDrop(dropResult) {
                this.modelTemp.questions = this.applyDrag(
                    this.modelTemp.questions,
                    dropResult
                );
                //todo emit model
            },
            applyDrag(arr, dragResult) {
                const {removedIndex, addedIndex} = dragResult;
                if (removedIndex === null && addedIndex === null) return arr;

                const result = [...arr];
                let itemToAdd = null;

                if (removedIndex !== null) {
                    itemToAdd = result.splice(removedIndex, 1)[0];
                }

                if (addedIndex !== null) {
                    result.splice(addedIndex, 0, itemToAdd);
                }

                return result;
            },
        },
    }
</script>

<style scoped>
    .manage-questions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .side-left {
        flex: 3;
        display: flex;
        justify-content: stretch;
    }

    .side-right {
        flex: 1;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .single-drag {
        padding: 0.5rem;
        font-size: 15px;
        line-height: 1.35;
        background-color: #f0f2f5;
        border-radius: 0.5rem;
        margin: 0 1rem 1rem 1rem;
        display: flex;
        align-items: center;
    }

    .drag-number {
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        color: white;
        background-color: #1c295c;
        margin: 0 0.5rem;
    }

    .drag-button {
        padding: 0.5rem;
        border-radius: 0.25rem;
    }
    .drag-button:hover {
        cursor: pointer;
        background-color: #a51e36;
        color: white;
    }
    .drag-button-disabled {
        opacity: 0.5;
    }
    .drag-button-disabled:hover {
        cursor: no-drop !important;
        background-color: transparent !important;
    }
    .drag-content {
        margin: 0 auto;
    }

    .qPoint{
        width: 60px;
        margin-right: 1rem;
    }

    .moreStr{
        display: flex;
        justify-content: center;
    }

    .lessStr{
        font-size: 13px;
        display: flex;
        justify-content: center;
    }

    .m-top{
        margin-top: 1rem;
    }
    .total-point {
      width: 145px;
      border: 1px solid gray;
      border-radius: 100px;
      padding: 5px;
    }
    .point-underline {
      margin-bottom: 2px;
    }
</style>

<style>
    .qPoint input {
        font-weight: bold;
    }
</style>