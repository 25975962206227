import supportRequestsListStore from '@/modules/support-requests/support-requests-list-store';
import supportRequestsViewStore from '@/modules/support-requests/support-requests-view-store';
import supportRequestsFormStore from '@/modules/support-requests/support-requests-form-store';
import supportRequestsDestroyStore from '@/modules/support-requests/support-requests-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: supportRequestsDestroyStore,
    form: supportRequestsFormStore,
    list: supportRequestsListStore,
    view: supportRequestsViewStore,
  },
};
