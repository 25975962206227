<template>
  <div>
    <app-autocomplete-one-input
      :fetchFn="fetchFn"
      v-if="mode !== 'multiple'"
      v-model="model"
    ></app-autocomplete-one-input>
    <app-autocomplete-many-input-ls
      :disabled="disabled"
      key="exampapermany"
      :fetchFn="fetchFn"
      @fetched="onfetched"
      v-if="mode === 'multiple'"
      v-model="model"
      :placeholder="placeholder"
    ></app-autocomplete-many-input-ls>
    <portal to="modal">
      <!--      <app-score-output-form-modal-->
      <!--        :visible="dialogVisible"-->
      <!--        @close="onModalClose"-->
      <!--        @success="onModalSuccess"-->
      <!--        v-if="dialogVisible"-->
      <!--      ></app-score-output-form-modal>-->
    </portal>
  </div>
</template>

<script>
//import ScoreOutputFormModal from '@/modules/score-output/components/score-output-form-modal';
import { ScoreOutputPermissions } from '@/modules/score-output/score-output-permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'app-score-output-autocomplete-input',
  props: ['value', 'mode', 'fetchFn', 'mapperFn', 'showCreate', 'disabled', 'placeholder'],

  // components: {
  //   [ScoreOutputFormModal.name]: ScoreOutputFormModal,
  // },

  data() {
    return {
      dialogVisible: false
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    model: {
      get: function() {
        return this.value;
      },

      set: function(value) {
        this.$emit('input', value);
      }
    },

    hasPermissionToCreate() {
      return new ScoreOutputPermissions(this.currentUser).create;
    }
  },

  methods: {
    doOpenModal() {
      this.dialogVisible = true;
    },

    onfetched(value) {
      this.$emit('fetched', value);
    },

    onModalSuccess(record) {
      if (this.mode === 'multiple') {
        this.model = [...this.model, this.mapperFn(record)];
      } else {
        this.model = this.mapperFn(record);
      }

      this.onModalClose();
    },
    onModalClose() {
      this.dialogVisible = false;
    },
  }
};
</script>

<style>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
