import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const ScoreBase = () =>
  import('@/modules/score-output/view/score-base.vue');
const AssignPapers = () =>
  import('@/modules/score-output/view/assign-papers.vue');
const ScoreOutputListPage = () =>
  import(
    '@/modules/score-output/view/score-output-list-page.vue'
  );
// const ScoreOutputFormPage = () =>
//     import('@/modules/score-output/components/score-output-form-page.vue');
const ScoreOutputViewPage = () =>
  import(
    '@/modules/score-output/components/score-output-view-page.vue'
  );
export default [
  {
    name: 'scoreOutputCommon',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'assignPapers',
        path: '/assign-papers',
        component: AssignPapers,
        meta: {
          auth: true,
          permission: Permissions.values.examOutputRead,
        },
      },
      {
        name: 'scoreOutput',
        path: '/score-output',
        component: ScoreOutputListPage,
        meta: {
          auth: true,
          permission: Permissions.values.scoreOutputView,
        },
      },
      {
        name: 'scoreOutputEdit',
        path: '/score-output/:id/edit',
        component: ScoreBase,
        meta: {
          auth: true,
          permission: Permissions.values.scoreOutputEdit,
        },
        props: true,
      },
      {
        name: 'scoreOutputView',
        path: '/score-output/:id',
        component: ScoreOutputViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.scoreOutputView,
        },
        props: true,
      },
    ],
  },
];
