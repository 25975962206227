import { ExamModel } from '@/modules/exam/exam-model';

const { fields } = ExamModel;

export default [
  fields.name,
  fields.examUpperLimit,
  fields.examLowerLimit,
  fields.duration,
  fields.examType,
  fields.examLanguage,
  fields.isFree,
  fields.price,
  fields.fixQuestions,
  fields.unScored,
  fields.createdAt,
];
