import axiosInstance from '@/shared/axios/ihu-axios';
import { getLanguageCode } from '@/i18n';
const lang = "tr-en".includes(getLanguageCode()) ? getLanguageCode():'en';

export class IhuPaymentService {

  static async getHTMLContent(merchantOrderNo){
    const response = await axiosInstance.get(
        `/card/3d/` + merchantOrderNo
    );
    return response.data;
  }

  static async askPaymentResult(merchantOrderNo, hasDialogClosed){
    return new Promise((resolve)=>{
      const intervalId = setInterval(async () => {
        const retVal = {
          isSuccess: false,
          message: ''
        }
        if(hasDialogClosed()){
          clearInterval(intervalId);
          retVal.isSuccess = false;
          retVal.message = "Unlock exam page closed";
          resolve(retVal);
        }
        const res = await this._askPaymentResult(merchantOrderNo);
        const status = res.result; //continues || fail || ok
        retVal.message = res.mesaj;
        if(status === 'fail'){
          clearInterval(intervalId);
          retVal.isSuccess = false;
          resolve(retVal);
        }
        else if(res.result === 'ok'){ //Successfull
          clearInterval(intervalId);
          retVal.isSuccess = true;
          resolve(retVal);
        }
        else if(status !== 'continues'){
          clearInterval(intervalId);
        }
      }, 2500);
    })
  }

  static async _askPaymentResult(merchantOrderNo){
    const formData = new FormData();
    formData.append('lang',lang);

    const response = await axiosInstance.post(
        `/card/status/`+ merchantOrderNo,
        formData,
    );
    //console.log("Payment Result: ", response.data);
    return response.data;
  }


}
