import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const UserDetailListPage = () =>
  import(
    '@/modules/user-detail/components/user-detail-list-page.vue'
  );
const UserDetailFormPage = () =>
  import(
    '@/modules/user-detail/components/user-detail-form-page.vue'
  );
const UserDetailViewPage = () =>
  import(
    '@/modules/user-detail/components/user-detail-view-page.vue'
  );
const UserDetailImporterPage = () =>
  import(
    '@/modules/user-detail/components/user-detail-importer-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'userDetail',
        path: '/user-detail',
        component: UserDetailListPage,
        meta: {
          auth: true,
          permission: Permissions.values.userDetailView,
        },
      },
      {
        name: 'userDetailNew',
        path: '/user-detail/new',
        component: UserDetailFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.userDetailCreate,
        },
      },
      {
        name: 'userDetailImporter',
        path: '/user-detail/import',
        component: UserDetailImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.userDetailImport,
        },
      },
      {
        name: 'userDetailEdit',
        path: '/user-detail/:id/edit',
        component: UserDetailFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.userDetailEdit,
        },
        props: true,
      },
      {
        name: 'userDetailView',
        path: '/user-detail/:id',
        component: UserDetailViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.userDetailView,
        },
        props: true,
      },
    ],
  },
];
