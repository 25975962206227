<template>
  <div>
    <el-button
      class="btn-next"
      type="primary"
      round
      @click="goNext"
      v-if="showNext"
    >
      Next
      <i
        class="text-contrast raw-icon el-icon-arrow-right"
      ></i>
    </el-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'next',
  computed: {
    ...mapGetters({
      showNext: 'onlineExam/main/showNext',
    }),
  },
  methods: {
    ...mapActions({
      goNext: 'onlineExam/main/goNext',
    }),
    // sleepGoNext() {
    //   const sleep = (milliseconds) => {
    //     return new Promise((resolve) =>
    //       setTimeout(resolve, milliseconds),
    //     );
    //   };
    //   sleep(1).then(() => {
    //     //do stuff
    //     this.goNext();
    //   });
    // },
  },
  // created() {
  //   if (process.env.NODE_ENV !== 'production') {
  //     this.$store.subscribe((mutation, state) => {
  //       console.log('mutation');
  //       console.log(mutation);
  //       console.log(state);
  //     });
  //   }
  // },
};
</script>

<style scoped>
.el-button.btn-next:hover,
.el-button.btn-break:hover {
  background-color: #981c32;
  border-color: #711525;
  color: white;
  box-shadow: 0 14px 26px -12px rgba(165, 30, 54, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12),
    0 8px 10px -5px rgba(165, 30, 54, 0.2);
}
.btn-next {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.el-button.btn-break,
.el-button.btn-next {
  color: #a51e36;
  font-weight: 500;
  background-color: white;
  border-color: #a51e36;
  box-shadow: 0 2px 2px 0 rgba(165, 30, 54, 0.14),
    0 3px 1px -2px rgba(165, 30, 54, 0.2),
    0 1px 5px 0 rgba(165, 30, 54, 0.12);
  text-transform: capitalize;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}

.btn-break {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
</style>
