export default {
  namespaced: true,

  state: {
    //findLoading: false,
  },

  getters: {
    //findLoading: (state) => !!state.findLoading,
  },

  mutations: {
    // RESET(state) {
    //   state.findLoading = false;
    // },
    // FIND_STARTED(state) {
    //   state.record = null;
    //   state.findLoading = true;
    // },
    //
    // FIND_SUCCESS(state, payload) {
    //   state.record = payload;
    //   state.findLoading = false;
    // },
    //
    // FIND_ERROR(state) {
    //   state.record = null;
    //   state.findLoading = false;
    // },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
  },
};
