import userDetailListStore from '@/modules/user-detail/user-detail-list-store';
import userDetailViewStore from '@/modules/user-detail/user-detail-view-store';
import userDetailImporterStore from '@/modules/user-detail/user-detail-importer-store';
import userDetailFormStore from '@/modules/user-detail/user-detail-form-store';
import userDetailDestroyStore from '@/modules/user-detail/user-detail-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: userDetailDestroyStore,
    form: userDetailFormStore,
    list: userDetailListStore,
    view: userDetailViewStore,
    importer: userDetailImporterStore,
  },
};
