import { QuestionService } from '@/modules/question/question-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class QuestionField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/question',
      Permissions.values.questionRead,
      QuestionService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record,
          label: record,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/question',
      Permissions.values.questionRead,
      QuestionService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        let questionPoint = 10; // #DEFAULTQUESTIONPOINT
        if(record.answer){
            questionPoint = record.answer.questionPoint || 10
        }
        return {
          id: record.id,
          label: record.keywords || record.id,
          questionPoint: questionPoint,
          statusStr: options.questionModelFields.questionStatus.forPresenter(record.questionStatus.toString()),
          questionType: record.questionType.questionType,
          questionLanguage: record.questionLanguage.code,
        };
      },
      options,
    );
  }
}
