export default {
  name: 'wordCountFilter',
  implementation(value) {
    if (value) {
      let wordCount = value.trim().split(/\s+/).length;
      return wordCount;
    }
    return 0;
  },
};
