<template>
  <div>
    <div class="many-input-ls-style">
      <app-autocomplete-one-input
        :fetchFn="fetchFn"
        v-model="itemToAdd"
        key="iamone"
        @input="itemSelected"
        @onfocus="onFocus"
        :placeholder="placeholder"
        v-loading="selectTeacherLoading"
      ></app-autocomplete-one-input>
      <el-button
        @click="doOpenModal()"
        icon="el-icon-plus"
        style="margin-left: 16px"
        type="primary"
        v-if="hasPermissionToCreate && showCreate"
        :disabled="disabled"
        >{{ text }}</el-button
      >
    </div>
    <div style="display: flex;">
      <app-autocomplete-many-input
        :disabled="disabled"
        key="exampapermany"
        :fetchFn="fetchFn"
        @fetched="onfetched"
        v-model="model"
        :dropdown="false"
      ></app-autocomplete-many-input>
      <div>
        <span class="clear-all-button" @click="clearAll"
          ><i class="el-icon-fa-trash-o"></i
        ></span>
      </div>
    </div>
  </div>
</template>
<script>
import { ExamOutputPermissions } from '@/modules/exam-output/exam-output-permissions';
import { mapGetters } from 'vuex';
export default {
  name: 'app-autocomplete-many-input-ls',
  props: {
    value: {
      type: Array
    },
    fetchFn: {
      type: Function
    },
    inMemoryFilter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showCreate: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    text: {
      type: String,
    }
  },

  data() {
    return {
      itemToAdd: null,
      selectTeacherLoading: false
    };
  },

  mounted() {
    console.log(this.showCreate);
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    model: {
      get: function() {
        return this.value;
      },

      set: function(value) {
        this.$emit('input', value);
      }
    },

    hasPermissionToCreate() {
      return new ExamOutputPermissions(this.currentUser).create;
    }
  },

  methods: {
    itemSelected(value) {
      if (!value) return;
      const items = this.model;
      const isItem = items.find(item => item.id === value.id);
      if (isItem) {
        const itemIndex = items.indexOf(isItem);
        items.splice(itemIndex, 1);
      }
      items.unshift(value);
      this.selectTeacherLoading = true;
      setTimeout(() => {
        this.selectTeacherLoading = false;
      }, 200);
    },
    onfetched(value) {
      this.$emit('fetched', value);
    },
    clearAll() {
      this.model = [];
    },
    onFocus() {
      this.itemToAdd = null;
    }
  }
};
</script>
<style>
.many-input-ls-style {
  display: flex;
  margin-bottom: 15px;
}

.el-form-item .el-form-item {
  margin-bottom: 22px;
}
span.clear-all-button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  line-height: 0px;
  margin-left: 5px;
}
span.clear-all-button:hover {
  cursor: pointer;
  background-color: #a51e36;
  color: white;
}
</style>
